export class Proveedor {
    id: number;
    idPais: number;
    razonSocial: string;
    logo: Uint8Array;
    srcImg: any;

    constructor(razonSocial: string){
      this.razonSocial= razonSocial;
    }
  }