<mat-card style="outline: none; box-shadow: none">
  <div class="connections text-center">
    <div class="connections__body">
      <div class="connections__title">
        <span class="span_title">{{ "ACEPTAR_CONEXION" | translate }} </span>
        <a
          href="https://ayuda.planexware.net/instructivos/doc_a_usuario.pdf"
          target="_conennections"
          class="connections__title__link"

          data-title="Haga click para conocer más acerca de la configuración de visualización de documentos a usuarios"
        >
          <fa-icon
            [icon]="['fas', 'question-circle']"
            style="color: rgb(0 0 0 )"
          ></fa-icon>
        </a>
      </div>
      <div class="connections__page-info">
        {{ "TIPO_DE_DOCUMENTO" | translate }}
        <span class="connections__page-badge">{{
          connectionsService.currentPage
        }}</span>
        {{ "de" | translate }}
        <span class="connections__page-badge">{{
          connectionsService.totalPages
        }}</span>
      </div>
      <div *ngIf="connectionsService.editingDoc">
        <p class="connections__document-info" style="font-size: 13px">
          {{ "PODRA" | translate }}
          <span class="font-weight-bold">
            {{ connectionsService.editingDoc.sentido | translate }}
            {{
              connectionsService.editingDoc.tipoDocumento.resourceKey
                | translate
            }}
            {{
              connectionsService.editingDoc.tipoDocumento.version | translate
            }}
          </span>
          {{ "de" | translate }}
          <span class="font-weight-bold">
            {{ connectionsService.editingConnection.interlocutor.razonSocial }}
          </span>
        </p>
      </div>

      <div
        class="connections__spinner m-5"
        *ngIf="connectionsService.isLoading"
      >
        <fa-icon
          [spin]="true"
          [icon]="['fas', 'sync-alt']"
          transform="grow-20"
        ></fa-icon>
      </div>

      <div *ngIf="!connectionsService.isLoading" class="info__user_wrapper">
        <!-- ####### forbidden users table -->
        <div
          class="user-table"
          *ngIf="
            connectionsService.connectionPermissions[
              connectionsService.documentIndex
            ]
          "
        >
          <div class="user-table__title">
            {{ "PermitaElAccesoA" | translate }}
            <span class="font-weight-bold">
              {{
                connectionsService.editingDoc.tipoDocumento.resourceKey
                  | translate
              }}
              {{
                connectionsService.editingDoc.tipoDocumento.version | translate
              }}
            </span>
          </div>
          <div
            class="user-table__card"
            *ngFor="
              let forbiddenUser of connectionsService.connectionPermissions[
                connectionsService.documentIndex
              ].forbidden;
              let index = index
            "
          >
            <ngx-avatar
              name="{{
                forbiddenUser.usuario.nombre +
                  ' ' +
                  forbiddenUser.usuario.apellido
              }}"
              size="40"
              initialsSize="2"
            ></ngx-avatar>
            <div class="user-table__info">
              <div
                class="info__name"
                [ngClass]="
                  connectionsService.isGuest(forbiddenUser.usuario.email)
                    ? 'info__name--guest'
                    : ''
                "
              >
                <fa-icon
                  [icon]="['fas', 'star']"
                  class="info__name__icon show"
                ></fa-icon>
                <span
                  >{{ forbiddenUser.usuario.nombre }}
                  {{ forbiddenUser.usuario.apellido }}</span
                >
              </div>
              <div class="info__mail small">
                {{ forbiddenUser.usuario.email }}
              </div>
            </div>
            <div>
              <div class="custom-control custom-switch custom-switch-md">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="{{ forbiddenUser.usuario.id }}"
                  [(ngModel)]="forbiddenUser.tieneAcceso"
                  [ngModelOptions]="{ standalone: true }"
                />
                <label
                  class="custom-control-label"
                  for="{{ forbiddenUser.usuario.id }}"
                ></label>
              </div>
            </div>

            <div
              class="user-table__reminder-check"
              *ngIf="
                connectionsService.editingDoc.tipoDocumento.resourceKey ==
                  'Factura' &&
                (connectionsService.editingDoc.tipoDocumento.version ==
                  'PUBLICA_FAC' ||
                  connectionsService.editingDoc.tipoDocumento.version ==
                    'LADRILLO_ARG')
              "
            >
              <label
                for="checkbox-{{ forbiddenUser.usuario.id }}"
                class="user-table__reminder-check__label"
              >
                <input
                  type="checkbox"
                  id="checkbox-{{ forbiddenUser.usuario.id }}"
                  [(ngModel)]="forbiddenUser.recibeNotificaciones"
                  [ngModelOptions]="{ standalone: true }"
                />
                {{ "RecibirEmailsRecordatoriosXVencimiento" | translate }}
              </label>
            </div>
          </div>
        </div>

        <!-- ####### allowed users table -->
        <div
          class="user-table"
          *ngIf="
            connectionsService.connectionPermissions[
              connectionsService.documentIndex
            ]
          "
        >
          <div class="user-table__title">
            {{ "UsuariosConAccesoA" | translate }}
            <span class="font-weight-bold">
              {{
                connectionsService.editingDoc.tipoDocumento.resourceKey
                  | translate
              }}
              {{
                connectionsService.editingDoc.tipoDocumento.version | translate
              }}
            </span>
          </div>
          <div
            class="user-table__card"
            *ngFor="
              let permittedUser of connectionsService.connectionPermissions[
                connectionsService.documentIndex
              ].permitted;
              let index = index
            "
          >
            <ngx-avatar
              name="{{
                permittedUser.usuario.nombre +
                  ' ' +
                  permittedUser.usuario.apellido
              }}"
              size="40"
              initialsSize="2"
            ></ngx-avatar>
            <div class="user-table__info">
              <div
                class="info__name"
                [ngClass]="
                  connectionsService.isGuest(permittedUser.usuario.email)
                    ? 'info__name--guest'
                    : ''
                "
              >
                <fa-icon
                  [icon]="['fas', 'star']"
                  class="info__name__icon show"
                ></fa-icon>
                <span
                  >{{ permittedUser.usuario.nombre }}
                  {{ permittedUser.usuario.apellido }}</span
                >
              </div>
              <div class="info__mail small">
                {{ permittedUser.usuario.email }}
              </div>
            </div>
            <div class="user-table__icon user-table__icon--permitted">
              <fa-icon [icon]="['fas', 'eye']" transform="grow-12"></fa-icon>
            </div>
            <div
              class="user-table__reminder-check"
              *ngIf="
                connectionsService.editingDoc.tipoDocumento.resourceKey ==
                  'Factura' &&
                (connectionsService.editingDoc.tipoDocumento.version ==
                  'PUBLICA_FAC' ||
                  connectionsService.editingDoc.tipoDocumento.version ==
                    'LADRILLO_ARG')
              "
            >
              <label
                for="checkbox-{{ permittedUser.usuario.id }}"
                class="user-table__reminder-check__label"
              >
                <input
                  type="checkbox"
                  id="checkbox-{{ permittedUser.usuario.id }}"
                  [(ngModel)]="permittedUser.recibeNotificaciones"
                  [ngModelOptions]="{ standalone: true }"
                />
                {{ "RecibirEmailsRecordatoriosXVencimiento" | translate }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="connections__navigation">
        <button
          (click)="closeModal()"
          style="background: none; color: #c44ace; border: none !important"
          *ngIf="connectionsService.currentPage === 1"
        >
          {{ "CANCELAR_Y_VOLVER" | translate }}
        </button>
        <button
          class="connections__buttons--back"
          *ngIf="connectionsService.currentPage !== 1"
          (click)="connectionsService.previousDocument()"
        >
          {{ "VOLVER" | translate }}
        </button>
        <button
          class="connections__buttons--next"
          *ngIf="
            connectionsService.currentPage !== connectionsService.totalPages
          "
          (click)="connectionsService.nextDocument()"
        >
          {{ "CONFIRMAR" | translate }}
        </button>
        <button
          class="connections__buttons--accept"
          *ngIf="
            connectionsService.currentPage == connectionsService.totalPages
          "
          (click)="
            connectionsService.submitPermissions(
              connectionsService.editingConnection.interlocutor.razonSocial
            )
          "
        >
          {{ "ACEPTAR" | translate }}
        </button>
      </div>
    </div>
  </div>
</mat-card>
