import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Injectable()
export class AppHttpInterceptorResponse implements HttpInterceptor {
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(httpRequest).pipe(
      filter(event => event instanceof HttpResponse),
      map((event: HttpResponse<any>) =>
      event.clone({
        headers: event.headers
          .set('Permissions-Policy', 'accelerometer=(), camera=(), geolocation=(), gyroscope=(), magnetometer=(), microphone=(), payment=(), usb=(), interest-cohort=()')
          .set('Strict-Transport-Security', 'max-age=31536000; includeSubdomains')
          .set('X-Frame-Options', 'SAMEORIGIN')
          .set('Referrer-Policy', 'strict-origin-when-cross-origin')
          .set('X-Content-Type-Options', 'nosniff')
          .set('X-Xss-Protection', '1; mode=block')
          .set('Content-Security-Policy', 'report-uri /csp-violation-report-endpoint/')
      }))
    );

  }
}
