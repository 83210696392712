import { Component, Inject, NgModule, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatosSobre } from '../modelos/DatosSobre';
import { DocumentoAsociado } from '../modelos/DocumentoAsociado';
import { AsociadorSncyNcService } from '../servicios/asociador-sncy-nc.service';

export interface Data {
  documentos: DocumentoAsociado;
}

@Component({
  selector: 'app-dialog-view-documentos-asociados',
  templateUrl: './dialog-view-documentos-asociados.component.html',
  styleUrls: ['./dialog-view-documentos-asociados.component.scss']
})
export class DialogViewDocumentosAsociadosComponent implements OnInit {
  isDownLoading: boolean = false;
  constructor(
    public dialogo: MatDialogRef<any>,
    public servicioAsociador: AsociadorSncyNcService,
    @Inject(MAT_DIALOG_DATA) public data: Data) { }

  ngOnInit(): void {
  }

  onClickGetDocumentoPDFPopup(idSobre: number, idPaisSobre: number, tipoDocumento: string, visualizador: string)  {
    this.isDownLoading = true;
    let datos: DatosSobre = this.mapeadorDatoSobre(idSobre, idPaisSobre, tipoDocumento, visualizador);

    this.servicioAsociador.postObtenerDocumentoPDFGrilla(datos).subscribe((
    documento: any) => 
    {
      if (documento!= null) {
        this.isDownLoading = false;
        var arrrayBuffer = this.base64ToArrayBuffer(documento.contenidoBinario); //data is the base64 encoded string    
        var blob = new Blob([arrrayBuffer], {type: "application/pdf"});
        var linkNew = window.URL.createObjectURL(blob);
        window.open(linkNew, '_blank');
      }
    });        
  }

  base64ToArrayBuffer(base64:any) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
  }

  mapeadorDatoSobre(idSobre: number, idPaisSobre: number, tipoDocumento: string, visualizador: string): DatosSobre {
    let datos: DatosSobre = new DatosSobre();

    datos.idDocumentoSobre=idSobre; 
    datos.idPaisDocumentoSobre=idPaisSobre;
    datos.tipoDocumento=tipoDocumento;
    datos.visualizador=visualizador;
    datos.formato = 'PDF';

    return datos;
  }  
}
