<div >
    <h1 style="display: center;">{{ 'DOCUMENTOS_ASOCIADOS' | translate }}</h1>
    <div *ngFor="let Documento of data.documentos" class="text-wrapper">
        <div  colspan="1" class="display-inline ">
            <div colspan="1" class="display-inline">
                <div style="width: 24px; height: 24px" *ngIf="!Documento.esDocumentoAsociador"></div>
                <mat-icon *ngIf="Documento.esDocumentoAsociador">device_hub</mat-icon>
            </div>
            <div  colspan="1" class="display-inline">
                <mat-icon *ngIf="Documento.id != 0" class="rotate-icon">link</mat-icon>
                <mat-icon *ngIf="Documento.id == 0" class="rotate-icon" style="color:lightgray">link</mat-icon>
            </div>
        </div>
 	    <div colspan="1" class="display-inline titulo-cell-center alineacion-vertical" *ngIf="Documento.id == 0">
            {{ Documento.tipoDocumento | translate }} N° {{ Documento.numeroDocumento }} 
        </div>
        <div colspan="1" class="display-inline titulo-cell-center alineacion-vertical" *ngIf="Documento.id != 0" onmouseover="" style="cursor: pointer;"
            (click)="onClickGetDocumentoPDFPopup(Documento.id, Documento.idPais, Documento.tipoDocumentoAsociado, Documento.visualizador)">
            <u>{{ Documento.tipoDocumento | translate }} N° {{ Documento.numeroDocumento }}</u>
            <mat-spinner diameter="34" *ngIf="buscandoPdfPopup"></mat-spinner>
        </div>
    </div>
    <p></p>
    <mat-progress-bar mode="indeterminate" *ngIf="isDownLoading"></mat-progress-bar>
    <p></p>
    <div  colspan="2" class="display-center-button">
        <button class="black-white" mat-stroked-button color="primary" mat-dialog-close>
            {{ ("CERRAR" | translate).toUpperCase()}}
        </button>
    </div>   
</div>

