<div style="text-align: center;">
    <img height="25" src="assets/krikos360.new.svg" />
</div>
<br>
<div>
    <div>
        <p style="font-size: 14px; font-family: montserrat-medium; text-align: left; padding-left: 15px;" mat-dialog-title>{{"CREDENCIALES_SOCIALES_TEXTO_IRREVERSIBLE" | translate}}</p>
    </div>
    <div>
        <p style="font-size: 14px; font-family: montserrat-medium;" mat-dialog-title>{{"DeseaContinuar" | translate}}</p>
    </div>
    <div fxLayout="column" style ="text-align: center;display: flex; justify-content: center; align-items: center;">
        <button class="m-2" mat-raised-button color="primary" (click)="aceptar()" style = "max-width: 170px; width: 170px;">
            {{ ("BOTON_CONTINUAR" | translate).toUpperCase() }}
        </button>
        <button class="m-2 cerrar" mat-raised-button color="primary" style="background: none; color: #c44ace;font-size: 12px;font-family: 'montserrat-semibold'; max-width: 170px; border: none; box-shadow: none;" (click)="rechazar()">
            {{ ("CANCELAR_Y_VOLVER" | translate).toUpperCase() }}
        </button>
    </div>
</div>