import { Component,OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Router, NavigationEnd } from '@angular/router'
import { ToasterService } from 'src/app/core/services/toaster.service'
import { GenericToasterService } from 'src/app/core/services/generic-toaster.service'
import { AuthService } from 'src/app/core/services/auth.service'

@Component({
  selector: 'app-main-k360',
  templateUrl: './main-k360.component.html',
  styleUrls: ['./main-k360.component.scss']
})
export class MainK360Component implements OnInit {
  public paisKey: string = "";

  constructor(
    private translate: TranslateService,
    public toasterService: ToasterService,
    public genericToasterService: GenericToasterService,
    public authService: AuthService,
    private router: Router) { }

  ngOnInit(): void {
    this.authService.isAuthenticated();
    if(this.router.url == '/main'){
      this.router.navigate(['/main/home']);
    }

    let html = document.getElementsByTagName("app-root")[0];
    let url = window.location.pathname;

    url.includes("login") && html.classList.contains("no-login") == false
    ? html.classList.add("login") : html.classList.add("no-login")

    if(url.includes("main") )
    html.classList.remove("login")

    this.authService.getPais().subscribe(
      async result => {
        this.paisKey = result.nombrePais;
      }
    );;
  }

}
