<h1 class="titulo-feature">
    {{ 'GestionSolicNCredito' | translate }}
</h1>


<mat-card class="buscador marginLR" [formGroup]="parametrosDeBusqueda">
    <div class="buscador__controls">

        <div class="buscador__subcontrols">
            <!-- FECHA DESDE -->
            <mat-form-field appearance="outline">
                <mat-label>{{ 'FECHA_DESDE' | translate }}</mat-label>
                <input matInput [matDatepicker]="fechaDesde" formControlName="fechaDesde" id="fechaDesde">
                <mat-datepicker-toggle class="color-icon-calendar" matSuffix [for]="fechaDesde">
                </mat-datepicker-toggle>
                <mat-datepicker #fechaDesde></mat-datepicker>
            </mat-form-field>

            <!-- FECHA HASTA -->
            <mat-form-field appearance="outline">
                <mat-label>{{ 'FECHA_HASTA' | translate }}</mat-label>
                <input matInput [matDatepicker]="fechaHasta" formControlName="fechaHasta" id="fechaHasta">
                <mat-datepicker-toggle class="color-icon-calendar" matSuffix [for]="fechaHasta">
                </mat-datepicker-toggle>
                <mat-datepicker #fechaHasta></mat-datepicker>
            </mat-form-field>
        </div>

        <!-- INTERLOCUTOR -->
        <mat-form-field appearance="outline">
            <mat-label>{{ 'INTERLOCUTOR' | translate }}</mat-label>

            <mat-chip-list #interlocutoresChips aria-label="interlocutor">
                <mat-chip *ngFor="let chip of parametrosDeBusqueda.controls.interlocutores.value" selectable="true"
                    removable="true" (removed)="removeInterlocutor(chip)" class="texto-interlocutor-selected">
                    {{ chip.razonSocial }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input #interlocutoresInput [formControl]="interlocutorControl" [matAutocomplete]="interlocutorPanel"
                    [matChipInputFor]="interlocutoresChips" (blur)="onBlurInterlocutor()">
            </mat-chip-list>

            <mat-autocomplete #interlocutorPanel="matAutocomplete" (optionSelected)="selectInterlocutor($event)"
                (closed)="onClosePanelInterlocutor()">
                <mat-option *ngFor="let option of interlocutoresOptions | async" [value]="option" class="texto-interlocutor">
                    {{option.razonSocial}}
                </mat-option>
            </mat-autocomplete>

            <mat-hint>{{ "SELECCIONE_INTERLOCUTOR" | translate }}</mat-hint>
        </mat-form-field>

        <!-- Estado conciliacion -->
        <mat-form-field appearance="outline" style="width: 32%;">
            <mat-label>{{ "ESTADO_CONCILIACION" | translate }}</mat-label>
            <mat-select formControlName="estadoConciliacion" class="fuente-usada">
                <mat-option *ngFor="let opt of estadosConciliacion" [value]="opt" class="fuente-usada">
                    {{ opt.resourceKey.toLowerCase() | translate }}
                </mat-option>
            </mat-select>
            <mat-hint>{{"SELECCIONE_ESTADO_DE_CONCILIACION" | translate }}</mat-hint>
        </mat-form-field>

    </div>

    <div class="buscador__controls">

        <!-- MOTIVO SOLICITUD -->
        <mat-form-field appearance="outline" style="width: 35%;">
            <mat-label>{{ "MOTIVO" | translate }}</mat-label>
            <mat-select formControlName="motivoSolicitud" class="fuente-usada">
                <mat-option *ngFor="let opt of motivosSolicitud" [value]="opt" class="fuente-usada">
                    {{ opt.resourceKey | translate }}
                </mat-option>
            </mat-select>

            <mat-hint>{{ "SELECCIONE_MOTIVO_DE_SOLICITUD" | translate }}</mat-hint>
        </mat-form-field>

        <div class="buscador__subcontrols" style="width: 28%;">
            <!-- OPERADOR -->
            <mat-form-field appearance="outline">
                <mat-label>{{ "Operador" | translate }}</mat-label>
                <mat-select formControlName="operador" [errorStateMatcher]="matcherOperadorSaldo" class="fuente-usada">
                    <mat-option *ngFor="let opt of binarySelectOptions" [value]="opt" class="fuente-usada">
                        {{ opt }}
                    </mat-option>
                </mat-select>
                <mat-hint>{{"SELECCIONE_OPERADOR" | translate}}</mat-hint>
            </mat-form-field>

            <!-- SALDO -->
            <mat-form-field appearance="outline">
                <mat-label>{{ "Saldo" | translate }}</mat-label>
                <input type="text" aria-label="saldo" matInput formControlName="saldo"
                    oninput="this.value = this.value.replace(/[^\d,-]/g, '')"
                    [errorStateMatcher]="matcherOperadorSaldo">
                <mat-hint>{{ "INGRESE_SALDO" | translate }}</mat-hint>
            </mat-form-field>

            <div class="mensaje-error-campos-dobles" *ngIf="validarErrorOperadorYSaldo()">
                <span> {{ "AMBOS_CAMPOS_DEBEN_COMPLETARSE" | translate }}</span>
            </div>
        </div>

        <div class="buscador__subcontrols"  style="width: 32%;">
            <!-- TIPO DOCUMENTO -->
            <mat-form-field appearance="outline" style="width: 52%;">
                <mat-label>{{ "TIPO_DOCUMENTO" | translate }}</mat-label>
                <mat-select formControlName="tipoDocumento" [errorStateMatcher]="matcherTipoDocNroDocumento" class="fuente-usada">
                    <mat-option *ngFor="let opt of tiposDocumentosSncNc" [value]="opt" class="fuente-usada">
                        {{ opt.resourceKey | translate }}
                    </mat-option>
                </mat-select>
                <mat-hint>{{ "SELECCIONE_TIPO_DOCUMENTO" | translate }}</mat-hint>
            </mat-form-field>

            <!-- NUMERO DOCUMENTO -->
            <mat-form-field appearance="outline" style="width: 47%;">
                <mat-label>{{ "NUMERO_DOCUMENTO" | translate }}</mat-label>
                <input type="text" aria-label="numeroDocumento" matInput formControlName="numeroDocumento"
                    [errorStateMatcher]="matcherTipoDocNroDocumento">
                <mat-hint>{{ "INGRESE_NUMERO_DOCUMENTO" | translate }}</mat-hint>
            </mat-form-field>

            <div class="mensaje-error-campos-dobles" *ngIf="validarErrorTipoYNumeroDocumento()">
                <span> {{ "AMBOS_CAMPOS_DEBEN_COMPLETARSE" | translate }}</span>
            </div>
        </div>
    </div>

    <div class="buscador__buttons">
        <button mat-stroked-button btn-link class="sin-borde" color="primary" (click)="limpiar()">{{'Limpiar' | translate}}</button>
        <button mat-raised-button color="primary" class="buscador__buttons__buscar" (click)="buscarGruposConciliacionDesdeBoton()"
            *ngIf="!buscando">{{'Buscar' | translate}}</button>
        <mat-spinner diameter="34" *ngIf="buscando"></mat-spinner>
    </div>

</mat-card>

    <div class="marginRL space-en-boton">
          <button mat-stroked-button color="primary" class="buscador__buttons__exportar marginLR" mat-button [matMenuTriggerFor]="exportar">
            {{ 'Exportar' | translate }}
          </button>

          <mat-menu class="cajaMenu" #exportar="matMenu" id="exportar">
            <button mat-menu-item (click)="exportarTodo()">
              <span>{{ 'EXPORTAR_TODO' | translate }}</span>
            </button>
            <button mat-menu-item (click)="exportarSeleccionados()" [disabled]="noExistenSeleccionados">
              <span>{{ 'EXPORTAR_SELECCIONADOS' | translate }}</span>
            </button>
          </mat-menu>
    </div>

<mat-progress-bar  mode="indeterminate" *ngIf="isDownLoading"></mat-progress-bar>
<div class="marginLR" style="margin-top: 5px;">
    <table mat-table #outerSort="matSort" [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8"
        matSort>
        <!-- Checkbox Column -->
        <ng-container matColumnDef="headerCheckbox">
            <th mat-header-cell *matHeaderCellDef style="width: 5%;">
                <mat-checkbox [formControl]="checkboxGral" (change)="masterToggle($event)"
                    [checked]="selection.hasValue() && isAllSelected()">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row" style="width: 5%;">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="controlMenuExportacion($event, selection, row)"
                    [checked]="selection.isSelected(row)">
                </mat-checkbox>
            </td>
        </ng-container>

        <!-- Intelocutor Column -->
        <ng-container matColumnDef="interlocutor">
            <th mat-header-cell *matHeaderCellDef class="titulo-grilla" style="width: 26%;"> {{ 'interlocutor' | translate }} </th>
            <td mat-cell *matCellDef="let element" class="textos-grilla" style="width: 26%;"> {{element.interlocutor}} </td>
        </ng-container>

        <!-- nroConciliacion Column -->
        <ng-container matColumnDef="nroConciliacion">
            <th mat-header-cell *matHeaderCellDef class="titulo-grilla titulo-header-cell-center"> {{ 'nroConciliacion' | translate }} </th>
            <td mat-cell *matCellDef="let element" class="textos-grilla tituloNumerico"> {{element.nroConciliacion}} </td>
        </ng-container>

        <!-- saldo Column -->
        <ng-container matColumnDef="saldo">
            <th mat-header-cell *matHeaderCellDef class="tituloNumerico titulo-grilla" style="padding-right: 20px;"> {{ 'saldo' | translate }} </th>
            <td mat-cell *matCellDef="let element" class="valorNumerico textos-grilla"> 
                {{ element.saldo != null ? '$' : '' }}{{ element.saldo | currency: 'ARS' : '' : '1.2-2' }}
            </td>
        </ng-container>

        <!-- estadoConciliacion Column -->
        <ng-container matColumnDef="estadoConciliacion">
            <th mat-header-cell *matHeaderCellDef class="titulo-header-cell-center titulo-grilla"> {{ 'estadoConciliacion' | translate }} </th>
            <td mat-cell *matCellDef="let element" class="textos-grilla ancho-estado"> 
                <span class="{{ element.estadoConciliacion.toLowerCase() }}">
                {{ element.estadoConciliacion.toLowerCase() | translate }} 
                </span>
            </td>
        </ng-container>

        <!-- flechita Column -->
        <ng-container matColumnDef="flechita">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <mat-icon *ngIf="element != expandedElement">expand_more</mat-icon>
                <mat-icon *ngIf="element == expandedElement">expand_less</mat-icon>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length" style="background: white;">
                <div class="example-element-detail" *ngIf="element.documentos?.data.length"
                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="inner-table mat-elevation-z8" *ngIf="expandedElement">
                        <table #innerTables mat-table #innerSort="matSort" [dataSource]="element.documentos" matSort>
                            <ng-container matColumnDef="tipoDocumento">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="titulo-grilla" style="width: 22%; padding-left: 40px;"> {{ 'tipoDocumento' | translate }}
                                </th>
                                <td mat-cell *matCellDef="let element" class="textos-grilla" style="width: 22%;"> {{ element.tipoDocumento | translate }} </td>
                            </ng-container>

                            <ng-container matColumnDef="motivo">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="titulo-grilla" style="width: 18;"> {{ 'motivo' | translate }}
                                </th>
                                <td mat-cell *matCellDef="let element" class="textos-grilla" style="width: 18%;"> {{ element.motivo | translate }} </td>
                            </ng-container>

                            <ng-container matColumnDef="numeroDocumento">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="titulo-grilla" style="text-align: center; width: 10%;"> {{ 'numeroDocumento' | translate }}
                                </th>
                                <td mat-cell *matCellDef="let element" onmouseover="" class="textos-grilla" style="cursor: pointer !important; width: 10%;"
                                    (click)="onClickGetDocumentoPDFGrilla(element.idDocumentoSobre, element.idPaisDocumentoSobre, element.tipoDocumento, element.visualizador)">
                                    <u>{{ element.numeroDocumento }} </u>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="fecha">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="titulo-grilla centrado" > {{ 'fecha' | translate }}</th>
                                <td mat-cell *matCellDef="let element" class="textos-grilla" style="width: 10%; text-align: center;"> {{ element.fecha }} </td>
                            </ng-container>

                            <ng-container matColumnDef="importe">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="titulo-grilla" style="width: 11%;"> {{ 'importe' | translate }}
                                </th>
                                <td mat-cell *matCellDef="let element" class="valorNumerico-segunda-fila textos-grilla" style="width: 11%; text-align: right; padding-right: 20px;"> 
                                    {{ element.importe != null ? '$' : '' }}{{ element.importe | currency: 'ARS' : '' : '1.2-2' }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="documentoAsociado">
                                <th mat-header-cell *matHeaderCellDef class="titulo-header-cell-center titulo-grilla"> {{ 'documentoAsociado' | translate }}</th>
                                <td mat-cell *matCellDef="let element" class="titulo-cell-center">
                                    <button mat-icon-button (click)="onClickGetDocAsoc(element.idDocumentoSobre, element.idPaisDocumentoSobre)" style="margin-left: 150px;">
                                        <!-- <mat-icon aria-hidden="false" aria-label="icon">attach_file</mat-icon> -->
                                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                          width="20px" height="20px" x="0px" y="0px" viewBox="0 0 52.623 52.623"
                                          style="enable-background:new 0 0 52.623 52.623;" xml:space="preserve">
                                          <path
                                            d="M14.371,52.623c-3.535,0-6.849-1.368-9.332-3.852s-3.852-5.797-3.852-9.332c0-3.534,1.368-6.848,3.852-9.331L32.333,2.814 c3.905-3.903,9.973-3.728,14.114,0.413c4.141,4.141,4.319,10.208,0.414,14.114L21.22,42.982c-2.407,2.407-6.327,2.411-8.738,0 c-2.409-2.41-2.408-6.33,0-8.738l17.369-17.369c0.586-0.586,1.535-0.586,2.121,0c0.586,0.586,0.586,1.535,0,2.121L14.603,36.365 c-1.239,1.239-1.239,3.256,0,4.496c1.241,1.239,3.257,1.237,4.496,0L44.74,15.22c2.695-2.696,2.518-6.94-0.414-9.872 s-7.176-3.109-9.872-0.413L7.16,32.229c-1.917,1.917-2.973,4.478-2.973,7.21c0,2.733,1.056,5.294,2.973,7.211 s4.478,2.973,7.211,2.973c2.732,0,5.293-1.056,7.21-2.973l27.294-27.294c0.586-0.586,1.535-0.586,2.121,0s0.586,1.535,0,2.121 L23.702,48.771C21.219,51.254,17.905,52.623,14.371,52.623z" />
                                          <g> </g>
                                          <g> </g>
                                          <g> </g>
                                          <g> </g>
                                          <g> </g>
                                          <g> </g>
                                          <g> </g>
                                          <g> </g>
                                          <g> </g>
                                          <g> </g>
                                          <g> </g>
                                          <g> </g>
                                          <g> </g>
                                          <g> </g>
                                          <g> </g>
                                        </svg>
                                    </button>          
                                </td>
                            </ng-container>
                            
                            <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: innerDisplayedColumns;"></tr>
                        </table>
                    </div>
                </div>
            </td>
        </ng-container>        

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
            [class.example-element-row]="element.documentos?.data.length"
            [class.example-expanded-row]="expandedElement === element" (click)="toggleRow(element)">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
</div>

<mat-paginator #paginator class="marginLR" 
    [length]="totalRows"
    [pageIndex]="currentPage" 
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions" 
    (page)="pageChanged($event)"
    aria-label="Select page">
</mat-paginator>