export const environment = {
  production: true,
  url: 'https://krikos360.planexware.net',
  apiUrl: 'https://krikos360.planexware.net/api2',
  gaTrackId:'G-C5HHD78KEH',
  googleClientId: '340820289997-g3bbus9bkuu5asftjfdhpeep5i26jjk1.apps.googleusercontent.com',
  webServices: {
    pdfExtractor: 'https://ws.planexware.net/K360.PdfExtractor',
    pdfMapper: 'https://ws.planexware.net/K360.PdfMapper',
    QrReader: 'https://ws.planexware.net/K360.QrReader',
  },
  conciliacion: false,
  clientId: 'b9d15110-c18d-4680-8d5a-6317f43bdfdf',
  redirectUri: 'https://krikos360.planexware.net/v2/main',
  knownAuthorities: ["aidasplanexware.b2clogin.com", "aidasplanexware.onmicrosoft.com"]
}
