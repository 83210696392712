import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DatosExtraFacturaCredito } from '../modelos/datos-extra-factura-credito';
import { DatosFacturaCredito } from '../modelos/datos-factura-credito';

@Injectable({
  providedIn: 'root'
})
export class SeguimientoFacturaCreditoService {

  constructor(private http: HttpClient) { }

  recuperarDatosIndicadoresFacturaCredito(): Observable<any[]> {
    const url = `${environment.apiUrl}/SeguimientoFacturaCredito/RecuperarDatosIndicadoresFacturaCredito`;
    return this.http.get<DatosFacturaCredito[]>(url);
  }

  recuperarDatosExtraIndicadoresFacturaCredito(): Observable<any[]> {
    const url = `${environment.apiUrl}/SeguimientoFacturaCredito/RecuperarDatosExtraIndicadoresFacturaCredito`;
    return this.http.get<DatosExtraFacturaCredito[]>(url);
  }

  RecuperarDatosExportacionCSV(idDocumentoSobre: number | undefined, idPaisDocumentoSobre: number | undefined): Observable<HttpResponse<Blob>>{
    return this.http.get(`${environment.apiUrl}/SeguimientoFacturaCredito/RecuperarDatosExportacionCSV/id/${idDocumentoSobre}/idpais/${idPaisDocumentoSobre}`, {responseType: 'blob', observe: 'response'});
  }

  
  RecuperarProveedoresHabituales(): Observable<HttpResponse<Blob>>{
    return this.http.get(`${environment.apiUrl}/SeguimientoFacturaCredito/RecuperarProveedoresHabituales`, {responseType: 'blob', observe: 'response'});
  }


}
