import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-terminos-ycondiciones',
  templateUrl: './terminos-ycondiciones.component.html',
  styleUrls: ['./terminos-ycondiciones.component.scss']
})
export class TerminosYCondicionesComponent implements OnInit {

  idioma: string;
  template: any;
  constructor(private translate: TranslateService,
              public authService: AuthService,) { }

  ngOnInit(): void {
    this.idioma = this.authService.getUserData()?.Cultura || "es-AR"
    if(this.idioma == "es-419"){
      this.idioma = "es-AR";
    }
  }

}
