import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LogoService } from 'src/app/core/services/logo.service';
import { environment } from 'src/environments/environment';
import { ClienteAD } from '../modelos/ad-cliente';
import { Proveedor } from '../modelos/proveedor';
@Injectable({
  providedIn: 'root'
})
export class AccesosDirectosHomeService {

  constructor(public http: HttpClient, private logoService: LogoService) { }

  getGrupos(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/AccesosDirectosHome/GetGruposDocumentos`)
  }

  getUltimosDocs(id: number, idPais: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/AccesosDirectosHome/GetUltimosDocumentos/Paises/${idPais}/Clientes/${id}`)
      // .pipe(map((respuesta: RespuestaUltimosDocs) => {return respuesta}))
  }

  getFiltroHome(categoria: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/AccesosDirectosHome/GetBusquedaDocumentosHome/Categoria/${categoria}`)
  }
  //pasar un modelo cliente
  getPortalDeProveedores(id: number, idPais: number): Observable<Proveedor[]> {
    return this.http.get(`${environment.apiUrl}/AccesosDirectosHome/GetPortalDeProveedores/Paises/${idPais}/Clientes/${id}`)
      .pipe(map((proveedores: any) => {

        let respuesta: Proveedor[] = [];

        proveedores.forEach((proveedor: any) => {
          let newProv = new Proveedor(proveedor.cliente.razonSocial.replace(/[^a-z0-9]/gi, ''));

          newProv.id = proveedor.cliente.id;
          newProv.idPais = proveedor.cliente.idPais;
          newProv.logo = this.logoService.convert(proveedor.logo.hex);

          respuesta.push(newProv)
        });

        return respuesta;
      }))
  }
}
