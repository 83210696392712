<h3 >Conciliación de Facturas</h3>
    <div> 
        Estamos desarrollando una nueva funcionalidad <br>
        ¡Déjenos sus datos y lo contactaremos para contarle más!
    </div>
    <br>
    <img style="border: 2.5px solid #c44ace; border-radius: 10.5px;" src="assets/images/ConciliacionFacturas\BLURRED CONCILIACION.png" alt="BLURRED CONCILIACION.png" />
    <br>
    <div style="width: 100%;">
        <form [formGroup]="formEmail" fxLayout="row" fxLayoutAlign="space-around center">
            <mat-form-field  appearance="outline" style="width: 40%;">
              <mat-label>
                Nombre y Apellido
              </mat-label>
              <input
                formControlName="nombreApellido"
                matInput
                maxlength="100"
                placeholder="Nombre y Apellido"
              />
          
            </mat-form-field>
            <mat-form-field  appearance="outline" style="width: 40%;">
                <mat-label>
                  {{'EMAIL' | translate}}
                </mat-label>
                <input
                  formControlName="email"
                  matInput
                  maxlength="100"
                  placeholder="{{'EMAIL' | translate}}"
                  
                />
            
              </mat-form-field>
            
          </form>
    </div>
    <br>
    <div fxLayout="column" fxLayoutAlign="space-around center" style="width: 100%;">
        <div colspan="3" class="text-alignment">
            <button
            mat-raised-button
            color="primary"
            (click)="Contactar()"
            style="width: 100%;  font-family: montserrat-semibold; text-transform: uppercase; font-size: 12px;"
            >
            QUIERO QUE ME CONTACTEN
            </button>
        </div>
        <div colspan="3" class="text-alignment">
            <button class="cerrar" mat-dialog-close>
            CANCELAR
            </button>
        </div>
    </div>
    