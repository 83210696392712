import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatoMensajesDocumentos'
})
export class FormatoMensajesDocumentosPipe implements PipeTransform {

  transform(value: string): string {
    if ((value.length - 3) > 300) {
      let shortValue : string = value.substring(0, 303) + "...";
      return shortValue.replace("*#*", "<br>");
    }else{
      return value.replace("*#*", "<br>");
    }
  }

}