import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DefaultGlobalConfig, GlobalConfig, IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class GenericToasterService {

  defalutOptions: Partial<GlobalConfig> = {
    closeButton: true,
    positionClass: "toast-top-full-width",
    toastClass: 'toastr-class',
    timeOut: 10000,
    extendedTimeOut: 5000,
    enableHtml: true,
    iconClasses: {
      error: 'fas fa-exclamation-circle',
      success: 'fas fa-check',
      warning: 'something',
    },
  };

  constructor(
    private toastr: ToastrService,
    private translate: TranslateService) {
  }

  pushError(message: string, params?: Object, disableTimeOut?: boolean, className?: string) {
    let options = this.defalutOptions;
    options.positionClass = className == undefined ? 'toast-top-full-width' : 'toast-top-full-width' + "_" + className
    this.toastr.overlayContainer = undefined;
    options.disableTimeOut = disableTimeOut ? disableTimeOut : false;
    this.toastr.error(this.translate.instant(message, params), '', options);
  }

  pushWarning(message: string, params?: Object, disableTimeOut?: boolean, className?: string) {
    let options = this.defalutOptions;
    options.positionClass = className == undefined ? 'toast-top-full-width' : 'toast-top-full-width' + "_" + className
    this.toastr.overlayContainer = undefined;
    options.disableTimeOut = disableTimeOut ? disableTimeOut : false;
    this.toastr.warning(this.translate.instant(message, params), '', options);
  }

  pushSuccess(message: string, params?: Object, disableTimeOut?: boolean, className?: string) {
    let options = this.defalutOptions;
    options.positionClass = className == undefined ? 'toast-top-full-width' : 'toast-top-full-width' + "_" + className
    this.toastr.overlayContainer = undefined;
    options.disableTimeOut = disableTimeOut ? disableTimeOut : false;
    this.toastr.success(this.translate.instant(message, params), '', options);
  }
}
