import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core'

@Directive({
  selector: '[appScrollAutohide]',
})
export class ScrollAutohideDirective implements OnInit {
  el: HTMLElement
  initialHeight: number
  lastScrollY = 0

  constructor(el: ElementRef<HTMLElement>) {
    this.el = el.nativeElement
    this.el.style.overflow = 'hidden'
    this.el.style.transition = '0.3s all'
  }

  ngOnInit(): void {
    this.initialHeight = this.el.scrollHeight
    this.lastScrollY = window.scrollY
  }

  @HostListener('document:scroll') onScroll = () => {
    const { scrollY } = window
    this.el.style.height = `${scrollY > this.lastScrollY ? 0 : this.initialHeight}px`
    this.lastScrollY = scrollY
  }
}
