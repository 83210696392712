<div>
  <div class="messages-wrapper">
    <div style="width: 400px"></div>
    <p class="title">{{"MENSAJERIA_SOBRE_DOCUMENTOS" | translate}}</p>

    <div class="non-message" *ngIf="mensajesDocumentosService.mensajesDocumentosCount$.value == 0">
      <p>{{"NO_HAY_MENSAJES" | translate}}</p>
    </div>

    <div class="message" *ngFor="let mensaje of mensajesDocumentosService.mensajesDocumentosMock$.value">
      <h5>{{"MENSAJES_DE" | translate}} <b>{{mensaje.razonSocial}}</b> {{"SOBRE" | translate}} <b>{{mensaje.tipoDocumento | translate}} N° {{mensaje.numeroDocumento}}</b> </h5>
      <p style="font-size: 11px" innerHtml="{{ mensaje.mensaje | formatoMensajesDocumentos}}"></p>
      <button (click)="onClickResponder(mensaje)">
        {{"RESPONDER" | translate}}
      </button>
    </div>
  </div>
</div>
