import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { GoogleAnalyticsEventsService } from 'src/app/core/services/google-analytics-events.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FAQComponent implements OnInit {

  constructor(
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService, 
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    if(!this.authService.esPlanexware()){
      var auth = this.authService.getUserData();
      if (auth) {
        this.googleAnalyticsEventsService.emitEvent('Ayuda', auth.Cliente.RazonSocial, auth.Apellido + ' ' + auth.Nombre, 0);
      }
    }
  }

  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this. ACA VA LA LISTA Q HAY Q REFACTORIZAR .filter = filterValue.trim().toLowerCase();
  // }

}
