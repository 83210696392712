import { Injectable } from '@angular/core'
import { faCopy, IconDefinition } from '@fortawesome/free-regular-svg-icons'
import { faLink, faCog } from '@fortawesome/free-solid-svg-icons'
import { FlipProp } from '@fortawesome/fontawesome-svg-core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'

export interface NavItemIcon {
  definition: IconDefinition
  flip?: FlipProp
}

export interface NavItem {
  claseEstilos: string
  habilitado: boolean
  id: number
  orden: number
  resourceKey: string
  url: string
}

export interface MainNavItem extends NavItem {
  icon: NavItemIcon
}

export interface NewDocumentItem extends NavItem {
  afterDivider?: boolean
}

@Injectable({
  providedIn: 'root',
})
export class MainNavService {
  constructor(private http: HttpClient) {
  }

  getMainItems(): Observable<NavItem[]> {
    return this.http.get<NavItem[]>(`${environment.apiUrl}/Menu/horizontal`)
  }

  getNewDocumentItems() {
    return this.http.get(`${environment.apiUrl}/nuevodocumento/GetBotonNuevoDocumento`)

  }
}
