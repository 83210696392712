import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { StorageService } from 'src/app/core/services/Storage.Service';
import { Cliente } from 'src/app/core/models/Cliente';
import { BusquedaDocumentos } from '../models/Busqueda/BusquedaDocumentos';
import { DatosBusqueda } from '../models/Busqueda/DatosBusqueda';
import { DocumentoFCredGrillaView } from '../models/DocumentoFCredGrillaView';

export class ParamsAccesosDirectos {
  tiposDocumento: number[];
  unicoDocRK: string;
  cliente: Cliente;
}

const TABLE_COLUMNS = ['comprobante', 'aceptacion', 'emisor', 'numero', 'circuito', 'fechaVtoAcept', 'fechaVtoPago', 'importe', 'saldo', 'estado'];

@Injectable({
  providedIn: 'root'
})
export class TableFCredDataService {

  private paramsAccesosDirectosSubject: BehaviorSubject<ParamsAccesosDirectos> = new BehaviorSubject({} as ParamsAccesosDirectos);
  public readonly paramsAccesosDirectos: Observable<ParamsAccesosDirectos> = this.paramsAccesosDirectosSubject.asObservable();

  private tableDataSourceSubject: BehaviorSubject<MatTableDataSource<DocumentoFCredGrillaView>> = new BehaviorSubject({} as MatTableDataSource<DocumentoFCredGrillaView>);
  public readonly tableDataSource: Observable<MatTableDataSource<DocumentoFCredGrillaView>> = this.tableDataSourceSubject.asObservable();

  private searchFiltersSubject: BehaviorSubject<BusquedaDocumentos | null> = new BehaviorSubject(null);
  public readonly searchFilters: Observable<BusquedaDocumentos | null> = this.searchFiltersSubject.asObservable();

  private BusquedaDocumentosSubject: BehaviorSubject<DatosBusqueda | null> = new BehaviorSubject(null);
  public readonly BusquedaDocumentos: Observable<DatosBusqueda | null> = this.BusquedaDocumentosSubject.asObservable();

  private FilterSubject: BehaviorSubject<string> = new BehaviorSubject('');
  public readonly Filter: Observable<string> = this.FilterSubject.asObservable();

  private LoadingDocumentosSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public readonly LoadingDocumentos: Observable<boolean> = this.LoadingDocumentosSubject.asObservable();

  displayedColumnsDefault: string[] = TABLE_COLUMNS;
  displayedColumnsOrdenCompra: string[] = TABLE_COLUMNS;
  displayedColumnsFactura: string[] = TABLE_COLUMNS;
  displayedColumnsFacturaSoporte: string[] = TABLE_COLUMNS;

  private DisplayColumsSubject: BehaviorSubject<string[]> = new BehaviorSubject(this.displayedColumnsDefault);
  public readonly displayColums: Observable<string[]> = this.DisplayColumsSubject.asObservable();

  public showHolding: boolean = false;

  constructor(
    private fb: FormBuilder,
    private storageService: StorageService,
    private authService: AuthService
  ) { }


  setSessionFilters(filtros: BusquedaDocumentos): void {
    sessionStorage.setItem("tagFiltrosFCREDV2", JSON.stringify(filtros));
  }

  setSearchFilters(filtros: BusquedaDocumentos): void {
    this.resetGrillaHeaders();
    this.LoadingDocumentosSubject.next(true);
    sessionStorage.setItem("tagFiltrosFCREDV2", JSON.stringify(filtros));
    this.searchFiltersSubject.next(filtros);
  }

  setTableDataSource(data: MatTableDataSource<DocumentoFCredGrillaView>): void {
    this.tableDataSourceSubject.next(data);
  }


  setBusquedaDocumentos(filtros: DatosBusqueda): void {
    this.BusquedaDocumentosSubject.next(filtros);
  }

  setDisplayColums(lista: string[]): void {
    this.DisplayColumsSubject.next(lista);
  }

  setInputFilter(filter: string) {
    this.FilterSubject.next(filter);
  }

  setLoadingDocumentos(loading: boolean) {
    this.LoadingDocumentosSubject.next(loading);
  }

  private resetGrillaHeaders() {
    this.displayedColumnsDefault = TABLE_COLUMNS;
    this.displayedColumnsOrdenCompra = TABLE_COLUMNS;
    this.displayedColumnsFactura = TABLE_COLUMNS;
    this.displayedColumnsFacturaSoporte = TABLE_COLUMNS;
  }

  setParamsAccesosDirectos(params: ParamsAccesosDirectos): void {
    this.paramsAccesosDirectosSubject.next(params);
  }
  ngOnDestroy(): void {
    this.searchFiltersSubject.next(null);
  }
}