<mat-card style="box-shadow: none;">
  <div class="downloads-list">
    <div class="downloads-list__title">
      <h3> {{'DESCARGAS_EXPORTACIONES' | translate}}</h3>
    </div>
    <div class="downloads-list__body">
      <div class="container">
        <p>
          {{'ARCHIVOS_48_HORAS' | translate }}
        </p>

        <div class="downloads-list__spinner" *ngIf="downloadService.downloadsLoading">
          <fa-icon
            [spin]="true"
            [icon]="['fas', 'sync-alt']"
            transform="grow-20"
          ></fa-icon>
        </div>
        <table mat-table [dataSource]="downloadService.downloadsList" matSort (matSortChange)="sortData($event)" style="width: 100%;">

          <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="nombre-th"> {{ 'Nombre' | translate }} </th>
            <td style="font-size: 12px" mat-cell *matCellDef="let element"> {{element.nombre}} </td>
          </ng-container>

          <ng-container matColumnDef="fechaInicio">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="nombre-th" > {{ 'FECHA_PEDIDO' | translate }} </th>
            <td style="font-size: 12px" mat-cell *matCellDef="let element"> {{ element.fechaInicio | date: "dd/MM/yyyy HH:mm"  : agregarTimeZone() }} </td >
          </ng-container>

          <ng-container matColumnDef="peso">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="nombre-th" > {{ 'PESO' | translate }} </th>
            <td style="font-size: 12px" mat-cell *matCellDef="let element"> {{ element.peso | megabytes }} </td >
          </ng-container>

         <ng-container matColumnDef="estado">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="nombre-th" > {{ 'Estado' | translate }} </th>
          <td style="font-size: 12px" mat-cell *matCellDef="let element">
            <div *ngIf="!element.generado" class="progress downloads-list__table__status">
              <mat-card class="descarga-pendiente">
                <div >
                  {{ 'DESCARGA_PENDIENTE' | translate }}</div>
              </mat-card>
            </div>
            <div *ngIf="element.generado && element.inicioDescarga" class="progress downloads-list__table__status">
              <mat-card class="descarga-descargado">
                <div>
                  {{ 'DESCARGA_DESCARGADO' | translate }}</div>
              </mat-card>
            </div>
            <div *ngIf="element.generado && !element.inicioDescarga" class="progress downloads-list__table__status">
              <mat-card style="box-shadow: none;" class="descarga-disponible">
                <div>
                  {{ 'DESCARGA_DISPONIBLE' | translate }}</div>
              </mat-card>
            </div>

          </td>
        </ng-container>
        <ng-container matColumnDef="control">
          <th mat-header-cell *matHeaderCellDef class="nombre-th"> </th>
            <td style="font-size: 12px" mat-cell *matCellDef="let element">
              <!-- <button mat-button > -->
                <mat-icon style="cursor: pointer;" (click)="downloadFile(element.id, element.nombre)" *ngIf="element.generado && !(element.id == loadingId)">get_app</mat-icon>
                <mat-icon class="rotate" style="cursor: pointer;"  *ngIf="(element.id == loadingId)">autorenew</mat-icon>
              <!-- </button> -->
            </td>
        </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <div
          class="downloads-list__table__empty"
          *ngIf="downloadService.downloadsList.length < 1 && !downloadService.downloadsLoading"
        >
          <fa-icon [icon]="['fas', 'info-circle']"></fa-icon>
          {{'NO_HAY_DESCARGAS_DISPONIBLES'  | translate }}
        </div>
        <div class="small">
          <p style="margin: 12px 0;">
            <fa-icon [icon]="['fas', 'info-circle']" style="color: #E25314"></fa-icon>
            {{'HELP_VALORES_COLUMNA_ESTADO'  | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</mat-card>
