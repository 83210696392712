import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MensajeHTMLView } from '../models/mensajeHTMLView';

@Injectable({
  providedIn: 'root'
})
export class NovedadesService {

  constructor(public http: HttpClient) { }

  getNovedades() {
    return this.http.get(`${environment.apiUrl}/NovedadesHome/GetNovedades`)
  }
}
