import { environment } from "src/environments/environment"

export default {
  misc: {
    planex: 'https://www.planexware.com/',
  },
  topBar: {
    contacto: 'https://www.planexware.com/contacto',
    email: 'mesadeayuda@planexware.com',
  },
  pages: { // link to existing Krikos360 legacy pages
    home: `${environment.apiUrl}/index.html#/Home/index`,
    user: {
      pwdChange: `${environment.apiUrl}/index.html#/contrasena/cambiar`,
      faq: `${environment.apiUrl}/index.html#/ayuda`,
    },
    nav: {
      documentos: `${environment.apiUrl}/index.html#/documentos/37`,
      conexiones: `${environment.apiUrl}/index.html#/conexiones/38`,
      administracion: `${environment.apiUrl}/index.html#/configuraciones/31`,
    },
    newDocument: {
      factura: `${environment.apiUrl}/index.html#/crearFactura/1`,
      ordenDeCompra: `${environment.apiUrl}/index.html#/crearOrdenCompra/2`,
      notaDeCredito: `${environment.apiUrl}/index.html#/crearNotaCredito/3`,
      subirArchivo: `${environment.apiUrl}/index.html#/subirArchivo`,
    }
  },
  social: {
    twitter: 'https://twitter.com/planexware',
    linkedin: 'https://www.linkedin.com/company/planexware',
  },
}
