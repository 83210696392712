<div style="max-width: 100%; max-height: 100%; scrollbar-width: thin; scrollbar-color: #c44ace white;">
  <div class="notification-wrapper">
    <p class="title">{{ "PANEL_NOTIFICACIONES" | translate }}</p>
    <span *ngIf="connectionsService.notificationsCount > 0" class="solicitudes">
      <a
        style="padding-left: 30px; color: #434040"
        [routerLink]="['./conexiones/38']"
        >{{ "VER_TODAS" | translate }}</a
      >
    </span>
    <div *ngIf="connectionsService.loadingNotifications" id="spinner-wrapper">
      <mat-spinner></mat-spinner>
    </div>
  </div>

  <!-- <mat-divider></mat-divider> -->
  <!-- Generar card de mensajes segun Miro. Con dos botones Responder y Ver Todos -->

  <div *ngIf="!connectionsService.loadingNotifications" class="content">
    <div *ngIf="connectionsService.notificationsCount == 0">
      <mat-icon style="font-size: 100px; color: darkgrey; margin-left: 38%; transform: translateX(-50%)"
        >notifications_off</mat-icon
      >
      <h6 style="text-align: center; color: darkgrey">
        {{ "USTED_NO_POSEE_SOLICITUDES_PENDIENTES" | translate }}
      </h6>
    </div>

    <mat-card *ngFor="let card of connectionsService.connectionsMock$.value" style="width: 100%;">
      <img [src]="card.interlocutor.logo" width="80"  height="80"
      style="align-items: center;
      object-fit: contain;
      justify-content: center;
      border: 1px solid #B3B3B3;
      border-radius: 5px;">
      <div
        *ngIf="!(card.interlocutor.id === rejectingConnectionId)"
        class="content-card"
        style="width: 100%"
      >
        <span>
         {{card.intercambiosDeDocumento.length > 0 ? "" : card.interlocutor.razonSocial}} {{ (card.intercambiosDeDocumento.length > 0 ? "EMPRESA_INVITADA_A" : "SER_SU_REPRESENTANTE") | translate }}</span
        >
        <ol>
          <li *ngFor="let document of card.intercambiosDeDocumento">
            {{ document.notificacion | translate }}
            {{ document.tipoDocumento.resourceKey | translate }}
            {{ document.tipoDocumento.version | translate }}
            {{ resolverSentido(document.notificacion) | translate }}
            {{ card.interlocutor.razonSocial }}
          </li>
        </ol>
        <div *ngIf="card.intercambiosDeDocumento.length > 0" class="footer-card">
          <a
            style="color: #d94a4a; text-decoration: none; cursor: pointer"
            [routerLink]=""
            triggers="{{ muestra() ? 'none' : 'mouseenter:mouseleave' }}"
            matTooltip="{{ tooltip_aceptarrechazar | translate }}"
            [ngClass]="{ 'disabled-repetitive-action': !muestra() }"
            (click)="
              muestra()
                ? rejectConnection(
                    card.interlocutor.id,
                    card.interlocutor.razonSocial
                  )
                : null;
              $event.stopPropagation();
              $event.preventDefault()
            "
            >{{ "RECHAZAR" | translate }}</a
          >
          &nbsp;&nbsp;

          <a
            style="color: #439664; text-decoration: none; cursor: pointer"
            [routerLink]=""
            triggers="{{ muestra() ? 'none' : 'mouseenter:mouseleave' }}"
            matTooltip="{{ tooltip_aceptarrechazar | translate }}"
            [ngClass]="{ 'disabled-repetitive-action': !muestra() }"
            (click)="
              muestra()
                ? confirmConnection(
                    card.interlocutor.id,
                    card.interlocutor.razonSocial
                  )
                : null;
              $event.stopPropagation();
              $event.preventDefault()
            "
            >{{ "CONFIRMAR" | translate }}</a
          >
        </div>
        <div *ngIf="card.intercambiosDeDocumento.length == 0 && funcionRepresentante()" class="footer-card" style="padding-top: 30px;">
          <a
            style="text-decoration: none; cursor: pointer"
            [routerLink]="['./configuraciones/0/Representantes']"
            matTooltip="{{ 'VER' | translate }}"

            >{{ "VER" | translate }}</a
          >
        </div>
      </div>

      <div
        *ngIf="card.interlocutor.id === rejectingConnectionId"
        class="footer-card"
      >
        <span>{{ "MENSAJE_MODAL_RECHAZAR_INVITACION" | translate }}</span>
        <div class="confirm-wrapper">
          <a
            style="
              color: #d94a4a;
              text-decoration: none;
              margin-top: 4rem;
              cursor: pointer;
            "
            [routerLink]="[]"
            (click)="cancelReject(card.interlocutor.id)"
            >{{ "CANCELAR" | translate }}</a
          >
          <a
            style="
              color: #439664;
              text-decoration: none;
              margin-top: 4rem;
              margin-left: 0.5rem;
            "
            [routerLink]="[]"
            (click)="
              confirmReject(card.interlocutor.id, card.interlocutor.razonSocial)
            "
            >{{ "CONFIRMAR" | translate }}</a
          >
        </div>
      </div>
    </mat-card>
  </div>
</div>
