<mat-toolbar class="mat-toolbar-top">
  <a href="https://www.planexware.com/" target="_blank" rel="noopener noreferrer">
    <img height="15" src="assets/planexware-white.svg" alt="Planexware" /></a>
</mat-toolbar>
<mat-toolbar class="mat-toolbar-sub">
  <mat-toolbar-row>
    <app-main-nav id="sub-menu"></app-main-nav>
    <div class="logo-wrapper">
      <a [routerLink]="['/main/home']">
        <img src="assets/krikos360.new.svg" alt="Krikos360" class="mat-logo" />
      </a>
    </div>

    <div *ngIf="!deviceXs">
      <button [hidden]="hideBell" mat-icon-button [matMenuTriggerFor]="menu2" (click)="toggleNotifications()">
        <div *ngIf="
            connectionsService.notificationsCount$.value== 0;
            then withoutNotificacion;
            else withNotificacion
          "></div>
        <ng-template #withoutNotificacion><mat-icon matBadgeColor="warn">notifications</mat-icon></ng-template>
        <ng-template #withNotificacion><mat-icon [matBadge]="connectionsService.notificationsCount$.value"
            matBadgeColor="warn">notifications</mat-icon></ng-template>
        <mat-menu #menu2="matMenu">
          <app-notification-panel></app-notification-panel>
        </mat-menu>
      </button>
      <div class="message-button" *ngIf="!mensajesDocumentosService.tieneServicioNoChat$.value && (!esUsuarioPLX() || estaConectadoComo())">
        <button mat-icon-button [matMenuTriggerFor]="menu3" (click)="getMensajesDocumentos()">
          <div *ngIf="mensajesDocumentosService.mensajesDocumentosCount$.value > 0
                      then withMessagesCount;
                      else withoutMessagesCount"></div>
          <ng-template #withMessagesCount>
            <div *ngIf="mensajesDocumentosService.mensajesDocumentosCount$.value > 9
              then greaterThanNine;
              else lessThanNine"></div>
              <ng-template #greaterThanNine>
                <mat-icon matBadge="9+">
                  chat
                </mat-icon>
              </ng-template>
              <ng-template #lessThanNine>
                <mat-icon [matBadge]="mensajesDocumentosService.mensajesDocumentosCount$.value">
                  chat
                </mat-icon>
              </ng-template>
          </ng-template>
          <ng-template #withoutMessagesCount>
            <mat-icon> chat </mat-icon>
          </ng-template>
          <mat-menu #menu3="matMenu">
            <app-mensajes-documentos></app-mensajes-documentos>
          </mat-menu>
        </button>

      </div>

      <mat-menu #menu2="matMenu" [hasBackdrop]="false" xPosition="before">
        <div class="notifications-wrapper" style="height: 12rem">
          <app-notification-panel></app-notification-panel>
        </div>
      </mat-menu>

      <button mat-icon-button (click)="openDownloads()">
        <div *ngIf="
            downloadService.downloadsAmount$.value == 0;
            then withoutDownloads;
            else withDownloads
          "></div>
        <ng-template #withoutDownloads><mat-icon matBadgeColor="warn">get_app</mat-icon></ng-template>
        <ng-template #withDownloads><mat-icon [matBadge]="downloadService.downloadsAmount$.value"
            matBadgeColor="warn">get_app</mat-icon></ng-template>
      </button>
      <button mat-icon-button [routerLink]="['/main/ayuda']">
        <mat-icon>contact_support</mat-icon>
      </button>

      <span id="username">
        {{ userData?.Nombre }} {{ userData?.Apellido }}
        {{ userData?.Cliente.RazonSocial }}</span>

      <span [matMenuTriggerFor]="menu" style="cursor: pointer; padding-top: 0.5rem">
        <mat-icon>person</mat-icon><mat-icon>arrow_drop_down</mat-icon>
      </span>

      <mat-menu #menu="matMenu">
        <button *ngIf="!usaSocialLogin" mat-menu-item [routerLink]="['/main/contrasena/cambiar']">
          {{ "CAMBIO_CONTRASENA" | translate }}
        </button>
        <div matTooltip="{{ 'NO_PUEDE_CAMBIAR_CONTRASENA_SSO' | translate }} ">
          <button *ngIf="usaSocialLogin" mat-menu-item disabled>
            {{ "CAMBIO_CONTRASENA" | translate }}
          </button>
        </div>
        <button mat-menu-item [routerLink]="['/main/ayuda']">
          {{ "PREGUNTAS_FRECUENTES" | translate }}
        </button>
        <button mat-menu-item [routerLink]="[]" (click)="openAdmins()">
          {{ "DATOS_DE_MI_ADMINISTRADOR" | translate }}
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item [routerLink]="''" (click)="logout()">
          {{ "SALIR" | translate }}
        </button>
      </mat-menu>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="deviceXs" style="justify-content: right">
    <div>
      <button mat-icon-button [matMenuTriggerFor]="menu2" [hidden]="hideBell" (click)="toggleNotifications()">
        <div *ngIf="
            connectionsService.notificationsCount == 0;
            then withNotificacion;
            else withoutNotificacion
          "></div>
        <ng-template #withNotificacion><mat-icon matBadgeColor="warn">notifications</mat-icon></ng-template>
        <ng-template #withoutNotificacion><mat-icon [matBadge]="connectionsService.notificationsCount$.value"
            matBadgeColor="warn">notifications</mat-icon></ng-template>
      </button>
      <mat-menu #menu2="matMenu" xPosition="before">
        <div class="notifications-wrapper" style="width: 24rem; height: 12rem">
          <app-notification-panel></app-notification-panel>
        </div>
      </mat-menu>
      <div class="message-button" *ngIf="!mensajesDocumentosService.tieneServicioNoChat$.value">
        <button mat-icon-button [matMenuTriggerFor]="menu3" (click)="getMensajesDocumentos()">
          <div *ngIf="mensajesDocumentosService.mensajesDocumentosCount$.value > 0
                      then withMessagesCount;
                      else withoutMessagesCount"></div>
          <ng-template #withMessagesCount>
            <div *ngIf="mensajesDocumentosService.mensajesDocumentosCount$.value > 9
              then greaterThanNine;
              else lessThanNine"></div>
              <ng-template #greaterThanNine>
                <mat-icon matBadge="9+">
                  chat
                </mat-icon>
              </ng-template>
              <ng-template #lessThanNine>
                <mat-icon [matBadge]="mensajesDocumentosService.mensajesDocumentosCount$.value">
                  chat
                </mat-icon>
              </ng-template>
          </ng-template>
          <ng-template #withoutMessagesCount>
            <mat-icon> chat </mat-icon>
          </ng-template>
          <mat-menu #menu3="matMenu">
            <app-mensajes-documentos></app-mensajes-documentos>
          </mat-menu>
        </button>

      </div>
      <button mat-icon-button (click)="openDownloads()">
        <div *ngIf="
            downloadService.downloadsAmount == 0;
            then withDownloads;
            else withoutDownloads
          "></div>
        <ng-template #withDownloads><mat-icon matBadgeColor="warn">get_app</mat-icon></ng-template>
        <ng-template #withoutDownloads><mat-icon [matBadge]="downloadService.downloadsAmount"
            matBadgeColor="warn">get_app</mat-icon></ng-template>
      </button>
      <button mat-icon-button [routerLink]="['/main/ayuda']">
        <mat-icon>contact_support</mat-icon>
      </button>

      <span [matMenuTriggerFor]="menu" style="cursor: pointer; padding-top: 0.5rem">
        <mat-icon>person</mat-icon><mat-icon>arrow_drop_down</mat-icon>
      </span>
      <mat-menu #menu="matMenu">
        <button mat-menu-item [routerLink]="['/main/contrasena/cambiar']">
          {{ "CAMBIO_CONTRASENA" | translate }}
        </button>
        <button mat-menu-item [routerLink]="['/main/ayuda']">
          {{ "PREGUNTAS_FRECUENTES" | translate }}
        </button>
        <button mat-menu-item [routerLink]="[]" (click)="openAdmins()">
          {{ "DATOS_DE_MI_ADMINISTRADOR" | translate }}
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item [routerLink]="''" (click)="logout()">
          {{ "SALIR" | translate }}
        </button>
      </mat-menu>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<app-modal modalId="connectionsModal">
  <app-connections></app-connections>
</app-modal>

<app-modal modalId="adminModal">
  <app-admin-list [administrators]="adminsList" class="test"></app-admin-list>
</app-modal>

<app-modal modalId="downloadsModal">
  <app-downloads-list></app-downloads-list>
</app-modal>