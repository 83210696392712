import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Inject } from '@angular/core';
import { ConnectionsService } from 'src/app/core/services/connections.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { isNullOrEmptyOrUndefined } from '../../../utils/utils.component'
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Connections } from 'src/app/core/models/connections.interface';
import { LogoService } from 'src/app/core/services/logo.service';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-notification-panel',
  templateUrl: './notification-panel.component.html',
  styleUrls: ['./notification-panel.component.scss'],
})
export class NotificationPanelComponent implements OnInit {

  rejectingConnectionId: number
  conectadoComo: boolean
  connectionsMock: Array<Connections>
  tieneFuncionAceptarRechazar = false;
  tieneFuncionRepresentantes = false;
  tooltip_aceptarrechazar: string;

  constructor(
    private translateService: TranslateService,
    public navService: NavigationService,
    public connectionsService: ConnectionsService,
    public modalService: ModalService,
    public authService: AuthService,
    private toastr: ToastrService,
    private logoService: LogoService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {

    //Generar metodo de obtencion de nuevos mensajes. Llamar al serviceMetod Notifaciones De Chat.
    //Modificar metodo de suma de conexiones en campanita. Agregar la cantidad de mensajes total.
    //Generar una nueva clase que se va a terminar mostrando a modo de card en el html.
    //Generar metodo de Responder con redireccion a documentos
    //Generar metodo de Ver todos con redireccion a documentos

    this.conectadoComo = !isNullOrEmptyOrUndefined(this.authService.userdata.ConectadoComo) && (this.authService.userdata.Tipo ?? this.authService.userdata.TipoUsuario) === 'Planex';
    var funciones = sessionStorage.getItem("Funciones")?.toString().includes("ConexionesPendientes/AceptarRechazar");
    if (funciones)
      this.tieneFuncionAceptarRechazar = true;
    else
      this.tieneFuncionAceptarRechazar = false;
    if (this.muestra())
      this.tooltip_aceptarrechazar = "";
    else
      this.tooltip_aceptarrechazar = 'CONTACTE_ADMINISTRADOR';// this.translateService.instant('CONTACTE_ADMINISTRADOR');

  }

  confirmConnection(id: number, razonSocial: string) {
    this.connectionsService.confirmConnection(id, razonSocial)
    this.modalService.openModal('connectionsModal');

  }

  rejectConnection(id: number, razonSocial: string) {
    this.rejectingConnectionId = id
  }

  resolverSentido(sentido: string) {
    let res: string = "";
    if (sentido == 'Enviarle'){
      res = "a";
    }
    else{
      res = "de";
    }
    return res;
  }



  cancelReject() {
    this.rejectingConnectionId = 0
    this.connectionsService.getNotificationsAmount();

  }

  confirmReject(id: number, razonSocial: string) {
    this.connectionsService.rejectConnection(this.rejectingConnectionId, razonSocial)
    this.connectionsService.getNotificationsAmount();

  }
  muestra(): boolean {
    return !this.conectadoComo && this.tieneFuncionAceptarRechazar
  }

  close() {
    this.navService.openNotificationPanel = false;
  }

  funcionRepresentante(): boolean {
    return sessionStorage.getItem("Funciones")?.toString().includes("configuraciones/Representantes") ?? false;
  }

}
