<div class="container-card-acceso" *ngIf="mostrarUltimosDocs">
  <mat-card>
    <h2 class="header-card-acceso">{{ "MIS_DOCUMENTOS" | translate }}</h2>

    <div class="slick-wrapper arrows-config top-carousel">
      <ngx-slick-carousel
        class="carousel"
        #slickModal="slick-carousel"
        [config]="slideConfig"
      >
        <div ngxSlickItem *ngFor="let doc of ultimosDocs" class="slide">
          <div class="carousel-element" (click)="onGrupoClick($event, doc)">
            <div class="container-icono-grupo">
              <img
                src="{{ 'assets/images/AccesosDirectos/'.concat(doc.icono) }}"
                viewBox="0 0 683 432"
                alt=""
                class="icono-svg"
              />
            </div>

            <p class="carousel-element-child documento">
              {{ doc.resourceKeyGrupo | translate }}
              {{ doc.sentido | translate }}
            </p>
            <p class="carousel-element-child ultimo-doc">
              {{ "UltDoc" | translate }}
              {{
                doc.fechaUltimoDocumento
                  | date : "dd/MM/yyyy HH:mm" : agregarTimeZone()
              }}
            </p>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </mat-card>
</div>

<div class="container-card-acceso" *ngIf="mostrarProveedores">
  <mat-card>
    <h2 class="header-card-acceso">
      {{ "PORTAL_DE_PROVEEDORES" | translate }}
    </h2>
    <h3 class="sub-header-card-acceso">
      {{ "SUB-HEADER-PORTAL-PROVEEDORES" | translate }}
    </h3>

    <div class="slick-wrapper arrows-config">
      <ngx-slick-carousel
        class="carousel"
        #slickModal="slick-carousel"
        [config]="proveedoresConfig"
      >
        <div
          ngxSlickItem
          *ngFor="let proveedor of proveedores; let i = index"
          class="slide"
        >
          <app-logo-pp
            [proveedor]="proveedores[i]"
            (click)="onProveedorClick($event, proveedor)"
          ></app-logo-pp>
        </div>
      </ngx-slick-carousel>
    </div>
  </mat-card>
</div>
