import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { BusquedaDocumentos } from 'src/app/pages/documentos-clientes/components/models/BusquedaDocumentos';
import { SolicitudDescarga } from 'src/app/pages/documentos-clientes/components/models/SolicitudDescarga';
import { environment } from 'src/environments/environment';
import { Downloads } from '../models/downloads.interface';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  public downloadsLoading = true
  public downloadsAmount = 0
  public downloadsList: Array<Downloads> = []
  downloadsAmount$: BehaviorSubject<number> = new BehaviorSubject(0);
  constructor(private http: HttpClient, 
    private toastr: ToastrService,
    private translateService: TranslateService) { 
    this.getDownloadsAmount()
  }

  getFile(id: Number) {
    return this.http.get(`${environment.apiUrl}/descargas/${id}`, {
      responseType: 'arraybuffer'
    })
  }

  getDownloadsList() {
    this.downloadsLoading = true
    this.http.get(`${environment.apiUrl}/descargas`)
      .subscribe(
        (data: Array<Downloads>) => {
          this.downloadsList = data;
          this.downloadsLoading = false
        },
        (err) => {
          this.downloadsLoading = false
          this.toastr.error(this.translateService.instant('ERROR_APLICACION'), '', {

            positionClass: "toast-top-full-width",
            disableTimeOut: true,
            closeButton: true
    
          });
        });
  }

  getDownloadsAmount() {
    setTimeout(() => {
      this.http.get<number>(`${environment.apiUrl}/descargas/cantidad`)
      .subscribe(
        (res) => {
          this.downloadsAmount$.next(res)
          this.downloadsAmount = res
        },
        (err) => {
          this.toastr.error(this.translateService.instant('ERROR_APLICACION'), '', {

            positionClass: "toast-top-full-width",
            disableTimeOut: true,
            closeButton: true
    
          });
        }
      )
    }, 2000);
  }

  getConfiguracionDeTiposDocumentoParaGestor() : Observable <any> {
    return this.http.get(`${environment.apiUrl}/descargas/RecuperarConfiguracionDeTiposDocumentoParaGestor`);
  }

  exportarTodosLosDocumentos(solicitudDescarga: SolicitudDescarga) : Observable <any> {
    return this.http.post(`${environment.apiUrl}/descargas/ExportarTodosLosDocumentos`, solicitudDescarga);
  }
  recuperarLimitesGeneracion() : Observable<any> {
    return this.http.get(`${environment.apiUrl}/descargas/RecuperarLimitesGeneracion`);
  }

  postExportarDocumentos(solicitudDescarga: SolicitudDescarga) : Observable <any> {
    return this.http.post(`${environment.apiUrl}/descargas/ExportarDocumentos`,solicitudDescarga);
  }

  postExportarResultados(filtros: BusquedaDocumentos, cantidadSeleccionados: number): Observable<any> {
    let solicitudDescarga = {
      DocumentosSeleccionados: {
        ClientesHolding: [],
        Documentos: [],
        Formato: null
      },
      FiltrosDeBusqueda: filtros,
      CantidadSolicitada: cantidadSeleccionados
    };
    return this.http.post(`${environment.apiUrl}/descargas/ExportarResultados`, solicitudDescarga);
  }
}
