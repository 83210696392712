import * as moment from "moment";
import { ClaveCompuestaHash } from "../dialog-chat-documentos/models/ClaveCompuestaHash";
import { ClienteRelacionDocumentosCliente } from "./ClienteRelacionDocumentosCliente";
import { ComponenteFiltro } from "./ComponenteFiltro";
import { EstadoSeguimientoDocumentosCliente } from "./EstadoSeguimientoDocumentosCliente";
import { TipoDocFiltro } from "./TipoDocFiltro";

export class BusquedaDocumentos {
  id: number;
  sobre: ClaveCompuestaHash | null;
  tiposDocumentos: TipoDocFiltro[];
  estadosDocumentos: number[];
  numerosDocumentos: string[];
  descargaAutomatica: EstadoSeguimientoDocumentosCliente[];
  empresasDelUsuario: ClienteRelacionDocumentosCliente[];
  interlocutores: ClienteRelacionDocumentosCliente[];
  componentes: ComponenteFiltro[];
  productivo: boolean | null;
  pageNumber: number;
  count: boolean;
  grupoDeDocumentos: string;
  importeDesde: number | null;
  importeHasta: number | null;
  origen: string | null;
  esWorkflow: boolean;

  //FECHAS
  rangoFecha: number;
  criterioFiltroFecha: string;
  fechaDesde: Date | null | moment.Moment;
  fechaHasta: Date | null | moment.Moment;
  fechaVencimientoDesde: Date | null | moment.Moment;
  fechaVencimientoHasta: Date | null | moment.Moment;
  fechaEmisionDesde: Date | null | moment.Moment;
  fechaEmisionHasta: Date | null | moment.Moment;
  fechaEntregaDesde: Date | null | moment.Moment;
  fechaEntregaHasta: Date | null | moment.Moment;

  constructor() {
    this.id = 0;
    this.sobre = null;
    this.tiposDocumentos = [];
    this.estadosDocumentos = [];
    this.numerosDocumentos = [];
    this.descargaAutomatica = [];
    this.empresasDelUsuario = [];
    this.interlocutores = [];
    this.componentes = [];
    this.productivo = null;
    this.pageNumber = 0;
    this.count = false;
    this.grupoDeDocumentos = 'Generico';
    this.importeDesde = null;
    this.importeHasta = null;
    this.origen = null;
    this.esWorkflow = false;
    this.rangoFecha = 0;
    this.criterioFiltroFecha = 'SIN_FILTRO_POR_FECHA';
    this.fechaDesde = null;
    this.fechaHasta = null;
    this.fechaVencimientoDesde = null;
    this.fechaVencimientoHasta = null;
    this.fechaEmisionDesde = null;
    this.fechaEmisionHasta = null;
    this.fechaEntregaDesde = null;
    this.fechaEntregaHasta = null;
  }
}