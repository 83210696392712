// es la misma clase que usamos en consulta de documentos?
export class BusquedaDocumentos {
  tiposComprobantes: number[];
  cuitEmisor: string;
  numerosDocumentos: string[];
  tipoFecha: string;
  fechaDesde: Date;
  fechaHasta: Date;
  rangoFecha: number;
  criterioFiltroFecha: string;
  estadosCtaCte: number[];
  circuito: string;
  tengoCmp: boolean;
  esProveedorHabitual: boolean | null;
  existeEnERP: boolean | null;
  existeEnK360: boolean | null;
  pantalla: string = "FCRED";
  pageNumber: number;
  productivo: boolean | null;

  constructor() {
    this.tiposComprobantes = [];
    this.cuitEmisor = "";
    this.numerosDocumentos = [];
    this.tipoFecha = "";
    this.fechaDesde = new Date();
    this.fechaHasta = new Date();
    this.rangoFecha = -1;
    this.criterioFiltroFecha = "DEFINIR_UN_RANGO";
    this.estadosCtaCte = [];
    this.circuito = "";
    this.tengoCmp = false;
    this.esProveedorHabitual = null;
    this.existeEnERP = null;
    this.existeEnK360 = null;
    this.pantalla = "FCRED";
    this.pageNumber = 1;
    this.productivo = false;
  }
}
