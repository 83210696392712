import { Injectable } from '@angular/core';
import { timer } from 'rxjs';
import { ToasterMessage } from '../models/alert-message.interface';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { MensajeService } from './mensaje.service';
import { MensajeHTMLView } from 'src/app/pages/novedades/models/mensajeHTMLView';
import { isNullOrEmptyOrUndefined } from 'src/app/shared/utils/utils.component';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  messages: ToasterMessage[] = [];
  interval = 300000 // 5 min
  private urlsSinToken = ["/login","contrasena/crear","terminosycondiciones", "politicasdeprivacidad"];
  constructor(private http: HttpClient,
    private mensajeService: MensajeService, private authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private translateService: TranslateService) {
    this.getMessages();
  }

  closeAlert(id: number | undefined) {
    let index = this.messages.findIndex((message) => message.id === id);
    var mensaje = this.messages[index];
    mensaje.resourceKey = 'CierreNoC'; // Se usa para grabar log de auditoria
    this.mensajeService.cerrarMensajeUsuario(mensaje).subscribe(x => {
      this.messages.splice(index, 1);
    });

  }

  pushMessage(mnessage: ToasterMessage) {
    var rs = this.authService.getUserData()?.Cliente.RazonSocial ?? "";
      mnessage.mensaje = mnessage.mensaje.replace("{0}", rs);
    // solo agrego el mensaje si no existe
    var aux = this.messages.find(cada => cada.mensaje === mnessage.mensaje && cada.id === mnessage.id);
    if (isNullOrEmptyOrUndefined(aux)) {
      this.messages.push(mnessage);
    }
  }

  getMessages() {
    timer(1000, this.interval).subscribe(() => {
      // TODO SACAR CUANDO SE VALIDE DESDE EL BACK EL TOKEN
      if (this.authService.isAuthenticated()) {
      this.http
        .get<ToasterMessage[]>(`${environment.apiUrl}/mensajes/GetMensajesParaElUsuario`)
        .subscribe(
          (res: ToasterMessage[]) => {
            if (res.length > 0) {
              res.forEach(cada => this.pushMessage(cada));
            }
          },
          (err) => {
            if (err.error !== null && err.status !== 0)
              this.toastr.error(this.translateService.instant('ERROR_APLICACION'), '', {

                positionClass: "toast-top-full-width",
                disableTimeOut: true,
                closeButton: true

              });
          }
        );
        // TODO SACAR CUANDO SE VALIDE DESDE EL BACK EL TOKEN
      } else if(!this.sinToken(this.router.url)) {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('ClientesHolding');
        sessionStorage.clear();
        this.router.navigateByUrl('/login')
      }
    })
  }

  sinToken(routerURL: string): boolean {
    var url = routerURL.toLowerCase();
    var sinToken = false;
    this.urlsSinToken.forEach(cada => {
      if(url.includes(cada))
        sinToken = true;
    });

    return sinToken;
  }

}
