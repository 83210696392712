import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MesajeDocumentosChatNotificacion } from 'src/app/core/models/mensajesDocumentos.interface';
import { StorageService } from 'src/app/core/services/Storage.Service';
import { AuthService } from 'src/app/core/services/auth.service';
import { GoogleAnalyticsEventsService } from 'src/app/core/services/google-analytics-events.service';
import { MensajesDocumentosService } from 'src/app/core/services/mensajes-documentos.service';
import { BusquedaDocumentos } from 'src/app/pages/documentos-clientes/components/models/BusquedaDocumentos';
import { TableDataService } from 'src/app/pages/documentos-clientes/components/services/table-data.service';

@Component({
  selector: 'app-mensajes-documentos',
  templateUrl: './mensajes-documentos.component.html',
  styleUrls: ['./mensajes-documentos.component.scss']
})
export class MensajesDocumentosComponent implements OnInit {

  constructor(
    public mensajesDocumentosService: MensajesDocumentosService,
    private tableDataSourceService: TableDataService,
    private router: Router,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    public authService: AuthService,
    private storageService: StorageService
    ) { }

  ngOnInit(): void {
  }

  onClickResponder(mensaje: MesajeDocumentosChatNotificacion){

    let filtro : BusquedaDocumentos =new BusquedaDocumentos;
    filtro.numerosDocumentos = [mensaje.numeroDocumento];
    filtro.sobre = mensaje.sobre;
    filtro.productivo = null;
    filtro.criterioFiltroFecha="SIN_FILTRO_POR_FECHA";
    filtro.tiposDocumentos=[]
    filtro.estadosDocumentos=[]
    filtro.descargaAutomatica=[]
    if(this.storageService.getHoldingMode()){
      filtro.empresasDelUsuario=[{id: mensaje.clienteFiltro.id, idPais: mensaje.clienteFiltro.idPais}]
    }else{
      filtro.empresasDelUsuario=[];
    }
    filtro.interlocutores=[]
    filtro.componentes=[]
    filtro.origen = "MensajesDocumentosNavBar"

    this.tableDataSourceService.setSearchFilters(filtro);
    this.router.navigate([
      '/main/documentos/37',
    ]);

    let owner = this.authService.getOwner();
    this.googleAnalyticsEventsService.emitEvent('Mensajeria', `Responder Msj - Empresa: ${owner.razonSocial}`, null, true);
  }
}
