<div class="header__login">
  <a
    href="https://www.planexware.com/"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img height="15" src="assets/planexware-white.svg" alt="Planexware"
  /></a>
</div>
<div class="header__login_body">
  <div class="col-md-12 login__card__wrapper">
    <mat-card class="login__card">
      <div class="login__header">
        <img height="25" src="assets/krikos360.new.svg" />
      </div>

      <div class="login__header set-padding">
        <mat-icon>cancel</mat-icon>
      </div>

      <div class="login__header set-padding">
        {{ "LINK_CADUCADO" | translate }}
      </div>

      <div class="login__header set-padding message">
        {{ "LINK_CADUCADO_MENSAJE" | translate }}
      </div>
    </mat-card>
  </div>
</div>
