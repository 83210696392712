<h1 class="plx-t3">Terms and conditions</h1>
<h4>1. ABOUT THIS DOCUMENT</h4>
<p>The following are the terms and conditions of use (hereinafter the "Terms and Conditions") applicable to the access and use of KRIKOS360 (hereinafter the "Platform" or the "System" indistinctly) belonging to PLANEXWARE S.A., domiciled at Alberti nr. 2154, 7th floor, Autonomous City of Buenos Aires, Republic of Argentina (hereinafter "PLANEXWARE") as well as the scope and restrictions of the services provided by it.</p>
<p>This document is a binding agreement between you (hereinafter the "User") and PLANEXWARE pursuant to the terms of Section 1105 and related provisions of the Civil and Commercial Code of the Republic of Argentina.</p>
<h4>2. ACCEPTANCE BY USERS</h4>
<p>PLANEXWARE, in its capacity as service provider, requests each of the users to accept and fulfill with the terms herein included as a necessary condition to access and use KRIKOS360 platform.</p>
<p>In this sense, any person who wishes to access and/or use KRIKOS360 platform may do so by observing the provisions herein and the national and local applicable legal rules, as the case may be.</p>
<p>Any User who does not accept the above mentioned, or does not agree thereto, or fails to comply with the Terms and Conditions herein, and/or with the Privacy Policy, shall not be authorized to access and use the System, and must refrain from any kind of access, login or use of the System.</p>
<p>Whenever a User logs in to the System through its ID with the credentials already granted, it will be understood that he accepts and agrees to the contents of this writing as a whole, and that he agrees to be bound by the provisions herein.</p>
<h4>3. USER CONDITION</h4>
<p class="subtitulo">3.1 Users of KRIKOS360</p>
<p>KRIKOS360 platform is not a free access service, but its use is subject to a previous agreement ("Membership Agreement") between PLANEXWARE as service provider and the customer (also the "Holder of the Service" or the "Licensee").</p>
<p>The Holder of the Service may authorize a certain number of KRIKOS360 users, belonging to its organization or not, according to the limits set forth in the Membership Agreement. In this sense, only those persons previously authorized by the holder of the service may become users of KRIKOS360, and the latter will be responsible for all the activities performed by the authorized users while using the Platform.</p>
<p>To become a "User", besides the express authorization by the Holder of the Service, it is necessary to accept these Terms and Conditions (according to the provisions herein set forth) and all those specific provisions applicable or to be applicable in the future pursuant to PLANEXWARE criteria in relation to the services provided by KRIKOS360.</p>
<p>However, to use certain services, previous registration of the User might be requested. The purpose of said registration is to determine the identity and information of the User's contact.</p>
<p class="subtitulo">3.2. Credentials to access KRIKOS360</p>
<p>When the Holder of the Service appoints a user for KRIKOS360, it shall provide an identification name and a password to let the user access the Platform on a personal, confidential and security basis, KRIKOS360 services have been conceived for the User's use, therefore, the access password granted by the Holder of the Service may only be used by the User, and it is forbidden its use by any other person rather than the user. The User is responsible for the care and custody of its access password, and it must inform immediately the Holder of the Service and PLANEXWARE whenever the confidentiality status is lost.</p>
<p>The User and the Holder of the Service will be jointly liable for all operations executed through the user's personal account, and he must notify immediately PLANEXWARE through any reliable means in case of any unauthorized use of his account and/or any login by unauthorized third party.</p>
<p>PLANEXWARE may decide the suspension or cancellation of a User's account at any time whatsoever, at its own criteria, and this decision does not imply any right of compensation to the User.</p>
<p class="subtitulo">3.3 User's legal capacity</p>
<p>KRIKOS360 may only be accessed and used by any person with capacity to express his consent and to execute agreements according to the legislation in force in his place of residence.</p>
<p>In case the User acts in representation of a legal person, he must have enough powers to enter into agreements on its behalf to bind it pursuant to the terms and conditions hereof. If the User does not have sufficient powers, or acts beyond the powers granted to him, it will be understood that the User is also bound personally.</p>
<h4>4. KRIKOS360 PLATFORM</h4>
<p>KRIKOS360 is a web integration platform which facilitates the transmission of e-documents and business data between different organizations using the system (holders of the service) through multiple communication protocols. It allows the conversion of all electronic formats, regardless the origin and destination, and admits different exchange systems. KRIKOS360 is a multi directional platform that allows not only vertical communication with the channel's partners but also horizontal communications between the users of the platform, as issuer and receiver of the information at the same time.</p>
<p>KRIKOS360 is not a repository aimed to store documents or messages. Within KRIKOS360, the storage of information is always temporary, and will be available for the Users only for a certain term as of the date of issue or reception. Upon expiration of that term, the information will be deleted, without possibilities to access it or to recover it any longer.</p>
<p>All services provided on KRIKOS360 are subject to changes, removal or replacement by PLANEXWARE at any time whatsoever.  Therefor, the number, type, quality, access conditions and use of the services offered by PLANEXWARE may change in time, without previous notice to the users, according to the commitments assumed by PLANEXWARE in the agreements entered into with each Holder of the Service.</p>
<h4>5. AVAILABILITY OF THE SERVICE</h4>
<p>PLANEXWARE makes its best efforts to keep and enhance the quality of its services. However, it does not guarantee a continuous and uninterrupted provision of KRIKOS360 services.  Likewise, the software systems on which KRIKOS360 depends might eventually not be available due to technical difficulties or to connectivity failures or to any other circumstance beyond PLANEXWARE control. The User acknowledges and accepts that access to the services provided by KRIKOS360 is only possible thanks to the infrastructure of resources provided by other third parties, as for example Internet connection providers, software and hardware providers, interconnection nodes, data carriers, electricity suppliers, etc, on which PLANEXWARE has no control whatsoever. Aware of the above mentioned, the User expressly waives any claim for compensation based on PLANEXWARE's strict liability or on any liability based on breach of a duty in case of failures, slowness and/or errors in the access and use of the services due to connectivity or communications problems, to failures or damages in the software systems or to any other possible contingency.</p>
<p>PLANEXWARE shall neither be liable in case of interruption of the service or of poor provision of services resulting from:</p>
<ol type="a">
    <li>Failures arising from computer viruses, Dos or DDoS attacks, unauthorized hacking into computer systems and any further circumstance susceptible to be qualified as a computer-related crime.</li>
    <li>Act of God or force majeure.</li>
    <li>Cyberterrorism activities according to the definition given by the U.N.</li>
    <li>Third parties criminal acts, such as sabotage, damage to the facilities, disruption of telecommunications, acts of government, emergencies, natural hazards, economic and financial crisis, war.</li>
    <li>Strikes or other trade union force actions.</li>
</ol>
<p>Besides, for a correct use of the services, Users must fulfill the following requirements: (a) have available a PC supporting the two last iterations chronologically published by the internet browser used (Edge, Chrome, Firefox, Safari); (b) broadband internet connection.</p>
<p>PLANEXWARE does not guarantee accessibility to services if the hardware used does not meet the above mentioned requirements or if accessibility settings are not correctly fixed. Requirements to access the site may change from time to time, so, therefore, the user accepts to update its software or hardware to use the service according to their technological development.</p>
<h4>6. USER'S DUTIES</h4>
<p class="subtitulo">6.1. Correct use of the services</p>
<p>The User agrees to use KRIKOS360 platform according to the regulations in force, to these Terms and Conditions and to the principles ruling morality, good conduct and public order. The User agrees to refrain from using the System for illegal and forbidden purposes, contrary to the provisions of these Terms and Conditions, or in detriment to third parties rights and interests.</p>
<p class="subtitulo">6.2. Supporting copies</p>
<p>The User acknowledges and agrees to make and store by its own all supporting copies of the messages and documents received and forwarded through KRIKOS360, since KRIKOS360 only stores on a temporary and limited basis all the documents and communications received and forwarded by the User.</p>
<p class="subtitulo">6.3. User's liabilities</p>
<p>Each user is solely liable for all actions executed within the framework of KRIKOS360 services. However, if PLANEXWARE receives a claim following the pertinent procedures, stating that a person was adversely affected by an unjustified detriment of any of his rights, PLANEXWARE will take immediately all the necessary measures to prevent the injurious situation and will inform the competent authorities about he circumstances of the case.</p>
<p class="subtitulo">6.4 Liability regarding messages and documents forwarding</p>
<p>Any User forwarding a message or a document, or who initiates a communication through KRIKOS360, shall be fully and exclusively liable for their forwarding, notwithstanding the joint liability of the Holder of the Service. The User shall indemnify PLANEXWARE whenever it shall act as a requested party in relation to the contents submitted by the user.</p>
<p class="subtitulo">6.5. Penalties Suspensions or disablements</p>
<p>Notwithstanding any further measures applicable by PLANEXWARE at its own criteria, it may warn, temporarily suspend of definitively disable a User's account in the following cases:</p>
<ol type="a">
    <li>If the User breaches a law, third parties rights, public order, morality and good conducts, or any of the provisions of the General Terms and Conditions or of the specific terms and conditions of each service or other provisions determined by PLANEXWARE.</li>
    <li>If the User fails to comply with his commitments as User.</li>
    <li>If at PLANEXWARE's criteria the User commits any willful or fraudulent misconduct or act.</li>
    <li>If the User develops activities aimed to harm the security and/or the normal function of the software systems belonging to PLANEXWARE or to any third party.</li>
</ol>
<h4>7. POWERS RELATED TO THE MANAGEMENT AND CONTROL OF SERVICES</h4>
<p class="subtitulo">7.1. Reserved powers</p>
<p>PLANEXWARE reserves all powers of control and management on KRIKOS360, in particular on the services, contents and communications therein contained. Thus, PLANEXWARE may introduce all changes and adjustments as it deems convenient at its own criteria, and may add, modify, substitute or delete any service at any time whatsoever.</p>
<p>NOTWITHSTANDING THE ABOVE MENTIONED RESERVED POWERS, AS REGARDS PRIVACY AND CONFIDENTIALITY OF THE USER'S COMMUNICATIONS, PLANEXWARE SHALL NOT EXERCISE ANY LEGAL AUTHORITY ON THE COMMUNICATIONS CARRIED OUT BETWEEN USERS.  THEREFORE, PLANEXWARE SHALL NOT BE LIABLE FOR THE SERVICES ' USE CONTRARY TO THE LAW INCURRED BY USERS.</p>
<p class="subtitulo">7.2. Authorization to intercept, access and divert communications</p>
<p>The USER expressly authorizes PLANEXWARE to monitor, supervise, intervene, detect, intercept, divert, access, suspend or interrupt e-communications forwarded through KRIKOS360 in order to supply the service, and to take action in any way whatsoever in case of any other circumstance which might in principle and preventively, be identified as hazardous, potentially hazardous or undesirable by PLANEXWARE. The User acknowledges and agrees that PLANEXWARE's activity in relation to his communications is consented and necessary to supply KRIKOS360 service, and that such activity is not covered by section 153 of the Argentine Criminal Code.</p>
<p>Likewise, the User authorizes PLANEXWARE to divert, suspend or interrupt his e-communications forwarded on KRIKOS360 in order to carry out maintenance and/or updating works in the software systems.</p>
<h4>8. LIABILITY DISCLAIMER FOR SERVICES AND CONTENTS</h4>
<p class="subtitulo">8.1 PLANEXWARE LIABILITY</p>
<p>PLANEXWARE KRICKOS360 services are supplied only as an auxiliary tool.  PLANEXWARE shall not be liable for any direct or indirect consequence arising from any action or omission to act executed by the User based on the information, on the services or on the elements accessed through KRIKOS360.</p>
<p>The User particularly agrees that PLANEXWARE shall not be liable for the access or the disruption of transmissions, data and/or files, or for any material or data forwarded or received, or not forwarded or not received.</p>
<p>PLANEXWARE shall not be liable for the loss of data and/or files, whatever the reasons, persons or circumstances thereof, except it is a result of its own and exclusive negligence or fraud.</p>
<p>Under no circumstance PLANEXWARE shall be liable for the usefulness, suitability, availability, accuracy, opportunity, reliability, appropriateness, updating, lawfulness or truthfulness of the files transmitted to and from KRIKOS360.</p>
<p>PLANEXWARE shall not be liable for a) any damage suffered by the User as a consequence of Internet own risks, including -but not limited to- interaction between KRIKOS360 and the User's hardware, or the disruption by third parties of information forwarded through KRIKOS360, or of information saved either in the User's or in PLANEXWARE's hardware and software; b) any disruption, loss or  data theft occurred while using public communications networks.</p>
<p>PLANEXWARE shall not be liable in case the User has problems while accessing KRIKOS360 as a consequence of the use of obsolete computer hardware or software or of poor quality internet connections or of weak broadband.</p>
<p>PLANEXWARE disclaims any liability for damages of any nature whatsoever which might be due to the lack of availability or to disruptions of the Product's operation, or to any disappointment caused by the expectations created by the users in relation to the scope of the Product.</p>
<p>PLANEXWARE DISCLAIMS WITH ALL THE EXTENT GRANTED BY THE LEGAL REGULATIONS IN FORCE, ANY LIABILITY FOR DAMAGES OF ANY NATURE WHATSOEVER DUE TO THE TRANSMISSION, DISCLOSURE, STORAGE, AVAILABILITY, FORWARDING AND RECEPTION, OF ANY AND ALL MESSAGES OR DOCUMENTS, AS WELL AS OF ANY FURTHER MATERIAL OR INFORMATION FORWARDED TO OR FROM KRIKOS360 PLATFORM.</p>
<p>PLANEXWARE shall not be liable for any circumstance (such as losses or any other similar circumstance), suffered by the User or any third party, which might arise from the loss, theft or misuse of the User's login or password.</p>
<p>The supply of the software shall be under the "as-is" mode, without any warranty, expressed or implied, of any nature in relation to a specific result.</p>
<p class="subtitulo">8.2. Confidentiality of the Information </p>
<p>PLANEXWARE shall deal with the User's information under strict confidentiality rules to ensure privacy, and under no circumstance it shall access or use the Users' information, except for those matters strictly related to the supply of KRIKOS360 services (pursuant to section 3 of Act 24766).</p>
<p class="subtitulo">8.3 Third parties material </p>
<p>While using KRIKOS360, the User may access to documents provided by third parties. PLANEXWARE does not grant any kind of guarantee on the lawfulness, accuracy, truthfulness and/or authenticity of the material provided by third parties.</p>
<h4>9. PRIVACY POLICY</h4>
<p><a ng-href="#/politicasDePrivacidad/en-US">See Privacy Policy</a></p>
<h4>10. CONTACT INFORMATION</h4>
<p>Users may contact PLANEXWARE and all communications forwarded to the following e-mail address shall be valid: <a href="mailto:info@planexware.com">info@planexware.com</a></p>
<p>All notices and communications sent by PLANEXWARE to the postal address, to the e-mail address informed directly by the user, to the address informed in the registration forms or to the address appearing as the sender's address if the User contacts a non-registered guest, shall be considered valid and effective. Likewise, all communications consisting of prompts and messages posted in KRIKOS360 or sent during the provision of a service with the purpose of informing the users about any special circumstance, shall also be considered valid and effective.</p>
<h4>11. CHANGES AND ADJUSTMENTS</h4>
<p>PLANEXWARE may introduce all changes and adjustments as it deems convenient at its own criteria, on these Terms and Conditions, at any time whatsoever, and without previous notice, only publishing the modified Terms in KRIKOS360 access page. All modified terms and conditions shall be effective as of the day following publication. Within 3 (three) days after publication of the modifications made by PLANEXWARE, the User must inform via e-mail if he rejects them, and such being the case, the services agreement will be terminated and the User will be disabled to act as such. Upon expiration of that term, it will be understood that the User accepts the new terms and conditions.</p>
<h4>12. JURISDICTION AND VENUE</h4>
<p>This agreement is ruled by the laws in force in the Republic of Argentina. Any claim arising from this agreement. related to its existence, validity, construction, scope or execution, shall be filed to the National Courts with jurisdiction in the Autonomous City of Buenos Aires.</p>
<br />
<div class="revision left">
    <span><strong>Code: POL/DES-8-008</strong></span>
    <br />
    <span>Revision: 002</span>
    <br />
    <span>F.U.R: 08/02/2022</span>
    <br />
</div>
<br />
