import { FormControl, FormGroupDirective, NgForm } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";

export class ErrorTipoDocNroDocumentoStateMatcher implements ErrorStateMatcher {
    public valid: boolean = true;

    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      if (form!.form.get("numeroDocumento")!.value != null && form!.form.get("numeroDocumento")!.value != '')
      {
        if (form!.form.get("tipoDocumento")!.value == 'todos' || (form!.form.get("tipoDocumento")!.value).resourceKey == 'todos')
        {
          this.valid = false;
          return true;
        }
        else
        {
          this.valid = true;
          return false;
        }
      }
      else
      {
        if (form!.form.get("tipoDocumento")!.value == 'todos' || (form!.form.get("tipoDocumento")!.value).resourceKey == 'todos')
        {
          this.valid = true;
          return false;
        }
        else
        {
          this.valid = false;
          return true;
        }
      }
    }
  }
  