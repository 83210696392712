<div class="toaster__alert toaster__alert{{getStyleClass(message.tipoMensaje)}}">
  <button class="toaster__close-button" (click)="closeAlert(message.id)">
    <span>×</span>
  </button>
  <div class="toaster__content">
    <div class="toaster__icon">
      <fa-icon [icon]="['fas', getIcon(message.tipoMensaje)]" transform="grow-3"></fa-icon>
    </div>
    <div [innerHTML]="message.mensaje" style="    padding-right: 0;"></div>
    
    <div *ngIf="message.tipoMensaje == 'Renovacion'" style="padding-left: 0">
      <button
        type="button"
        class="btn critical-btn-alert check"
        (click)="renovarAbono()"
        translate
      >
        RENOVAR_ABONO
      </button>
    </div>
    
  </div>
</div>
