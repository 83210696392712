<div
  id="{{ modalId }}"
  class="modal-background animated fade"
  [ngClass]="modalId === modalService._openModal ? 'visible show' : 'invisible'"
  #modalBackground
>
  <mat-card class="modal--body">
    <!-- <div class="modal--close-button" (click)="close()">
      <fa-icon [icon]="['fas', 'times']" transform="grow-4"></fa-icon>
    </div> -->
    <ng-content></ng-content>
  </mat-card>
</div>

