import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Cliente } from '../modelos/Cliente';
import { ConfiguracionSeguridadView } from '../modelos/ConfiguracionSeguridadView';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionSeguridadService {
  constructor(public http: HttpClient) { 
  }

  getComplejidadesHash(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/ComplejidadesHash`)
  }

  getConfiguracionSeguridad(empresaActual: any): Observable<any> {
    return this.http.get(`${environment.apiUrl}/Paises/${empresaActual.IdPais}/Clientes/${empresaActual.Id}/ConfiguracionSeguridad`)
  }

  putConfiguracionSeguridad(empresaActual: any, config: ConfiguracionSeguridadView) {
    return this.http.put(`${environment.apiUrl}/Paises/${empresaActual.IdPais}/Clientes/${empresaActual.Id}/ConfiguracionSeguridad`, config)
  }
  
}
