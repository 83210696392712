import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Cliente } from 'src/app/pages/clientes-mixtos/modelos/Cliente';
import { environment } from 'src/environments/environment';
import { BusquedaDocumentos } from '../../../models/BusquedaDocumentos';
import { ClienteDocumentos } from '../../../models/ClienteDocumentos';
import { TipoDocumentoClientes } from '../../../models/TipoDocumentoClientes';

@Injectable({
  providedIn: 'root'
})
export class DialogFiltersService {
  
  getEstados(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/DocumentosCliente/GetEstados`)
  }

  getDocumentos(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/DocumentosCliente/GetTiposDocumento`)
  }

  getTiposDocumentosPorClientes(clientes:Cliente[]): Observable<TipoDocumentoClientes[]> {
    return this.http.post<TipoDocumentoClientes[]>(`${environment.apiUrl}/maestros/GetTiposDocumento`,clientes);
  }

  getInterlocutores() : Observable<any>{
    return this.http.get(`${environment.apiUrl}/DocumentosCliente/GetInterlocutores`)
  }

  getEstructuras(interlocutor : ClienteDocumentos) : Observable<any>{
    return this.http.get(`${environment.apiUrl}/DocumentosCliente/GetEstructuras/Id/${interlocutor.id}/IdPais/${interlocutor.idPais}`)
  }

  getEstadosSeguimiento(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/DocumentosCliente/GetEstadosSeguimiento`)
  }

  getFechas(): string[] {
    let fechas: string[] = ["HOY", "AYER", "ULTIMOS_3_DIAS", "ULTIMOS_7_DIAS", "ULTIMOS_30_DIAS", "ULTIMOS_90_DIAS", "DEFINIR_UN_RANGO"];
    return fechas;
  }
  
  putFilters(filters: BusquedaDocumentos){
    return this.http.put(`${environment.apiUrl}/DocumentosCliente/SaveFiltroBusquedaDocumentos`, filters)
  }

  postGetDocumentosTest(clientes: Cliente[]):Observable<boolean>{
    return this.http.post<boolean>(`${environment.apiUrl}/DocumentosCliente/GetDocumentosTest`, clientes)
  }

  constructor(public http: HttpClient) { }
}