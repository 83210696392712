import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationStrategy, DOCUMENT } from "@angular/common";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {

  fullURL: string
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private locationStrategy: LocationStrategy,
    @Inject(DOCUMENT) private document: Document
  ) {
    // //console.log('*** ROUTER URL');
    // //console.log(router.url);
    // //console.log('*** BASE_HREF');
    // //console.log(this.locationStrategy.getBaseHref());
    // //console.log('FULL URL');

    this.fullURL = `${environment.url}/index.html#${router.url}`
    ////console.log('Redirecting to:');
    ////console.log(this.fullURL);
  }



  ngOnInit(): void {
    this.document.location.href = this.fullURL
    // this.router.navigate(['/../v1/legacyComponent']);
  }
}
