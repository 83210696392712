import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MesajeDocumentosChatNotificacion } from '../models/mensajesDocumentos.interface';

@Injectable({
  providedIn: 'root'
})
export class MensajesDocumentosService {

  mensajesDocumentosCount$: BehaviorSubject<number> = new BehaviorSubject(0);
  mensajesDocumentosMock$: BehaviorSubject<Array<MesajeDocumentosChatNotificacion>> = new BehaviorSubject([] as Array<MesajeDocumentosChatNotificacion>);
  tieneServicioNoChat$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  interval = 300000 // 5 min


  constructor(
    private toastr: ToastrService,
    private translateService: TranslateService,
    private http: HttpClient,
  ) {
    timer(0, this.interval).subscribe(() => {
      this.getMensajesDocumentosAmount()
    })
  }

  getMensajesDocumentosAmount() {
    this.http.get<number>(`${environment.apiUrl}/PanelMensajeriaChat/GetCantidadMensajes`)
      .subscribe(
        (res) => {
          this.mensajesDocumentosCount$.next(res)
        },
        (err) => {
          this.toastr.error(this.translateService.instant('ERROR_APLICACION'))
        }
      )
  }

  getCantidadMensajesConRetorno(): Observable<number>{
    return this.http.get<number>(`${environment.apiUrl}/PanelMensajeriaChat/GetCantidadMensajes`)
  }

  getMensajesDocumentos() {
    this.http.get<Array<MesajeDocumentosChatNotificacion>>(`${environment.apiUrl}/PanelMensajeriaChat/GetMensajes`)
      .subscribe(
        (res) => {
          this.mensajesDocumentosMock$.next(res);
        },
        (err) => {
          this.toastr.error(this.translateService.instant('ERROR_APLICACION'))
        }
      );
  }

  checkServicioNoChat() {
    this.http.get<boolean>(`${environment.apiUrl}/PanelMensajeriaChat/GetCheckServicio`)
      .subscribe(
        (res) => {
          this.tieneServicioNoChat$.next(res);
        },
        (err) => {
          this.toastr.error(this.translateService.instant('ERROR_APLICACION'))
        }
      );

  }
}
