import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface Data {
  titulo: string;
  mensaje: string;
  params?: Object;
  mensaje2?: string;
  confirmacion: string;
  rechazo: string;
}

@Component({
  selector: 'app-confirmacion-dialog',
  templateUrl: './confirmacion-dialog.component.html',
  styleUrls: ['./confirmacion-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmacionDialogComponent {
  public element: string;

  constructor(
    public dialogo: MatDialogRef<ConfirmacionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data) { }
  
  cerrarDialogo(): void {
    this.dialogo.close(false);
  }
  confirmado(): void {
    this.dialogo.close(true);
  }
}
