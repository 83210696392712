import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { isNullOrEmptyOrUndefined } from 'src/app/shared/utils/utils.component';

@Directive({
  selector: '[campoNumerico]'
})
export class CampoNumericoDirective implements OnInit {

  @Input() campoNumerico: { length: number, esAlfaNumerico: boolean };
  navigationArrows: string[] = ['Delete', 'Backspace', 'Tab', 'Escape', 'Enter', 'Home', 'End', 'ArrowLeft', 'ArrowRight']
  FRegex: RegExp = /F[0-9]{1,2}$/
  regex: RegExp;
  campo: FormControl;
  
  constructor(
    private el: ElementRef,
    public control: NgControl) { }
  
  ngOnInit(){
    if(this.campoNumerico.esAlfaNumerico) {
      this.FRegex = /^[a-zA-Z0-9_]*$/;
    }

    this.campo = this.control.control as FormControl;
    if(this.campoNumerico){
      this.regex = new RegExp(`^\\d{0,${this.campoNumerico}}$`);
    }
    else {
      this.regex =  new RegExp(`^\\d*$`);
    }
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    this.onKeyDown(event);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    
    let e = <KeyboardEvent>event;
    if (
      // Allow: home, end, left, right'Delete', 'Backspace', 'Tab', 'Escape', 'Enter'
      this.navigationArrows.indexOf(e.key) !== -1
      // Allow: Ctrl+A
      || (e.key === 'a' && (e.ctrlKey || e.metaKey))
      // Allow: Ctrl+C
      || (e.key === 'c' && (e.ctrlKey || e.metaKey))
      // Allow: Ctrl+V
      || (e.key === 'v' && (e.ctrlKey || e.metaKey))
      // Allow: Ctrl+X
      || (e.key === 'x' && (e.ctrlKey || e.metaKey))
      // Allow FX keys
      || (this.FRegex.test(e.key))
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (e.key === ' ' || isNaN(Number(e.key))) {
      e.preventDefault();
    }
  }

  @HostListener('focusout')
  onFocusOut() {
    var element = this.el.nativeElement as HTMLInputElement;
    let valor = element.value;
    if(this.campoNumerico.length > 0 && !isNullOrEmptyOrUndefined(valor)){
      this.campo.setValue(valor.padStart(this.campoNumerico.length,'0'));
    }
    this.campo.updateValueAndValidity();
  }

  @HostListener('paste',['$event'])
  onPaste(event: ClipboardEvent) {
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const endPos = this.el.nativeElement.selectionEnd;
    let clipboard: string = event.clipboardData!.getData('text');;

    const next: string = [current.slice(0, position), clipboard , current.slice(endPos)].join('');
    if (next) {
      if(!this.regex.test(next)){
        this.campo.setValue('');
        event.preventDefault();
      }else{
        return
      }
    }
  }
}
