import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { ClientInfo } from 'src/app/core/models/accessToken';
import { AuthService } from 'src/app/core/services/auth.service';
import { JwtService } from 'src/app/core/services/jwt.service';
import { LogoService } from 'src/app/core/services/logo.service'; import { DialogFiltersService } from '../../documentos-clientes/components/filters/dialog-filters/service/dialog-filters.service';
import { BusquedaDocumentos } from '../../documentos-clientes/components/models/BusquedaDocumentos';
import { ClienteRelacionDocumentosCliente } from '../../documentos-clientes/components/models/ClienteRelacionDocumentosCliente';
import { TipoDocFiltro } from '../../documentos-clientes/components/models/TipoDocFiltro';
import { TipoDocumentoClientes } from '../../documentos-clientes/components/models/TipoDocumentoClientes';
import { DocumentosClienteService } from '../../documentos-clientes/components/services/documentos-cliente.service';
import { TableDataService } from '../../documentos-clientes/components/services/table-data.service';
import { Documento } from './modelos/Documento';
import { Proveedor } from './modelos/proveedor';
import { UltimoDoc } from './modelos/ultimoDoc';
import { AccesosDirectosHomeService } from './servicios/accesos-directos-home.service';


const MAX_PROVEEDORES = 8;

@Component({
  selector: 'app-accesos-directos-pp',
  templateUrl: './accesos-directos-pp.component.html',
  styleUrls: ['./accesos-directos-pp.component.scss']
})
export class AccesosDirectosPpComponent implements OnInit {

  mostrarProveedores = false;
  empresaActual: ClientInfo | null;

  ultimosDocs: any[];
  mostrarUltimosDocs = false;
  grupos: any;

  // ordenar por fecha ultimo doc
  proveedores: Proveedor[] = []

  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 4,
    "infinite": false,
    "arrows": true,
    "draggable": false,
    "responsive": [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  proveedoresConfig = {
    "slidesToShow": 8,
    "slidesToScroll": 1,
    "infinite": false,
    "arrows": true,
    "draggable": false,
    "responsive": [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  constructor(private logoService: LogoService,
    private ADService: AccesosDirectosHomeService,
    private jwtService: JwtService,
    private service: DocumentosClienteService,
    private authService: AuthService,
    private dialogFiltersService: DialogFiltersService,
    private tableDataSourceService: TableDataService,
    private router: Router) { }

  ngOnInit(): void {
    this.empresaActual = this.setEmpresa();

    this.ADService.getUltimosDocs(this.empresaActual?.Id!, this.empresaActual?.IdPais!).subscribe(ultimosDocs => {
      this.ultimosDocs = ultimosDocs;
      if (this.ultimosDocs.length > 0) {
        this.mostrarUltimosDocs = true;
      }
    })

    this.ADService.getGrupos().subscribe(grupos => {
      let result = grupos.reduce(function (r: any, grupo: any) {
        r[grupo.tipoGrupo] = r[grupo.tipoGrupo] || [];
        r[grupo.tipoGrupo].push(new Documento(grupo.idTipoDocumento, grupo.tipoDocumento));
        return r;
      }, Object.create(null));
      this.grupos = result;
    })

    this.ADService.getPortalDeProveedores(this.empresaActual?.Id!, this.empresaActual?.IdPais!).subscribe(proveedores => {
      this.proveedores = proveedores;
      if (this.proveedores.length > 0) {
        this.mostrarProveedores = true;
      }
      this.proveedores = this.shuffleAndPickProveedores(this.proveedores);
    })
  }

  setEmpresa(): ClientInfo | null {
    return this.jwtService.getAccessToken()?.userdata?.Cliente ?? null;
  }

  agregarTimeZone(): string {
    return this.authService.getTimeZone();
  }

  shuffleAndPickProveedores(arrayProveedores: Proveedor[]) {
    const shuffledArray = arrayProveedores.sort((a, b) => 0.5 - Math.random());
    return shuffledArray;
  }

  setearFechassessionStorage(filtrosessionStorage: BusquedaDocumentos): void {
    filtrosessionStorage.fechaDesde = moment(filtrosessionStorage.fechaDesde).isValid() ? moment(filtrosessionStorage.fechaDesde) : null;
    filtrosessionStorage.fechaHasta = moment(filtrosessionStorage.fechaHasta).isValid() ? moment(filtrosessionStorage.fechaHasta) : null;
    filtrosessionStorage.fechaVencimientoDesde = moment(filtrosessionStorage.fechaVencimientoDesde).isValid() ? moment(filtrosessionStorage.fechaVencimientoDesde) : null;
    filtrosessionStorage.fechaVencimientoHasta = moment(filtrosessionStorage.fechaVencimientoHasta).isValid() ? moment(filtrosessionStorage.fechaVencimientoHasta) : null;
    filtrosessionStorage.fechaEmisionDesde = moment(filtrosessionStorage.fechaEmisionDesde).isValid() ? moment(filtrosessionStorage.fechaEmisionDesde) : null;
    filtrosessionStorage.fechaEmisionHasta = moment(filtrosessionStorage.fechaEmisionHasta).isValid() ? moment(filtrosessionStorage.fechaEmisionHasta) : null;
    filtrosessionStorage.fechaEntregaDesde = moment(filtrosessionStorage.fechaEntregaDesde).isValid() ? moment(filtrosessionStorage.fechaEntregaDesde) : null;
    filtrosessionStorage.fechaEntregaHasta = moment(filtrosessionStorage.fechaEntregaHasta).isValid() ? moment(filtrosessionStorage.fechaEntregaHasta) : null;
  }

  onGrupoClick(event: any, doc: UltimoDoc) {
    if (doc.resourceKeyGrupo === "facturasIncompletas") {
      const busqueda = new BusquedaDocumentos();
      busqueda.estadosDocumentos.push(doc.idEstadoDocumento);
      busqueda.criterioFiltroFecha = "ULTIMOS_90_DIAS";
      busqueda.rangoFecha = 90;
      this.setearFechassessionStorage(busqueda);
      this.tableDataSourceService.setSearchFilters(busqueda);
      this.router.navigate([
        '/main/documentos/37',
      ]);
      return;
    }
    this.ADService.getFiltroHome("90DHOME").pipe(take(1)).subscribe((data: BusquedaDocumentos) => {
      this.dialogFiltersService.getTiposDocumentosPorClientes([this.authService.getOwner()]).subscribe((tiposDocumentosClientes: TipoDocumentoClientes[]) => {
        let documentosFiltrados: TipoDocumentoClientes[] = this.filterDuplicados(tiposDocumentosClientes);
        if (this.grupos[doc.resourceKeyGrupo]) {
          this.grupos[doc.resourceKeyGrupo].forEach((documento: any) => {
            if (documentosFiltrados.find((docFiltrado: TipoDocumentoClientes) => docFiltrado.idTipoDocumento === documento.id && docFiltrado.sentido === this.resolverSentido(doc.sentido.toUpperCase()))) {
              let documetoGrupo: TipoDocFiltro = { id: documento.id, resourceKey: documento.resourceKey, sentido: this.resolverSentido(doc.sentido.toUpperCase()) }
              data.tiposDocumentos.push(documetoGrupo);
            }
          });
        }
        else {
          let documetoGrupo: TipoDocFiltro = { id: doc.documento.id, resourceKey: doc.documento.resourceKey!, sentido: this.resolverSentido(doc.sentido.toUpperCase()) }
          data.tiposDocumentos.push(documetoGrupo);
        }
        this.setearFechassessionStorage(data);
        this.tableDataSourceService.setSearchFilters(data);
      });
    })

    this.router.navigate([
      '/main/documentos/37',
    ]);
  }
  resolverSentido(sentido: string): string {
    if (sentido === 'ENVIADAS' || sentido === 'ENVIADOS' || sentido === 'ENVIADO' || sentido === 'ENVIADA') {
      return 'ENVIADO';
    } else {
      return 'RECIBIDO';
    }
  }

  filterDuplicados(res: TipoDocumentoClientes[]): TipoDocumentoClientes[] {
    let tiposDocumento: TipoDocumentoClientes[] = [];
    res.forEach(element => {
      let encontrado = false;
      tiposDocumento.forEach(element2 => {
        if (element.resourceKeyTipoDocumento === element2.resourceKeyTipoDocumento && element.esDeEmision === element2.esDeEmision) {
          encontrado = true;
        }
      });
      if (!encontrado) {
        tiposDocumento.push(element);
      }
    });
    return tiposDocumento;
  }

  onProveedorClick(event: any, proveedor: any) {
    this.ADService.getFiltroHome("90DHOME").pipe(take(1)).subscribe((data: BusquedaDocumentos) => {
      let interlocutor: ClienteRelacionDocumentosCliente = { id: proveedor.id, idPais: proveedor.idPais };
      data.interlocutores.push(interlocutor);
      this.setearFechassessionStorage(data);
      this.tableDataSourceService.setSearchFilters(data);
    })

    this.router.navigate([
      '/main/documentos/37',
    ]);
  }

}
