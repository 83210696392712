import { Injectable } from "@angular/core";

import { BehaviorSubject } from "rxjs";

@Injectable()
 export class StorageService {

  getHoldingMode() : boolean {
    const clientesHolding: any = JSON.parse(sessionStorage.getItem("ClientesHolding")!)
    return clientesHolding.MostrarHolding;
  }

  getClientesHolding() : any { 
    return JSON.parse(sessionStorage.getItem('ClientesHolding') ?? '{}');
  }
  
//   private cantidadItemsEvent = new BehaviorSubject<number>(0);
//   cantidadItemsActual = this.cantidadItemsEvent.asObservable();

//   setEmpresaVendedora(empresaVendedora: EmpresaView) {
//     localStorage.setItem("empresaVendedora", JSON.stringify(empresaVendedora));
//   }

//   getEmpresaVendedora()
//     : EmpresaView {
//     return JSON.parse(localStorage.getItem("empresaVendedora"));
//   }

//   setEmpresa(empresa: EmpresaView) {
//     localStorage.setItem("empresa", JSON.stringify(empresa));
//   }

//   getEmpresa()
//     : EmpresaView {
//     return JSON.parse(localStorage.getItem("empresa"));
//   }

//   setUsuario(usuario: UsuarioView) {
//     localStorage.setItem("usuario", JSON.stringify(usuario));
//   }

//   getUsuario()
//     : UsuarioView {
//     return JSON.parse(localStorage.getItem("usuario"));
//   }

//   setDivisionesHabilitadas(divisionesHabilitadas: DivisionView[]) {
//     localStorage.setItem("divisionesHabilitadas", JSON.stringify(divisionesHabilitadas));
//   }

//   getDivisionesHabilitadas()
//     : DivisionView[] {
//     return JSON.parse(localStorage.getItem("divisionesHabilitadas"));
//   }

//   setSucursalesHabilitadas(sucursalesHabilitadas: SucursalView[]) {
//     localStorage.setItem("sucursalesHabilitadas", JSON.stringify(sucursalesHabilitadas));
//   }

//   getSucursalesHabilitadas()
//     : SucursalView[] {
//     return JSON.parse(localStorage.getItem("sucursalesHabilitadas"));
//   }

//   setLugaresDeEntregaHabilitados(lugaresDeEntregaHabilitados: LugarDeEntregaView[]) {
//     localStorage.setItem("lugaresDeEntregaHabilitados", JSON.stringify(lugaresDeEntregaHabilitados));
//   }

//   getLugaresDeEntregaHabilitados()
//     : LugarDeEntregaView[] {
//     return JSON.parse(localStorage.getItem("lugaresDeEntregaHabilitados"));
//   }

//   setCabeceraPedido(cabeceraPedido: PedidoCabeceraView) {
//     localStorage.setItem("cabeceraPedido", JSON.stringify(cabeceraPedido));
//   }

//   getDetallePedido()
//     : ItemDePedidoView[] {
//     return JSON.parse(localStorage.getItem("detallePedido"));
//   }

//   setDetallePedido(detallePedido: ItemDePedidoView[]) {
//     localStorage.setItem("detallePedido", JSON.stringify(detallePedido));
//     // mover esto al carrito service cuando se saque el carrito del localstorage
//     this.cantidadItemsEvent.next(detallePedido != null ? detallePedido.length : 0);
//   }

//   getCabeceraPedido()
//     : PedidoCabeceraView {
//     return JSON.parse(localStorage.getItem("cabeceraPedido"));
//   }

//   setFiltroPedidos(filtroPedidos: FiltroPedidos) {
//     localStorage.setItem("filtroPedidos", JSON.stringify(filtroPedidos));
//   }

//   getFiltroPedidos()
//     : FiltroPedidos {
//     return JSON.parse(localStorage.getItem("filtroPedidos"));
//   }
 }
