<mat-toolbar
  class="mat-toolbar-support"
  style="flex-wrap: wrap; justify-content: space-between"
>
  <div style="display: flex; align-items: center">
    <mat-icon>info</mat-icon>
    <span
      >{{ "ESTA_UTILIZANDO_KRIKOS360_EN_NOMBRE_DE" | translate }}
      {{ client }}
      ({{ pais}})</span
    >
  </div>
  <!-- <span style="flex: 1 1 20rem"></span> -->
  <button mat-raised-button (click)="disconnectSupport()">
    {{ "RESTAURAR_PERFIL" | translate | uppercase }}
  </button>
</mat-toolbar>
<hr class="line" />
