import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-politicas-de-privacidad',
  templateUrl: './politicas-de-privacidad.component.html',
  styleUrls: ['./politicas-de-privacidad.component.scss']
})
export class PoliticasDePrivacidadComponent implements OnInit {

  idioma: string;
  template: any;
  constructor(private translate: TranslateService,
              public authService: AuthService,) { }

  ngOnInit(): void {
    this.idioma = this.authService.getUserData()?.Cultura || "es-AR"
    if(this.idioma == "es-419"){
      this.idioma = "es-AR";
    }
  }

}
