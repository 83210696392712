import { Component, OnInit } from '@angular/core'
import { NavItem, MainNavService, NewDocumentItem } from '../../core/services/main-nav.service'
import { icons as navIcons } from './main-nav.icons'
import { MatDialog } from '@angular/material/dialog'

import { GoogleAnalyticsEventsService } from 'src/app/core/services/google-analytics-events.service'
import { AuthService } from 'src/app/core/services/auth.service'
import { DialogConciliacionContactarComponent } from '../dialog-conciliacion-contactar/dialog-conciliacion-contactar.component'
import { ConfigurationServicesService } from 'src/app/core/services/configuration-services.service'
import { environment } from 'src/environments/environment'
import { DialogFakeDoorButtonComponent } from '../dialog-fake-door-button/dialog-fake-door-button.component'

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
})
export class MainNavComponent implements OnInit {
  mainNavIcons = navIcons
  navItems: NavItem[] = []
  newDocumentItems: any[] = []
  conciliacionVisible = false;
  seguimientoEntregaFakeDoorButtonVisible = false;

  constructor(private mainNavService: MainNavService,
    public dialog: MatDialog,
    private authService: AuthService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private configurationServicesService: ConfigurationServicesService, 
    ) {
  }

  async ngOnInit(): Promise<void> {
    this.mainNavService.getMainItems().subscribe((data) => {
      this.navItems = data;
      //TODO: FIX
      this.navItems.forEach((item) => {
        item.url = "/main" + item.url
      });
    });
    this.mainNavService.getNewDocumentItems().subscribe((data: any[]) => {
      this.newDocumentItems = data;
    });
    let userdata = this.authService.userdata;
    let EmFacRetail = await this.configurationServicesService.ValidarClienteTieneServicioContratado(userdata.Cliente.Id,userdata.Cliente.IdPais,'EmFacRetail');
    let EmNcreRetail = await this.configurationServicesService.ValidarClienteTieneServicioContratado(userdata.Cliente.Id, userdata.Cliente.IdPais,'EmNcreRetail');
    let EmFCMiPymeRetail = await this.configurationServicesService.ValidarClienteTieneServicioContratado(userdata.Cliente.Id,userdata.Cliente.IdPais,'EmFCMiPymeRetail');
    let EmNCMiPymeRetail = await this.configurationServicesService.ValidarClienteTieneServicioContratado(userdata.Cliente.Id,userdata.Cliente.IdPais,'EmNCMiPymeRetail');

    this.conciliacionVisible = (EmFacRetail || EmNcreRetail || EmFCMiPymeRetail || EmNCMiPymeRetail) && environment.conciliacion && !this.authService.esPlanexware();
    if(this.conciliacionVisible)
      this.googleAnalyticsEventsService.emitEvent('Boton en barra - Conciliacion', 'Boton en barra - Conciliacion', null, true);

    this.evaluarFakeDoorButtonSeguimientoEntrega();
  }

  evaluarFakeDoorButtonSeguimientoEntrega(){
    this.seguimientoEntregaFakeDoorButtonVisible = environment.fakeDoorButton.seguimientoDeEntrega.visible && !this.authService.esPlanexware();
    if(this.seguimientoEntregaFakeDoorButtonVisible){
      const ga = 'Boton en barra - '+ environment.fakeDoorButton.seguimientoDeEntrega.GA;
      this.googleAnalyticsEventsService.emitEvent(ga, ga, null, true);
    }
  }

  onClickConciliacion(){
    let userdata = this.authService.userdata;
    let logAnalytic = "Mostrar - " + userdata.Cliente.RazonSocial + ' - ' + userdata.Nombre + ' ' + userdata.Apellido + ' - ' + userdata.NombreUsuario;
    this.googleAnalyticsEventsService.emitEvent('Conciliacion', logAnalytic, null, true);
    this.dialog.open(DialogConciliacionContactarComponent);
  }

  onClickFakeDoorButton(){
    const userdata = this.authService.userdata;
    const logAnalytic = "Mostrar - " + userdata.Cliente.RazonSocial + ' - ' + userdata.Nombre + ' ' + userdata.Apellido + ' - ' + userdata.NombreUsuario;
    this.googleAnalyticsEventsService.emitEvent(environment.fakeDoorButton.seguimientoDeEntrega.GA, logAnalytic, null, true);

    this.dialog.open(DialogFakeDoorButtonComponent,
      {
        data: {
          title: 'FAKE_DOOR_BUTTON.SEGUIMIENTO_DE_ENTREGAS_TITLE',
          text: 'FAKE_DOOR_BUTTON.SEGUIMIENTO_DE_ENTREGAS_DESC',
          imgName:'IMG_BLURRED_SEGUIMIENTO_ENTREGA.png',
          GAEventCategory: environment.fakeDoorButton.seguimientoDeEntrega.GA,
          imgWidth: '58%',
        },
        autoFocus: false,
        maxHeight: '95vh',
        panelClass: 'fake-door-dialog-container' 
      }
    );

  }
}
