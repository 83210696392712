import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @Input() modalId: String
  @Input() closeFromBackground: Boolean = true

  @ViewChild('modalBackground') modalBackground: ElementRef

  constructor(public modalService: ModalService, private renderer: Renderer2) {
    
  }

  ngOnInit(): void {
  }
  
  ngAfterViewInit() {
    this.renderer.listen(this.modalBackground.nativeElement, 'click',(e:Event)=>{
      if (e.target && this.closeFromBackground && e.target == this.modalBackground.nativeElement) {
        this.close()
      }
      /**
       * Only run when toggleButton is not clicked
       * If we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * And the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition abbove must close the menu
       */
    //  if(e.target !== this.toggleButton.nativeElement && e.target!==this.menu.nativeElement){
    //      this.isMenuOpen=false;
    //  }
 });
    
   }

  handleClickEvent(e: Event) {
  }

  close(): void {
    this.modalService.closeModal()
  }

  backgroundClose() {
  }

}
