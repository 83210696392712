import { FormControl, FormGroupDirective, NgForm } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";

export class ErrorOperadorSaldoStateMatcher implements ErrorStateMatcher {
  public valid: boolean = true;

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    if (form!.form.get("saldo")!.value != null && form!.form.get("saldo")!.value != '')
    {
      if (form!.form.get("operador")!.value == 'todos' || form!.form.get("operador")!.value == 'TODOS')
      {
        this.valid = false;
        return true;
      }
      else
      {
        this.valid = true;
        return false;
      }
    }
    else
    {
      if (form!.form.get("operador")!.value != 'todos' && form!.form.get("operador")!.value != 'TODOS')
      {
        this.valid = false;
        return true;
      }
      else
      {
        this.valid = true;
        return false;
      }
    }
  }
}
