<mat-card class="popup">
  <mat-toolbar class="popup__title">
    <h3 class="popup__title__text">
      {{ "DATOS_DE_MI_ADMINISTRADOR" | translate }}</h3
    >
  </mat-toolbar>

  <div class="popup__content">
    <div *ngFor="let admin of administrators">
      <mat-card class="popup__content__mat-card">
        <div class="person__wrapper">
          <!-- <mat-icon class="popup__content__mat-card__Icons" color="primary"
            >account_circle</mat-icon
          > -->
          <ngx-avatar
            [name]="admin.nombre"
            size="30"
            [round]="true"
            initialsSize="1"
          ></ngx-avatar>
          <div class="info">
            <p style="text-align: initial">
              {{ admin.nombre }} {{ admin.apellido }}
            </p>
            <a
              style="float: left; margin-left: 75px; font-size: 12px;"
              href="mailto:{{ admin.email }}"
              >{{ admin.email }}</a
            >
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</mat-card>

<button (click)="closeModal()">{{ "CERRAR" | translate }}</button>
