import { Component, Inject, OnInit , ElementRef, OnDestroy} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ExportService } from 'src/app/core/services/export.service';

export interface Data {
  url: string;
}
@Component({
  selector: 'app-dialog-view-documents',
  templateUrl: './dialog-view-documents.component.html',
  styleUrls: ['./dialog-view-documents.component.scss']
})

export class DialogViewDocumentsComponent implements OnDestroy {
  loading = true;
  url: any = null;
  private urlSubscription: Subscription | undefined;
  constructor(    @Inject(MAT_DIALOG_DATA) public data: Data,
  private dialogRef: MatDialogRef<DialogViewDocumentsComponent>, private _domElement: ElementRef, private exportService: ExportService) { }


  closeDialog(){
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.urlSubscription?.unsubscribe();
  }

  public setDoc(value:any){
    if(value){
      this.loading = false;
      this.url = value;
    }   
  }
}
