import {
    AfterViewInit,
    Directive,
    Host,
    Optional,
    Renderer2,
    Self,
    ViewContainerRef,
    Input
  } from '@angular/core';
  import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BusquedaDocumentos } from 'src/app/pages/documentos-clientes/components/models/BusquedaDocumentos';
import { DocumentosClienteService } from 'src/app/pages/documentos-clientes/components/services/documentos-cliente.service';
  
  @Directive({
    selector: '[appStylePaginator]',
  })
  export class StylePaginatorDirective implements AfterViewInit {
    private count: number | null = null;
    @Input() filterObject: BusquedaDocumentos;
    constructor(
      @Host() @Self() @Optional() private readonly matPag: MatPaginator,
      private readonly vr: ViewContainerRef,
      private readonly ren: Renderer2,
      private documentosService: DocumentosClienteService,
      private traslate: TranslateService
    ) {}

    private removeCreateContainer():void{
      const pagingContainerMain = this.vr.element.nativeElement.querySelector('.mat-paginator-range-actions');
      if (
        this.vr.element.nativeElement.querySelector('div.mat-paginator-range-actions div.btn_custom-paging-container')
      ) {
        this.ren.removeChild(
          pagingContainerMain,
          this.vr.element.nativeElement.querySelector('div.mat-paginator-range-actions div.btn_custom-paging-container'),
        );
      }
      if(this.matPag.length > 999){
        if ((this.matPag.length - 21) % 1000 != 0) this.count = this.matPag.length;
        const pagingContainerBtns = this.ren.createElement('div');
        const refNode = this.vr.element.nativeElement.childNodes[0].childNodes[0].childNodes[2].childNodes[2];
        this.ren.addClass(pagingContainerBtns, 'btn_custom-paging-container');
        this.ren.insertBefore(pagingContainerMain, pagingContainerBtns, refNode); 
        const pageRange = this.vr.element.nativeElement.querySelector(
          'div.mat-paginator-range-actions div.btn_custom-paging-container',
        );
        pageRange.innerHtml = '';
        this.ren.insertBefore(pageRange, this.createButtonCount(), null);
      }     
    }
    
    private createButtonCount(): any {
      const linkBtn = this.ren.createElement('mat-button'); 
      let text = this.ren.createText(this.count == null ? '?' : '' + this.count.toString());
      this.ren.addClass(linkBtn, 'mat-custom-page');
      if(this.count == null){
        this.ren.addClass(linkBtn, 'mat-icon-button');
        this.ren.listen(linkBtn, 'click', (event) => this.clickButton(event));   
      }            
      this.ren.appendChild(linkBtn, text);
      return linkBtn;
    }
  
    private initPageButton(): void {
      this.removeCreateContainer();   
    }

    private clickButton(event:any): void {
      if(this.count == null){
        this.documentosService.getDocumentosCount(this.filterObject).pipe(take(1)).subscribe( (count:number) => {
          this.count = count;
          this.initPageButton();
        });      
      }          
    }

  
    public ngAfterViewInit() {
      this.matPag._intl.getRangeLabel = (page: number, pageSize: number, length: number) => this.rangeLabelPaginator(page,pageSize,length);
      setTimeout(() => {      
          this.initPageButton();         
      }, 500);
    }

    public Update(){
      this.count = null;
      setTimeout(() => {      
          this.initPageButton();        
      }, 200);
    }

    rangeLabelPaginator(page: number, pageSize: number, length: number):string {

      const pageNumber = this.filterObject?.pageNumber ?? 1;
      const ITEMS_PER_PAGE = 1000;
      const pageStartIndex = page * pageSize + 1;
      const pageEndIndex = Math.min(length, (page + 1) * pageSize);
      const filterPageStartIndex = (pageNumber - 1) * ITEMS_PER_PAGE;
      const itemsTotal = length > ITEMS_PER_PAGE - 1 ? '' : (length + filterPageStartIndex).toString();
      if (length === ITEMS_PER_PAGE * pageNumber) {
        this.matPag.length = ITEMS_PER_PAGE * pageNumber + 21;
      }
      return `${pageStartIndex} - ${pageEndIndex} ${this.traslate.instant('DE_PAGER')} ${itemsTotal}`;

    };
  }