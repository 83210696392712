import {
    AfterViewInit,
    Directive,
    Renderer2,
    ViewContainerRef
  } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ExportService } from 'src/app/core/services/export.service';
  
  @Directive({
    selector: '[docHistorialDirective]',
  })
  export class DocHistorialDirective implements AfterViewInit {
    constructor(
      private readonly vr: ViewContainerRef,
      private readonly ren: Renderer2,
      private readonly exportService: ExportService,
      public dialog: MatDialog,
    ) {}
  
    public ngAfterViewInit() {
        let elem = this.vr.element.nativeElement.querySelector('span a');
        if(elem){
          elem?.classList.add('documentoOriginal');
          elem?.addEventListener('click', this.onClick.bind(this));
        }
       
    }

    async onClick(elem:any){
        let idSobre = elem.currentTarget.attributes.sobre.nodeValue;
        let idPais = elem.currentTarget.attributes.pais.nodeValue;
        await this.exportService.DescargarDocumentoHistorial({Id: idSobre, IdPais: idPais});
        
     }
  }