import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tyc-br',
  templateUrl: './tyc-br.component.html',
  styleUrls: ['./tyc-br.component.scss']
})
export class TycBRComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
