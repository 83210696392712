import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RolUsuario, UsuarioConfiguracion, UsuarioCorporativoView, UsuarioGuardar } from '../models/UsuarioCorporativo';
import { EstadoUsuario } from '../models/EstadosUsuario';
import { ClienteUsuario } from '../models/clienteUsuario';
import { RepresentanteUsuario } from '../models/RepresentanteUsuario';
import { GenericToasterService } from 'src/app/core/services/generic-toaster.service';

@Injectable({
  providedIn: 'root'
})
export class UsuariosDeLaEmpresaService {

  constructor(
    private httpClient: HttpClient,
    private genericToasterService: GenericToasterService
    ) { }

  GetUsuarios(): Observable<UsuarioCorporativoView> {
    return this.httpClient.get<UsuarioCorporativoView>(`${environment.apiUrl}/UsuarioCorporativo/GetUsuariosCorporativos`);
  }

  GetEstadoEmpresa(idCliente:number, idPaisCliente:number): Observable<boolean> {
    return this.httpClient.get<boolean>(`${environment.apiUrl}/UsuarioCorporativo/GetEstadoEmpresa`, {params:{idCliente:idCliente,idPaisCliente:idPaisCliente }});
  }
  
  GetEstadosUsuario(): Observable<Array<EstadoUsuario>> {
    return this.httpClient.get<Array<EstadoUsuario>>(`${environment.apiUrl}/UsuarioCorporativo/GetEstadosUsuario`);
  }
  
  GetUsuarioConfiguracion(idUsuario:number, idPaisUsuario:number): Observable<UsuarioConfiguracion> {
    return this.httpClient.get<UsuarioConfiguracion>(`${environment.apiUrl}/UsuarioCorporativo/GetUsuarioConfiguracion`, {params:{idUsuario:idUsuario,idPaisUsuario:idPaisUsuario }});
  }

  ValidarMailEnUso(email:string): Promise<boolean>{
    const headers = new HttpHeaders().set('Content-Type',  'application/json');
    const requestOptions: Object = { headers };
    return new Promise((resolve)=>{
      this.httpClient.post<boolean>(`${environment.apiUrl}/UsuarioCorporativo/GetValidarMailEnUso`, JSON.stringify(email), requestOptions).subscribe({
        next: (data) => { resolve(data); },
        error: (err) => {  this.genericToasterService.pushError("ERROR_APLICACION"); }
      });
    });
  }

  ValidarClienteTieneMenu(idCliente:number, idPaisCliente:number, menu:string): Promise<boolean>{
    return new Promise((resolve)=>{
      this.httpClient.post<boolean>(`${environment.apiUrl}/UsuarioCorporativo/GetValidarClienteTieneMenu`, {idCliente:idCliente, idPaisCliente:idPaisCliente, resourceKeyMenu:menu}).subscribe({
        next: (data) => { resolve(data); },
        error: (err) => {  this.genericToasterService.pushError("ERROR_APLICACION"); }
      });
    });
  }

  UpdateUltimaRevisionUsuariosClientes(): Promise<void>{
    return new Promise((resolve)=>{
      this.httpClient.post<any>(`${environment.apiUrl}/UsuarioCorporativo/UpdateUltimaRevisionUsuariosClientes`, {}).subscribe({
        next: () => {
          resolve();
        },
        error: (err) => {      
          this.genericToasterService.pushError("ERROR_APLICACION");
        }
      });
    });
  }
  
  GetClientesUsuarioCorporativo(idUsuario:number, idPaisUsuario:number): Observable<Array<ClienteUsuario>> {
    return this.httpClient.get<Array<ClienteUsuario>>(`${environment.apiUrl}/UsuarioCorporativo/GetClientesUsuarioCorporativo`, {params:{idUsuario:idUsuario,idPaisUsuario:idPaisUsuario }});
  }
  
  GetClienteRoles(cliente: any): Observable<Array<RolUsuario>> {
    return this.httpClient.post<Array<RolUsuario>>(`${environment.apiUrl}/UsuarioCorporativo/GetClienteRoles`, cliente??{});
  }
  
  GetUsuarioRol(idUsuario:number, idPaisUsuario:number): Observable<number> {
    return this.httpClient.get<number>(`${environment.apiUrl}/UsuarioCorporativo/GetUsuarioRol`, {params:{idUsuario:idUsuario,idPaisUsuario:idPaisUsuario }});
  }
  
  GetRepresentantes(cliente: any): Observable<Array<RepresentanteUsuario>> {
    return this.httpClient.post<Array<RepresentanteUsuario>>(`${environment.apiUrl}/UsuarioCorporativo/GetRepresentantes`, cliente??{});
  }

  GetEstadosUsuarioAdmin(): Observable<Array<EstadoUsuario>> {
    return this.httpClient.get<Array<EstadoUsuario>>(`${environment.apiUrl}/UsuarioCorporativo/GetEstadosUsuarioAdmin`);
  }

  UpdateUsuario(usuario: UsuarioGuardar): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiUrl}/UsuarioCorporativo/PostUsuario`,usuario);
  }

  InsertUsuario(usuario: UsuarioGuardar): Observable<any> {
    return this.httpClient.put<any>(`${environment.apiUrl}/UsuarioCorporativo/PutUsuario`,usuario);
  }

  ReenviarMailNuevoUsuario(email:string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type',  'application/json');
    const requestOptions: Object = { headers };
    return this.httpClient.post<any>(`${environment.apiUrl}/UsuarioCorporativo/ReenviarMailNuevoUsuario`,JSON.stringify(email),requestOptions);
  }

  EnviarMailDeSolicitudDeUsuarios(cliente: ClienteUsuario): Observable<any> {
    return this.httpClient.post<any>(`${environment.apiUrl}/UsuarioCorporativo/EnviarMailDeSolicitudDeUsuarios`, cliente);
  }

  ActualizarEstadoActivo(body: any){
    return this.httpClient.post(`${environment.apiUrl}/UsuarioCorporativo/ActualizarEstadoActivo`, body)
  }
}
