import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FAQComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this. ACA VA LA LISTA Q HAY Q REFACTORIZAR .filter = filterValue.trim().toLowerCase();
  // }

}
