import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LogoService } from 'src/app/core/services/logo.service';
import { Proveedor } from '../modelos/proveedor';

@Component({
  selector: 'app-logo-pp',
  templateUrl: './logo-pp.component.html',
  styleUrls: ['./logo-pp.component.scss']
})
export class LogoPpComponent implements OnInit {

  constructor(private logoService: LogoService, private sanitizer:DomSanitizer) { }

  src: any;
  @Input() proveedor: Proveedor;

  ngOnChanges(changes: SimpleChanges) {
    let img: any = document.querySelector('.heximage');
    let src = window.URL.createObjectURL(new Blob([this.proveedor.logo], { type: 'application/octet-stream' }));
    this.src = this.sanitizer.bypassSecurityTrustUrl(src); 

  }

  ngOnInit(): void {

  }

}
