import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/auth.service';
import { GenericToasterService } from 'src/app/core/services/generic-toaster.service';
import { GoogleAnalyticsEventsService } from 'src/app/core/services/google-analytics-events.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dialog-fake-door-button',
  templateUrl: './dialog-fake-door-button.component.html',
  styleUrls: ['./dialog-fake-door-button.component.scss']
})
export class DialogFakeDoorButtonComponent implements OnInit {

  formEmail: FormGroup = this.fb.group({
    email: new FormControl('', [Validators.required]),
    nombreApellido: new FormControl('', [Validators.required])
  });

  title: string = '';
  text: string = '';
  imgSrc: string = '';
  imgName: string = '';
  imgWidth:string='';

  private user = this.authService.userdata;

  constructor(
    public dialogRef: MatDialogRef<DialogFakeDoorButtonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private authService: AuthService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private genericToasterService: GenericToasterService,
  ) { }

  ngOnInit(): void {
    this.formEmail.controls['nombreApellido'].setValue(this.user.Nombre + ' ' + this.user.Apellido);
    this.formEmail.controls['email'].setValue(this.user.NombreUsuario);
    this.formEmail.controls['email'].disable();

    this.title = this.data.title;
    this.text = this.data.text;
    this.imgSrc = "assets/images/FakeDoorButtonImg/" + this.data.imgName;
    this.imgName = this.data.imgName;
    this.imgWidth = this.data.imgWidth;
  }

  Contactar() {
    const logAnalytic = "Contactar - " + this.user.Cliente.RazonSocial + ' - ' + this.formEmail.controls['nombreApellido'].value + ' - ' + this.user.NombreUsuario;
    this.googleAnalyticsEventsService.emitEvent(this.data.GAEventCategory, logAnalytic, null, true);
    
    this.genericToasterService.pushSuccess("FAKE_DOOR_BUTTON.DATOS_ENVIADOS_CORRECTAMENTE");
    this.dialogRef.close();
  }

}
