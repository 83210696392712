<div class="header__login">
  <a
    href="https://www.planexware.com/"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img height="15" src="assets/planexware-white.svg" alt="Planexware"
  /></a>
</div>
<div class="header__login_body">
  <div class="col-md-12 login__card__wrapper">
    <mat-card class="login__card">
      <div class="login__header">
        <img height="25" src="assets/krikos360.new.svg" />
      </div>
      <!--Login-->
      <ng-tamplate *ngIf="template_login">
        <mat-card-title style="padding-top: 10px; padding-bottom: 5px">
          <mat-label class="login__lbl_ingresar">{{
            "INGRESAR" | translate
          }}</mat-label>
        </mat-card-title>
        <form>
        <mat-card-content>
          <mat-form-field class="input mail" appearance="outline">
            <mat-label>{{ "EMail" | translate }} *</mat-label>
            <input
              matInput
              [formControl]="emailControl"
              [(ngModel)]="email"
              placeholder="{{ 'EMail' | translate }}"
              required="false"
              autocomplete
              
            />
          </mat-form-field>
          <br />
          <mat-form-field class="input password" appearance="outline">
            <mat-label>{{ "CONTRASENA" | translate }} *</mat-label>
            <input
              matInput
              [type]="hide ? 'password' : 'text'"
              [formControl]="passwordControl"
              [(ngModel)]="password"
              placeholder="{{ 'CONTRASENA' | translate }}"
              required="false"
              autocomplete
            />
            <mat-icon matSuffix (click)="hide = !hide"
              >{{ hide ? "visibility_off" : "visibility" }}
            </mat-icon>
          </mat-form-field>
          <br />
          <div class="" style="padding: 10px">
            <button
              class="login__btn_acceder "
              mat-raised-button
              (click)="login()"
              id="loginButton"
            >
              {{ ("ACCEDER" | translate).toUpperCase() }}
            </button>
          </div>
          <div class="" style="padding: 10px">
            <mat-label
              class="login__lbl_olvide_contrasena"
              mat-raised-button
              (click)="recuperoContrasenaTemplate(true)"
              >{{
                ("OLVIDE_MI_CONTRASENA" | translate).toUpperCase()
              }}</mat-label
            >
          </div>
          
          <br>
          <span class="connect-with"><span class="line"></span><p style="color: #d3d3d3; font-family: montserrat-bold; font-size: 12px;">O</p></span>
          <br>
          <!--external-login-->
          <div class="" style="padding: 10px; width: 100%;">
            <div fxLayout="column" fxLayoutAlign="space-around center" style="width: 100%;">
            <button
            class="login__btn_acceder_credenciales "
            id="microsoft-login-button"
            mat-raised-button
            style="border: 0.1px solid #D2D2D2; width: 90%; height: 34px;"
            (click)="login_google()"
          >
              <div class="external-login-wrapper" > 
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" class="google"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg>
                <div>
                {{"CONTINUAR_GOOGLE" | translate}}
                </div>
                <!--svg class="microsoft" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 129 129" style="enable-background:new 0 0 129 129;" xml:space="preserve"><style type="text/css">.st0{fill:#F25022;}.st1{fill:#7FBA00;}.st2{fill:#00A4EF;}.st3{fill:#FFB900;}</style><path class="st0" d="M0,0h61.3v61.3H0V0z"/><path class="st1" d="M67.7,0H129v61.3H67.7V0z"/><path class="st2" d="M0,67.7h61.3V129H0V67.7z"/><path class="st3" d="M67.7,67.7H129V129H67.7V67.7z"/></-svg-->
                
                </div>
          </button>
            <br>
            <button
              class="login__btn_acceder_credenciales "
              id="microsoft-login-button"
              mat-raised-button
              style="border: 0.1px solid #D2D2D2; width: 90%; height: 34px;"
              (click)="login_microsoft()"
            >
                <div class="external-login-wrapper" > 
                  <svg class="microsoft" enable-background="new 0 0 2075 2499.8" viewBox="0 0 2075 2499.8" xmlns="http://www.w3.org/2000/svg"><path d="m0 2016.6v-1519.8l1344.4-496.8 730.6 233.7v2045.9l-730.6 220.3-1344.4-483.3 1344.4 161.8v-1769.2l-876.8 204.6v1198.3z" fill="#eb3c00"/></svg>
                  <div>
                  {{"CONTINUAR_MICROSOFT" | translate}}
                  </div>
                  <!--svg class="microsoft" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 129 129" style="enable-background:new 0 0 129 129;" xml:space="preserve"><style type="text/css">.st0{fill:#F25022;}.st1{fill:#7FBA00;}.st2{fill:#00A4EF;}.st3{fill:#FFB900;}</style><path class="st0" d="M0,0h61.3v61.3H0V0z"/><path class="st1" d="M67.7,0H129v61.3H67.7V0z"/><path class="st2" d="M0,67.7h61.3V129H0V67.7z"/><path class="st3" d="M67.7,67.7H129V129H67.7V67.7z"/></-svg-->
                  
                  </div>
            </button>
            <br>
            <button
              class="login__btn_acceder_credenciales "
              mat-raised-button
              style="border: 0.1px solid #D2D2D2; width: 90%; height: 34px;"
              (click)="boton_sso()"
            >
                <div class=""> 
                  {{"CONTINUAR_SSO" | translate}}    
                </div>
            </button>
          </div>
        </div>
        </mat-card-content>
      </form>
      </ng-tamplate>

      <!--Recupero Contraseña-->
      <ng-tamplate *ngIf="template_recupero_contrasena">
        <mat-card-title style="padding-top: 10px; padding-bottom: 5px">
          <mat-label class="login__lbl_ingresar">{{
            "OLVIDE_MI_CONTRASENA" | translate
          }}</mat-label>
          <br />
        </mat-card-title>

        <mat-card-content>
          <mat-form-field class="input" appearance="outline">
            <mat-label>Email *</mat-label>
            <input
              matInput
              [formControl]="emailControl"
              [(ngModel)]="email"
              placeholder="Email"
              required="false"
            />
          </mat-form-field>

          <div class="" style="padding: 10px">
            <mat-label class="login__lbl" style="font-size: 13px;"
              >{{ "INGRESE_SU_DIRECCION_ENVIAREMOS_MAIL" | translate }}
            </mat-label>
          </div>

          <div class="" style="padding: 10px">
            <button
              class="login__btn_acceder"
              mat-raised-button
              (click)="recuperoContrasena(true)"
            >
              {{ ("ENVIAR" | translate).toUpperCase() }}
            </button>
          </div>

          <div class="" style="padding: 10px">
            <mat-label
              class="login__lbl_cancelar_volver"
              (click)="cancelar(true)"
              >{{ ("CANCELAR_Y_VOLVER" | translate).toUpperCase() }}</mat-label
            >
          </div>
        </mat-card-content>
      </ng-tamplate>

      <!--Credenciales sociales o contrasena-->
      <ng-tamplate *ngIf="template_nuevo_credencial_o_contrasena">
        <mat-card-title style="padding-top: 10px; padding-bottom: 5px">
          <mat-label class="login__lbl_ingresar">{{
            "BIENVENIDA" | translate
          }}</mat-label>
          <br />
        </mat-card-title>

        <mat-card-content>
          <div class="login__terminos" style="padding: 10px">
            <mat-checkbox
              class="example-margin"
              [(ngModel)]="terminos_condiciones"
              (change)="habilitarBotones()"
            >
              {{ "ACEPTO_LOS" | translate }}
              <a href="terminosYCondiciones" target="blank">{{
                "TERMINOS_Y_CONDICIONES" | translate
              }}</a>
            </mat-checkbox>
            <br />
            <mat-hint
              class="login__terminos_condicion"
              *ngIf="ver_terminos_condiciones"
            >
              {{ "DEBE_ACEPTAR_TERMINOS_Y_CONDICIONES" | translate }}
            </mat-hint>
          </div>          
          <div style="padding: 10px"  matTooltip="{{ !terminos_condiciones ? ('ACEPTE_TYC'| translate) : ''  }} ">
            <div fxLayout="column" fxLayoutAlign="space-around center">
            <div class="" style="border: 0.1px solid #000000; border-radius: 4px;" id="google-button"></div>
            <br>
            <button
              id="microsoft-button"
              class="login__btn_acceder_credenciales hover-back"
              mat-raised-button
              style="border: 0.1px solid #000000; width: 300px; height: 34px;"
              (click)="login_microsoft()"
            >
              <div class="external-login-wrapper"> 
                <svg class="microsoft" enable-background="new 0 0 2075 2499.8" viewBox="0 0 2075 2499.8" xmlns="http://www.w3.org/2000/svg"><path d="m0 2016.6v-1519.8l1344.4-496.8 730.6 233.7v2045.9l-730.6 220.3-1344.4-483.3 1344.4 161.8v-1769.2l-876.8 204.6v1198.3z" fill="#eb3c00"/></svg>
                <div>
                  {{("CONTINUAR_MICROSOFT" | translate)}}
                </div>
              <!--svg class="microsoft" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 129 129" style="enable-background:new 0 0 129 129;" xml:space="preserve"><style type="text/css">.st0{fill:#F25022;}.st1{fill:#7FBA00;}.st2{fill:#00A4EF;}.st3{fill:#FFB900;}</style><path class="st0" d="M0,0h61.3v61.3H0V0z"/><path class="st1" d="M67.7,0H129v61.3H67.7V0z"/><path class="st2" d="M0,67.7h61.3V129H0V67.7z"/><path class="st3" d="M67.7,67.7H129V129H67.7V67.7z"/></-svg-->
              
              </div>
            </button>
            <br>
            <button
              class="login__btn_acceder_credenciales hover-back"
              mat-raised-button
              style="border: 0.1px solid #000000; width: 300px; height: 34px;"
              id="sso-button"
              (click)="boton_sso()"
            >
              <div class=""> 
                {{("CONTINUAR_SSO" | translate)}}            
              </div>
            </button>
            </div>
          </div>
          <span class="connect-with"><span class="line"></span></span>
          <div style="padding: 10px" matTooltip="{{ !terminos_condiciones ? ('ACEPTE_TYC'| translate) : ''  }} ">
            <button
              class="login__btn_acceder"
              mat-raised-button
              style="width: 300px;"
              (click)="nuevaContrasena(true)"
              [disabled]="!terminos_condiciones"
            >
              {{ ("REGISTRARSE_CON_CORREO" | translate).toUpperCase() }}
            </button>
          </div>
        </mat-card-content>
      </ng-tamplate>

      <!--Nueva Contraseña-->
      <ng-tamplate *ngIf="template_nueva_contrasena">
        <form>
        <mat-card-title style="padding-top: 10px; padding-bottom: 5px">
          <mat-label class="login__lbl_ingresar">{{
            "GENERACION_CONTRASENA" | translate
          }}</mat-label>
          <br />
        </mat-card-title>

        <mat-card-content>
          <mat-form-field class="input" appearance="outline">
            <mat-label>Email *</mat-label>
            <input
              matInput
              [formControl]="emailControl"
              [(ngModel)]="email"
              placeholder="Email"
              required="false"
            />
          </mat-form-field>

          <mat-form-field class="input" appearance="outline">
            <mat-label>{{ "NUEVA_CONTRASENA" | translate }} *</mat-label>
            <input
              matInput
              [(ngModel)]="password_nueva"
              [type]="hide ? 'password' : 'text'"
              placeholder="{{ 'CONTRASENA' | translate }}"
              [formControl]="passwordNuevaControl"
              required="false"
              autocomplete="new-password"
              name="new-password"
            />
            <mat-icon matSuffix (click)="hide = !hide"
              >{{ hide ? "visibility_off" : "visibility" }}
            </mat-icon>
          </mat-form-field>
          <br />
          <div class="login__terminos_condicion">
            <p>
              {{ complejidadContrasena | translate }}
              <span>
                <mat-icon [ngClass]="icono">
                  {{ icono }}
                </mat-icon>
              </span>
            </p>

          </div>

          <div
            class=""
            style="padding-bottom: 20px; margin-left: 15px; margin-right: 15px"
          >
            <div style="text-align: left">
              <mat-label
                class="login__lbl_ingresar"
                style="font-family: montserrat-regular; font-size: 13px"
                >{{ "FORTALEZA_CONTRASENA" | translate }}
              </mat-label>
              <mat-icon
                matTooltip="{{ tooltip_contrasena }}"
                matTooltipClass="login__tooltip_contrasena"
                matTooltipPosition="right"
                style="
                  font-size: 16px;
                  padding-top: 0.2rem;
                  padding-left: 0.2rem;
                  position: absoulte;
                "
                >help</mat-icon
              >
            </div>
            <div class="barra">
              <password-strength-meter
                [password]="password_nueva"
                (strengthChange)="strengthChange($event)"
              ></password-strength-meter>
            </div>
          </div>

          <mat-form-field class="input" appearance="outline">
            <mat-label>{{ "REPITA_NUEVA_CONTRASENA" | translate }}</mat-label>
            <input
              matInput
              [(ngModel)]="repassword"
              [type]="hide_repeat ? 'password' : 'text'"
              placeholder="{{ 'CONTRASENA' | translate }}"
              [formControl]="repasswordControl"
              required="false"
              autocomplete="new-repassword"
              name="new-repassword"
            />
            <mat-icon matSuffix (click)="hide_repeat = !hide_repeat"
              >{{ hide_repeat ? "visibility_off" : "visibility" }}
            </mat-icon>
          </mat-form-field>
          <br />
          <mat-hint class="login__terminos_condicion" *ngIf="contrasena_corta">
            {{ "CONTRASENA_CORTA" | translate }}
          </mat-hint>

          <div style="padding: 10px">
            <button
              class="login__btn_acceder"
              mat-raised-button
              (click)="guardar()"
            >
              {{ ("GUARDAR_Y_VOLVER" | translate).toUpperCase() }}
            </button>
          </div>
          <div style="padding: 10px">
            <mat-label
            *ngIf="this.contrasena.estadoUsuario == 'Pendiente'"
              class="login__lbl_cancelar_volver"
              (click)="volver_contrasena()"
              >{{ ("CANCELAR_Y_VOLVER" | translate).toUpperCase() }}</mat-label
            >
          </div>
        </mat-card-content>
      </form>
      </ng-tamplate>  

      <!--Usuario Multi Empresa-->
      <ng-tamplate *ngIf="template_usuario_multiempresa">
        <mat-card-title style="padding-top: 10px; padding-bottom: 5px">
          <mat-label class="login__lbl_ingresar">{{
            "CON_QUE_EMPRESA_OPERA" | translate
          }}</mat-label>
        </mat-card-title>

        <mat-card-content>
          <mat-form-field class="input" appearance="outline">
            <mat-label>{{ "EMPRESA" | translate }} *</mat-label>
            <mat-select
              [formControl]="multiEmpresaControl"
              required="false"
              #tooltip="matTooltip"
              [(ngModel)]="clienteSeleccionado"
              matTooltip="{{ multiEmpresaControl?.value?.razonSocial ?? '' }}"
            >
              <mat-option
                *ngFor="let clientes of userInfo.clientesParaMultiEmpresa"
                [value]="clientes"
                #tooltip="matTooltip"
                matTooltip="{{ clientes.razonSocial }}"
                style="font-size: 13px; font-family: 'montserrat-regular'"
              >
                {{ clientes.razonSocial }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="multiEmpresaControl.hasError('required')">{{
              "CAMPO_OBLIGATORIO" | translate
            }}</mat-error>
          </mat-form-field>

          <br />
          <div class="" style="padding: 10px">
            <button
              class="login__btn_acceder"
              mat-raised-button
              (click)="loginMultiEmpresa()"
            >
              {{ ("CONTINUAR" | translate).toUpperCase() }}
            </button>
          </div>
          <div class="" style="padding: 10px">
            <mat-label
              class="login__lbl_cancelar_volver"
              (click)="cancelar(true)"
              >{{ ("CANCELAR_Y_VOLVER" | translate).toUpperCase() }}</mat-label
            >
          </div>
        </mat-card-content>
      </ng-tamplate>

      <!--Recupero Contraseña-->
      <ng-tamplate *ngIf="template_recupero_contrasena_ok">
        <mat-card-content>
          <div class="" style="padding: 10px; align-content: center">
            <br />
            <mat-icon>check_circle</mat-icon>
            <br />
            <mat-label class="login__lbl">{{
              "ENLACE_ENVIADO" | translate
            }}</mat-label>
          </div>
          <div class="" style="padding: 10px">
            <mat-label
              class="login__lbl__desc"
              innerHtml="{{ 'ENLACE_ENVIADO_DESCRIPCION' | translate }}"
            >
            </mat-label>
            <br />
            <mat-label class="login__lbl_bold"
              ><strong>{{ email }}</strong></mat-label
            >
          </div>

          <div class="" style="padding: 10px">
            <button
              class="login__btn_acceder"
              [disabled]="ver_reenviar"
              mat-raised-button
              (click)="recuperoContrasena(true)"
              style="margin-bottom: 10px"
            >
              {{ ("REENVIAR" | translate).toUpperCase() }}
            </button>
            <br />
            <mat-hint class="login__volver_enviar" *ngIf="ver_reenviar">
              {{
                "VOLVER_ENVIAR_ESPERA"
                  | translate: { segundosRestantes: segundosRestantes }
              }}
            </mat-hint>
          </div>

          <div class="" style="padding: 10px">
            <mat-label
              class="login__lbl_cancelar_volver"
              (click)="cancelar(true)"
              >{{ ("VOLVER" | translate).toUpperCase() }}</mat-label
            >
          </div>
        </mat-card-content>
      </ng-tamplate>
      <!--SSO-->
      <ng-tamplate *ngIf="template_sso">
        <mat-card-title style="padding-top: 10px; padding-bottom: 5px">
          <mat-label class="login__lbl_ingresar">{{
            "INICIO_SSO" | translate
          }}</mat-label>
        </mat-card-title>

        <mat-card-content>
          <mat-label class="login__lbl_ingresar" style="justify-content: left; text-align: left; float: left; margin-left: 25px; font-size: 13px;">{{
            "SSO_INGRESE" | translate
          }}</mat-label>
          <mat-form-field class="mail_sso" appearance="outline" style="margin-top: 20px;">
            <mat-label>{{ "EMail" | translate }} *</mat-label>
            <input
              matInput
              [formControl]="emailControl"
              [(ngModel)]="email"
              placeholder="{{ 'EMail' | translate }}"
              required="false"
              autocomplete
              
            />
            <mat-hint
            class="hint_sso"
            *ngIf="hint_sso"
          >
            {{ "SSO_ERROR_MAIL" | translate }}
          </mat-hint>
          </mat-form-field>
          <div class="espacio_boton_sso" *ngIf="hint_sso">
            <br>
          </div>
          <div class="" style="padding: 10px">
            <button
              class="login__btn_acceder "
              mat-raised-button
              (click)="login_sso()"
              id="loginButton"
            >
              {{ ("BOTON_CONTINUAR" | translate).toUpperCase() }}
            </button>
          </div>
          <div class="" style="padding: 10px">
            <mat-label
              class="login__lbl_olvide_contrasena"
              mat-raised-button
              (click)="cancelar_sso()"
              >{{
                ("CANCELAR_Y_VOLVER" | translate).toUpperCase()
              }}</mat-label
            >
          </div>
        </mat-card-content>
      </ng-tamplate>
    </mat-card>
  </div>
</div>
<!-- <app-footer class="footerLogin"
></app-footer> -->
