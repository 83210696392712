<div class="modal-fake-door">
  <h3 mat-dialog-title class="modal-title">{{title|translate}}</h3>

  <div class="modal-wrapper">
    <p class="modal-text" innerHtml="{{ text | translate }}"></p>
    
    <img style="border-radius: 10.5px;" [src]="imgSrc" [alt]="imgName" [style.width]="imgWidth"/>
    
    <div style="width: 100%;">
      <form [formGroup]="formEmail">
        <mat-form-field appearance="outline">
          <mat-label> {{'NOMBRE_Y_APELLIDO' | translate}} </mat-label>
          <input formControlName="nombreApellido" matInput maxlength="100"  />
          
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label> {{'EMAIL' | translate}} </mat-label>
          <input formControlName="email" matInput maxlength="100" />
        </mat-form-field>
        
      </form>
    </div>
  </div>
  
  <div class="buttons">
    <button mat-raised-button class="save"(click)="Contactar()">{{"QUIERO_QUE_ME_CONTACTEN" | translate}}</button>
    <button mat-stroked-button class="cancel" mat-dialog-close>{{"CANCELAR" | translate}}</button>
  </div>

</div>