import { Component, Input, OnInit } from '@angular/core';
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { ModalService } from 'src/app/core/services/modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-admin-list',
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.scss']
})
export class AdminListComponent implements OnInit {
  @Input() administrators: any
  // Icons
  faUser = faUser
  constructor(public modalService: ModalService, private translate: TranslateService) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.modalService.closeModal()
  }
}
