import { Component} from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { AuthService } from './core/services/auth.service'
import { GenericToasterService } from './core/services/generic-toaster.service'
import { ToasterService } from './core/services/toaster.service'
import { Router, NavigationEnd } from '@angular/router'
import { environment } from '../environments/environment'
import { CookieService } from 'ngx-cookie-service';
import { MatDialog } from '@angular/material/dialog'

// declare ga as a function to access the JS code in TS
declare let ga: Function;
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  constructor(
    private translate: TranslateService,
    public toasterService: ToasterService,
    public genericToasterService: GenericToasterService,
    public authService: AuthService,
    private router: Router,
    private cookieService: CookieService,
    private dialogService: MatDialog
  )
  {
    this.grabTheTrackId();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('event', 'page_view', { page_path: event.urlAfterRedirects });
      }
    });
  }

  title = 'krikos360-angular'

  ngOnInit(): void {
    this.translate.addLangs(['es-AR','en-US','pt-BR']);
    let cultura = this.cookieService.get('ucultura');
    if(!cultura){
      cultura = this.authService.getUserData()?.Cultura || 'es-AR';
      if(cultura) this.cookieService.set('ucultura', cultura || 'es-AR',{ path: '/', secure:true,sameSite: "Strict"});
    }
    this.translate.setDefaultLang(cultura ||'es-AR');

  }

  grabTheTrackId(){
    let gaTrackId = environment.gaTrackId
    let customGtagScriptEle: HTMLScriptElement = document.createElement('script');
    customGtagScriptEle.async = true;
    customGtagScriptEle.src = 'https://www.googletagmanager.com/gtag/js?id=' + gaTrackId;
    document.head.prepend(customGtagScriptEle);
    gtag('config', gaTrackId, { send_page_view: false, cookie_flags: 'max-age=7200;secure;samesite=none' });
  }


}
