import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MensajeHTMLView } from 'src/app/pages/novedades/models/mensajeHTMLView';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MensajeService {


  constructor(private http: HttpClient) { 

  }
  getMensajeUser_ResourceKey(body: any){
    return this.http.get<MensajeHTMLView[]>(`${environment.apiUrl}/Mensajes/GetMensajesParaElUsuarioByResourceKey?resourceKey=${body}`);
  }

  cerrarMensajeUsuario(body: any){
    return this.http.post(`${environment.apiUrl}/Mensajes/CerrarMensajeDelUsuario`,body);
  }
}
