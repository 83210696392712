import { Injectable } from '@angular/core'
import { TokenUserData } from '../models/accessToken'
import { clienteHolding } from '../models/clienteHolding'
import { JwtService } from './jwt.service'
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { isNullOrEmptyOrUndefined } from 'src/app/shared/utils/utils.component';
import { GenericToasterService } from './generic-toaster.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public userdata: any
  public hasValidToken: boolean = false

  constructor(private jwtService: JwtService, public http: HttpClient, private genericToasterService: GenericToasterService ) {}

  isAuthenticated(): boolean {
    const accessToken = this.jwtService.getAccessToken()

    if (!accessToken) {
      return false
    }
    this.userdata = accessToken.userdata
    const exp = accessToken.exp * 1000
    this.hasValidToken = exp >= new Date().getTime()
    return this.hasValidToken
  }

  hasRole(role: string): boolean {
    const accessToken = this.jwtService.getAccessToken();
    if (role == '*')
        return true;

    return accessToken ? accessToken.role.includes(role) : false
  }

  getUserData(): TokenUserData | undefined {
    const accessToken = this.jwtService.getAccessToken()
    if (accessToken && accessToken.userdata) {
      return accessToken.userdata
    }
  }

  getTimeZone(): string {
    if(this.getUserData())
      return 'GMT' + this.getUserData()?.Cliente.UtcOffsetString;
    return '';
    
  }

  getRawToken(): string | null {
    return sessionStorage.getItem('token');
  }

  getClienteHolding(): clienteHolding {
    const clientesHolding: clienteHolding = JSON.parse(sessionStorage.getItem("ClientesHolding")!)
    return clientesHolding
  }

  getLogin(body: any){
    return this.http.post(`${environment.apiUrl}/Acceso/Login`, body)
  }

  getLoginMultiEmpresa(body: any, socialLogin: boolean){
    return this.http.post(`${environment.apiUrl}/Acceso/LoginMultiEmpresa/${socialLogin}`, body)
  }

  async getClientesHoldingFromApi(): Promise<void> {
    try {
      const data = await this.http.get<any>(`${environment.apiUrl}/Cliente/GetClientesHolding`)
        .toPromise();
      //TODO: Verificar esta basura porque no encontramos forma de que tome las propiedades en mayuscula.
      // --> ver config backend: newtonsoft json serializer set to camelCase
      const clientes = data.map((cliente: any) => ({
        Id: cliente.id,
        IdPais: cliente.idPais,
        RazonSocial: cliente.razonSocial,
        EstadoAutenticacion: cliente.estadoAutenticacion,
        IdInterno: cliente.idInterno,
        Owner: cliente.owner,
        EsHolding: cliente.esHolding,
        Acceso: cliente.acceso,
        AliasEmpresa: cliente.aliasEmpresa,
        CantidadEstructuras: cliente.cantidadEstructuras,
        Identificador: cliente.identificador,
        Domicilio: cliente.domicilio,
        CodigoTipoIdentificacion: cliente.codigoTipoIdentificacion
      }));
      sessionStorage.setItem("ClientesHolding", JSON.stringify({ clientes, MostrarHolding: clientes.length > 1 }));
    } catch (err) {
      this.genericToasterService.pushError("ERROR_APLICACION");
    };
  }

  getOwner(){
    const clientesHolding = JSON.parse(sessionStorage.getItem('ClientesHolding')!);
    let ownerIndex = 0;
    const empresas = clientesHolding.clientes.map((client: any, index: any) => {
      if (client.Owner) {
        ownerIndex = index;
      }
      return {
        id: client.Id,
        idPais: client.IdPais,
        razonSocial: client.RazonSocial,
      }
    });
    return empresas[ownerIndex];
  }

  getPantallasMigradasFromApi() {
    this.http.get(`${environment.apiUrl}/Acceso/RecuperarPantallasMigradas`)
    .subscribe(
      (data: any) => {
        // es responsabilidad del auth service?
        sessionStorage.setItem("pm", JSON.stringify(data));
      },
      (err) => {
        this.genericToasterService.pushError("ERROR_APLICACION");
      });
  }

  getFuncionesFromToken() {
    const accessToken = this.jwtService.getAccessToken();
    sessionStorage.setItem("Funciones", JSON.stringify(accessToken?.role));
  }

  getRecuperarContrasena(body: any){
    return this.http.post(`${environment.apiUrl}/Acceso/RecuperarContrasena`, body)
  }

  postRenovarContrasena(body: any){
    return this.http.post(`${environment.apiUrl}/Acceso/RenovarContrasena`, body)
  }

  postGenerarNuevaContrasena(body: any){
    return this.http.post(`${environment.apiUrl}/Acceso/GenerarNuevaContrasena`, body)
  }

  postGenerarUsuarioCredencialesSociales(body: any){
    return this.http.post(`${environment.apiUrl}/Acceso/GenerarUsuarioCredencialesSociales`, body)
  }
  actualizarUsaSocialLogin(body: any){
    return this.http.post(`${environment.apiUrl}/Acceso/UsuarioUsaCredencialesSociales`, body)
  }
  
  desconectarSoporte(){
    return this.http.get(`${environment.apiUrl}/Acceso/DesconectarSoporte`)
  }
  generarTokenJWT(){
    return this.http.get(`${environment.apiUrl}/Acceso/GenerarTokenJWT`)
  }

  generarTokenJWTDesconectarSoporte(){
    return this.http.get(`${environment.apiUrl}/Acceso/GenerarTokenJWTDesconectarSoporte`)
  }

  getRecuperarAvisoCambioContrasena(){
    return this.http.get<any>(`${environment.apiUrl}/Acceso/RecuperarAvisoContrasena`)
  }

  esPlanexwareYConectadoComo():boolean{
    var esUsuarioPlanexwareConectadoComo = !isNullOrEmptyOrUndefined(this.userdata.ConectadoComo);

    //Se chequea por los dos tipos de usuario, por los token generados en WebApi y V2.
    var esUsuarioPlanexwareSoporteDobleToken = (this.userdata.Tipo ?? this.userdata.TipoUsuario) === 'Planex';

    return (esUsuarioPlanexwareSoporteDobleToken && esUsuarioPlanexwareConectadoComo);
  }

  esPlanexware():boolean{
    //Se chequea por los dos tipos de usuario, por los token generados en WebApi y V2.
    var esUsuarioPlanexwareSoporteDobleToken = (this.userdata.Tipo ?? this.userdata.TipoUsuario) === 'Planex';

    return esUsuarioPlanexwareSoporteDobleToken;
  }

  conectadoComo():boolean{
    var esUsuarioPlanexwareConectadoComo = !isNullOrEmptyOrUndefined(this.userdata.ConectadoComo);
    return esUsuarioPlanexwareConectadoComo;
  }
  getPais(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/acceso/RecuperarPais`)
  }

  getUser(body: any, socialLogin: boolean){
    return this.http.post(`${environment.apiUrl}/Acceso/GetUser/${socialLogin}`, body)
  }

}
