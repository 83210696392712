import { AfterViewInit, Component, HostListener, Inject, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../core/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { GenericToasterService } from 'src/app/core/services/generic-toaster.service';
import { Cliente } from '../clientes-mixtos/modelos/Cliente';
import { JwtInfo } from './Models/JwtInfo';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { isNullOrEmptyOrUndefined } from '../../shared/utils/utils.component';
import { GoogleAnalyticsEventsService } from '../../core/services/google-analytics-events.service';
import { Contrasena } from './Models/Contrasena';
import { MensajeService } from 'src/app/core/services/mensaje.service';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { CookieService } from 'ngx-cookie-service';
import { ChekPasswordValueService } from 'src/app/core/helpers/chekPasswordValue.service';
import { ConfiguracionSeguridadService } from '../configuracion-seguridad/servicios/configuracion-seguridad.service';
import { JwtService } from 'src/app/core/services/jwt.service';
import { MatDialog } from '@angular/material/dialog';
import { RedirectHelper } from 'src/app/core/helpers/redirect.helper';
import { lastValueFrom, take } from 'rxjs';
import { FeatureToggleService } from 'src/app/core/services/featureToggle.service';
import { MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionType, PopupRequest, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import jwtDecode from 'jwt-decode';
import { Client } from '@microsoft/microsoft-graph-client';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { PopUpCredencialesComponent } from './popup-credenciales/popup-credenciales.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { environment } from 'src/environments/environment';
import { UsuariosDeLaEmpresaService } from '../usuarios-de-la-empresa/servicio/usuarios-de-la-empresa.service';
declare var google: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, AfterViewInit {
  /*vars*/
  email: string;
  password: string;
  password_nueva: string;
  tooltip_contrasena: string;
  clienteSeleccionado: Cliente;
  repassword: string;
  intentos = 0;
  contrasena_corta = false;
  /*Templates*/
  template_login: boolean = true;
  template_recupero_contrasena: boolean = false;
  template_nueva_contrasena: boolean = false;
  template_usuario_multiempresa: boolean = false;
  template_recupero_contrasena_ok: boolean = false;
  template_nuevo_credencial_o_contrasena: boolean = false;
  template_sso: boolean = false;

  userInfo: JwtInfo;
  multiEmpresaControl = new FormControl('', Validators.required);
  emailControl = new FormControl('', Validators.required);
  passwordControl = new FormControl('', Validators.required);
  repasswordControl = new FormControl('', Validators.required);
  passwordNuevaControl = new FormControl('', Validators.required);

  hide = true;
  hide_repeat = true;
  ver_reenviar = true;
  segundosRestantes: number;
  terminos_condiciones: boolean = false;
  ver_terminos_condiciones: boolean;
  contrasena: Contrasena;
  passStrength: number;
  minLengt = 8;

  complejidadContrasena: string;
  complejidadDelCliente: number;

  icono = "clear"
  private usaCredencialesSociales: boolean = false;
  public graphClient?: Client;
  hint_sso = false;

  constructor(
    private translate: TranslateService,
    public genericToasterService: GenericToasterService,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private totasterService: ToasterService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private route: ActivatedRoute,
    private mensajeService: MensajeService,
    private cookieService: CookieService,
    private chekPasswordValueService: ChekPasswordValueService,
    private configuracionSeguridadService: ConfiguracionSeguridadService,
    private jwtService: JwtService,
    private dialogService: MatDialog,
    private redirectHelper: RedirectHelper,
    private featureToggleService: FeatureToggleService,
    private authMsalService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private ngZone: NgZone,
    public dialog: MatDialog,
    private usuarioService: UsuariosDeLaEmpresaService
  ) { }


  ngOnInit(): void {
    // Boton login google
    // @ts-ignore
    window.onGoogleLibraryLoad = () => {
      // @ts-ignore
      google.accounts.id.initialize({
        client_id: environment.googleClientId,
        // @ts-ignore
        callback: ({ credential }) => {
          this.ngZone.run(() => {
            this.callback_login_google(credential);
          });
        },
      });
      // @ts-ignore
      google.accounts.id.renderButton(
        // @ts-ignore
        document.getElementById("google-button"),
        { theme: "outline", size: "medium", width: "300px", text: "continue_with", locale: this.cookieService.get('ucultura') || 'es-AR', logo_alignment: "center" }

      );

    };
    this.dialogService.closeAll();
    this.dialogService.ngOnDestroy();
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['main/home']);
    }

    if (sessionStorage.getItem("msal.interaction.status") == "b9d15110-c18d-4680-8d5a-6317f43bdfdf") sessionStorage.removeItem('msal.interaction.status');
    this.setPantallasMigradas();
    this.seteoVariables();
    this.route.queryParams.subscribe((params) => {
      let body = { ...params.keys, ...params };

      if (isNullOrEmptyOrUndefined(body.rtu) || isNullOrEmptyOrUndefined(body.ff))
        this.cambiarTemplate(true);
      else {

        this.authService.postRenovarContrasena(body).subscribe((param) => {
          this.contrasena = param as Contrasena;
          if (this.contrasena.complejidadHashDescripcion === 'Alta') {
            this.minLengt = 10;
            this.tooltip_contrasena = '';
          }
          this.textoTooltipContrasena(this.contrasena.complejidadHashDescripcion);
          switch (this.contrasena.complejidadHashDescripcion) {
            case 'Baja':
              this.complejidadContrasena = this.translate.instant('COMPLEJIDAD_CONTRASENA_BAJA');
              this.complejidadDelCliente = 1
              break;
            case 'Media':
              this.complejidadContrasena = this.translate.instant('COMPLEJIDAD_CONTRASENA_MEDIA');
              this.complejidadDelCliente = 2
              break;
            case 'Alta':
              this.complejidadContrasena = this.translate.instant('COMPLEJIDAD_CONTRASENA_ALTA');
              this.complejidadDelCliente = 3
              break;
            default:
              break;
          }

          this.email = this.contrasena.usuario;
          this.emailControl.disable();
          this.passwordNuevaControl.reset();
          this.password_nueva = '';
          if(this.contrasena.usuarioNuevo){
            // primera vez
            this.nuevaContrasena_o_sociales(true);
          } else {
            // recupera la contraseña
            this.nuevaContrasena(true);
          }

        },
          (err) => {
            err = err;
            if (err.error.Message === "Error with Message='ERROR_LINK_NO_VIGENTE'") {
              this.router.navigate(['linkVencido']);
            } else {
              this.toastr.error(this.translate.instant('ERROR_APLICACION'), '', {

                positionClass: "toast-top-full-width",
                disableTimeOut: true,
                closeButton: true

              });
            }
          }
        );
        this.nuevaContrasena_o_sociales(true);
      }
    });
    this.checkNewPassword();

  }

  ngAfterViewInit(): void {
    // Limpio contraseña al recuperar
    let html = document.getElementsByTagName("app-root")[0];
    let url = window.location.pathname;
    (url.includes("login") || url.includes("contrasena")) && html.classList.contains("no-login") == false
      ? html.classList.add("login") : html.classList.add("no-login")
    if (url.includes("login")) {
      html.classList.remove("no-login")
      html.classList.add("login")
    }
    if (this.template_nuevo_credencial_o_contrasena)
      this.habilitarBotones();
  }

  checkNewPassword() {
    this.passwordNuevaControl.valueChanges.subscribe(data => {
      if (data != undefined || data != null) {
        this.validateNewPassword(data);
      }
    })
  }

  validateNewPassword(data: any) {
    switch (this.complejidadDelCliente) {
      case 1:
        if (data.length > 7 && this.chekPasswordValueService.tieneMayuscula(data) && this.chekPasswordValueService.tieneMinuscula(data)) {
          this.icono = "done"
        } else {
          this.icono = "clear"
        }
        break;
      case 2:
        if (data.length > 7 && this.chekPasswordValueService.complegidadMedia(data)) {
          this.icono = "done"
        } else {
          this.icono = "clear"
        }
        break;
      case 3:
        if (this.chekPasswordValueService.complejidadAlta(data)) {
          this.icono = "done"
        } else {
          this.icono = "clear"
        }
        break;
      default:
        break;
    }
  }

  login() {
    const element = document.getElementById("loginButton");
    element?.classList.add("loading");
    this.emailControl.disable()
    this.passwordControl.disable()

    localStorage.clear();
    sessionStorage.clear();
    this.borrarToast();
    this.googleAnalyticsEventsService.emitEvent('Acciones', 'Login', null, true);

    let body = {
      usuario: this.email,
      contrasena: this.password
    }

    if (isNullOrEmptyOrUndefined(this.email) || isNullOrEmptyOrUndefined(this.password)) {
      this.genericToasterService.pushError(this.translate.instant('POR_FAVOR_COMPLETE_TODOS_LOS_DATOS'), '', true, "login");
      this.emailControl.markAsTouched();
      this.passwordControl.markAsTouched();
      element?.classList.remove("loading");
      return;
    }

    this.googleAnalyticsEventsService.emitEvent('Login', 'Boton Acceder', null, 0);
    this.authService.getLogin(body).subscribe(async (respuesta) => {
      this.userInfo = <JwtInfo>respuesta;
      if (this.userInfo.jwt != null) {
        // nota del dev: la linea de featureToggle DEBE ser comentada en la rama de dev. NO borrar, gracias.
        // await this.featureToggleService.obtenerFeatureToggles();
        if (this.userInfo.clientesParaMultiEmpresa.length > 1) {
          this.setIdiomaDespuesLogin(this.userInfo.cultura);
          this.templateUsuarioMultiEmpresa(true);
        }
        else {
          sessionStorage.setItem('token', this.userInfo.jwt);
          await this.setClientesHolding();
          this.setFunciones();
          this.setIdiomaDespuesLogin(this.userInfo.cultura);
          if (this.redirectHelper.tengoQueRedireccionar()) {
            this.redirectHelper.redirect();
          } else {
            this.recuperarMensajesUsuario();
          }
        }
      }

    },
      (err: HttpErrorResponse) => {
        var mensaje = err.error.Message;
        if (this.intentos !== 0 && mensaje === "USUARIO_CONTRASENA_INCORRECTO") {
          mensaje = "USUARIO_CONTRASENA_INCORRECTO_DOS"
        }
        this.intentos++;

        this.genericToasterService.pushError(mensaje, '', true, "login");

        element?.classList.remove("loading");

        this.emailControl.enable()
        this.passwordControl.enable()
      });
    this.setPantallasMigradas();
  }

  recuperarMensajesUsuario() {
    this.mensajeService.getMensajeUser_ResourceKey('MsjCritico').subscribe(x => {
      if (x && x.length > 0) {
        this.router.navigate(['main/mensajesCriticos']);
      }
      else {
        this.totasterService.getMessages();
        this.router.navigate(['main/home']);
      }
    });
  }

  recuperoContrasenaTemplate(value: boolean) {
    this.template_login = false;
    this.template_recupero_contrasena = true;
  }

  nuevaContrasena(value: boolean) {
    this.template_login = false;
    this.template_recupero_contrasena = false;
    this.template_nueva_contrasena = true;
    this.template_recupero_contrasena_ok = false;
    this.template_nuevo_credencial_o_contrasena = false;
    this.template_sso = false;
  }

  nuevaContrasena_o_sociales(value: boolean) {
    this.template_login = false;
    this.template_recupero_contrasena = false;
    this.template_nueva_contrasena = false;
    this.template_recupero_contrasena_ok = false;
    this.template_nuevo_credencial_o_contrasena = true;
    this.template_sso = false;
  }
  cancelar(value: boolean) {
    this.template_login = true;
    this.template_recupero_contrasena = false;
    this.template_nueva_contrasena = false;
    this.template_usuario_multiempresa = false;
    this.template_recupero_contrasena_ok = false;
    this.template_nuevo_credencial_o_contrasena = false;
    this.template_sso = false;
    this.usaCredencialesSociales = false;
    setTimeout(() => {
      let cultura = this.cookieService.get('ucultura') || 'es-AR'
      // @ts-ignore
      google.accounts.id.renderButton(
        // @ts-ignore
        document.getElementById("google-button"),
        { theme: "outline", size: "medium", width: "300px", text: "continue_with", locale: cultura, logo_alignment: "center" }
      );
    }, 10);
    this.emailControl.enable();
  }
  cancelar_sso(value: boolean) {
    this.hint_sso = false;
    this.emailControl.setErrors(null);
    this.emailControl.markAsUntouched();

    this.template_recupero_contrasena = false;
    this.template_nueva_contrasena = false;
    this.template_usuario_multiempresa = false;
    this.template_recupero_contrasena_ok = false;
    this.template_sso = false;
    this.usaCredencialesSociales = false;

    if (window.location.pathname.includes("contrasena")) {
      // Es creacion de contraseña
      this.template_login = false;
      this.template_nuevo_credencial_o_contrasena = true;
      setTimeout(() => {
        let cultura = this.cookieService.get('ucultura') || 'es-AR'
        // @ts-ignore
        google.accounts.id.renderButton(
          // @ts-ignore
          document.getElementById("google-button"),
          { theme: "outline", size: "medium", width: "300px", text: "continue_with", locale: cultura, logo_alignment: "center" }
        );
      }, 10);
    } else {
      this.template_nuevo_credencial_o_contrasena = false;
      this.template_login = true;
      this.emailControl.enable();
    }
  }
  volver_contrasena() {
    this.template_login = false;
    this.template_recupero_contrasena = false;
    this.template_nueva_contrasena = false;
    this.template_usuario_multiempresa = false;
    this.template_recupero_contrasena_ok = false;
    this.template_nuevo_credencial_o_contrasena = true;
    this.template_sso = false;
    this.usaCredencialesSociales = false;
    setTimeout(() => {
      let cultura = this.cookieService.get('ucultura') || 'es-AR'
      // @ts-ignore
      google.accounts.id.renderButton(
        // @ts-ignore
        document.getElementById("google-button"),
        { theme: "outline", size: "medium", width: "300px", text: "continue_with", locale: cultura, logo_alignment: "center" }
      );
    }, 10);
  }

  cambiarTemplate(value: boolean) {
    this.template_login = value;
    this.template_recupero_contrasena = !value;
  }

  templateUsuarioMultiEmpresa(value: boolean) {
    this.template_usuario_multiempresa = value;
    this.template_login = !value;
  }

  textoTooltipContrasena(complejidad: string) {
    switch (complejidad) {
      case 'Baja':
        this.tooltip_contrasena = this.translate.instant('COMPLEJIDAD_CONTRASENA_BAJA');
        break;
      case 'Media':
        this.tooltip_contrasena = this.translate.instant('COMPLEJIDAD_CONTRASENA_MEDIA');
        break;
      case 'Alta':
        this.tooltip_contrasena = this.translate.instant('COMPLEJIDAD_CONTRASENA_ALTA');
        break;
      default:
        break;
    }
    this.tooltip_contrasena += " \n\n" + this.translate.instant('CONSEJO_CAMBIO_CONTRASENA');
    this.tooltip_contrasena += " \n\n" + this.translate.instant('NO_UTILIZAR_PALABRAS_CONTRASENA');
    this.tooltip_contrasena += " \n\n" + this.translate.instant('CONSEJO_LA_MEJOR_CONTRASENA');
    this.tooltip_contrasena += " \n\n" + this.translate.instant('CONTRASEÑA_INTRANSFERIBLE');
    this.tooltip_contrasena += " \n\n" + this.translate.instant('NO_REUTILIZAR_CONTRASENAS');
  }

  loginMultiEmpresa() {
    this.borrarToast();
    this.userInfo.cliente.id = this.clienteSeleccionado.id;
    this.userInfo.cliente.idPais = this.clienteSeleccionado.idPais;
    this.userInfo.cliente.razonSocial = this.clienteSeleccionado.razonSocial;

    this.authService.getLoginMultiEmpresa(this.userInfo, this.usaCredencialesSociales).subscribe(async (respuesta) => {
      this.userInfo = <JwtInfo>respuesta;
      sessionStorage.removeItem('token');
      sessionStorage.setItem('token', this.userInfo.jwt);
      await this.setClientesHolding();
      this.setFunciones();
      if (this.redirectHelper.tengoQueRedireccionar()) {
        this.redirectHelper.redirect();
      } else {
        this.recuperarMensajesUsuario();
      }
    },
      (err: HttpErrorResponse) => {
        var mensaje = err.error.Message;
        if (this.intentos !== 0 && mensaje === "USUARIO_CONTRASENA_INCORRECTO") {
          mensaje = "USUARIO_CONTRASENA_INCORRECTO_DOS"
        }
        this.intentos++;
        this.toastr.error(this.translate.instant(mensaje), '', {

          positionClass: "toast-top-full-width",
          disableTimeOut: true,
          closeButton: true

        });
      });
  }

  async setClientesHolding() {
    await this.authService.getClientesHoldingFromApi();
  }

  setFunciones() {
    this.authService.getFuncionesFromToken();
  }

  setPantallasMigradas() {
    this.authService.getPantallasMigradasFromApi();
  }

  setIdiomaDespuesLogin(cultura: string) {
    var lang = this.authService.getUserData()?.Cultura;
    this.translate.use(lang || 'es-AR');
    this.cookieService.set('ucultura', lang || 'es-AR', { path: '/', secure: true, sameSite: "Strict" });
  }

  recuperoContrasena(value: boolean) {

    this.seteoVariables();
    if (isNullOrEmptyOrUndefined(this.email)) {
      this.toastr.error(this.translate.instant('POR_FAVOR_COMPLETE_TODOS_LOS_DATOS'), '', {

        positionClass: "toast-top-full-width",
        disableTimeOut: true,
        closeButton: true

      });
      this.emailControl.markAsTouched();
      return;
    }

    let body = {
      usuario: this.email,
      rtu: ''
    }

    this.authService.getRecuperarContrasena(body).subscribe((respuesta) => {
      //muestro el template de envio de recupero
      this.recuperoContrasenaTemplateOk(true);
      this.startCountdownRecoveryPassoword(59);
    },
      (err: HttpErrorResponse) => {
        var mensaje = err.error.Message;
        this.toastr.error(this.translate.instant(mensaje), '', {
          positionClass: "toast-top-full-width",
          disableTimeOut: true,
          closeButton: true
        });
      }
    );
  }

  borrarToast() {
    this.toastr.toasts.forEach(cada => {
      this.toastr.remove(cada.toastId);
    });
  }

  @HostListener('document:keydown.enter')
  onKeydownHandler() {
    this.toastr.clear();
    if (this.template_login) this.login();
    if (this.template_recupero_contrasena) this.recuperoContrasena(true);
    if (this.template_nueva_contrasena) this.nuevaContrasena(true);
  }

  recuperoContrasenaTemplateOk(value: boolean) {
    this.template_login = !value;
    this.template_recupero_contrasena = !value;
    this.template_recupero_contrasena_ok = value;
  }

  private startCountdownRecoveryPassoword(seconds: number) {
    let counter = seconds;

    const interval = setInterval(() => {
      this.segundosRestantes = counter;
      counter--;

      if (counter < 0) {
        clearInterval(interval);
        this.ver_reenviar = false;
      }
    }, 1000);
  }

  seteoVariables() {
    this.ver_reenviar = true;
    this.segundosRestantes = 60;
    this.toastr.clear();
  };

  guardar() {
    this.borrarToast();
    this.ver_terminos_condiciones = this.terminos_condiciones ? false : true; // Si esta adentro del if. El mat hint solo desaparece si vuelve a entrar en el if, sino queda siempre visible
    if (isNullOrEmptyOrUndefined(this.emailControl.value)
      || isNullOrEmptyOrUndefined(this.passwordNuevaControl.value)
      || isNullOrEmptyOrUndefined(this.repasswordControl.value)) {
      this.toastr.error(this.translate.instant('POR_FAVOR_COMPLETE_TODOS_LOS_DATOS'), '', {

        positionClass: "toast-top-full-width",
        disableTimeOut: true,
        closeButton: true

      });
      this.emailControl.markAsTouched();
      this.passwordNuevaControl.markAsTouched();
      this.repasswordControl.markAsTouched();
      return;
    }

    if (this.passwordNuevaControl.value != this.repasswordControl.value) {
      this.toastr.error(this.translate.instant('CONTRASENAS_NO_COINCIDEN'), '', {
        positionClass: "toast-top-full-width",
        disableTimeOut: true,
        closeButton: true
      });
      return;
    }
    //Verifica contraseña corta
    if (this.passwordNuevaControl.value.length < this.minLengt) {
      this.contrasena_corta = true;
      this.toastr.error(this.translate.instant('PorFavorVerifiqueLosDatosIngresados' + ''), '', {
        positionClass: "toast-top-full-width",
        disableTimeOut: true,
        closeButton: true
      });
      return;
    }
    this.contrasena_corta = false;

    if (!this.chekPasswordValueService.tieneMayuscula(this.passwordNuevaControl.value) ||
      !this.chekPasswordValueService.tieneMinuscula(this.passwordNuevaControl.value) ||
      (this.contrasena.complejidadHashDescripcion == 'Media' && (this.passStrength < 2 ||
        !this.chekPasswordValueService.complegidadMedia(this.passwordNuevaControl.value))) ||
      (this.contrasena.complejidadHashDescripcion == 'Alta' && (this.passStrength < 2 ||
        !this.chekPasswordValueService.complejidadAlta(this.passwordNuevaControl.value)))) {

      this.toastr.error(this.translate.instant('CONTRASENA_NO_CUMPLE_REQ_SEG') + ': ' + this.complejidadContrasena, '', {
        positionClass: "toast-top-full-width",
        disableTimeOut: true,
        closeButton: true
      });
      return;
    }

    let param = {
      usuario: this.contrasena.usuario,
      rtu: this.contrasena.rtu,
      ff: this.contrasena.ff,
      contrasena: this.passwordNuevaControl.value,
      repeticionContrasena: this.repasswordControl.value
    }

    this.authService.postGenerarNuevaContrasena(param).subscribe((res) => {
      this.googleAnalyticsEventsService.emitEvent('Registrarse', 'Correo electronico', null, 0);
      this.toastr.success(this.translate.instant('NUEVA_CONTRASENA_CREADA'), '', {
        positionClass: "toast-top-full-width",
        disableTimeOut: true,
        closeButton: true
      });
      setTimeout(() => {
        this.router.navigate(['login']);
      }, 1000);

    },
      (err) => {
        var msj;
        var errorMessaje: string[] = err.error.Message.split('=');

        if (errorMessaje.length == 2 && errorMessaje[1] != '') {
          msj = errorMessaje[1].slice(1, -1);
        } else {
          msj = "ERROR_APLICACION";
        }
        this.toastr.error(this.translate.instant(msj), '', {

          positionClass: "toast-top-full-width",
          disableTimeOut: true,
          closeButton: true

        });
      });
  }


  guardarCredencialesSociales(auth: AuthenticationResult) {
    let param = {
      usuario: this.contrasena.usuario,
      rtu: this.contrasena.rtu,
      ff: this.contrasena.ff,
      contrasena: this.passwordNuevaControl.value,
      repeticionContrasena: this.repasswordControl.value
    }

    this.authService.postGenerarUsuarioCredencialesSociales(param).subscribe((res) => {
      this.afterCredenciales(auth)
    },
      (err) => {
        var msj;
        var errorMessaje: string[] = err.error.Message.split('=');

        if (errorMessaje.length == 2 && errorMessaje[1] != '') {
          msj = errorMessaje[1].slice(1, -1);
        } else {
          msj = "ERROR_APLICACION";
        }
        this.toastr.error(this.translate.instant(msj), '', {

          positionClass: "toast-top-full-width",
          disableTimeOut: true,
          closeButton: true

        });
      });
  }

  async callback_login_google(json: any) {
    // console.log("JSON", json);
    let token = jwtDecode(json) as any;
    // console.log(token);
    let auth = { account: { username: token.email } } as AuthenticationResult;
    if (token.email_verified) {
      if (this.template_nuevo_credencial_o_contrasena) {
        // En el caso de que es un usuario nuevo, se debe actualizar los datos del usuario
        this.googleAnalyticsEventsService.emitEvent('Registrarse', 'Google', null, 0);
        this.guardarCredencialesSociales(auth);

      } else {
        this.afterCredenciales(auth);

      }
    }
  }

  login_google() {
    if (!this.template_nuevo_credencial_o_contrasena) {
      this.googleAnalyticsEventsService.emitEvent('Login', 'Boton Google', null, 0);
    }

    // Crea un boton falso de google para poder hacer click
    const createFakeGoogleWrapper = () => {
      const googleLoginWrapper = document.createElement("div");
      // Or you can simple hide it in CSS rule for custom-google-button
      googleLoginWrapper.style.display = "none";
      googleLoginWrapper.classList.add("custom-google-button");

      // Add the wrapper to body
      document.body.appendChild(googleLoginWrapper);

      // Use GSI javascript api to render the button inside our wrapper
      // You can ignore the properties because this button will not appear
      google.accounts.id.renderButton(googleLoginWrapper, {
        type: "icon",
        width: "200",
      });

      const googleLoginWrapperButton = googleLoginWrapper.querySelector("div[role=button]");

      return {
        click: () => {
          // @ts-ignore
          googleLoginWrapperButton.click();
        },
      };
    };
    // Now we have a wrapper to click
    const googleButtonWrapper = createFakeGoogleWrapper();
    googleButtonWrapper.click();
  }

  login_microsoft() {
    if (!this.template_nuevo_credencial_o_contrasena) {
      this.googleAnalyticsEventsService.emitEvent('Login', 'Boton Microsoft', null, 0);
    }
    this.authMsalService.loginPopup()
      .subscribe({
        next: async (result) => {
          this.ver_terminos_condiciones = this.terminos_condiciones ? false : true;
          if (this.template_nuevo_credencial_o_contrasena && this.terminos_condiciones == false) {
            // Si no acepto los terminos y condiciones, no hago nada
            this.toastr.error(this.translate.instant('POR_FAVOR_COMPLETE_TODOS_LOS_DATOS'), '', {

              positionClass: "toast-top-full-width",
              disableTimeOut: true,
              closeButton: true

            });
            return;
          }

          this.authMsalService.instance.setActiveAccount(result.account);
          let email = await this.getUserMicrosoft(true)
          let auth = { account: { username: email } } as AuthenticationResult;
          if (this.template_nuevo_credencial_o_contrasena) {
            this.googleAnalyticsEventsService.emitEvent('Registrarse', 'Microsoft', null, 0);
            // En el caso de que es un usuario nuevo, se debe actualizar los datos del usuario
            this.guardarCredencialesSociales(auth);

          } else {
            this.afterCredenciales(auth);

          }
        },
        error: (error) => {
          //console.log(error)
          //esta key es la que controla el estado del logeo
          localStorage.removeItem('msal.interaction.status');
          sessionStorage.removeItem('msal.interaction.status');
          localStorage.clear();
          sessionStorage.clear();

        }
      });

  }

  private async getUserMicrosoft(authenticated: boolean): Promise<string | undefined> {
    if (!authenticated) return undefined;

    // Create an authentication provider for the current user
    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
      this.authMsalService.instance as PublicClientApplication,
      {
        account: this.authMsalService.instance.getActiveAccount()!,
        scopes: ['user.read'],
        interactionType: InteractionType.Popup,
      },
    );

    // Initialize the Graph client
    this.graphClient = Client.initWithMiddleware({
      authProvider: authProvider,
    });

    // Get the user from Graph (GET /me)
    const graphUser: MicrosoftGraph.User = await this.graphClient
      .api('/me')
      .select('displayName,mail,userPrincipalName')
      .get();

    return graphUser.mail ?? graphUser.userPrincipalName ?? '';
  }

  afterCredenciales(result: AuthenticationResult) {

    //verificar si existe el usuario en DPT, login
    let body = {
      usuario: result.account?.username,
      rtu: ''
    }

    this.usaCredencialesSociales = true;
    this.authService.getUser(body, true).subscribe(async (respuesta) => {

      this.userInfo = <JwtInfo>respuesta;
      // let authResult = respuesta as AuthenticationResult;
      // this.authMsalService.instance.setActiveAccount(authResult.account);
      if (this.userInfo.jwt != null) {
        // nota del dev: la linea de featureToggle DEBE ser comentada en la rama de dev. NO borrar, gracias.
        // await this.featureToggleService.obtenerFeatureToggles();
        // @ts-ignore
        if (!this.jwtService.getToken(this.userInfo.jwt)?.userdata?.UsaSocialLogin) {
          await this.mostrarPopUpPrimerCredencialSocial();
        } else {
          await this.afterCredenciales_multiempresa_o_home();
        }
      }

    },
      (err: HttpErrorResponse) => {
        var mensaje = err.error.Message;
        if (this.intentos !== 0 && mensaje === "USUARIO_CONTRASENA_INCORRECTO") {
          mensaje = "USUARIO_CONTRASENA_INCORRECTO_DOS"
        }
        this.intentos++;

        this.genericToasterService.pushError(mensaje, '', true, "login");

        //element?.classList.remove("loading");
        localStorage.clear();
        sessionStorage.clear();
        this.emailControl.enable()
        this.passwordControl.enable()
      });
  }

  async afterCredenciales_multiempresa_o_home() {
    if(this.userInfo.estadoUsuario != "Activo"){
      let param = {
        id: this.jwtService.getToken(this.userInfo.jwt)?.userdata?.Id,
        idPais : this.jwtService.getToken(this.userInfo.jwt)?.userdata?.IdPais
      }
      this.usuarioService.ActualizarEstadoActivo(param).subscribe(x => {
        
      });
    }


    if (this.userInfo.clientesParaMultiEmpresa.length > 1) {
      this.setIdiomaDespuesLogin(this.userInfo.cultura);
      this.templateUsuarioMultiEmpresa(true);
    }
    else {
      sessionStorage.setItem('token', this.userInfo.jwt);
      await this.setClientesHolding();
      this.setFunciones();
      this.setIdiomaDespuesLogin(this.userInfo.cultura);
      if (this.redirectHelper.tengoQueRedireccionar()) {
        this.redirectHelper.redirect();
      } else {
        this.recuperarMensajesUsuario();
      }
    }
  }
  _habilitarBotones() {
    if (this.terminos_condiciones) {
      // @ts-ignore
      google.accounts.id.renderButton(
        // @ts-ignore
        document.getElementById("google-button"),
        { theme: "outline", size: "medium", width: "300px", text: "continue_with", locale: this.cookieService.get('ucultura') || 'es-AR', logo_alignment: "center" }
      );
    }
  }

  habilitarBotones() {
    if (this.terminos_condiciones == false) {

      var input_microsoft = document.getElementById("google-button-front") ?? new HTMLElement();
      input_microsoft.style.opacity = "0.6";
      input_microsoft.style.pointerEvents = "none";

      var input_microsoft = document.getElementById("microsoft-button") ?? new HTMLElement();
      input_microsoft.style.opacity = "0.6";
      input_microsoft.style.pointerEvents = "none";

      var input_sso = document.getElementById("sso-button") ?? new HTMLElement();
      input_sso.style.opacity = "0.6";
      input_sso.style.pointerEvents = "none";
    }

    if (this.terminos_condiciones) {
      var input_microsoft = document.getElementById("google-button-front") ?? new HTMLElement();
      input_microsoft.style.opacity = "1";
      input_microsoft.style.pointerEvents = "auto";

      var input_microsoft = document.getElementById("microsoft-button") ?? new HTMLElement();
      input_microsoft.style.opacity = "1";
      input_microsoft.style.pointerEvents = "auto";

      var input_sso = document.getElementById("sso-button") ?? new HTMLElement();
      input_sso.style.opacity = "1";
      input_sso.style.pointerEvents = "auto";
    }

  }

  boton_sso() {
    if (!this.template_nuevo_credencial_o_contrasena) {
      this.googleAnalyticsEventsService.emitEvent('Login', 'Boton SSO', null, 0);
    }
    this.template_login = false;
    this.template_recupero_contrasena = false;
    this.template_nueva_contrasena = false;
    this.template_usuario_multiempresa = false;
    this.template_recupero_contrasena_ok = false;
    this.template_nuevo_credencial_o_contrasena = false;
    this.template_sso = true;
    this.hint_sso = false;
    this.emailControl.setErrors(null);
    this.emailControl.markAsUntouched();
  }
  login_sso() {
    if (isNullOrEmptyOrUndefined(this.email)) {
      this.genericToasterService.pushError(this.translate.instant('POR_FAVOR_COMPLETE_TODOS_LOS_DATOS'), '', true, "login");
      this.emailControl.markAsTouched();
      return;
    }
    this.hint_sso = true;

    this.emailControl.setErrors({ 'incorrect': true });
    this.googleAnalyticsEventsService.emitEvent('Login SSO', 'Ingreso SSO', this.email, 0);
  }

  private async mostrarPopUpPrimerCredencialSocial() {
    const dialogRef = this.dialog.open(PopUpCredencialesComponent, {
      autoFocus: true,
      scrollStrategy: new NoopScrollStrategy(),
      width: '500px',
      height: '382px',
      maxWidth: '500px',
      maxHeight: '382px',
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe(
      async result => {
        if (result) {
          // guardar usa credenciales
          let param = {
            id: this.jwtService.getToken(this.userInfo.jwt)?.userdata?.Id,
            idPais : this.jwtService.getToken(this.userInfo.jwt)?.userdata?.IdPais
          }
          this.authService.actualizarUsaSocialLogin(param).pipe(take(1)).subscribe( async x => {
            await this.afterCredenciales_multiempresa_o_home();
          });
            
          
        } else {
          // mandarlo a login
          localStorage.clear();
          sessionStorage.clear();
        }
      }
    );

  }
}
