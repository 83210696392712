<div>
  <div class="title-wrapper">
    <h2>
      {{ "CAMBIO_CONTRASENA" | translate }}
    </h2>
  </div>
  <div [formGroup]="formModificarContrasena">
    <mat-card>
      <div class="card-elements-wrapper">
        <div class="left-elements-wrapper">
          <div class="passwords-wrapper">
            <mat-form-field appearance="outline">
              <mat-label for="origen"
                >{{ "CONTRASENA_ACTUAL" | translate }} *</mat-label
              >
              <input
                [type]="showPassword ? 'text' : 'password'"
                matInput
                name="contrasenaActual"
                #contrasenaActual
                id="contra"
                formControlName="contrasenaActual"
                minlength="8"
                
                required="false"
              />
              <mat-icon matSuffix (click)="togglePasswordVisibility()">
                {{ showPassword ? "visibility" : "visibility_off" }}
              </mat-icon>
              <mat-hint
                class="hintStyle"
                *ngIf="
                  flagGuardar &&
                  formModificarContrasena.get('contrasenaActual')!.invalid
                "
                >{{
                  "POR_FAVOR_COMPLETE_TODOS_LOS_DATOS" | translate
                }}</mat-hint
              >
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label for="origen"
                >{{ "NUEVA_CONTRASENA" | translate }} *</mat-label
              >
              <input
                [type]="showPasswordNew ? 'text' : 'password'"
                matInput
                name="contrasenaNueva"
                #contrasenaNueva
                formControlName="contrasenaNueva"
                minlength="{{ minLengh }}"
                
                required="false"
              />
              <mat-icon matSuffix (click)="togglePasswordVisibilityNew()">
                {{ showPasswordNew ? "visibility" : "visibility_off" }}
              </mat-icon>
              <!-- <mat-hint class="hintStyle"
                *ngIf="flagGuardar && formModificarContrasena.get('contrasenaNueva')!.invalid"
                >{{ (formModificarContrasena.controls.contrasenaNueva.value === '' ? "POR_FAVOR_COMPLETE_TODOS_LOS_DATOS" : "CONTRASENA_DEBE_POSEER")| translate }}
                </mat-hint> -->
            </mat-form-field>
          </div>
          <div class="login__terminos_condicion">
            <p>
              {{ complejidadContrasena | translate }}
              <span>
                <mat-icon [ngClass]="icono">
                  {{ icono }}
                </mat-icon>
              </span>
            </p>
          </div>
          <div>
            <mat-label
              class="ingresar"
              style="font-family: montserrat-regular; margin-top: 0.5rem"
              >{{ "FORTALEZA_CONTRASENA" | translate }}
            </mat-label>
            <mat-icon
              matTooltip="{{ tooltip_contrasena }}"
              matTooltipClass="login__tooltip_contrasena"
              matTooltipPosition="right"
              style="
                font-size: 16px;
                padding-top: 0.2rem;
                padding-left: 0.2rem;
                position: absoulte;
              "
              >help</mat-icon
            >
          </div>
          <div class="barra">
            <password-strength-meter
              [password]="contrasenaNueva.value"
              [enableFeedback]="false"
              strengthChange="3"
            ></password-strength-meter>
          </div>
          <!-- <div class="contrasena-wrapper">
            <mat-icon class="done" *ngIf="this.formModificarContrasena.get('contrasenaNueva')?.value.length >= minLengh">
              done
            </mat-icon>
            <mat-icon class="cancel" *ngIf="this.formModificarContrasena.get('contrasenaNueva')?.value.length < minLengh">
              clear icon
            </mat-icon>
            <mat-label class="contrasena_debe_poseer"
              >{{ contrasenaDebePoseer }}
            </mat-label>
            <!-- <button (click)="toggleCheckVisibility()">Test</button>
            <p>{{ showCheck }}</p>
          </div> -->

          <mat-form-field appearance="outline">
            <mat-label for="origen"
              >{{ "REPITA_NUEVA_CONTRASENA" | translate }} *</mat-label
            >
            <input
              [type]="showPasswordRepeat ? 'text' : 'password'"
              matInput
              name="repeticionContrasena"
              #repeticionContrasena
              formControlName="repeticionContrasena"
              minlength="{{ minLengh }}"
              
              required="false"
            />
            <mat-icon matSuffix (click)="togglePasswordVisibilityRepet()">
              {{ showPasswordRepeat ? "visibility" : "visibility_off" }}
            </mat-icon>
            <mat-hint
              class="hintStyle"
              *ngIf="
                flagGuardar &&
                formModificarContrasena.get('repeticionContrasena')!.invalid
              "
              >{{ validatePassRepeat() }}</mat-hint
            >
          </mat-form-field>
        </div>
      </div>

      <div class="buttons-wrapper">
        <div style="padding: 10px">
          <button mat-button class="cancelar" [routerLink]="['/main/home']">
            {{ "CANCELAR_Y_VOLVER" | translate }}
          </button>
        </div>

        <div style="padding: 10px" *ngIf="!usaSocialLogin">
          <button class="acceder" mat-raised-button (click)="getPassword()">
            {{ "GUARDAR_Y_VOLVER" | translate }}
          </button>
        </div>
        <div matTooltip="{{ 'NO_PUEDE_CAMBIAR_CONTRASENA_SSO' | translate }} ">
          <button
            *ngIf="usaSocialLogin"
            class="m-2"
            mat-raised-button
            disabled
            
          >
          {{ "GUARDAR_Y_VOLVER" | translate }}
          </button>
        </div>
      </div>
    </mat-card>
  </div>
</div>
