import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogoService {

  constructor(public http: HttpClient) { }
  
  getLogoWeb(id: number, idPais: number){
    return this.http.get(`${environment.apiUrl}/Cliente/GetLogoWeb/Paises/${idPais}/Clientes/${id}`).pipe(map((x: any) => this.convert(x.hex)))
  }

  convert(img: string | null): Uint8Array{
      if(!img){
        return new Uint8Array();
      }
      let input = img.replace(/[^A-Fa-f0-9]/g, "");
      if (input.length % 2) {
          return new Uint8Array();
      }
  
      let binary = new Array();
      for (let i = 0; i < input.length / 2; i++) {
          let h = input.substr(i * 2, 2);
          binary[i] = parseInt(h, 16);
      }
  
      let byteArray = new Uint8Array(binary);
      return byteArray;  
  }
}
