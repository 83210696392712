<div>
  <app-navbar></app-navbar>
  <div class="mainContent">
    <app-main-nav id="hide-menu-on-mobile"></app-main-nav>
    <div class="page__toaster-messages" *ngIf="toasterService.messages">
      <ng-container *ngFor="let item of toasterService.messages">
        <app-toaster [message]="item"></app-toaster>
      </ng-container>
    </div>
    <div class="connected-as">
      <ng-container *ngIf="authService.userdata.ConectadoComo">
        <app-connected-support
          [client]="authService.userdata.ConectadoComo"
          [pais]="paisKey"
        ></app-connected-support>
      </ng-container>
    </div>
    <div style="background: #F2F2F2">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<!-- <app-footer style="  position: inherit;
bottom: 0;"></app-footer> -->
