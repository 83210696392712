import { Injectable } from '@angular/core'
import navigation from './data/navigation.data'
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

export type NavigationData = typeof navigation

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  public readonly from: NavigationData
  openNotificationPanel: Boolean = false

  constructor(private http: HttpClient) {
    this.from = navigation
  }

  getNotifications() {
    return this.http.get(`${environment.apiUrl}/descargas/cantidad`)
  }

  getAdminsInfo() {
    return this.http.get(`${environment.apiUrl}/cliente/administradores`)
  }
  
  getVersion() {
    return this.http.get(`${environment.apiUrl}/version`)
  }

  logout() {
    return this.http.get(`${environment.apiUrl}/acceso/salir`)
  }
  logout_msoft() : Observable<any> {
    return this.http.post<any>(`https://login.live.com/logout.srf`, null)
  }
  

}
