
 <mat-toolbar class="mat-footer">
  <mat-toolbar-row>
    <span>
      <app-planex-logo height="25px" inline="true"></app-planex-logo>
    </span>

    <div class="contacto">
      <span class="icons_media">
        <a href="{{socialNav.twitter}}" target="_blank" rel="noreferrer noopener">
          <fa-icon class="icon_white" [icon]="faTwitter"></fa-icon>
        </a>
        <a href="{{socialNav.linkedin}}" target="_blank" rel="noreferrer noopener">
          <fa-icon class="icon_white" [icon]="faLinkedIn"></fa-icon>
        </a>
      </span>
      <span>
        <div>
          <a href="https://www.planexware.com/contacto" target="_blank" class="plx-iphone" rel="noopener noreferrer">
            <fa-icon class="icon_red" [icon]="faPhoneAlt"></fa-icon><span>+54 (11) 7701-8282</span>
          </a>
          <a href="mailto:mesadeayuda@planexware.com" class="plx-imail">
            <fa-icon class="icon_red" [icon]="faEnvelope"></fa-icon><span>mesadeayuda&#64;planexware.com</span>
          </a>
       </div>
      </span>

    </div>
  </mat-toolbar-row>
  <mat-toolbar-row>
    <span>
      <div class="copyright">
        <mat-label>© Copyright {{currentYear}} Planexware S.A. Versión {{ version }} </mat-label> <span class="hidden-copyright-separator"> | </span>
          <a routerLink="main/terminosYCondiciones" target="terminosYCondiciones">{{"TERMINOS_CONDICIONES" | translate}}</a> |
          <a routerLink="main/politicasDePrivacidad" target="politicasDePrivacidad">{{"POLITICAS_PRIVACIDAD" | translate}}</a>
      </div>
    </span>
    <span>
      <div style="height: 55px;">
        <a href="http://qr.afip.gob.ar/?qr=ZfqOOFvMtPvU-djG9_ld2w,," target="_F960AFIPInfo">
          <img src="assets/certificates.png" alt="Planexware" width="220" />
          <img id="certificado-afip" src="assets/afip.png" alt="AFIP Data Fiscall" width="30">
        </a>
      </div>
    </span>
  </mat-toolbar-row>
</mat-toolbar>
