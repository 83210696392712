import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export class PaginatorI18n {

  constructor(private readonly translate: TranslateService) {}

  private RangeLabel = (page: number, pageSize: number, length: number) => {
    let de = this.translate.instant("DE_PAGER");
    if (length == 0 || pageSize == 0) { return `0 ${de} ${length}`; }
    
    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${de} ${length}`;
  }


  public getPaginatorIntl():MatPaginatorIntl {
    const paginatorIntl = new MatPaginatorIntl(); 

    paginatorIntl.itemsPerPageLabel = this.translate.instant("ITEMS_POR_PAGINA");
    paginatorIntl.nextPageLabel = this.translate.instant("PAGINA_SIGUIENTE");
    paginatorIntl.previousPageLabel = this.translate.instant("PAGINA_ANTERIOR");
    paginatorIntl.firstPageLabel = this.translate.instant("PRIMER_PAGINA");
    paginatorIntl.lastPageLabel = this.translate.instant("ULTIMA_PAGINA");
    paginatorIntl.getRangeLabel = this.RangeLabel; 
    
    return paginatorIntl;
  }
}