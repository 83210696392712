import { Pipe, PipeTransform, ViewContainerRef } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'DocHistorial' })
export class DocHistorialPipe implements PipeTransform {
   constructor(private domSanitizer: DomSanitizer,
      public readonly elementRef: ViewContainerRef) {

   }

   transform(html: string): SafeHtml {
      return this.domSanitizer.bypassSecurityTrustHtml(html);
   }

}