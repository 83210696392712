import { Injectable } from '@angular/core'
import jwtDecode from 'jwt-decode'
import { AccessToken } from '../models/accessToken'

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  getAccessToken(): AccessToken | undefined {
    const rawToken = sessionStorage.getItem('token')
    return this.getToken(rawToken)
  }

  getToken(token: string|null): AccessToken | undefined {
    if (token) {
      const decoded = jwtDecode<AccessToken>(token)
      return {
        ...decoded,
        userdata: JSON.parse(decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata']),
      }
    }
  }
}
