import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClaveCompuestaView } from 'src/app/core/models/ClaveCompuestaView';
import { environment } from 'src/environments/environment';
import { BusquedaDocumentos } from '../models/BusquedaDocumentos';
import { ClienteDocumentos } from '../models/ClienteDocumentos';
import { DocumentosExportarMasClientes } from '../models/DocumentosExportarMasClientes';
import { DocumentosMasClientes } from '../models/DocumentosMasClientes';
import { DocData } from '../table-doc/table-doc.component';
import { GenericToasterService } from 'src/app/core/services/generic-toaster.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentosClienteService {

  constructor(public http: HttpClient,
      private genericToasterService: GenericToasterService) {

  }

  getDocumentos(esPrueba: boolean, searchFilters: BusquedaDocumentos): Observable<any> {
    return this.http.post(`${environment.apiUrl}/DocumentosCliente/RecuperarMultiDocumentos/EsPrueba/${esPrueba}`, searchFilters);
  }

  getDocumentosCount(searchFilters: BusquedaDocumentos): Observable<any> {
    return this.http.post(`${environment.apiUrl}/DocumentosCliente/RecuperarMultiDocumentosCount`, searchFilters);
  }

  getDocumentosAsocidos(idSobre: string, idPaisSobre: string, esPrueba: boolean): Observable<any> {
    return this.http.get(`${environment.apiUrl}/DocumentosCliente/RecuperarDocumentosAsociados`,{ params: {idSobre: idSobre,IdPaisSobre:idPaisSobre,EsPrueba: esPrueba}});
  }

  getDocumentosReferencia(idSobre: string, idPaisSobre: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/DocumentosCliente/RecuperarDocumentosReferencia`,{ params: {idSobre: idSobre, IdPaisSobre: idPaisSobre}});
  }

  getDetalleDocumento(id: string, idPais: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/DocumentosCliente/RecuperarDetalleDocumento`,{ params: {id: id,idPais:idPais}});
  }
  getDetalleDocumentoVersionRegistro(id: string, idPais: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/DocumentosCliente/RecuperarDetalleDocumentoVersionRegistro`,{ params: {id: id,idPais:idPais}});
  }

  getHistorialDocumento(id: string, idPais: string, sentido: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/DocumentosCliente/RecuperarHistorialDocumento`,{ params: {id: id,idPais:idPais,sentido: sentido}});
  }

  getTiposExportacionHabilitados(): Observable <any> {
    return this.http.get(`${environment.apiUrl}/DocumentosCliente/DocumentosFormatosExportacionHabilitados`);
  }

  getEstructuras(id: number, idPais: number): Observable <any> {
    return this.http.get(`${environment.apiUrl}/DocumentosCliente/GetEstructuras/Id/${id}/IdPais/${idPais}`);
  }

  GetDefaultBusquedaDocumentos(): Observable <any> {
    return this.http.get(`${environment.apiUrl}/DocumentosCliente/GetDefaultBusquedaDocumentos`);
  }

  // GetEmptyBusquedaDocumentos(): Observable <any> {
  //   return this.http.get(`${environment.apiUrl}/DocumentosCliente/GetEmptyBusquedaDocumentos`);
  // }

  PutBusquedaDocumentos(busquedaDocumentos: BusquedaDocumentos): Observable <any> {
    return this.http.put(`${environment.apiUrl}/DocumentosCliente/SaveFiltroBusquedaDocumentos`, busquedaDocumentos);
  }

  getVerificarPoseeDocumentosVinculados(id: string, idPais: string, sentido: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/DocumentosCliente/VerificarPoseeDocumentosVinculados`,{ params: {idDocumentoSobreHash: id,idPaisHash: idPais,origen: sentido}});
  }

  getVerificarDisponibleVisualizadorHash(id: string, idPais: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/DocumentosCliente/VerificarDisponibleVisualizadorHash`,{ params: {idHash: id,idPaisHash:idPais}});
  }

  getVerificarDisponibleVisualizador(id: string, idPais: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/DocumentosCliente/VerificarDisponibleVisualizador`,{ params: {id: id,idPais:idPais}});
  }

  postExportarDocumentos(docs: any[], clientesHolding:any[], formato:string): Observable<any> {
    let documentosMasClientes = {
      Documentos : docs,
      ClientesHolding: clientesHolding,
      Formato : formato
    }
    return this.http.post(`${environment.apiUrl}/DocumentosCliente/ExportarDocumentos`,documentosMasClientes);
  }

  postExportarDocumentosHistorial(docs: any[], clientesHolding:any[], formato:string): Observable<any> {
    let documentosMasClientes = {
      Documentos : docs,
      ClientesHolding: clientesHolding,
      Formato : formato
    }
    return this.http.post(`${environment.apiUrl}/DocumentosCliente/ExportarSobre`,documentosMasClientes);
  }

  getExportarDocumentosAdjunto(doc: any): Observable<any> {
    return this.http.get(`${environment.apiUrl}/DocumentosCliente/RecoverContenidoBinarioDArchivoAdjunto`, { params: {id: doc.id, idPais: doc.idPais}});
  }

  getRecuperarExportacionDocumentos(idDescarga: string, documentoSponsoreado: boolean = false): Observable<any> {
    return this.http.get(`${environment.apiUrl}/DocumentosCliente/RecuperarExportacionDocumentos`,{ params: {id: idDescarga, documentoSponsoreado: documentoSponsoreado}});
  }

  getVisualizarDocumento(guid: string, filename: string, tipoContenido: string, m: boolean, attach: boolean): Observable<any> {
    return this.http.get(`${environment.apiUrl}/DocumentosCliente/VisualizarDocumento`,{ params: {guid: guid,filename:filename,tipoContenido:tipoContenido,m:m,attach:attach}});
  }

  getDescargaDocumento(guid: string, filename: string, tipoContenido: string, m: boolean, attach: boolean): Observable<any> {
    return this.http.get(`${environment.apiUrl}/DocumentosCliente/VisualizarDocumento`,{ params: {guid: guid,filename:filename,tipoContenido:tipoContenido,m:m,attach:attach},responseType: 'arraybuffer'});
  }

  postExportarGrilla(documentos:DocData[]): Observable<any> {
    return this.http.post(`${environment.apiUrl}/DocumentosCliente/ExportarGrilla`,documentos);
  }

  postExportarGrillaTodosLosResultados(claveCompuesta:ClaveCompuestaView): Observable<any> {
    return this.http.post(`${environment.apiUrl}/DocumentosCliente/ExportarGrillaTodosLosResultados`,claveCompuesta);
  }

  getValidarAccesoDocumentoAsociado(id: string, idPais: string): Observable<any> {

    return this.http.get(`${environment.apiUrl}/DocumentosCliente/ValidarAccesoDocumentosAsociados`, { params: {id: id, idPais: idPais}});

  }

  postReprocesarDocumento(documentos:ClaveCompuestaView[]): Observable<number> {
    return this.http.post<number>(`${environment.apiUrl}/DocumentosCliente/ReprocesarDocumentos`,documentos);
  }

  postRedisponibilizarDocumentos(documentos:ClaveCompuestaView[]): Observable<number> {
    return this.http.post<number>(`${environment.apiUrl}/DocumentosCliente/RedisponibilizarDocumentos`,documentos);
  }

  postCancelarDocumentos(documentos:ClaveCompuestaView[]): Observable<number> {
    return this.http.post<number>(`${environment.apiUrl}/DocumentosCliente/CancelarDocumentos`,documentos);
  }
  getMotivosDeRechazo(id: number, idPais: number, idDocumento: number): Observable <any> {
    return this.http.get(`${environment.apiUrl}/DocumentosCliente/RecuperarMotivosDeRechazo/Paises/${idPais}/Clientes/${id}/TiposDocumento/${idDocumento}`);
  }

  postDocumentosTipoExportacionDocumentos(documentosExportarMasClientes:DocumentosExportarMasClientes): Observable <any> {
    return this.http.post(`${environment.apiUrl}/DocumentosCliente/DocumentosTipoExportacionDocumentos`, documentosExportarMasClientes);
  }

  postRecuperarUrlEdicionRemitoCarnico(claveCompuesta:ClaveCompuestaView): Observable<any>{
    return this.http.post(`${environment.apiUrl}/DocumentosCliente/RecuperarURLEdicionRemitoCarnico`,claveCompuesta, {responseType: 'text' });
  }
  getRecuperarResultadoAltaMasivaClientes(id: string, idPais: string, tipoDocumento: string, numero: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/DocumentosCliente/RecuperarResultadoAltaMasivaClientes`,{ params: {id: id, p:idPais, tipoDocumento:tipoDocumento, numero:numero}, responseType: 'arraybuffer'});
  }
  getEtiqueta(docs: DocData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/DocumentosCliente/VisualizarEtiqueta`,docs, {responseType: 'text' });
  }
  postHistorialVisualizacionSponspor(documentos:ClaveCompuestaView[]) {
    return this.http.post(`${environment.apiUrl}/DocumentosCliente/PostHistorialVisualizacionSponsor`,documentos);
  }
  //Generar nuevo get de obtencion de apertura de chat segun condicion.
  
  getTieneDescargasEnProceso(): Promise<boolean>{
      return new Promise((resolve)=>{
        this.http.get<boolean>(`${environment.apiUrl}/Descargas/TieneDescargasEnProceso`).subscribe({
          next: (data: boolean) => { resolve(data); },
          error: (err) => { this.genericToasterService.pushError("ERROR_APLICACION"); }
        });
      });
    }

}
