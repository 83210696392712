export class ClienteRelacionDocumentosCliente {
    id: number;
    idPais: number;
    idInterno?: number;

    razonSocial?: string;
    owner?: boolean;
    esHolding?: boolean;
    acceso?: boolean;

    aliasEmpresa?: string;
    identificador?: string;
    domicilio?: string;
    codigoTipoIdentificacion?: string;
    estadoAutenticacion?: string;

    cantidadEstructuras?: number;
}