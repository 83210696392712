
import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor,HttpRequest} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class AppHttpInterceptorRequest implements HttpInterceptor {
    constructor() {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      req = req.clone({
        setHeaders: {
          "Permissions-Policy": "accelerometer=(), camera=(), geolocation=(), gyroscope=(), magnetometer=(), microphone=(), payment=(), usb=(), interest-cohort=()",
          "Strict-Transport-Security": "max-age=31536000; includeSubdomains",
          "X-Frame-Options": "SAMEORIGIN",
          "Referrer-Policy": "strict-origin-when-cross-origin",
          "X-Content-Type-Options": "nosniff",
          "X-Xss-Protection": "1; mode=block",
          "Content-Security-Policy": "default-src 'self'; form-action 'self'; script-src 'unsafe-inline' 'self'"
        }
      });
        return next.handle(req);
    }
}
