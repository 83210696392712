<div class="custom-text">

    <div>
        <h2 class="tituloPagina"
        style="font-family: montserrat-semibold">
            {{ "PanelSeguimiento" | translate }}
            <i class="fa fa-question-circle" style="margin-left: 5px; cursor: pointer;" matTooltipPosition="right"
                matTooltip="{{ 'seguimiento_fcred_info' | translate }}" (click)="onClickAyudaSeguimientoFcred()">
            </i>
        </h2>
    </div>

    <div style="display: flex; align-items: flex-start; align-items: first baseline;">

        <mat-form-field appearance="outline" style="width: 32%; margin-left: 10px;">
            <mat-select [(value)]="opcionSeleccionada">
                <mat-option *ngFor="let opt of datosFacturaCreditoSelected" [value]="opt">
                    {{ opt.resourceKey | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <a *ngIf="opcionSeleccionada?.resourceKey == 'IndProvNoHab'"
            style="display: flex; align-items: center; margin-left: 15px; color: #434040; font-size: 11px; cursor: pointer; text-transform: uppercase;"
            (click)="OnClickRecuperarPoroveedoresHabituales()">
            <span>{{"VER_PROV_HABITUAL" | translate}}</span>
            <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 -960 960 960" width="24"
                style="margin-left: 5px;">
                <path
                    d="M702-480 560-622l57-56 85 85 170-170 56 57-226 226Zm-342 0q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Z" />
            </svg>
        </a>

        <div style="margin-left: auto; display: flex; align-items: center; position: relative;">

            <div
                style="position: absolute; top: -7px; right: 10px; font-size: 9px; color: #aaa; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                {{ "UltimaActualizacion" | translate }} {{ ultimaFecha?.valor }}
            </div>

            <button *ngIf="opcionSeleccionada?.tieneSalida" mat-stroked-button color="primary"
                style="margin: 10px; bottom: 0;" (click)="OnClickRecuperarDatosTotal()">
                <mat-icon>download</mat-icon>
            </button>
            <button mat-stroked-button color="primary" *ngIf="opcionSeleccionada?.resourceKey != 'IndTotNoVenc'"
                style="margin: 10px; bottom: 0;" (click)="ConsultaFCRED_TOP()">{{ "GESTIONAR_COMPROBANTES" | translate }}</button>
        </div>

    </div>



    <div class="grid-container small">
        <div class="grid-item">
            <h2 class="grid-item-header">{{ "Facturas" | translate }}</h2>
            <div class="grid-item-content space-content">
                <svg xmlns="http://www.w3.org/2000/svg" height="50" viewBox="0 -960 960 960" width="65"
                    style="color: #c44ace; margin-right: 15px;">
                    <path
                        d="M240-80q-50 0-85-35t-35-85v-120h120v-560l60 60 60-60 60 60 60-60 60 60 60-60 60 60 60-60 60 60 60-60v680q0 50-35 85t-85 35H240Zm480-80q17 0 28.5-11.5T760-200v-560H320v440h360v120q0 17 11.5 28.5T720-160ZM360-600v-80h240v80H360Zm0 120v-80h240v80H360Zm320-120q-17 0-28.5-11.5T640-640q0-17 11.5-28.5T680-680q17 0 28.5 11.5T720-640q0 17-11.5 28.5T680-600Zm0 120q-17 0-28.5-11.5T640-520q0-17 11.5-28.5T680-560q17 0 28.5 11.5T720-520q0 17-11.5 28.5T680-480ZM240-160h360v-80H200v40q0 17 11.5 28.5T240-160Zm-40 0v-80 80Z" />
                </svg>
                <p class="text-info-fac">{{ opcionSeleccionada?.cantidad }}</p>
            </div>
        </div>
        <div class="grid-item">
            <h2 class="grid-item-header">{{ "Proveedores" | translate }}</h2>
            <div class="grid-item-content space-content">
                <svg xmlns="http://www.w3.org/2000/svg" height="50" viewBox="0 0 24 24" width="65"
                    style="color: #c44ace; margin-right: 15px;">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                        d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z" />
                </svg>
                <p class="text-info-fac">{{ opcionSeleccionada?.proveedores }}</p>
            </div>
        </div>
        <div class="grid-item">
            <h2 class="grid-item-header">{{ "ImporteTotal" | translate }}</h2>
            <div class="grid-item-content space-content">
                <svg xmlns="http://www.w3.org/2000/svg" height="50" viewBox="0 -960 960 960" width="65"
                    style="margin-right: 15px;">
                    <path
                        d="M560-440q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM280-320q-33 0-56.5-23.5T200-400v-320q0-33 23.5-56.5T280-800h560q33 0 56.5 23.5T920-720v320q0 33-23.5 56.5T840-320H280Zm80-80h400q0-33 23.5-56.5T840-480v-160q-33 0-56.5-23.5T760-720H360q0 33-23.5 56.5T280-640v160q33 0 56.5 23.5T360-400Zm440 240H120q-33 0-56.5-23.5T40-240v-440h80v440h680v80ZM280-400v-320 320Z" />
                </svg>
                <p class="text-info-fac">$ {{ opcionSeleccionada?.saldo | number:'1.2-2' }}</p>
            </div>
        </div>
    </div>

    <div class="grid-container big">
        <div class="grid-item">
            <h2 style="font-family: montserrat-semibold" class="grid-item-header-left">{{ "FacturasVencer" | translate }}</h2>
            <div class="grid-item-buttons">
                <button mat-button class="item-button"
                    [class.delineated]="datosFacturaCreditoDias?.resourceKey == 'IndVencMan'"
                    (click)="onSeleccionDays('IndVencMan')">{{ "MAÑANA_UPPER" | translate }}</button>
                <button mat-button class="item-button"
                    [class.delineated]="datosFacturaCreditoDias?.resourceKey == 'IndConf1'"
                    (click)="onSeleccionDays('IndConf1')">{{ "Proximo_UPPER" | translate }}
                    {{onSeleccionDiasShow('DiasIndConf1')}} {{ "DÍAS" | translate }}</button>
                <button mat-button class="item-button"
                    [class.delineated]="datosFacturaCreditoDias?.resourceKey == 'IndConf2'"
                    (click)="onSeleccionDays('IndConf2')">{{ "Proximo_UPPER" | translate }}
                    {{onSeleccionDiasShow('DiasIndConf2')}} {{ "DÍAS" | translate }}</button>
            </div>
            <div class="grid-item-content">
                <div class="grid-item-column">
                    <div style="margin-left: -20px;">
                        <canvas baseChart [colors]="doughnutChartColors" [data]="doughnutChartData"
                            [chartType]="doughnutChartType" [options]="doughnutChartOptions"
                            [labels]="doughnutChartLabels"></canvas>
                    </div>
                    <div style="display: flex; justify-content: center;">{{ "TOTAL" | translate }}: {{totalFacturas}}
                    </div>
                </div>
                <div class="grid-item-column" style="margin-top: 25px; margin-left: 10%;">

                    <div class="grid-item-row">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"
                            style="color: #c44ace; margin-right: 15px;">
                            <path
                                d="M240-80q-50 0-85-35t-35-85v-120h120v-560l60 60 60-60 60 60 60-60 60 60 60-60 60 60 60-60 60 60 60-60v680q0 50-35 85t-85 35H240Zm480-80q17 0 28.5-11.5T760-200v-560H320v440h360v120q0 17 11.5 28.5T720-160ZM360-600v-80h240v80H360Zm0 120v-80h240v80H360Zm320-120q-17 0-28.5-11.5T640-640q0-17 11.5-28.5T680-680q17 0 28.5 11.5T720-640q0 17-11.5 28.5T680-600Zm0 120q-17 0-28.5-11.5T640-520q0-17 11.5-28.5T680-560q17 0 28.5 11.5T720-520q0 17-11.5 28.5T680-480ZM240-160h360v-80H200v40q0 17 11.5 28.5T240-160Zm-40 0v-80 80Z"
                                style="fill: #c44ace;" />
                        </svg>
                        <p class="text-info-small">{{ datosFacturaCreditoDias?.cantidad }} {{ "Facturas" | translate }}
                        </p>
                    </div>
                    <div class="grid-item-row">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"
                            style="color: #c44ace; margin-right: 15px;">
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path
                                d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"
                                style="fill: #c44ace;" />
                        </svg>
                        <p class="text-info-small">{{ datosFacturaCreditoDias?.proveedores }} {{ "Proveedores" |
                            translate }}</p>
                    </div>
                    <div class="grid-item-row">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"
                            style="margin-right: 15px;">
                            <path
                                d="M560-440q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM280-320q-33 0-56.5-23.5T200-400v-320q0-33 23.5-56.5T280-800h560q33 0 56.5 23.5T920-720v320q0 33-23.5 56.5T840-320H280Zm80-80h400q0-33 23.5-56.5T840-480v-160q-33 0-56.5-23.5T760-720H360q0 33-23.5 56.5T280-640v160q33 0 56.5 23.5T360-400Zm440 240H120q-33 0-56.5-23.5T40-240v-440h80v440h680v80ZM280-400v-320 320Z"
                                style="fill: #c44ace;" />
                        </svg>
                        <p class="text-info-small">$ {{ datosFacturaCreditoDias?.saldo | number:'1.2-2' }}</p>
                    </div>
                </div>
            </div>
            <div class="button-download" style=" position: absolute; bottom: 0; right: 0;">
                <button *ngIf="datosFacturaCreditoDias?.tieneSalida" mat-stroked-button color="primary"
                    style="margin: 5px;" (click)="OnClickRecuperarDatos()">
                    <mat-icon>download</mat-icon>
                </button>
                <button mat-stroked-button color="primary" style="margin: 10px;" (click)="ConsultaFCRED()">{{ "GESTIONAR_COMPROBANTES" | translate }}</button>
            </div>
        </div>
        <div class="grid-item">
            <h2 style="font-family: montserrat-semibold" class="grid-item-header-left">{{ "FacturasAceptadas" | translate }}</h2>
            <div class="grid-item-buttons">
                <button mat-button class="item-button"
                    [class.delineated]="datosFacturaCreditoTacitasExpresas?.resourceKey == 'IndAceptadas'"
                    (click)="onSeleccionExpTac('IndAceptadas')">{{ "TACITAS" | translate }}</button>
                <button mat-button class="item-button"
                    [class.delineated]="datosFacturaCreditoTacitasExpresas?.resourceKey == 'IndAceptExp'"
                    (click)="onSeleccionExpTac('IndAceptExp')">{{ "EXPRESAS" | translate }}</button>
            </div>
            <div class="grid-item-row-space">
                <div class="item-row">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"
                        style="margin-right: 15px;">
                        <path
                            d="M240-80q-50 0-85-35t-35-85v-120h120v-560l60 60 60-60 60 60 60-60 60 60 60-60 60 60 60-60 60 60 60-60v680q0 50-35 85t-85 35H240Zm480-80q17 0 28.5-11.5T760-200v-560H320v440h360v120q0 17 11.5 28.5T720-160ZM360-600v-80h240v80H360Zm0 120v-80h240v80H360Zm320-120q-17 0-28.5-11.5T640-640q0-17 11.5-28.5T680-680q17 0 28.5 11.5T720-640q0 17-11.5 28.5T680-600Zm0 120q-17 0-28.5-11.5T640-520q0-17 11.5-28.5T680-560q17 0 28.5 11.5T720-520q0 17-11.5 28.5T680-480ZM240-160h360v-80H200v40q0 17 11.5 28.5T240-160Zm-40 0v-80 80Z" />
                    </svg>
                    <p class="text-info-small">{{ datosFacturaCreditoTacitasExpresas?.cantidad }} {{ "Facturas" |
                        translate }}</p>
                </div>
                <div class="item-row">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"
                        style="color: #c44ace; margin-right: 15px;">
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path
                            d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z" />
                    </svg>
                    <p class="text-info-small">{{ datosFacturaCreditoTacitasExpresas?.proveedores }} {{ "Proveedores" |
                        translate }}</p>
                </div>
                <div class="item-row">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"
                        style="margin-right: 15px;">
                        <path
                            d="M560-440q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM280-320q-33 0-56.5-23.5T200-400v-320q0-33 23.5-56.5T280-800h560q33 0 56.5 23.5T920-720v320q0 33-23.5 56.5T840-320H280Zm80-80h400q0-33 23.5-56.5T840-480v-160q-33 0-56.5-23.5T760-720H360q0 33-23.5 56.5T280-640v160q33 0 56.5 23.5T360-400Zm440 240H120q-33 0-56.5-23.5T40-240v-440h80v440h680v80ZM280-400v-320 320Z" />
                    </svg>
                    <p class="text-info-small">$ {{ datosFacturaCreditoTacitasExpresas?.saldo | number:'1.2-2' }}</p>
                </div>
            </div>

            <mat-divider></mat-divider>

            <div class="grid-item-buttons">
                <button mat-button [class.delineated]="!esADC()" class="item-button"
                    (click)="onSeleccionSCAADC('IndSCA')">{{ "CIRCUITOSCA" | translate }}
                    <svg matTooltip="{{ 'SCA_MENSAJE_TOOLTIP' | translate}}" style="margin-left: 5px;"
                        xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="24px"
                        fill="#5f6368" style="margin-left: 5px;">
                        <path
                            d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z" />
                    </svg>
                </button>
                <button mat-button [class.delineated]="esADC()" class="item-button"
                    (click)="onSeleccionSCAADC('IndADC')">{{ "CIRCUITOADC" | translate }}
                    <svg matTooltip="{{ 'ADC_MENSAJE_TOOLTIP' | translate}}" style="margin-left: 5px;"
                        xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="24px"
                        fill="#5f6368" style="margin-left: 5px;">
                        <path
                            d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z" />
                    </svg>
                </button>
            </div>
            <div class="grid-item-row-space" *ngFor="let dato of datosFacturaCreditoSCAADC; let last = last">
                <div class="item-row-title" *ngIf="esADC()">
                    <p class="text-info-small" style="margin-left: 10px;">{{ onADCTitulo(dato) }}</p>
                </div>
                <div class="item-row">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"
                        style="margin-right: 15px;">
                        <path
                            d="M240-80q-50 0-85-35t-35-85v-120h120v-560l60 60 60-60 60 60 60-60 60 60 60-60 60 60 60-60 60 60 60-60v680q0 50-35 85t-85 35H240Zm480-80q17 0 28.5-11.5T760-200v-560H320v440h360v120q0 17 11.5 28.5T720-160ZM360-600v-80h240v80H360Zm0 120v-80h240v80H360Zm320-120q-17 0-28.5-11.5T640-640q0-17 11.5-28.5T680-680q17 0 28.5 11.5T720-640q0 17-11.5 28.5T680-600Zm0 120q-17 0-28.5-11.5T640-520q0-17 11.5-28.5T680-560q17 0 28.5 11.5T720-520q0 17-11.5 28.5T680-480ZM240-160h360v-80H200v40q0 17 11.5 28.5T240-160Zm-40 0v-80 80Z" />
                    </svg>
                    <div class="text-info-small" style="display: flex; margin-top: 10px;">
                        <p>
                            {{ dato?.cantidad }}
                        </p>
                        <p style="margin-left: 3px;" *ngIf="!esADC()">{{ "Facturas" | translate }}</p>
                    </div>
                </div>
                <div class="item-row">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"
                        style="color: #c44ace; margin-right: 15px;">
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path
                            d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z" />
                    </svg>
                    <div class="text-info-small" style="display: flex; margin-top: 10px;">
                        <p>
                            {{ dato?.proveedores }}
                        </p>
                        <p style="margin-left: 3px;" *ngIf="!esADC()">{{ "Proveedores" | translate }}</p>
                    </div>
                </div>
                <div class="item-row">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"
                        style="margin-right: 15px;">
                        <path
                            d="M560-440q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM280-320q-33 0-56.5-23.5T200-400v-320q0-33 23.5-56.5T280-800h560q33 0 56.5 23.5T920-720v320q0 33-23.5 56.5T840-320H280Zm80-80h400q0-33 23.5-56.5T840-480v-160q-33 0-56.5-23.5T760-720H360q0 33-23.5 56.5T280-640v160q33 0 56.5 23.5T360-400Zm440 240H120q-33 0-56.5-23.5T40-240v-440h80v440h680v80ZM280-400v-320 320Z" />
                    </svg>
                    <p class="text-info-small">$ {{ dato?.saldo | number:'1.2-2' }}</p>
                </div>
                <mat-divider *ngIf="esADC() && !last"></mat-divider>
            </div>
            <div class="button-download">
                <button mat-stroked-button color="primary" style="margin: 10px;" (click)="OnClickRecuperarDatos()"
                    [disabled]="true" matTooltip="Funcionalidad no Implementada">
                    <mat-icon>download</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>