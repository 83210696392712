import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChekPasswordValueService {

constructor() { }

  esTextoAlfa(pass: string): boolean {
    const regex = /([a-zA-Z])/;
    return regex.test(pass)
  }
  tieneMayuscula(pass: string): boolean {
    const regex = /([A-Z])/;
    return regex.test(pass)
  };
  tieneMinuscula(pass: string): boolean {
    const regex = /([a-z])/;
    return regex.test(pass)
  };
  tieneNumero(pass: string): boolean {
    const regex = /([0-9])/;
    return regex.test(pass)
  };
  tieneCaracterEspecial(pass: string): boolean {
    const regex = /.[!,@,#,$,%,\^,&,*,?,¿,_,~,¡,=,),(,\/,",|,.,:,;,\-,',\+,<,>,[,\],{,}]/;
    return regex.test(pass)
  };

  complegidadMedia(pass: string): boolean {
    return this.esTextoAlfa(pass) && this.tieneNumero(pass) && this.tieneMayuscula(pass) && this.tieneMinuscula(pass) && this.tieneCaracterEspecial(pass);
  }

  complejidadAlta(pass: string): boolean {
    return this.complegidadMedia(pass) && pass.length > 9;
  }
}
