import { Component, ElementRef, OnInit, ViewChild, ViewChildren, QueryList, ChangeDetectorRef, NgModule, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { JwtService } from 'src/app/core/services/jwt.service';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, Observable, of, Subscription } from 'rxjs';
import { GenericToasterService } from 'src/app/core/services/generic-toaster.service';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { AsociadorSncyNcService } from './servicios/asociador-sncy-nc.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator, PageEvent  } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { TranslateService } from '@ngx-translate/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { Cliente } from './modelos/Cliente';
import { BusquedaAsociaciones } from './modelos/BusquedaAsociaciones';
import { ErrorOperadorSaldoStateMatcher } from './modelos/ErrorOperadorSaldoStateMatcher';
import { ErrorTipoDocNroDocumentoStateMatcher } from './modelos/ErrorTipoDocNroDocumentoStateMatcher';
import { DocumentoAsociado } from './modelos/DocumentoAsociado';
import { DialogViewDocumentosAsociadosComponent } from './dialog-view-documentos-asociados/dialog-view-documentos-asociados.component';
import { DatosSobre } from './modelos/DatosSobre';
import { registerLocaleData } from '@angular/common';
import localeES from '@angular/common/locales/es';
import { DateAdapter } from '@angular/material/core';
import { AuthService } from 'src/app/core/services/auth.service';

registerLocaleData(localeES, 'es');

export interface Conciliaciones {
  headerCheckbox: boolean;
  IdDocumentoSobre: number;
  IdPaisDocumentoSobre: number;
  Cuit: string;
  Interlocutor: string;
  NroConciliacion: string;
  Saldo: string;
  EstadoConciliacion: string;
  documentos?: Documento[] | MatTableDataSource<Documento>;
}

export interface Documento {
  TipoDocumento: string;
  Motivo: string;
  NumeroDocumento: string;
  Fecha: string;
  Importe: string;
  Visualizador: string;
}

@Component({
  selector: 'app-asociador-sncy-nc',
  templateUrl: './asociador-sncy-nc.component.html',
  styleUrls: ['./asociador-sncy-nc.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class AsociadorSncyNcComponent implements OnInit {
  buscando: boolean = false;

  parametrosDeBusqueda: FormGroup = this.fb.group({
    fechaDesde: [new Date()],
    fechaHasta: [new Date()],
    idCliente: 0,
    idPaisCliente: 0,
    interlocutores: [new Array()],
    estadoConciliacion: ['todos'],
    motivoSolicitud: ['todos'],
    operador: ['todos'],
    saldo: [''],
    tipoDocumento: ['todos'],
    numeroDocumento: [''],
  });

  checkboxGral = new FormControl();
  checkboxGral1 = new FormControl();
  matcherOperadorSaldo = new ErrorOperadorSaldoStateMatcher();
  matcherTipoDocNroDocumento = new ErrorTipoDocNroDocumentoStateMatcher();
  noExistenSeleccionados: boolean = true;

  empresaActual: any;
  interlocutoresFiltrados: Observable<Cliente[]>;
  estadosConciliacion: any;
  motivosSolicitud: any;
  tiposDocumentosSncNc: any;

  isDownLoading = false;
  totalRows = 0;
  pageSize = 30;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 30, 50, 100];

  @ViewChild('outerSort', { static: true }) sort: MatSort;
  @ViewChildren('innerSort') innerSort: QueryList<MatSort>;
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<Documento>>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selection = new SelectionModel<any>(true, []);

  dataSource: MatTableDataSource<Conciliaciones>;
  columnsToDisplay = ['headerCheckbox', 'interlocutor', 'nroConciliacion', 'saldo', 'estadoConciliacion', 'flechita'];
  innerDisplayedColumns = ['tipoDocumento', 'motivo', 'numeroDocumento', 'fecha', 'importe', 'documentoAsociado'];
  expandedElement: Conciliaciones | null;
  Grupos: Conciliaciones[] = [];

  toggleRow(element: Conciliaciones) {
    element.documentos && (element.documentos as MatTableDataSource<Documento>).data.length ? (this.expandedElement = this.expandedElement === element ? null : element) : null;
    this.cd.detectChanges();
    this.innerTables.forEach((table, index) => (table.dataSource as MatTableDataSource<Documento>).sort = this.innerSort.toArray()[index]);
  }

  applyFilter(filterValue: string) {
    this.innerTables.forEach((table, index) => (table.dataSource as MatTableDataSource<Documento>).filter = filterValue.trim().toLowerCase());
  }

  private subscriptions = new Subscription()
  constructor(
    private fb: FormBuilder,
    public servicioAsociador: AsociadorSncyNcService,
    public jwtService: JwtService,
    public genericToasterService: GenericToasterService,
    public translate: TranslateService,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private authService: AuthService,
    private _adapter: DateAdapter<any>,
    )
    { }


  ngOnInit(): void {
    this._adapter.setLocale(this.authService.getUserData()?.Cultura || 'es-AR');
    const fechaSemanaAnterior = new Date(new Date());
    // tomamos una semana para atras por defecto
    fechaSemanaAnterior.setDate(fechaSemanaAnterior.getDate() - 7);

    this.setEmpresa();

    const requests: any = [
      this.servicioAsociador.getEstadosConciliacion()
        .pipe(catchError(error => of(this.genericToasterService
        .pushError("ERROR_APLICACION")))),

      this.servicioAsociador.getMotivosSolicitud(this.empresaActual)
        .pipe(catchError(error => of(this.genericToasterService
        .pushError("ERROR_APLICACION")))),

      this.servicioAsociador.getTiposDocumentosSncNc()
        .pipe(catchError(error => of(this.genericToasterService
          .pushError("ERROR_APLICACION")))),

    ];

    this.servicioAsociador.getInterlocutores(this.empresaActual).then(x => {
      this.interlocutores = x;
      this.interlocutoresOptions.next(x);
      });

    this.subscriptions.add(
      this.filteredInterlocutores.subscribe(x => this.interlocutoresOptions.next(x)));

    const joined$ = forkJoin(requests);

    joined$.subscribe((respuestas: any) => {
      this.estadosConciliacion = respuestas[0];
      this.motivosSolicitud = respuestas[1];
      this.tiposDocumentosSncNc= respuestas[2];
    });
    this.parametrosDeBusqueda.get("fechaDesde")!.setValue(fechaSemanaAnterior);

    //Recupero la busqueda si ya se había realizado antes
    let busquedaGuardada = sessionStorage.getItem('filtroBusqueda');
    if((busquedaGuardada != '') && (busquedaGuardada != null)){
       let busquedaJSON = JSON.parse(busquedaGuardada);
       this.parametrosDeBusqueda.setValue(busquedaJSON);
    }

    this.buscarGruposConciliacion();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  binarySelectOptions: string[] = ["TODOS", "<=", ">="]
  registrosConciliacion: Conciliaciones;

  validarErrorOperadorYSaldo()
  {
    if (this.parametrosDeBusqueda!.get("saldo")!.value != null && this.parametrosDeBusqueda!.get("saldo")!.value != '')
    {
      if (this.parametrosDeBusqueda!.get("operador")!.value == 'todos' || this.parametrosDeBusqueda!.get("operador")!.value == 'TODOS')
      {
        return true;
      }
      else
      {
        return false;
      }
    }
    else
    {
      if (this.parametrosDeBusqueda!.get("operador")!.value != 'todos' && this.parametrosDeBusqueda!.get("operador")!.value != 'TODOS')
      {
        return true;
      }
      else
      {
        return false;
      }
    }
  }

  validarErrorTipoYNumeroDocumento()
  {
    if (this.parametrosDeBusqueda!.get("numeroDocumento")!.value != null && this.parametrosDeBusqueda!.get("numeroDocumento")!.value != '')
    {
      this.deshabilitarControles()
      if (this.parametrosDeBusqueda!.get("tipoDocumento")!.value == 'todos' || (this.parametrosDeBusqueda!.get("tipoDocumento")!.value).resourceKey == 'todos')
      {
        return true;
      }
      else
      {
        return false;
      }
    }
    else
    {
      this.habilitarControles();
      if (this.parametrosDeBusqueda!.get("tipoDocumento")!.value != 'todos' && (this.parametrosDeBusqueda!.get("tipoDocumento")!.value).resourceKey != 'todos')
      {
        return true;
      }
      else
      {
        return false;
      }
    }
  }

  setEmpresa() {
    this.empresaActual = this.jwtService.getAccessToken()?.userdata?.Cliente ?? null;
  }

  mapeadorBusqueda(busqueda: any): BusquedaAsociaciones
  {
    if (busqueda.interlocutores == '' || busqueda.interlocutores.length == 0)
    {
      busqueda.interlocutores = null;
    }

    if (busqueda.estadoConciliacion == 'todos' || busqueda.estadoConciliacion.resourceKey == 'TODOS')
    {
       busqueda.estadoConciliacion = null;
    }

    if (busqueda.motivoSolicitud == 'todos' || busqueda.motivoSolicitud.resourceKey == 'todos')
    {
       busqueda.motivoSolicitud= null;
    }

    if (busqueda.tipoDocumento == 'todos' || busqueda.tipoDocumento.resourceKey == 'todos')
    {
       busqueda.tipoDocumento= null;
    }

    if ( busqueda.numeroDocumento != null && busqueda.numeroDocumento != '')
    {
       busqueda.numeroDocumento = busqueda.numeroDocumento.trim();
    }

    if (busqueda.operador == 'todos' || busqueda.operador == 'TODOS')
    {
      busqueda.operador = "";
    }

    if (busqueda.saldo == null || busqueda.saldo == '')
    {
      busqueda.saldo = "0";
    }

    busqueda.idCliente = this.empresaActual.Id;
    busqueda.idPaisCliente= this.empresaActual.IdPais;
    if (this.currentPage == 0)
    {
      busqueda.nroPagina = 1;
    }
    else
    {
      if (busqueda.numeroDocumento != null && busqueda.numeroDocumento != '') {
        this.currentPage = 0;
        busqueda.nroPagina = 1;
      }
      else {
        busqueda.nroPagina = this.currentPage + 1;
      }
    }
    busqueda.cantPorPagina = this.pageSize;

    return busqueda;
  }

  buscarGruposConciliacionDesdeBoton() {
    this.currentPage = 0;
    this.buscarGruposConciliacion();
  }

  buscarGruposConciliacion() {
    const isValidTipoyNumero: boolean = this.matcherTipoDocNroDocumento.valid;
    const isValidOperacionYsaldo: boolean = this.matcherOperadorSaldo.valid;
    this.checkboxGral.setValue(false);
    this.clearAll();

    if (isValidTipoyNumero && isValidOperacionYsaldo) {
      this.buscando = true;
      let busqueda = this.mapeadorBusqueda(this.parametrosDeBusqueda.getRawValue());

      this.servicioAsociador.postBuscarAsociacionesSncNc(busqueda).subscribe((
      resultado: any) =>
      {
        this.buscando = false;
        this.Grupos = resultado.registros;
        this.totalRows = resultado.cantidadRegistros;

        if (resultado.registros.length == 0) {
          this.dataSource = new MatTableDataSource();
          this.mostrarMensaje();
        }
        else {
          let registrosConciliacion: Conciliaciones[] = [];
          this.Grupos.forEach(asociacion => {
            if (asociacion.documentos && Array.isArray(asociacion.documentos) && asociacion.documentos.length) {
              registrosConciliacion = [...registrosConciliacion, {...asociacion, documentos: new MatTableDataSource(asociacion.documentos)}];
            } else {
              registrosConciliacion = [...registrosConciliacion, asociacion];
            }
          });
          this.dataSource = new MatTableDataSource(registrosConciliacion);
          setTimeout(() => {
            this.paginator.pageIndex = this.currentPage;
            this.paginator.length = this.totalRows;
          });

          ////guardar busqueda
          sessionStorage.setItem('filtroBusqueda', JSON.stringify(this.parametrosDeBusqueda.value));
        }
      });
    }
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.buscarGruposConciliacion();
  }

  limpiar() {
    const fechaSemanaAnterior = new Date(new Date());
    // tomamos una semana para atras por defecto
    fechaSemanaAnterior.setDate(fechaSemanaAnterior.getDate() - 7);

    this.parametrosDeBusqueda.reset();
    this.parametrosDeBusqueda.get("fechaDesde")!.setValue(fechaSemanaAnterior);
    this.parametrosDeBusqueda.get("fechaHasta")!.setValue(new Date());
    this.parametrosDeBusqueda.get("interlocutores")!.setValue(new Array());
    this.parametrosDeBusqueda.get("estadoConciliacion")!.setValue(['todos']);
    this.parametrosDeBusqueda.get("motivoSolicitud")!.setValue(['todos']);
    this.parametrosDeBusqueda.get("tipoDocumento")!.setValue(['todos']);
    this.parametrosDeBusqueda.get("operador")!.setValue(['todos']);
    this.interlocutoresOptions.next(this.interlocutores);
    sessionStorage.removeItem('filtroBusqueda');
    this.buscarGruposConciliacion();
  }

  //Interlocutor
  interlocutores: any[] = [];
  interlocutorControl = new FormControl();
  @ViewChild('interlocutoresInput') interlocutoresInput: ElementRef<HTMLInputElement>;
  @ViewChild('interlocutorPanel') interlocutorPanel: MatAutocomplete;
  interlocutoresOptions: BehaviorSubject<any[]> = new BehaviorSubject([])
  @ViewChild(MatAutocompleteTrigger) panel: MatAutocompleteTrigger;

  filteredInterlocutores: Observable<any[]> = this.interlocutorControl.valueChanges.pipe(
    map((chip: string | null) => {
      let result: any[];
      if (typeof chip == 'string') {
        let filterValue = chip.toLowerCase();
        result = this.interlocutores.filter(x => x.razonSocial.toLowerCase().includes(filterValue)
        || (x.aliasEmpresa != null && x.aliasEmpresa.toLowerCase().includes(filterValue))
        || x.cuit.toLowerCase().includes(filterValue));
      }
      else {
        result = this.interlocutores.filter(x => !this.parametrosDeBusqueda.value.interlocutores?.includes(x) ?? true);
      }
      return result;
    })
  );

  selectInterlocutor(event: MatAutocompleteSelectedEvent): void {
    this.parametrosDeBusqueda.controls.interlocutores.value.push(event.option.value);
    this.limpiarInputInterlocutor();
  }

  limpiarInputInterlocutor() {
    this.interlocutoresInput.nativeElement.value = '';
    this.interlocutorControl.reset();
  }

  removeInterlocutor(chip: any): void {
    const index = this.parametrosDeBusqueda.controls.interlocutores.value.indexOf(chip);
    if (index >= 0) {
      this.parametrosDeBusqueda.controls.interlocutores.value.splice(index, 1);
    }
  }

  onBlurInterlocutor(): void {
    if (!(this.panel.panelOpen)) {
      this.interlocutorControl.reset();
    }
  }

  onClosePanelInterlocutor(): void {
    this.limpiarInputInterlocutor();
  }

  exportarTodo()
  {
    let busqueda = this.mapeadorBusqueda(this.parametrosDeBusqueda.getRawValue());

    this.servicioAsociador.postExportarTodasLasAsociacionesSncNc(busqueda).subscribe((
      res: any) =>
      {
        if (res.length > 0) {
          const fechaDelDia = new Date(new Date());
          let csvContent =this.generarArchivoCSVTodosPorFiltro(res);
          var hiddenElement = document.createElement('a');
          hiddenElement.href= 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvContent);
          hiddenElement.target= '_blank';
          hiddenElement.download= "GestionSolicNCredito" + "_"
                                + fechaDelDia.getFullYear().toString()
                                + this.devolverNumeroFormateado(fechaDelDia.getMonth() + 1)
                                + this.devolverNumeroFormateado(fechaDelDia.getDate())
                                + this.devolverNumeroFormateado(fechaDelDia.getHours())
                                + this.devolverNumeroFormateado(fechaDelDia.getMinutes()) + '.csv';
          hiddenElement.click();
        }
      });
  }

  exportarSeleccionados() {
    let csvContent =this.generarArchivoCSVSeleccionados();
    const fechaDelDia = new Date(new Date());
    var hiddenElement = document.createElement('a');
    hiddenElement.href= 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvContent);
    hiddenElement.target= '_blank';
    hiddenElement.download= "GestionSolicNCredito" + "_"
                          + fechaDelDia.getFullYear().toString()
                          + this.devolverNumeroFormateado(fechaDelDia.getMonth() + 1)
                          + this.devolverNumeroFormateado(fechaDelDia.getDate())
                          + this.devolverNumeroFormateado(fechaDelDia.getHours())
                          + this.devolverNumeroFormateado(fechaDelDia.getMinutes()) + '.csv';
    hiddenElement.click();
  }

  devolverNumeroFormateado(numero: number) : string {
    let numeroFormateado = "";
    if (("00" + (numero).toString()).length == 3 ) {
      numeroFormateado = ("00" + (numero).toString()).substring(1, 3);
    }
    else {
      numeroFormateado = ("00" + (numero).toString()).substring(2, 4);
    }
    return numeroFormateado;
  }

  generarArchivoCSVTodosPorFiltro(asociaciones: any): string
  {
      let registro: string[] = ['cuit', 'interlocutor', 'nroConciliacion', 'importe', 'saldo', 'estadoConciliacion', 'tipoDocumento', 'motivo', 'numeroDocumento', 'fecha'];

      let rowWithPropertyNames = registro.join(';') + '\n';

      let csvContent = rowWithPropertyNames;
      let rows: string[]= [];

      asociaciones.forEach((asociacion: any) => {
        let values: string[]= [];

        registro.forEach((key)=> {
          let val: any= asociacion[key];

          if(val !== undefined && val !== null){
            if (key == "tipoDocumento"){
              val = new String(this.translate.instant(val));
            }
            else if (key == "estadoConciliacion") {
              val = new String(this.translate.instant(val.toLowerCase()));
            }
            else if (key == "motivo") {
              if (val != "") {
                val = new String(this.translate.instant(val));
              }
              else {
                val = new String(val);
              }
            }
            else {
              val = new String(val);
            }
          }else {
            val= '';
          }
          values.push(val);
        });

        rows.push(values.join(';'));
      });

      csvContent+= rows.join('\n');

      return csvContent;
  }

  generarArchivoCSVSeleccionados(): string
  {
      if(this.selection.selected.length == 0) {
         return '';
      }

      let cabecera: string[] = ['cuit', 'interlocutor', 'nroConciliacion', 'saldo', 'estadoConciliacion']
      let detalle: string[] = ['tipoDocumento', 'motivo', 'numeroDocumento', 'fecha', 'importe']

      let rowWithPropertyNames = ['cuit', 'interlocutor', 'nroConciliacion', 'importe', 'saldo', 'estadoConciliacion', 'tipoDocumento', 'motivo', 'numeroDocumento', 'fecha'].join(';') + '\n';

      let csvContent = rowWithPropertyNames;
      let rows: string[]= [];

      this.selection.selected.sort((a, b) => (a.nroConciliacion > b.nroConciliacion) ? -1 : 1);

      this.selection.selected.forEach((asociacion)=> {
        let valuesCabecera: string[]= [];

        cabecera.forEach((key)=> {
          let val: any= asociacion[key];

          if(val !== undefined && val !== null){
            if (key == "estadoConciliacion") {
              val = new String(this.translate.instant(val.toLowerCase()));
            }
            else {
              val= new String(val);
            }
          }else {
            val= '';
          }

          valuesCabecera.push(val);
        });

        asociacion.documentos._data._value.forEach((documento: any) => {
            let i = 0;
            let valuesRegistro: string[]= [];
            let valuesDetalle: string[]= [];
            let tipoDocumento : any;

            detalle.forEach((key)=> {
              let valDetalle: any= documento[key];

              if(valDetalle !== undefined && valDetalle !== null){
                if (key == "tipoDocumento"){
                  tipoDocumento = valDetalle;
                  valDetalle= new String(this.translate.instant(valDetalle));
                }
                else if (key == "motivo") {
                  if (valDetalle != "") {
                    valDetalle = new String(this.translate.instant(valDetalle));
                  }
                  else {
                    valDetalle = new String(valDetalle);
                  }
                }
                else {
                  valDetalle= new String(valDetalle);
                }
              }else {
                valDetalle = '';
              }
              valuesDetalle.push(valDetalle);
            });

            /// cambio el orden de los campos a pedido del cliente
            let valuesRegistroAuxiliar: string[]= [];
            valuesRegistroAuxiliar[0] = valuesCabecera[0];
            valuesRegistroAuxiliar[1] = valuesCabecera[1];
            valuesRegistroAuxiliar[2] = valuesCabecera[2];
            if (tipoDocumento != 'SOLNCRE') {
              ///negativo a las NC y NCMiPyme
              var numberValue = Number(valuesDetalle[4]) * (-1);
              valuesRegistroAuxiliar[3] = String(numberValue);
            }
            else {
              valuesRegistroAuxiliar[3] = valuesDetalle[4];
            }
            if (valuesCabecera[3] == "") {
              valuesRegistroAuxiliar[4] = "0";
            }
            else {
              valuesRegistroAuxiliar[4] = valuesCabecera[3];
            }
            valuesRegistroAuxiliar[5] = valuesCabecera[4];
            valuesRegistroAuxiliar[6] = valuesDetalle[0];
            valuesRegistroAuxiliar[7] = valuesDetalle[1];
            valuesRegistroAuxiliar[8] = valuesDetalle[2];
            valuesRegistroAuxiliar[9] = valuesDetalle[3];

            valuesRegistro = valuesRegistroAuxiliar;
            rows.push(valuesRegistro.join(';'));
        });
      });

      csvContent+= rows.join('\n');

      return csvContent;
  }

  habilitarControles()
  {
    this.parametrosDeBusqueda.get("fechaDesde")?.enable();
    if (this.parametrosDeBusqueda.get("fechaDesde")?.value == null)
    {
      this.parametrosDeBusqueda.get("fechaDesde")?.setValue(new Date());
    }
    this.parametrosDeBusqueda.get("fechaHasta")?.enable();
    if (this.parametrosDeBusqueda.get("fechaHasta")?.value == null)
    {
      this.parametrosDeBusqueda.get("fechaHasta")?.setValue(new Date());
    }
    this.parametrosDeBusqueda.get("interlocutores")?.enable();
    this.parametrosDeBusqueda.get("estadoConciliacion")?.enable();
    this.parametrosDeBusqueda.get("motivoSolicitud")?.enable();
    this.parametrosDeBusqueda.get("operador")?.enable();
    this.parametrosDeBusqueda.get("saldo")?.enable();
  }

  deshabilitarControles()
  {
    this.parametrosDeBusqueda.get("fechaDesde")?.disable();
    this.parametrosDeBusqueda.get("fechaDesde")?.setValue(null);
    this.parametrosDeBusqueda.get("fechaHasta")?.disable();
    this.parametrosDeBusqueda.get("fechaHasta")?.setValue(null);
    this.parametrosDeBusqueda.get("interlocutores")?.disable();
    this.parametrosDeBusqueda.get("estadoConciliacion")?.setValue('todos');
    this.parametrosDeBusqueda.get("estadoConciliacion")?.disable();
    this.parametrosDeBusqueda.get("motivoSolicitud")?.setValue('todos');
    this.parametrosDeBusqueda.get("motivoSolicitud")?.disable();
    this.parametrosDeBusqueda.get("operador")?.setValue('todos');
    this.parametrosDeBusqueda.get("operador")?.disable();
    this.parametrosDeBusqueda.get("saldo")?.setValue('');
    this.parametrosDeBusqueda.get("saldo")?.disable();
  }

  controlMenuExportacion(evento: any, selection: any, row: any) {
    evento ? selection.toggle(row): null;
    this.isAllSelected()
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected =  this.selection.selected != null ? this.selection.selected.length : 0;
    const numRows = this.dataSource.data.length;

    if (numSelected >= 1) {
      this.noExistenSeleccionados = false;
    }
    else {
      if (numSelected == 0) {
        this.noExistenSeleccionados = true;
      }
    }

    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(evento: MatCheckboxChange) {
    this.isAllSelected() ?
      this.clearAll() :
      this.markAll();
  }

  clearAll() {
    this.selection.clear();
    this.noExistenSeleccionados = true;
  }

  markAll() {
    this.selection.clear();
    this.dataSource.data.forEach(row => this.selection.select(row));
  }

  onClickGetDocAsoc(idSobre: number, idPaisSobre: number)  {
    this.servicioAsociador.getDocumentosAsociados(idSobre, idPaisSobre).subscribe((data: DocumentoAsociado[]) => {
      this.dialog
        .open(DialogViewDocumentosAsociadosComponent, {
          data: {
            documentos: data
          },
          autoFocus: false,
          scrollStrategy: new NoopScrollStrategy()
        })
      }
    );
  }

  mostrarMensaje() {
    this.genericToasterService.pushError("LA_BUSQUEDA_NO_ARROJO_RESULTADOS")
  }

  onClickGetDocumentoPDFGrilla(idSobre: number, idPaisSobre: number, tipoDocumento: string, visualizador: string)  {
    this.isDownLoading = true;
    let datos: DatosSobre = this.mapeadorDatoSobre(idSobre, idPaisSobre, tipoDocumento, visualizador);

    this.servicioAsociador.postObtenerDocumentoPDFGrilla(datos).subscribe((
    documento: any) =>
    {
      if (documento!= null) {
        this.isDownLoading = false;
        var arrrayBuffer = this.base64ToArrayBuffer(documento.contenidoBinario); //data is the base64 encoded string    
        var blob = new Blob([arrrayBuffer], {type: "application/pdf"});
        var linkNew = window.URL.createObjectURL(blob);
        window.open(linkNew, '_blank');
      }
    });
  }

  base64ToArrayBuffer(base64:any) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
  }

  mapeadorDatoSobre(idSobre: number, idPaisSobre: number, tipoDocumento: string, visualizador: string): DatosSobre {
    let datos: DatosSobre = new DatosSobre();

    datos.idDocumentoSobre=idSobre;
    datos.idPaisDocumentoSobre=idPaisSobre;
    datos.tipoDocumento=tipoDocumento;
    datos.visualizador=visualizador;
    datos.formato = 'PDF';

    return datos;
  }
}
