<mat-toolbar class="mat-toolbar-top">
  <a
    href="https://www.planexware.com/"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img height="15" src="assets/planexware-white.svg" alt="Planexware"
  /></a>
</mat-toolbar>
<div id="politicas-privacidad">
  <div class="form-signin">
      <div class="row">
        <div *ngIf="idioma == 'es-AR'">
          <app-pyp-es></app-pyp-es>
        </div>
        <div *ngIf="idioma == 'en-US'">
          <app-pyp-us></app-pyp-us>
        </div>
        <div *ngIf="idioma == 'pt-BR'">
          <app-pyp-br></app-pyp-br>
        </div>
      </div>
  </div>
</div>
