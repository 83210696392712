<div class="container-img">
    <img class="heximage" [src]="src" *ngIf="proveedor.logo.length != 0"/>
    <ngx-avatar *ngIf="proveedor.logo.length == 0"
              name="{{
                proveedor.razonSocial
              }}"
              size="90"
              initialsSize="2"
              cornerRadius="4"  [round]="false"
            ></ngx-avatar>
</div>
