import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/services/auth.service';
import { Contrasena } from '../login/Models/Contrasena';
import { RecuperarContrasena } from './models/RecuperarContrasena';
import { ConfiguracionSeguridadService } from '../configuracion-seguridad/servicios/configuracion-seguridad.service';
import { JwtService } from 'src/app/core/services/jwt.service';
import { ChekPasswordValueService } from 'src/app/core/helpers/chekPasswordValue.service';

@Component({
  selector: 'app-modificar-contrasena',
  templateUrl: './modificar-contrasena.component.html',
  styleUrls: ['./modificar-contrasena.component.scss']
})
export class ModificarContrasenaComponent implements OnInit {

  urlsprtu: string | null = "";
  urlspff: string | null = "";

  formModificarContrasena: FormGroup;
  tooltip_contrasena: string;
  passStrength: number;
  loginOk: boolean = false;

  public showPassword: boolean = false;
  public showPasswordNew: boolean = false;
  public showPasswordRepeat: boolean = false;

  postRenovarContrasena: any;
  public showCheck: boolean = false;
  contrasena: Contrasena;
  password: string;
  flagGuardar = false;
  contrasenaDebePoseer = '';
  minLengh = 8;

  complejidadContrasena: string;
  complejidadDelCliente: number;

  icono = "clear"
  usaSocialLogin = false;

  constructor(private formBuilder: FormBuilder,
              private translate: TranslateService,
              private authService: AuthService,
              private router: Router,
              private toastr: ToastrService,
              private configuracionSeguridadService: ConfiguracionSeguridadService,
              private jwtService: JwtService,
              private chekPasswordValueService: ChekPasswordValueService) { }

  ngOnInit(): void {
    this.createForm();
    this.cargarConfig();

    this.toggleCheckVisibility();
    let html = document.getElementsByTagName("app-root")[0];
    let url = window.location.pathname;

    (url.includes("login") || url.includes("contrasena")) && html.classList.contains("no-login") == false
    ? html.classList.add("login") : html.classList.add("no-login")

    if(url.includes("login") ){
      html.classList.remove("no-login")
      html.classList.add("login")
    }
    this.checkNewPassword();
    this.usaSocialLogin = this.authService.getUserData()?.UsaSocialLogin?? false;
  }

  createForm(){
    this.formModificarContrasena = this.formBuilder.group({
      contrasenaActual: ['', Validators.required],
      contrasenaNueva: ['', Validators.required],
      repeticionContrasena: ['', Validators.required],
    });
  }

  get formContrasenaNueva() {
    return this.formModificarContrasena.get('contrasenaNueva') as FormControl;
  }

  checkNewPassword(){
    this.formContrasenaNueva?.valueChanges.subscribe(data => {
      if(data != undefined || data != null)
      {
        this.validateNewPassword(data);
      }
    })
  }

  validateNewPassword(data: any){
    switch (this.complejidadDelCliente) {
        case  1:
          if(data.length > 7 && this.chekPasswordValueService.tieneMayuscula(data) && this.chekPasswordValueService.tieneMinuscula(data))
          {
            this.icono = "done"
          }else{
            this.icono = "clear"
          }
        break;
        case  2:
          if(data.length > 7 && this.chekPasswordValueService.complegidadMedia(data))
          {
            this.icono = "done"
          }else{
            this.icono = "clear"
          }
        break;
        case  3:
          if(this.chekPasswordValueService.complejidadAlta(data))
          {
            this.icono = "done"
          }else{
            this.icono = "clear"
          }
        break;
      default:
        break;
    }
  }

  cargarConfig(){
    this.configuracionSeguridadService.getConfiguracionSeguridad(this.jwtService.getAccessToken()?.userdata?.Cliente ?? null).subscribe((respuesta: any) => {
      switch (respuesta.complejidadHash.id) {
        case 1:
          this.complejidadContrasena = this.translate.instant('COMPLEJIDAD_CONTRASENA_BAJA');
          this.complejidadDelCliente = 1
          break;
        case 2:
          this.complejidadContrasena = this.translate.instant('COMPLEJIDAD_CONTRASENA_MEDIA');
          this.complejidadDelCliente = 2
          break;
        case 3:
          this.complejidadContrasena = this.translate.instant('COMPLEJIDAD_CONTRASENA_ALTA');
          this.complejidadDelCliente = 3
          break;
        default:
          break;
      }
      this.textoTooltipContrasena(respuesta.complejidadHash.id)
    });
  }

  getPassword(){
    this.flagGuardar = true;
    this.borrarToast();
    let userData = this.authService.getUserData();
    let login = {
      usuario: userData?.Email,
      contrasena: this.formModificarContrasena.get('contrasenaActual')?.value
    }
    var mensaje = this.translate.instant('CONTRASENA_NO_CUMPLE_REQ_SEG')+': '+this.complejidadContrasena;
    if(this.formModificarContrasena.controls.contrasenaActual.value.length === 0 || this.formModificarContrasena.controls.contrasenaNueva.value.length === 0 || this.formModificarContrasena.controls.repeticionContrasena.value.length === 0){
      this.toastr.error(this.translate.instant("POR_FAVOR_COMPLETE_TODOS_LOS_DATOS"), '', {

        positionClass: "toast-top-full-width",
        disableTimeOut: true,
        closeButton: true
      });
    } else if(this.formModificarContrasena.controls.contrasenaActual.value.length < 8 || this.formModificarContrasena.controls.contrasenaNueva.value.length < 8 || this.formModificarContrasena.controls.repeticionContrasena.value.length < 8){
      this.toastr.error(mensaje, '', {

        positionClass: "toast-top-full-width",
        disableTimeOut: true,
        closeButton: true
      });
    }
    else if(this.formModificarContrasena.get('contrasenaNueva')?.value != this.formModificarContrasena.get('repeticionContrasena')?.value){
      this.toastr.error(this.translate.instant("CONTRASENAS_NO_COINCIDEN"), '', {

        positionClass: "toast-top-full-width",
        disableTimeOut: true,
        closeButton: true
      });
    }
    else{
      this.authService.getLogin(login).subscribe((respuesta: any) => {
        this.loginOk = true;
        this.getRecuperoContrasena();
      },
        (err: HttpErrorResponse) => {
          var mensaje = err.error.Message;
          if(mensaje === "USUARIO_CONTRASENA_INCORRECTO"){
            mensaje = "CONTRASENA_ACTUAL_INCORRECTA"
          }else{
            mensaje = "POR_FAVOR_COMPLETE_TODOS_LOS_DATOS"
          }
          this.toastr.error(this.translate.instant(mensaje), '', {

            positionClass: "toast-top-full-width",
            disableTimeOut: true,
            closeButton: true
          });         
        });

    }
  }

  getRecuperoContrasena() {
    let userData = this.authService.getUserData();

    let recuperarContrasena = {
      usuario: userData?.Email,
      rtu: "",
      NoEnvioMail: true
    }

    this.authService.getRecuperarContrasena(recuperarContrasena).subscribe((pass: RecuperarContrasena) => {
      let url = new URL(pass.rtu);
      let urlsp = url.searchParams;
      //Cargo demas datos para renovar contraseña
      this.urlsprtu = urlsp.get("rtu");
      this.urlspff = urlsp.get("ff");
      this.postContrasena();
    },
      (err: HttpErrorResponse) => {
        var mensaje = err.error.Message;
        if (mensaje === "PorFavorVerifiqueLosDatosIngresados") {
          mensaje = "PorFavorVerifiqueLosDatosIngresados"
        }
        this.toastr.error(this.translate.instant(mensaje), '', {

          positionClass: "toast-top-full-width",
          disableTimeOut: true,
          closeButton: true
        });
      });
  }

  postContrasena() {
    let userData = this.authService.getUserData();
    let body = {
      usuario: userData?.Email,
      contrasena: this.formModificarContrasena.get('contrasenaNueva')?.value,
      repeticionContrasena: this.formModificarContrasena.get('repeticionContrasena')?.value,
      rtu: this.urlsprtu,
      ff: userData?.Email,
      complejidadHashDescripcion: "",
      estadoPendiente: true,
      NoEnvioMail: true
    }

    this.authService.postGenerarNuevaContrasena(body).subscribe(data => {
      this.toastr.success(this.translate.instant('NUEVA_CONTRASENA_CREADA'), '', {
        positionClass: "toast-top-full-width",
        disableTimeOut: true,
        closeButton: true
      });
      setTimeout(() => {
        this.router.navigate(['main/home']);
      }, 1000);

    },
      (err) => {
        var msj;
        var mensaje = this.translate.instant('CONTRASENA_NO_CUMPLE_REQ_SEG') + ': ' + this.complejidadContrasena;
        switch (err.error.Message) {
          case "Error with Message='CONTRASENA_NO_CUMPLE_REQ_SEG'":
            msj = mensaje;
            break;
          case "Error with Message='CONTRASENA_UTILIZADA_ANTERIORMENTE'":
            msj = "CONTRASENA_UTILIZADA_ANTERIORMENTE";
            break;
          default:
            msj = "ERROR_APLICACION";
            break;
        }
        this.toastr.error(this.translate.instant(msj), '', {
          positionClass: "toast-top-full-width",
          disableTimeOut: true,
          closeButton: true
        });
      });
  }

  strengthChange(value: number) {
    this.passStrength = value;
  };

  textoTooltipContrasena(complejidad: number) {

    switch (complejidad) {
      case 1:
        this.tooltip_contrasena = this.translate.instant('COMPLEJIDAD_CONTRASENA_BAJA');
        break;
      case 2:
        this.tooltip_contrasena = this.translate.instant('COMPLEJIDAD_CONTRASENA_MEDIA');
        break;
      case 3:
        this.tooltip_contrasena = this.translate.instant('COMPLEJIDAD_CONTRASENA_ALTA');
        break;
      default:
        break;
    }
    this.tooltip_contrasena += " \n\n" + this.translate.instant('CONSEJO_CAMBIO_CONTRASENA');
    this.tooltip_contrasena += " \n\n" + this.translate.instant('NO_UTILIZAR_PALABRAS_CONTRASENA');
    this.tooltip_contrasena += " \n\n" + this.translate.instant('CONSEJO_LA_MEJOR_CONTRASENA');
    this.tooltip_contrasena += " \n\n" + this.translate.instant('CONTRASEÑA_INTRANSFERIBLE');
    this.tooltip_contrasena += " \n\n" + this.translate.instant('NO_REUTILIZAR_CONTRASENAS');
  }


  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  public togglePasswordVisibilityNew(): void {
    this.showPasswordNew = !this.showPasswordNew;
  }

  public togglePasswordVisibilityRepet(): void {
    this.showPasswordRepeat = !this.showPasswordRepeat;
  }

  public toggleCheckVisibility(): void {
    if (this.formModificarContrasena.get('contrasenaNueva')?.value.length >= 8) {
      this.showCheck = true;
    }
  }

  validatePassRepeat() {
    if (this.flagGuardar && this.formModificarContrasena.controls.repeticionContrasena.value === '') { // Primero valido que hayan completado el campo
      return this.translate.instant('POR_FAVOR_COMPLETE_TODOS_LOS_DATOS');
    } else if (this.formModificarContrasena.get('contrasenaNueva')?.value != this.formModificarContrasena.get('repeticionContrasena')?.value) {
      return this.translate.instant('CONTRASENAS_NO_COINCIDEN');
    }
  }

  borrarToast() {
    this.toastr.toasts.forEach(cada => {
      this.toastr.remove(cada.toastId);
    });
  }

}
