
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'joinTranslate',
  pure: false
})

export class JoinTranslatePipe implements PipeTransform {

  constructor( private translate: TranslateService){
    //translate.addLangs(['es-AR','en-US','pt-BR']);
  }

  transform(input: Array<string>): string {
    let result: string[] = input.map(x=> {return this.translate.instant(x)});
    return result.join(', ');
  }
}
