import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DownloadService } from 'src/app/core/services/download.service';

import { Downloads } from 'src/app/core/models/downloads.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-downloads-list',
  templateUrl: './downloads-list.component.html',
  styleUrls: ['./downloads-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DownloadsListComponent implements OnInit {
  loadingId: number | undefined
  constructor(
    public downloadService: DownloadService,
    private router: Router,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private authService: AuthService
  ) {}

  displayedColumns: string[] = ['nombre', 'fechaInicio', 'peso', 'estado','control'];

  ngOnInit(): void {
  }

  downloadFile(id: number, nombre: string) {
    this.loadingId = id;

    this.downloadService.getFile(id).subscribe((data: ArrayBuffer) => {
      this.loadingId = undefined
      const file = new File([data], nombre, {
        type: 'application/zip',
      });
      let blobURL = window.URL.createObjectURL(file);
      //TODO: Revisar
     // if (window.navigator.msSaveOrOpenBlob) {
        // IE needs special hand-holding....
       // window.navigator.msSaveOrOpenBlob(file, 'EXPLORER.zip');
      //} else {
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute('style', 'display:none');
        a.setAttribute('href', blobURL);
        a.setAttribute('download', nombre);
        a.click();
        URL.revokeObjectURL(a.href);
        a.remove();
        //window.open(blobURL);
      //}
      var index = this.downloadService.downloadsList.findIndex(cada => cada.id === id);
      this.downloadService.downloadsList[index].inicioDescarga = true;
      this.downloadService.downloadsList[index].generado = true;
    },
    (err) => {
      this.downloadFailed(err)
    });
  }

  downloadFailed(err: HttpErrorResponse) {

    this.toastr.error(this.translateService.instant('ERROR_APLICACION'), '', {

      positionClass: "toast-top-full-width",
      disableTimeOut: true,
      closeButton: true

    });
    this.loadingId = undefined
    ////console.log(err.message)
  }
  sortData(sort: Sort) {
    const data = this.downloadService.downloadsList.slice();
    if (!sort.active || sort.direction === '') {
      this.downloadService.downloadsList = data;
      return;
    }

    this.downloadService.downloadsList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'nombre':
          return compare(a.nombre, b.nombre, isAsc);
        case 'fechaInicio':
          return compare(a.fechaInicio, b.fechaInicio, isAsc);
        case 'peso':
          return compare(a.peso, b.peso, isAsc);
        case 'estado':
          return compare(this.estadoANumero(a), this.estadoANumero(b), isAsc);

        default:
          return 0;
      }
    });
  }

  estadoANumero(row: Downloads): number{
    if(row.generado === false)
    return 10;
    if(row.inicioDescarga === true)
    return 5;
    return 0;
  }

  agregarTimeZone(): string {
    return this.authService.getTimeZone();
  }


}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

