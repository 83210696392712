<div style="height: 100%; width: 100%;">
<div style="text-align: end; margin-bottom: 5px;">
  <label class="m-2" mat-raised-button mat-dialog-close class="close-button" (click)="closeDialog()">
    X
  </label>
</div>
<div *ngIf="loading">
  <mat-spinner class="spinner"></mat-spinner>
</div>
<iframe #iframe *ngIf="url != null" [src]="url" style="width: 100%; border: none; height: 95%;"> 
</iframe>
</div>