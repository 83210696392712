import { BehaviorSubject, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Connections, ConnectionDocument, DocumentPermissions, RejectResponse } from '../models/connections.interface';
import { ModalService } from './modal.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LogoService } from './logo.service';



@Injectable({
  providedIn: 'root',
})
export class ConnectionsService {
  connectionsMock: Array<Connections> = []
  loadingNotifications = false
  // currentDocument = documentPermissions;
  notificationsCount: number = 0
  connectionsMock$: BehaviorSubject<Array<Connections>> = new BehaviorSubject(this.connectionsMock);
  notificationsCount$: BehaviorSubject<number> = new BehaviorSubject(0);

  myBoolean = false

  editingConnection: Connections;
  editingConnectionDocs: Array<ConnectionDocument | undefined>
  editingDoc: ConnectionDocument | undefined
  connectionPermissions: Array<any> = [];

  isLoading: boolean = false;
  totalPages: number | undefined = 0;
  interval = 300000 // 5 min
  currentPage: number = 1;
  connectionIndex: number = 0;
  documentIndex: number = 0;

  guestEmail: string = 'none'

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private modalService: ModalService,
    private toastr: ToastrService,
    private logoService: LogoService,
    private translateService: TranslateService,
    ) {
    this.getGuestUser()
    timer(1000, this.interval).subscribe(() => {
      this.getNotificationsAmount()
    })
  }

  //Agregar Get al back para obtener el total de notificaciones con sus detalles.
  //this.getMensajesAmount
  //Agregar variable BehaviorSubject para mensajes obtenidos y pasarlos al componente

  getNotificationsAmount() {
    if(!this.authService.hasRole("/panelnotificaciones")) return;
    setTimeout(() => {
      this.http.get<number>(`${environment.apiUrl}/PanelNotificaciones/Cantidad`)
      .subscribe(
        (res) => {
          this.notificationsCount = res
          this.notificationsCount$.next(res)
        },
        (err) => {
          // TODO SACAR CUANDO SE VALIDE DESDE EL BACK EL TOKEN
          if(this.authService.isAuthenticated()){
            this.toastr.error(this.translateService.instant('ERROR_APLICACION'))
          }
        }
      )
    }, 1000);
  }

  getGuestUser() {
    if(!this.authService.hasRole("/conexiones")) return;
    setTimeout(() => {
      let userData = this.authService.getUserData()
      let userObj = {
        id: userData!.Cliente.Id,
        idPais: userData!.Cliente.IdPais
      }
      this.http
          .post(
            `${environment.apiUrl}/PanelNotificaciones/RecuperarUltimaInvitacion`,
            userObj
          )
          .subscribe((res: any) => {
            if (res) {
              this.guestEmail = res.invitado.email
            }
          });
    },1000);
  }

  getConnections() {
    this.http.get<Array<Connections>>(`${environment.apiUrl}/PanelNotificaciones/`)
      .subscribe(
        (res) => {
          this.loadingNotifications = false
          res.forEach(element => {
            element.intercambiosDeDocumento.forEach(documento => {
              switch (documento.sentido) {
                case 'ENVIAR':
                  documento.sentido = 'RECIBIR'
                  documento.notificacion = this.translateService.instant('RECIBIR')
                  break;
                case 'RECIBIR':
                  documento.sentido = 'ENVIARLE'
                  documento.notificacion = this.translateService.instant('ENVIARLE')
                  break;
                default:
                  break;
              }
            })

          })
          this.connectionsMock = res
          this.connectionsMock.forEach(element => {
            this.cargarImagen(element)
          });
          this.connectionsMock$.next(res)

        },
        (err) => {
          this.loadingNotifications = false
          // TODO SACAR CUANDO SE VALIDE DESDE EL BACK EL TOKEN
          if(this.authService.isAuthenticated()) {
            this.toastr.error(this.translateService.instant('ERROR_APLICACION'))
          }

        }
      )
  }

  cargarImagen(element: any) {
    this.logoService.getLogoWeb(element.interlocutor.id, element.interlocutor.idPais).subscribe(byteArray => {
      if (byteArray.byteLength) {
        element.interlocutor.logo = 'data:image/png;base64,' +  btoa(new Uint8Array(byteArray).reduce(function (data, byte) {
          return data + String.fromCharCode(byte);
        }, ''));          
      } else {
        //Verificar
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        canvas.width = 80;
        canvas.height = 80;
        ctx!.fillStyle = 'rgb(26, 188, 156)';
        ctx!.fillRect(0, 0, 80, 80);
        ctx!.font = '25px / 60px Helvetica, Arial, sans-serif';
        ctx!.fillStyle = 'white';
        ctx!.textAlign = 'center';
        ctx!.textBaseline = 'middle';
        ctx!.fillText(element.interlocutor.razonSocial[0], 40, 40);
        element.interlocutor.logo = canvas.toDataURL();
      }
    });
  }

  rejectConnection(id: number, razonSocial: string) {
    this.connectionIndex = this.connectionsMock.findIndex(
      (e) => e.interlocutor.id === id
    )
    let connectionObj = this.connectionsMock[this.connectionIndex]
    this.http
        .post(
          `${environment.apiUrl}/PanelNotificaciones/rechazar`,
          connectionObj
        )
        .subscribe((res: RejectResponse) => {
          if (res.isSuccess) {
            this.toastr.error(this.translateService.instant('SE_RECHAZO_LA_CONEXION_CON')+razonSocial);
          } else {
            this.toastr.error(this.translateService.instant('ERROR_APLICACION'))
          }
          this.getNotificationsAmount()

        });

        this.connectionsMock.splice(this.connectionIndex, 1)
  }

  confirmConnection(id: number, razonSocial: string) {
    this.resetPages()
    this.isLoading = true;
    this.connectionIndex = this.connectionsMock.findIndex(
      (e) => e.interlocutor.id === id
    )
    this.editingConnection = this.connectionsMock[this.connectionIndex]
    this.editingConnectionDocs = this.connectionsMock[this.connectionIndex].intercambiosDeDocumento
    this.setDocument()

    this.calculatePages();
    this.getDocumentPermissions();
  }

  calculatePages() {
    this.totalPages = this.editingConnectionDocs!.length;
  }

  getDocumentPermissions() {
    // debugger
    let documentObj = this.editingConnectionDocs![this.documentIndex];
    if (this.connectionPermissions[this.documentIndex] === undefined) {
      this.isLoading = true;
      this.http
        .post(
          `${environment.apiUrl}/panelnotificaciones/RecuperarAccesosAlDocumento`,
          documentObj
        )
        .subscribe((res: DocumentPermissions[]) => {
          this.connectionPermissions[this.documentIndex] = this.sortPermissions(res);
          this.isLoading = false;
        });
    }
  }

  sortPermissions(permissions: DocumentPermissions[]) {
    let forbiddenArr: Array<DocumentPermissions> = [];
    let permittedArr: Array<DocumentPermissions> = [];

    permissions.map((e: DocumentPermissions) => {
      if (e.esSeleccionable) {
        if (e.usuario.email === this.guestEmail) {
          e.recibeNotificaciones = true
        }
        forbiddenArr.push(e)
      } else {
        e.tieneAcceso = true;
        e.recibeNotificaciones = true;
        permittedArr.push(e);
      }
    });
    return { forbidden: forbiddenArr, permitted: permittedArr };
  }

  setDocument() {
    this.editingDoc = this.editingConnectionDocs[this.documentIndex]
  }

  isGuest(email: string) {
    if (email === this.guestEmail) {
      return true
    } else {
      false
    }
  }

  nextDocument() {
    this.currentPage = this.currentPage + 1;
    this.documentIndex = this.documentIndex + 1;
    this.setDocument()
    this.getDocumentPermissions();
  }

  previousDocument() {
    this.currentPage = this.currentPage - 1;
    this.documentIndex = this.documentIndex - 1;
    this.setDocument()
    this.getDocumentPermissions();
  }

  submitPermissions(razonSocial: string) {
    this.connectionPermissions.map((e, index) => {
      let permissions: DocumentPermissions[] = []
      e.forbidden.forEach((element: DocumentPermissions) => {
        permissions.push(element)
      });
      e.permitted.forEach((element: DocumentPermissions) => {
        permissions.push(element)
      });
      this.editingConnectionDocs[index]!.configuracionDeAccesoAlDocumento = permissions
    })

    this.http
        .post(
          `${environment.apiUrl}/PanelNotificaciones/Aceptar`,
          this.connectionsMock[this.connectionIndex]
        )
        .subscribe(
          (res) => {
            this.toastr.success(this.translateService.instant('SE_ACEPTO_LA_CONEXION_CON')+razonSocial);
            this.getNotificationsAmount()
          },
          (err) => {
            this.toastr.error(this.translateService.instant('ERROR_APLICACION'))
          });
    this.connectionsMock.splice(this.connectionIndex, 1)
    this.modalService.closeModal()
    this.resetPages()
  }

  resetPages() {
    this.currentPage = 1;
    this.documentIndex = 0;
    this.connectionPermissions = []
  }
}
