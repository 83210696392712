<mat-toolbar class="mat-toolbar-main-nav">
  <div id="hamburger">
    <input type="checkbox" />
    <span></span>
    <span></span>
    <span></span>
    <ul id="hamburger-menu-open">
      <ng-container *ngFor="let item of navItems">
        <a
          class="main-nav-items"
          [routerLink]="item.url"
          *ngIf="item.habilitado && (item.resourceKey == 'DOCUMENTOS' || item.resourceKey =='BACKOFFICE')"
        >
          <fa-icon *ngIf="item.resourceKey != 'BACKOFFICE'" 
            [icon]="['fas', mainNavIcons[item.resourceKey + '_ICON']]"
          ></fa-icon>
          {{ item.resourceKey | translate }}
        </a>
      </ng-container>
      
      <button mat-raised-button [matMenuTriggerFor]="menu" id="nuevo-documento">
        {{ "NuevoDocumento" | translate }}<mat-icon>arrow_drop_down</mat-icon
        ><span class="nuevodoc"></span>
      </button>
    </ul>
  </div>

  <ng-container *ngFor="let item of navItems">
    <a class="main-nav-items" [routerLink]="item.url" *ngIf="item.habilitado">
      <fa-icon *ngIf="item.resourceKey != 'BACKOFFICE'" 
        [icon]="['fas', mainNavIcons[item.resourceKey + '_ICON']]"
      ></fa-icon>
      {{ item.resourceKey | translate }}
    </a>
  </ng-container>

  <button mat-raised-button [matMenuTriggerFor]="menu" id="boton-nuevo-documento">
    {{ "NuevoDocumento" | translate }}<mat-icon>arrow_drop_down</mat-icon
    ><span class="nuevodoc"></span>
  </button>
  <mat-menu #menu="matMenu" id="menu">
    <ng-container mat-button *ngFor="let item of newDocumentItems">
      <button mat-menu-item [routerLink]="[item.url]">
        {{ item.resourceKey | translate }}
      </button>
    </ng-container>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="onClickSubirArchivo()">
      {{ "SubidaArchivos" | translate }}
    </button>
  </mat-menu>
  <button *ngIf="conciliacionVisible" mat-raised-button id="boton-conciliacion-fact" (click)="onClickConciliacion()">
    {{ "CONCILIACIONFACT" | translate }}
  </button>
</mat-toolbar>
