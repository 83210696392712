<div class="estructuras__header d-flex">
  <h2 class="estructuras__title" style="font-family: 'montserrat-semibold';
font-size: 20px">
    {{ "PREGUNTAS_FRECUENTES" | translate }}
  </h2>
</div>

<!-- <mat-form-field appearance="standard">
  <mat-label>{{ "BUSQUEDA" | translate }}</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
</mat-form-field> -->

<mat-accordion>
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ "FAQ_DONDE_COMUNICO" | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p>{{'FAQ_DONDE_COMUNICO_RTA.DESC' | translate }} <a href="tel:+541177018282">(+5411) 7701 8282</a> {{'FAQ_DONDE_COMUNICO_RTA.TEL' | translate }} <a href="tel:+541108104447599">0810 444 7599</a>, {{'FAQ_DONDE_COMUNICO_RTA.EMAIL' | translate }} <a href="mailto:mesadeayuda@planexware.com">mesadeayuda@planexware.com</a>.</p>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ "FAQ_DONDE_INSTRUCTIVOS" | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p>{{'FAQ_DONDE_INSTRUCTIVOS_RTA' | translate }} <a href="https://ayuda.planexware.net/instructivos/IND_EXT.pdf" target="_instructivos">{{'AQUI' | translate }}</a>.</p>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{'FAQ_CAMBIO_CONTRASENA' | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p>{{'FAQ_CAMBIO_CONTRASENA_RTA' | translate }} </p>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{'FAQ_GENERAR_USUARIOS_COMPANIEROS' | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div [innerHtml]="'FAQ_GENERAR_USUARIOS_COMPANIEROS_RTA' | translate"></div>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{'FAQ_BUSCAR_DOCUMENTO' | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p>{{'FAQ_BUSCAR_DOCUMENTO_RTA' | translate }}</p>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{'FAQ_DOCUMENTOS_AUTOMATICOS' | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p>{{'FAQ_DOCUMENTOS_AUTOMATICOS_RTA' | translate }}</p>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{'FAQ_SIGNIFICADO_COLUMNAS' | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div [innerHtml]="'FAQ_SIGNIFICADO_COLUMNAS_RTA' | translate "></div>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{'FAQ_FLECHA_REGISTRO' | translate}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div [innerHtml]="'FAQ_FLECHA_REGISTRO_RTA' | translate"></div>
    <!-- <div><img src="assets/images/Ayuda/es-ar/flecha-registro.png" alt="flecha-registro.png"> </div> -->
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{'FAQ_ACEPTAR_RECHAZAR_OC' | translate}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div [innerHtml]="'FAQ_ACEPTAR_RECHAZAR_OC_RTA' | translate"></div>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{'FAQ_DOC_ASOCIADOS' | translate}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div [innerHtml]="'FAQ_DOC_ASOCIADOS_RTA' | translate"></div>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{'FAQ_HISTORIAL_DOCUMENTO' | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div [innerHtml]="'FAQ_HISTORIAL_DOCUMENTO_RTA' | translate"></div>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{'FAQ_BUSCAR_OC_SUC' | translate}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div [innerHtml]="'FAQ_BUSCAR_OC_SUC_RTA' | translate"></div>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{'FAQ_EXPORTAR_K360' | translate}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div [innerHtml]="'FAQ_EXPORTAR_K360_RTA' | translate"></div>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{'FAQ_AVISO_EMAIL_NUEVO_DOC' | translate}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div [innerHtml]="'FAQ_AVISO_EMAIL_NUEVO_DOC_RTA' | translate"></div>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{'FAQ_DONDE_DIRECC_ENTREGA_SUPER' | translate}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div [innerHtml]="'FAQ_DONDE_DIRECC_ENTREGA_SUPER_RTA' | translate"></div>
  </mat-expansion-panel>
</mat-accordion>

