import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';
import { icons as menuIcons } from './vertical-menu.icons';

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
})
export class VerticalMenuComponent implements OnInit {
  menuId: string | null;
  menu: any[];
  menuSubject: BehaviorSubject<any[]>
  numero: string = "(+5411) 7701 8277"
  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((param: ParamMap) => {
      this.menuId = param.get('menuId')
      this.getMenu();
    });
  }

  getMenu() {
    this.http
      .get(
        `${environment.apiUrl}/menu/vertical/${this.menuId}`
      )
      .subscribe((res: any[]) => {

        ////console.log("🚀 ~ file: vertical-menu.component.ts ~ line 38 ~ VerticalMenuComponent ~ .subscribe ~ res", res)
        this.menuSubject = new BehaviorSubject<any[]>(res);
        this.menu = this.menuSubject.value;
        sessionStorage.setItem('menu', JSON.stringify(this.menu));
        //TODO:FIX
        this.menu.forEach((item: any) => {
          item.url = "/main/" + item.url
        })

        if(this.authService.getUserData()?.Rol == 'Operador'){
          this.menu = this.menu.filter(item=> !item.disabled);
        }
      });
  }

  getIcon(resourceKey: string) {
    const result = menuIcons[`${resourceKey}_ICON`];
    return result;
  }

  iconClass(icon: string | null): string {
    return icon != "AltaMail" ? 'item__icon' : 'item__icon_empty';
  }

  canAccessToMenuPoint(item: any): boolean {
      return !item.disabled ||
        (item.disabled &&
          item.esEspecifico &&
          this.authService.userdata.ConectadoComo) ||
        (item.disabled &&
          !item.esEspecifico &&
          this.authService.userdata.ConectadoComo) ||
        (item.disabled &&
          !item.esEspecifico &&
          !this.authService.userdata.ConectadoComo)
  }

  itemDisabledAndConectedAsClient(item: any): boolean {
    return item.disabled && !this.authService.userdata.ConectadoComo;
  }

  itemEnabledAndConectedAsClient(item: any): boolean {
    return !item.disabled && !this.authService.userdata.ConectadoComo;
  }

  itemDisabledAndConectedAsSupport(item: any): boolean {
    return item.disabled && this.authService.userdata.ConectadoComo;
  }

  itemEnabledAndConectedAsSupport(item: any): boolean {
    return !item.disabled || this.authService.userdata.ConectadoComo
  }

  enableItemOrBackOffice(item: any): boolean {
    return this.itemEnabledAndConectedAsClient(item) || this.authService.userdata.ConectadoComo;
  }
}

