import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'megabytes'
})
export class MegabytesPipe implements PipeTransform {

  transform(value: number): string {
    let size = value / 1024
    let formattedSize = size.toFixed(2)
    return `${formattedSize} MB`;
  }

}
