import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { JwtInfo } from 'src/app/pages/login/Models/JwtInfo';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-connected-support',
  templateUrl: './connected-support.component.html',
  styleUrls: ['./connected-support.component.scss']
})
export class ConnectedSupportComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document,
                                private authService: AuthService,
                                private translate: TranslateService,) {
  }

  @Input() client: string;
  @Input() pais: string;

  ngOnInit(): void {


  }

  disconnectSupport(): void{
    this.authService.desconectarSoporte()
    .subscribe((respuesta) => {
      let userInfo: JwtInfo = <JwtInfo>(respuesta);

      if (userInfo.jwt != null) {
        sessionStorage.setItem('token', userInfo.jwt);
        this.authService.getFuncionesFromToken();
        let fullURL = `${environment.url}/v2/main/home`;
        this.document.location.href = fullURL;
      }

    },
      (err: HttpErrorResponse) => {
        ////console.log(err)
      });
  }
}
