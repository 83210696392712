import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { GenericToasterService } from './generic-toaster.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationServicesService {

  constructor(
    private http: HttpClient, 
    private genericToasterService: GenericToasterService
  ) { }

  GetServicesConfigurations(): Promise<any> {
    return this.http.get(`${environment.apiUrl}/ConfiguracionServicios/ListarConfiguraciones`)
    .pipe(take(1))
    .pipe(catchError(error => of(this.genericToasterService.pushError("ERROR_APLICACION"))))
    .toPromise();
  }

  GetServicesConfigurationsByClient(clienteId:number,clienteIdPais:number): Promise<any> {
    return this.http.get(`${environment.apiUrl}/ConfiguracionServicios/ListarConfiguracionesPorCliente`,{params:{clienteId: clienteId,clienteIdPais: clienteIdPais}})
    .pipe(take(1))
    .pipe(catchError(error => of(this.genericToasterService.pushError("ERROR_APLICACION"))))
    .toPromise();
  }

  GetDownloadServicesConfigurations(): Promise<any> {
    return this.http.get(`${environment.apiUrl}/ConfiguracionServicios/ListarServiciosDescarga`)
    .pipe(take(1))
    .pipe(catchError(error => of(this.genericToasterService.pushError("ERROR_APLICACION"))))
    .toPromise();
  }

  GetDownloadServicesConfigurationsByDocumentType(tipoDoc:string,servicioDMS:string): Promise<any> {
    return this.http.get(`${environment.apiUrl}/ConfiguracionServicios/ListarServiciosDescargaPorTipoDocumento`,{params:{tipoDocumento: tipoDoc,servicioDMS: servicioDMS}})
    .pipe(take(1))
    .pipe(catchError(error => of(this.genericToasterService.pushError("ERROR_APLICACION"))))
    .toPromise();
  }

  GetConfiguracionesPorCategoria(categoria: string): Promise<any>{
    return new Promise((resolve)=>{
      this.http.get<Array<any>>(`${environment.apiUrl}/ConfiguracionServicios/ListarConfiguracionesPorCategoria`,{params:{categoria: categoria}}).subscribe({
        next: (data:any) => {
          resolve(data);
        },
        error: (err) => {      
          this.genericToasterService.pushError("ERROR_APLICACION");
        }
      });
    });
  }

  GetCantidadLimiteDeAltasPorCategoriaServicio(idCliente:number|null, idPaisCliente:number|null, servicio:string): Promise<any>{
    return new Promise((resolve)=>{
    const requestOptions: Object = { params: {idCliente : idCliente, idPaisCliente: idPaisCliente, servicio:servicio} };
      this.http.get<any>(`${environment.apiUrl}/ConfiguracionServicios/RecuperarCantidadLimiteDeAltasPorCategoriaServicio`,requestOptions).subscribe({
        next: (data:any) => {
          resolve(data);
        },
        error: (err) => {      
          this.genericToasterService.pushError("ERROR_APLICACION");
        }
      });
    });
  }

  ValidarClienteTieneServicioContratado(idCliente:number, idPaisCliente:number, servicio:string): Promise<boolean>{
    return new Promise((resolve)=>{
      this.http.get<boolean>(`${environment.apiUrl}/ConfiguracionServicios/ValidarClienteTieneServicioContratado`,{params:{idCliente: idCliente,idPaisCliente: idPaisCliente, servicio: servicio}}).subscribe({
        next: (data:any) => { resolve(data); },
        error: (err) => { this.genericToasterService.pushError("ERROR_APLICACION"); }
      });
    });
  }
}
