import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BusquedaAsociaciones } from '../modelos/BusquedaAsociaciones';
import { DatosSobre } from '../modelos/DatosSobre';

@Injectable({
  providedIn: 'root'
})
export class AsociadorSncyNcService {

  constructor(public http:HttpClient) { }

  getInterlocutores(cliente: any): Promise<any>
  {
    return this.http.get<any[]>(`${environment.apiUrl}/AsociadorSncYNc/Clientes/${cliente.Id}/Paises/${cliente.IdPais}/ListarInterlocutores`).toPromise();
  }  

  getEstadosConciliacion(): Observable<any>
  {
    return this.http.get(`${environment.apiUrl}/AsociadorSncYNc/ListarEstadosConciliacion`);
  }

  getMotivosSolicitud(cliente: any): Observable<any>
  {
    return this.http.get(`${environment.apiUrl}/AsociadorSncYNc/Clientes/${cliente.Id}/Paises/${cliente.IdPais}/ListarMotivosSolicitud`);
  }

  getTiposDocumentosSncNc(): Observable<any>
  {
    return this.http.get(`${environment.apiUrl}/AsociadorSncYNc/ListarTiposDocumentosSncNc`);
  }

  postBuscarAsociacionesSncNc(searchFilters: BusquedaAsociaciones): Observable<any> 
  {
    return this.http.post(`${environment.apiUrl}/AsociadorSncYNc/BuscarAsociacionesSncNc`, searchFilters);
  }

  postExportarTodasLasAsociacionesSncNc(searchFilters: BusquedaAsociaciones): Observable<any> 
  {
    return this.http.post(`${environment.apiUrl}/AsociadorSncYNc/ExportarTodasLasAsociacionesSncNc`, searchFilters);
  }

  getDocumentosAsociados(idSobre: number, idPaisSobre: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/AsociadorSncYNc/Sobres/${idSobre}/Paises/${idPaisSobre}/RecuperarDocumentosAsociados`);
  }

  postObtenerDocumentoPDFGrilla(datos: DatosSobre): Observable<any> {
    return this.http.post(`${environment.apiUrl}/DocumentosCliente/ObtenerDocumentoPDF`, datos);
  }
}
