<h1 class="plx-t3" translate>Políticas de privacidad</h1>
<p>La presente Política de Privacidad de Datos, tiene por objeto poner en conocimiento de los interesados los alcances de la protección integral de los datos personales asentados en los archivos; registros; bancos o bases de datos; u otros medios técnicos de tratamiento de datos implementados por PLANEXWARE S.A., con domicilio en Alberti 2154 piso 7°, Ciudad Autónoma de Buenos Aires, República Argentina, (en adelante PLANEXWARE), asegurando el respeto al derecho a la intimidad de las personas, así como también al libre acceso a la información que sobre las mismas pueda eventualmente registrarse.</p>
<p>PLANEXWARE considera que cualquier tipo de información relativa a una persona es información de carácter personal, y por consiguiente vela en todo momento por la privacidad y confidencialidad de la misma. La confidencialidad de la información implicará su mantenimiento en archivos y/o bancos o bases de datos seguros, de modo tal que el acceso por parte de terceros que no se encuentren autorizados a tal efecto, se encuentre impedido.</p>
<p>Para preguntas sobre esta Política o cualquier circunstancia relativa al tratamiento de información de carácter personal, los interesados podrán contactarse a través de cualquiera de estos medios:</p>
<p>E-mail:</p>
<p class="cuadroContacto"> <a href="mailto:info@planexware.com">info@planexware.com</a></p>
<p>Correo postal:</p>
<p class="cuadroContacto">
    Alberti 2154 piso 7°<br />
    C1247AAT<br />
    Ciudad Autónoma de Buenos Aires<br />
    República Argentina<br />
</p>
<p>Las notificaciones y comunicaciones cursadas por PLANEXWARE a la dirección postal o, a la casilla de correo electrónico consignada directamente por el interesado, o aquella que surja como domicilio o dirección de correo del remitente se considerarán eficaces y plenamente válidas. Asimismo, se considerarán eficaces las comunicaciones que consistan en avisos y mensajes insertos en el sitio web de PLANEXWARE www.planexware.com, o que se envíen durante la prestación de un servicio, que tengan por finalidad informar a los usuarios sobre determinada circunstancia.</p>
<p>Esta Política de Privacidad refiere a los siguientes aspectos: </p>
<ul>
    <li>Voluntariedad en la entrega de datos</li>
    <li>Datos sensibles</li>
    <li>Datos financieros</li>
    <li>Autorización de uso de la información personal</li>
    <li>Recolección y uso de Información</li>
    <li>Qué información recaba PLANEXWARE</li>
    <li>Transferencia de la información personal</li>
    <li>Divulgación de la información</li>
    <li>Lugar físico en donde tiene lugar el tratamiento de los datos personales del usuario</li>
    <li>Comunicaciones del Sitio</li>
    <li>Derechos de los titulares de los datos</li>
    <li>Utilizamos Métodos de Seguridad Apropiados</li>
    <li>Cumplimiento de la ley 25.326</li>
    <li>Cambios a esta Política</li>
    <li>¿Qué ocurre con su información personal cuando cierra su cuenta con nosotros?</li>
</ul>
<h4>Voluntariedad en la entrega de datos</h4>
<p>La presente Política está destinada a informar a los interesados acerca del tratamiento de datos personales llevado adelante por PLANEXWARE, con el objeto que libre y voluntariamente determinen la entrega o no, de sus datos personales cuando les sean requeridos o que se puedan obtener a partir de la utilización de alguno de los servicios provistos por PLANEXWARE.</p>
<p>Por regla general, cuando para utilizar un servicio o acceder a cierto contenido se solicite algún dato personal, la entrega del mismo no es obligatoria, con excepción de aquellos casos donde específicamente se indicara que es un dato requerido para la prestación del servicio.</p>
<h4>Datos sensibles</h4>
<p>PLANEXWARE no recaba datos sensibles de ninguna naturaleza. Si cualquiera de los datos requeridos fueren en el futuro calificados como dato sensible de tipo personal por la Dirección Nacional de Protección de Datos Personales, u otra autoridad competente, ningún usuario estará obligado a la entrega de ese dato a efectos de acceder a los servicios provistos por PLANEXWARE</p>
<h4>Datos financieros</h4>
<p>PLANEXWARE no recaba datos financieros de ninguna naturaleza.</p>
<h4>Autorización de uso de la información personal</h4>
<p>El cliente o usuario que facilitara sus datos personales, autoriza expresamente a PLANEXWARE para el uso de los datos aportados con los fines aquí expuestos. Implica ello además la aceptación de todos los términos contenidos en esta Política, y en los respectivos contratos de prestación de servicios. </p>
<h4>Recolección y uso de Información </h4>
<p>La recolección y tratamiento de datos de carácter personal de los Usuarios tiene por finalidad la prestación, gestión, administración, personalización, actualización y mejora de los productos y servicios puestos a disposición de sus usuarios por parte de PLANEXWARE  Es posible asimismo que se utilice la información personal de los Usuarios para todos los fines legales, los cuales pueden incluir, pero sin limitarse a, responder sus solicitudes, procesar transacciones, para fines administrativos. </p>
<h4>Qué información recaba PLANEXWARE</h4>
<p>La información recolectada es:</p>
<ol>
    <li>Nombre de usuario y contraseña de acceso.</li>
    <li>También al utilizar los servicios prestados, es posible que PLANEXWARE reciba de parte de los usuarios información no personal como "referencias de navegación", direcciones IP, acciones de usuario y distintas variables del entorno.</li>
</ol>
<br />
<ul>
    <li><strong>Referencias</strong><p>Una "referencia" es información que el explorador Web transmite a los servidores de PLANEXWARE y que hace alusión al URL desde el que accede al Sitio. </p></li>
    <li><strong>Dirección IP.</strong> <p>Al ingresar a PLANEXWARE es posible que se obtenga la dirección IP (Internet Protocol), y el tipo de navegador que utiliza el Usuario. La dirección IP permite además conocer la ubicación geográfica de la computadora o dispositivo de navegación por internet desde el cual se ha accedido a PLANEXWARE, la fecha y hora de acceso. El navegador asimismo puede llegar a permitirnos conocer cuáles son las páginas de internet que el Usuario ha visitado previamente.</p></li>
    <li><strong>Variables de entorno</strong><p>Las "variables del entorno" son, entre otras cosas, la hora de acceso, el tipo de explorador, el sistema operativo o plataforma que se utilizan, la dirección de Internet o del sitio web que le remite a PLANEXWARE </p></li>
    <li><strong>Acciones de usuario</strong><p>Las “acciones de usuario” remiten a la información relativa a la utilización de los recursos ofrecidos por el sitio. Esta información entre otras aspecto podrá referir a los documentos consultados por el usuario así como su cantidad y tipo, los servicios a los cuales acceda el usuario dentro del sitio, a los criterio de búsqueda utilizados, y en general a cualquier otro dato que describa la actividad de los usuarios dentro del sitio. Estos datos podrán almacenarse en forma singular o servir de base para la elaboración de información estadística.</p></li>
    <li><strong>Cookies</strong><p>Eventualmente PLANEXWARE puede utilizar cookies, que se instalaran en la computadora del usuario cuando este navegue por el sitio. Tienen por finalidad facilitar la navegación por el sitio al usuario, y proporcionar a PLANEXWARE, información que le ayudará a mejorar sus servicios y contenidos. En ningún caso las cookies utilizadas por PLANEXWARE proporcionarán información de carácter personal del usuario, quien en relación a las mismas mantendrá pleno anonimato, aun frente a PLANEXWARE, dado que tampoco suministran información tendiente a la individualización del usuario. Es intención de PLANEXWARE poner de resalto que para navegar por el sitio, no resulta necesario que el usuario permita la instalación de las cookies enviadas por PLANEXWARE, ello solo podrá requerirse en relación a ciertos servicios y/o contenidos.</p></li>
</ul>
<p>Esta información se recopila para uso exclusivamente interno de PLANEXWARE, por ejemplo, para incrementar la seguridad del servicio.</p>
<h4>Cesión de los datos</h4>
<p>Todos los datos personales recolectados de los Usuarios, son de uso exclusivamente interno por parte de PLANEXWARE. Toda vez que se recabe información personal como parte de la relación directa con un usuario, en respeto a la privacidad y confidencialidad de los usuarios, PLANEXWARE no cederá ni transferirá esa información personal a ningún tercero que no sea parte de PLANEXWARE, o sus asociados. Únicamente se compartirá con terceros la información personal de los usuarios, en los siguientes casos:</p>
<ul>
    <li>Cuando exista obligación legal de hacerlo. </li>
    <li>Cuando exista una orden emanada de un Juez competente.</li>
</ul>
<h4>Divulgación de la información</h4>
<p>PLANEXWARE puede llegar a compartir cierta información personal, con terceras partes según los términos que se describen a continuación. </p>
<ul>
    <li>
        <strong>Asociados</strong><p>
            Es posible que se comparta información, incluyendo la información personal que proporcione, con terceros asociados, tales como socios comerciales.
            PLANEXWARE se asegura que todos sus socios cumplan con las obligaciones impuestas por la Ley de Protección de Datos Personales, incluyendo la implementación de sistemas de seguridad adecuados, y en idéntico sentido que suscriban y cumplan en cuanto resulte aplicable, los principios sostenidos por PLANEXWARE en materia de protección y confidencialidad de datos personales.
        </p>
    </li>
    <li>
        <strong>Terceros prestadores de servicios de tratamiento de datos</strong><p>
            Para la puesta a disposición de sus servicios, es posible que PLANEXWARE contrate ciertas prestaciones a cargo de terceros. Estas prestaciones podrán consistir, sin que está enunciación resulte taxativa, en tratamiento automatizado de datos, almacenamiento de información, servicios de envío de comunicaciones electrónicas, procesamiento de transacciones. Para la adecuada prestación de los servicios, los terceros a cargo de los mismos es posible que requieran acceso a las bases de datos de PLANEXWARE. Sin embargo PLANEXWARE no cede a estos terceros información de orden personal de ningún tipo, el acceso por parte de los mismos a las bases de datos de PLANEXWARE se limita estrictamente a las actividades necesarias para la prestación del servicio, siéndoles prohibida la extracción de datos o la realización de copias totales o parciales de las bases de datos de PLANEXWARE.
            PLANEXWARE se asegura que todos sus proveedores cumplan con las obligaciones impuestas por la Ley de Protección de Datos Personales, incluyendo la implementación de sistemas de seguridad adecuados, y en idéntico sentido que suscriban y cumplan en cuanto resulte aplicable, los principios sostenidos por PLANEXWARE en materia de protección y confidencialidad de datos personales.
        </p>
    </li>
</ul>
<h4>Lugar físico en donde tiene lugar el tratamiento de los datos personales de los usuarios</h4>
<p>La información recabada en relación con los servicios brindados por PLANEXWARE puede procesarse y almacenarse en el territorio de la República Argentina o fuera de éste. EL USUARIO NO RESIDENTE EN LA REPUBLICA ARGENTINA, ACEPTA EXPRESAMENTE LA EVENTUAL TRANSFERENCIA DE LA INFORMACIÓN PERSONAL A LA REPUBLICA ARGENTINA. ASIMISMO TODOS LOS USUARIOS RESIDENTES O NO, ACEPTAN EXPRESAMENTE QUE SU INFORMACIÓN PERSONAL PUEDA SER CONSERVADA EN MEDIOS UBICADOS FUERA DE LA REPÚBLICA ARGENTINA. Este consentimiento se entenderá otorgado en forma tácita cuando el usuario se hubiere hecho uso de cualquiera de los servicios prestados por PLANEXWARE. En cualquier caso PLANEXWARE garantiza que la información será tratada únicamente en una jurisdicción que asegure una adecuada protección legal a los titulares de los datos.</p>
<h4>Comunicaciones de PLANEXWARE</h4>
<p>Ocasionalmente los datos de los usuarios podrán ser utilizados para el envío de comunicaciones a los propios usuarios, en lo referente a los servicios brindados por PLANEXWARE. Cuando un usuario prefiera no ser contactado con estos fines, podrá: </p>
<ul>
    <li>Comunicarse con PLANEXWARE según los datos de contacto aquí suministrados, y solicitar que no le sea enviada información sobre los productos y servicios.</li>
    <li>Ejecutar de las instrucciones a ese efecto incluidas en los correos enviados por PLANEXWARE</li>
</ul>
<p>Finalmente se enviarán  a los usuarios mensajes relacionados con el estado o cambios relativos a los servicios siempre que ello sea necesario. Así por ejemplo, si un servicio es modificado, agrega nuevas funcionalidades, o actualiza su forma de operar.</p>
<h4>Derechos de los titulares de los datos</h4>
<p>
    Res 10/08, DNPDP,  art 1: "El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo al efecto conforme lo establecido en el artículo 14, inciso 3 de la Ley Nº 25.326"
    A su vez, y como consecuencia de la garantía de los derechos amparados, se expresa también como finalidad, la de permitir en todo momento el acceso a la información por parte de las personas vinculadas a los datos registrados.
    De este modo, el interesado podrá ejercitar los derechos de acceso, rectificación, bloqueo y supresión, que más adelante se mencionarán.
</p>
<p>
    <strong>El derecho de acceso los datos:</strong> es el derecho a solicitar y obtener información de los datos de carácter personal incluidos en las bases de datos.<br />
    <strong>El derecho a exigir la rectificación de los datos:</strong> En principio, el derecho a exigir la rectificación puede ser ejercido ante la falsedad, inexactitud, imprecisión, falta de vigencia, o carácter erróneo que tengan los datos. Su reconocimiento implica el de la preservación de la veracidad de la información, condición que hace a la calidad de la misma.<br />
    <strong>El derecho de bloqueo:</strong> constituye el derecho a exigir el retiro de los datos de carácter personal de una base de datos destinada a publicidad.<br />
    <strong>Los derechos a la supresión:</strong> La "supresión" de un dato implica su eliminación definitiva del archivo o registro, esto es su completa desaparición.<br />
</p>
<p>El ejercicio de dichos derechos podrá ser efectivizado por cada usuario mediante comunicación dirigida a PLANEXWARE, según la información de contacto aquí brindada. La solicitud de acceso, rectificación, actualización o supresión deberá contener y acompañar lo siguiente:</p>
<ul>
    <li>El nombre del titular, dirección de correo electrónico y domicilio para comunicar la respuesta a su solicitud;</li>
    <li>Los documentos que acrediten la identidad o, en su caso, la representación legal del titular;</li>
    <li>La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos antes mencionados, y</li>
    <li>Cualquier otro elemento o documento que facilite la localización de los datos personales.</li>
    <li>En el caso de solicitudes de rectificación de datos personales, el titular deberá indicar, además de lo señalado en los puntos anteriores, las modificaciones a realizarse y aportar la documentación que sustente su petición.</li>
</ul>
<p>Efectuado un requerimiento por el titular de los datos, PLANEXWARE, emitirá una constancia de recepción de la solicitud, y procederá según el caso:</p>
<p>Solicitud de <strong>acceso </strong>a datos personales almacenados: se informarán los datos almacenados en el plazo máximo de 10 días corridos de recibido el reclamo del titular de los datos. </p>
<p>Solicitud de <strong>rectificación</strong>, o <strong>supresión </strong>datos personales almacenados: las operaciones necesarias al fin solicitado será arbitradas dentro de los 5 días hábiles de recibido el reclamo del titular de los datos.</p>
<h4>Utilizamos métodos de seguridad apropiados </h4>
<p>En PLANEXWARE la seguridad es importante. PLANEXWARE adopta todas las medidas de seguridad lógica y física exigidas por las reglamentaciones, y las que resultan de la adecuada prudencia y diligencia en la protección de los Usuarios que han depositado su confianza en PLANEXWARE, para proteger la información personal reunida contra el acceso no autorizado, alteración o destrucción. PLANEXWARE evalúa y mejora sus sistemas de seguridad toda vez que sea necesario.</p>
<h4>Cumplimiento de la ley 25.326 </h4>
<p>
    En cumplimiento de las obligaciones impuestas por la Ley 25.326 de Protección de Datos Personales y normativa complementaria, PLANEXWARE posee su bases de datos con información personal debidamente inscriptas en el registro administrado por la Dirección Nacional de Protección de Datos Personales dependiente del Ministerio de Justicia y Derechos Humanos, Poder Ejecutivo, República Argentina. Los Usuarios que deseen más información relativa al Régimen de Protección de Datos Personales vigente en la República Argentina podrán dirigirse a:<br />
    Dirección Nacional de Protección de Datos Personales.<br />
    Sarmiento 1118, piso 5°, Ciudad Autónoma de Buenos Aires, República Argentina.<br />
    Teléfono: (+5411) 5300-4000.<br />
    http://www.jus.gob.ar/datos-personales/
</p>
<p>La DIRECCION NACIONAL DE PROTECCION DE DATOS PERSONALES, Órgano de Control de la Ley Nº 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales.</p>
<h4>Cambios a esta Política </h4>
<p>En caso de modificación de esta Política, publicaremos esos cambios en esta sección, la pagina principal del Sitio, y otros lugares que sean considerados apropiados para notificar a los interesados sobre los cambios. PLANEXWARE  se reserva el derecho a modificar esta Política en cualquier momento, a cuyo efecto los interesados deberán tomar conocimiento de la misma chequeando esta sección en forma regular.</p>
<h4>¿Qué ocurre con su Información Personal cuando cierra su cuenta de usuario? </h4>
<p>En el caso que un cliente o usuario decida cerrar su cuenta con PLANEXWARE, nuestra Política de Privacidad continuará vigente respecto ese cliente o usuario. Toda su información personal será señalada como no disponible; sin embargo el cliente o usuario debe tener en cuenta que esto no significa la eliminación de su información. La información de los clientes o usuarios seguirá almacenada en los sistemas de PLANEXWARE permaneciendo indisponible, solamente podrá ser usada para resolver cualquier controversia o situación legal que pudiere acontecer, y hasta el cumplimiento del término de la prescripción legal de las obligaciones de naturaleza contractual. La obligación de confidencialidad continuará indefinidamente respecto la información que constituya un dato personal en los términos de la ley 25.326 y en tanto esos datos sean conservados por PLANEXWARE.</p>
<br />
<div class="revision left">
    <span><strong>Código: POL/DES-8-004</strong></span>
    <br />
    <span>Revisión: 002</span>
    <br />
    <span>F.U.R: 08/02/2022</span>
    <br />
</div>
<br />
