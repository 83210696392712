import { Component, OnInit } from '@angular/core'
import { faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { NavigationData, NavigationService } from '../../core/services/navigation.service'
import { AuthService } from '../../core/services/auth.service'
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  faTwitter = faTwitter
  faLinkedIn = faLinkedinIn
  faPhoneAlt = faPhoneAlt
  faEnvelope = faEnvelope

  socialNav: NavigationData['social']

  version: string = ''
  cultura: string = ''
  currentYear = new Date().getFullYear();

  constructor(public navService: NavigationService, public authService: AuthService) {
    this.socialNav = navService.from.social
    this.cultura = this.authService.getUserData()?.Cultura || 'es-AR'
  }

  ngOnInit(): void {
    this.getVersion()


  }

  getVersion() {
    this.navService.getVersion()
      .subscribe((res: string) => {
        this.version = res
      })
  }

}
