import {Injectable} from "@angular/core";

declare let gtag: Function;

@Injectable()
export class GoogleAnalyticsEventsService {

  public emitEvent(eventCategory: string,
                   eventAction: string,
                   eventValue: any = null,
                   eventLabel: any = null) {
    gtag('event', eventCategory, {eventAction,eventLabel,eventValue});
  }
}