import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { StorageService } from 'src/app/core/services/Storage.Service';
import { isNullOrEmptyOrUndefined } from 'src/app/shared/utils/utils.component';
import { Cliente } from 'src/app/core/models/Cliente';
import { environment } from 'src/environments/environment';
import { BusquedaDocumentos } from '../models/BusquedaDocumentos';
import { ClienteRelacionDocumentosCliente } from '../models/ClienteRelacionDocumentosCliente';
import { DatosBusqueda } from '../models/DatosBusqueda';
import { DocData } from '../table-doc/table-doc.component';

export class ParamsAccesosDirectos {
  tiposDocumento: number[];
  unicoDocRK: string;
  cliente: Cliente;
}

@Injectable({
  providedIn: 'root'
})
export class TableDataService {
  
  formDialogFilter: FormGroup = this.fb.group({
    empresaCtrl: [''],
    tiposDocumentoCtrl: [''],
    estadosCtrl: [''],
    rangosFechasGroup: this.fb.group({
      rangosFechasCtrl: [''],
      fechaInicioCtrl: [''],
      fechaFinCtrl: ['']
    }),
    numeroDocumentoCtrl: [''],
    interlocutoresCtrl: [''],
    estructuraCtrl: [''],
    estadoSeguimientoCtrl: [''],
    fechasEmisionGroup: this.fb.group({
      fechaInicioCtrl: [''],
      fechaFinCtrl: ['']
    }),
    fechasVencimientoGroup: this.fb.group({
      fechaInicioCtrl: [''],
      fechaFinCtrl: ['']
    }),
    fechasEntregaGroup: this.fb.group({
      fechaInicioCtrl: [''],
      fechaFinCtrl: ['']
    }),
    importeGroup: this.fb.group({
      importeInicioCtrl: [''],
      importeFinCtrl: ['']
    }),
  });

  private paramsAccesosDirectosSubject: BehaviorSubject<ParamsAccesosDirectos> = new BehaviorSubject({} as ParamsAccesosDirectos);
  public readonly paramsAccesosDirectos: Observable<ParamsAccesosDirectos> = this.paramsAccesosDirectosSubject.asObservable(); 

  private tableDataSourceSubject: BehaviorSubject<MatTableDataSource<DocData>> = new BehaviorSubject({} as MatTableDataSource<DocData>);
  public readonly tableDataSource: Observable<MatTableDataSource<DocData>> = this.tableDataSourceSubject.asObservable();

  private searchFiltersSubject: BehaviorSubject<BusquedaDocumentos | null> = new BehaviorSubject(null);
  public readonly searchFilters: Observable<BusquedaDocumentos | null> = this.searchFiltersSubject.asObservable();

  private formFiltersSubject: BehaviorSubject<FormGroup> = new BehaviorSubject(this.formDialogFilter as FormGroup);
  public readonly formFilters: Observable<FormGroup> = this.formFiltersSubject.asObservable();

  private BusquedaDocumentosSubject: BehaviorSubject<DatosBusqueda | null> = new BehaviorSubject(null);
  public readonly BusquedaDocumentos: Observable<DatosBusqueda | null> = this.BusquedaDocumentosSubject.asObservable();

  private FilterSubject: BehaviorSubject<string> = new BehaviorSubject('');
  public readonly Filter: Observable<string> = this.FilterSubject.asObservable();

  private LoadingDocumentosSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public readonly LoadingDocumentos: Observable<boolean> = this.LoadingDocumentosSubject.asObservable();

  displayedColumnsDefault: string[] = ['select','interlocutor', 'tipoDoc','nDoc','fechaIngreso', 'estado','seguimiento', 'actions'];
  displayedColumnsOrdenCompra: string[] = ['select','interlocutor','tipoDoc','nDoc','fechaIngreso', 'fechaEntrega', 'glnSucursal', 'estado','seguimiento', 'actions'];
  displayedColumnsFactura: string[] = ['select','interlocutor','tipoDoc','nDoc','fechaIngreso', 'fechaEmision', 'fechaVencimiento', 'importe', 'estado','seguimiento', 'actions'];
  displayedColumnsFacturaSoporte: string[] = ['select','interlocutor','tipoDoc','nDoc','fechaIngreso', 'fechaEmision', 'fechaVencimiento', 'estado','seguimiento', 'actions'];
  
  private DisplayColumsSubject: BehaviorSubject<string[]> = new BehaviorSubject(this.displayedColumnsDefault);
  public readonly displayColums: Observable<string[]> = this.DisplayColumsSubject.asObservable();

  listFact : string[] = ["Factura", "NotaCredito", "DEBITO", "FCRE", "NDMiPyME", "NCMiPyME"];
  listOC : string[] = ["OrdenCompra", "ORDERA"]

  public showHolding: boolean = false;

  constructor(
    private fb: FormBuilder,
    private storageService: StorageService,
    private authService: AuthService
  ) { }

  /**
   * Aca se setean los filtros que disparan toda la cadena de eventos para que actuen los demas componentes,
   * tabla de documentos, etc.
   * @param filtros los filtros que va a usar para filtrar los documentos.
   */
  setSearchFilters(filtros: BusquedaDocumentos): void {
    this.resetGrillaHeaders();
    if(this.showHolding){
      this.setEmpresaGrillaHeaders();
    }else{
      this.resetGrillaHeaders();
    }
    this.LoadingDocumentosSubject.next(true);
    if(filtros.tiposDocumentos.every(doc => this.listOC.includes(doc.resourceKey)) && filtros.tiposDocumentos.length>0){
      this.setDisplayColums(this.displayedColumnsOrdenCompra);
      filtros.grupoDeDocumentos = 'Retail';
    }
    else if(filtros.tiposDocumentos.every(doc => this.listFact.includes(doc.resourceKey)) && filtros.tiposDocumentos.length>0){
      if(this.authService.esPlanexwareYConectadoComo()){
        this.setDisplayColums(this.displayedColumnsFacturaSoporte);
      } else {
        this.setDisplayColums(this.displayedColumnsFactura);
      }
      
      filtros.grupoDeDocumentos = 'Def';
    }
    else{
      this.setDisplayColums(this.displayedColumnsDefault);
      filtros.grupoDeDocumentos = 'Generico';
    }
    filtros = this.chequeoFiltrosFecha(filtros);
    filtros = this.chequeoEmpresasDelUsuario(filtros);
   if(!this.authService.esPlanexwareYConectadoComo()){
    sessionStorage.setItem("tagFiltrosV2", JSON.stringify(filtros));
   }
    this.searchFiltersSubject.next(filtros); 
  }

  setTableDataSource(data: MatTableDataSource<DocData>): void {
    this.tableDataSourceSubject.next(data);
  }

  setFormFilterDialog(form: FormGroup): void {
    this.formFiltersSubject.next(form);
  }

  setBusquedaDocumentos(filtros: DatosBusqueda): void {
    this.BusquedaDocumentosSubject.next(filtros);
  }
  
  setDisplayColums(lista : string[]): void {
    this.DisplayColumsSubject.next(lista);
  }

  setInputFilter(filter:string){
    this.FilterSubject.next(filter);
  }

  setLoadingDocumentos(loading:boolean){
    this.LoadingDocumentosSubject.next(loading);
  }

  private chequeoFiltrosFecha(filtros: BusquedaDocumentos) : BusquedaDocumentos {
    filtros.fechaDesde = (filtros.fechaDesde != null && filtros.fechaDesde > moment("0001-01-01T00:00:00+00:00")) ? filtros.fechaDesde : null;
    filtros.fechaHasta = (filtros.fechaHasta != null && filtros.fechaHasta > moment("0001-01-01T00:00:00+00:00")) ? filtros.fechaHasta : null;
    filtros.fechaEmisionDesde = (filtros.fechaEmisionDesde != null && filtros.fechaEmisionDesde > moment("0001-01-01T00:00:00+00:00")) ? filtros.fechaEmisionDesde : null;
    filtros.fechaEmisionHasta = (filtros.fechaEmisionHasta != null && filtros.fechaEmisionHasta > moment("0001-01-01T00:00:00+00:00")) ? filtros.fechaEmisionHasta : null;
    filtros.fechaEntregaDesde = (filtros.fechaEntregaDesde != null && filtros.fechaEntregaDesde > moment("0001-01-01T00:00:00+00:00")) ? filtros.fechaEntregaDesde : null;
    filtros.fechaEntregaHasta = (filtros.fechaEntregaHasta != null && filtros.fechaEntregaHasta > moment("0001-01-01T00:00:00+00:00")) ? filtros.fechaEntregaHasta : null;
    filtros.fechaVencimientoDesde = (filtros.fechaVencimientoDesde != null && filtros.fechaVencimientoDesde > moment("0001-01-01T00:00:00+00:00")) ? filtros.fechaVencimientoDesde : null;
    filtros.fechaVencimientoHasta = (filtros.fechaVencimientoHasta != null && filtros.fechaVencimientoHasta > moment("0001-01-01T00:00:00+00:00")) ? filtros.fechaVencimientoHasta : null;
    return filtros;
  }

  private chequeoEmpresasDelUsuario(filtros: BusquedaDocumentos) : BusquedaDocumentos{
    if(filtros.empresasDelUsuario.length == 0){
      let clientesHolding: any = this.storageService.getClientesHolding();
      let ownerIndex: number = 0;
      let empresas = clientesHolding.clientes.map(
        (client: any, index: number) => {
          if (client.Owner) {
            ownerIndex = index;
          }
          let clienteRelacion: ClienteRelacionDocumentosCliente = new ClienteRelacionDocumentosCliente();
          clienteRelacion.id = client.Id;
          clienteRelacion.idPais = client.IdPais;
          return clienteRelacion;
        }
      );
      let clienteActual = empresas[ownerIndex];
      filtros.empresasDelUsuario = [clienteActual];
    }    
    return filtros;
  }

  private resetGrillaHeaders(){
    this.displayedColumnsDefault = ['select','interlocutor','tipoDoc','nDoc','fechaIngreso', 'estado','seguimiento', 'actions'];
    this.displayedColumnsOrdenCompra = ['select','interlocutor','tipoDoc','nDoc','fechaIngreso', 'fechaEntrega', 'glnSucursal', 'estado','seguimiento', 'actions'];
    this.displayedColumnsFactura = ['select','interlocutor','tipoDoc','nDoc','fechaIngreso', 'fechaEmision', 'fechaVencimiento', 'importe', 'estado','seguimiento', 'actions'];
  }

  private setEmpresaGrillaHeaders(){
    this.displayedColumnsDefault = ['select','empresa','interlocutor','tipoDoc','nDoc','fechaIngreso', 'estado','seguimiento', 'actions'];
    this.displayedColumnsOrdenCompra = ['select','empresa','interlocutor','tipoDoc','nDoc','fechaIngreso', 'fechaEntrega', 'glnSucursal', 'estado','seguimiento', 'actions'];
    this.displayedColumnsFactura = ['select','empresa','interlocutor','tipoDoc','nDoc','fechaIngreso', 'fechaEmision', 'fechaVencimiento', 'importe', 'estado','seguimiento', 'actions'];
  }
  
  setParamsAccesosDirectos(params: ParamsAccesosDirectos): void{
    this.paramsAccesosDirectosSubject.next(params);
  }
  ngOnDestroy(): void {
    this.searchFiltersSubject.next(null);
  }
}