
import { formatCurrency } from '@angular/common';

export const isNullOrEmptyOrUndefined = (value: any): boolean => {
    return value === null || value === undefined || value === '';
};

export const stringToNumber = (value: string): number => {

    if (value != null && typeof value == 'string')
        if (value.includes('.') && value.includes(','))
            value = value.replace(/\./g,"").replace(',', '.');
        else
            value = value.replace(',','.');

    return Number(value);
};

export const numberToString = (value: number): string => {
    let retorno = '';
    if (value != null && typeof value == 'number')
       if (value.toString().includes('.') && value.toString().includes(','))
            retorno = value.toString().replace(/\,/g,"").replace('.', ',');
        else
            retorno = value.toString().replace(',','.');
        
    return retorno;
};

export const formatearInfo = (data: any, simbolo: string): any => {
    if (simbolo == '%')
    {
        if (Array.isArray(data))
        {
            let info = data.map((x:any) => ({ ...x, desc_ok: generarDescripcionPorcentage(x.descripcion) }))
            return (info).sort((a:any,b:any)=> { return a.descripcion - b.descripcion });
        }
             
      return  generarDescripcionPorcentage(data);
     
    }

    if (simbolo == '$')
    {
        return retornaCampoImporte(data);
    }

};

function generarDescripcionPorcentage(x:any) {
    let desc =  x.toString().replace('.', ',');
    let numArr = desc.split(",");
    if (numArr.length > 1) {
  
    if (numArr[1].length === 1) { 
      //20,0 a 20,00
      desc += "0"; 
     }
 
    if (numArr[1].length > 2) {
       //10,052 1, 10,05
           desc = desc.slice(0, 2 + desc.indexOf(',') + 1);
      }
  
   }
   else
   {   //1 a 1,00
       desc += ",00";
   }
   return desc;
}

function retornaCampoImporte(pImporte:any) {
    
    let local = sessionStorage.getItem('lenguage') || 'es-AR';
    let imp = stringToNumber(pImporte);
    let importe = formatCurrency(imp, local, '', '');
   
    return importe;
   
}

export const aDecimalConPuntoSinSepMil = (importe: string | number, decimals: number = 2): string => {
    let value: string = '';
    if (typeof importe == 'number'){
        value = serializarImporte(importe);
    } else {
        value = importe;
    }

    const lang = sessionStorage.getItem('lenguage') || 'es-AR';

    const separador: RegExp = lang == 'en-US' ? /,/g : /\./g;
    const decimal: string = lang == 'en-US' ? '.' : ',';
    value = value.replace(separador, '');
    value = value.replace(decimal, '.');

    if (!isNullOrEmptyOrUndefined(value)) {
        if(value.indexOf('.') === -1) {
            value = value + ".";
        }

        let i: number = value.length - value.indexOf('.');

        for (let index = i; index <= decimals; index++) {
            value = value + "0";
        }
    }

    return value;
}

export const deserializarImporte = (importe: string): number => {
    const lang = sessionStorage.getItem('lenguage') || 'es-AR';

    const caracterMillar: RegExp = lang == 'en-US' ? /,/g : /\./g;
    const caracterDecimal: string = lang == 'en-US' ? '.' : ',';
    importe = importe.replace(caracterMillar, '');
    importe = importe.replace(caracterDecimal, '.');
    return parseFloat(importe);
}

export const serializarImporte = (importe: number, decimales: number = 2, seccionItems?: boolean): string => {
    const lang = sessionStorage.getItem('lenguage') || 'es-AR';
    const formatter = new Intl.NumberFormat(lang, {
      style: 'currency',
      currency: 'ARS',
      minimumFractionDigits: decimales,
      maximumFractionDigits: decimales
    });
    // @ts-ignore: Salta un error por la version desactualizada de TS
    const output: string = formatter.formatToParts(importe).map(({ type, value }) => {
      switch (type) {
        case 'currency': return '';
        case 'literal': return '';
        case 'group': {
            if(seccionItems) return '';
            else return value;
        };
        default: return value;
      }
    }).reduce((string: string, part: string) => string + part);
    return output;
  }

  //#region El método recibe 2 numeros para evaluar una diferencia, devuelve true si la diferencia entre los 2 numeros es igual o mayor a +-diferencia
  export const existeDiferencia = (primero: number, segundo: number, diferencia: number): boolean =>{
      return ((primero - segundo)/diferencia)**2 >= 1
  }
  //#endregion



