import { Injectable, Query } from '@angular/core';
// import { environment } from 'src/environments/environment';
import { ActivatedRouteSnapshot, Router, ActivatedRoute } from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class RedirectHelper {

    private redirectPathQuery: {
        path: string | undefined,
        queryParams: object
    };

    constructor(private router: Router) { }

    /**
     * redirecciona al path y query seteados
     */
    redirect() {
        this.router.navigate(
            [this.redirectPathQuery.path],
            { queryParams: this.redirectPathQuery.queryParams }
        );
    }

    /**
     * Evalua si debe redireccionar basandose en si existe path y query seteados previamente.
     * @returns si debe redireccionar
     */
    tengoQueRedireccionar(): boolean {
        return !!this.redirectPathQuery;
    }

    setRedirectUrlParams(route: ActivatedRouteSnapshot): void {
        let basePath = route.parent?.url.map(url => url.path).join('/');
        let relativePath = route.url.map(url => url.path).join('/');
        this.redirectPathQuery = {
            path: `${basePath}/${relativePath}`,
            queryParams: route.queryParams
        };
    }

}