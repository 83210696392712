import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tyc-es',
  templateUrl: './tyc-es.component.html',
  styleUrls: ['./tyc-es.component.scss']
})
export class TycESComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
