import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { lastValueFrom, map } from 'rxjs';

export class Feature {
  nombre: string;
  activa: boolean;
  descripcion: string;
}

@Injectable({
  providedIn: 'root',
})
export class FeatureToggleService {

  constructor(private http: HttpClient) { }

  /**
   * Setea el estado del feature toggle para poder consultarlo despues.
   */
  public async obtenerFeatureToggles(): Promise<void> {
    const request = this.http.get<Feature[]>(`${environment.apiUrl}/Acceso/RecuperarFeatureToggles`);
    const features = await lastValueFrom(request);
    sessionStorage.setItem("featureToggles", JSON.stringify(features));
    return Promise.resolve();
  }

  /**
   * Se fija si la feature está activa o no.
   * @param featureName el nombre de la feature.
   * @returns true/false.
   */
  public isFeatureEnabled(featureName: string): boolean {
    const features: Feature[] = JSON.parse(sessionStorage.getItem('featureToggles') || '[]');
    const feature = features.find(x => x.nombre == featureName);
    if (feature) {
      return feature.activa;
    }
    return false;
  }

}