// Module imports
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './shared/shared.module';
import { PipesModule } from './core/pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateModule, TranslateLoader, TranslateService} from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { PdfViewerModule } from 'ng2-pdf-viewer';



import {  IPublicClientApplication,  PublicClientApplication,  BrowserCacheLocation,  LogLevel,  InteractionType } from '@azure/msal-browser';
import {  MSAL_INSTANCE,  MSAL_INTERCEPTOR_CONFIG,  MsalInterceptorConfiguration,  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,  MsalBroadcastService,  MsalService,  MsalGuard,  MsalRedirectComponent,
  MsalModule,  MsalInterceptor,
} from '@azure/msal-angular';


// Services
import { ToasterService } from './core/services/toaster.service';

// Component imports
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { AuthInterceptorRequest } from './core/http-interceptors/AuthInterceptorRequest';

// FontAwesome imports
import {
  FaIconLibrary, FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { AuthService } from './core/services/auth.service';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { GenericToasterService } from './core/services/generic-toaster.service';
import { AuthGuardService } from './core/guards/auth-guard.service';
import { RoleGuardService } from './core/guards/role-guard.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorI18n } from './shared/utils/paginador-intl';
import { LoginComponent } from './pages/login/login.component';
import { GoogleAnalyticsEventsService } from './core/services/google-analytics-events.service';
import { StorageService } from './core/services/Storage.Service';
import { MainK360Component } from './pages/main-k360/main-k360.component';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ChartsModule } from 'ng2-charts';
import { FAQComponent } from './pages/faq/faq.component';
import { NovedadesComponent } from './pages/novedades/novedades.component';
import { ModificarContrasenaComponent } from './pages/modificar-contrasena/modificar-contrasena.component';
import { TerminosYCondicionesComponent } from './pages/terminos-ycondiciones/terminos-ycondiciones.component';
import { PoliticasDePrivacidadComponent } from './pages/politicas-de-privacidad/politicas-de-privacidad.component';
import { MensajeCriticoComponent } from './pages/mensaje-critico/mensaje-critico.component';
import { TycESComponent } from './pages/terminos-ycondiciones/regionalizacion/tyc-es/tyc-es.component';
import { TycUSComponent } from './pages/terminos-ycondiciones/regionalizacion/tyc-us/tyc-us.component';
import { TycBRComponent } from './pages/terminos-ycondiciones/regionalizacion/tyc-br/tyc-br.component';
import { PypESComponent } from './pages/politicas-de-privacidad/regionalizacion/pyp-es/pyp-es.component';
import { PypUSComponent } from './pages/politicas-de-privacidad/regionalizacion/pyp-us/pyp-us.component';
import { PypBRComponent } from './pages/politicas-de-privacidad/regionalizacion/pyp-br/pyp-br.component';
import { LinkVencidoComponent } from './pages/link-vencido/link-vencido.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AsociadorSncyNcComponent } from './pages/asociador-sncy-nc/asociador-sncy-nc.component';
import { AppHttpInterceptorRequest } from './core/http-interceptors/AppHttpInterceptorRequest';
import { AppHttpInterceptorResponse } from './core/http-interceptors/AppHttpInterceptorResponse';
import { AccesosDirectosPpComponent } from './pages/home-page/accesos-directos-pp/accesos-directos-pp.component';
import { LogoPpComponent } from './pages/home-page/accesos-directos-pp/logo-pp/logo-pp.component';
import { AvatarModule } from 'ngx-avatar';
import { SeguimientoFacturaCreditoComponent } from './pages/seguimiento-factura-credito/seguimiento-factura-credito.component';
import { environment } from './../environments/environment';
import { PopUpCredencialesComponent } from './pages/login/popup-credenciales/popup-credenciales.component';


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient,"/v2/assets/i18n/",".json");
}


const isIE =  window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;


//Metodo para loguear info despues del callback de la generacion del token
export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message);
}

//La funci�n se utiliza para configurar y crear una instancia de la librer�a MSAL para su uso en una aplicaci�n. 
//La interfaz�IPublicClientApplication representa una aplicaci�n cliente p�blica
//que se puede utilizar para autenticar usuarios y adquirir tokens para recursos protegidos
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      redirectUri: environment.redirectUri,
      knownAuthorities: environment.knownAuthorities,
      postLogoutRedirectUri: environment.redirectUri
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

//#region 

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Popup,
    authRequest: {
      scopes: ['user.read'],
    },
  };
}
//#endregion

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent, HomePageComponent, LoginComponent, MainK360Component, FAQComponent, NovedadesComponent, ModificarContrasenaComponent, TerminosYCondicionesComponent, PoliticasDePrivacidadComponent, MensajeCriticoComponent, LinkVencidoComponent, TycESComponent, TycUSComponent, TycBRComponent, PypESComponent, PypUSComponent, PypBRComponent, AsociadorSncyNcComponent, AccesosDirectosPpComponent, LogoPpComponent, SeguimientoFacturaCreditoComponent, PopUpCredencialesComponent],
  imports: [
    AvatarModule,
    SlickCarouselModule,
    ScrollingModule,
    BrowserModule,
    RouterModule.forRoot([], { relativeLinkResolution: 'legacy' }),
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    NgbModule,
    FontAwesomeModule,
    HttpClientModule,
    SharedModule,
    PipesModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({ preventDuplicates: true }),
    PasswordStrengthMeterModule,
    NgxChartsModule,
    CarouselModule,
    ChartsModule,
    PdfViewerModule
  ],
  providers: [
    CurrencyPipe,
    ToasterService,
    GenericToasterService,
    AuthService,
    AuthGuardService,
    RoleGuardService,
    StorageService,
    GoogleAnalyticsEventsService,
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptorResponse, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorRequest, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptorRequest, multi: true },
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
    //{ provide: HTTP_INTERCEPTORS, useClass: HelperInterceptorRequest, multi: true }, Este sirve por si hay q verificar las conexiones a la api, dejar comentado
    { provide: MatPaginatorIntl, deps:[TranslateService], useFactory:(translateService: TranslateService) => new PaginatorI18n(translateService).getPaginatorIntl() },
    DatePipe,
    CookieService,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIconPacks(fas, far);
  }
}
