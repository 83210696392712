import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { NgbCollapseModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FooterComponent } from './footer/footer.component';
import { PlanexLogoComponent } from './planex-logo/planex-logo.component';
import { ScrollAutohideDirective } from './directives/scroll-autohide.directive';
import { MainNavComponent } from './main-nav/main-nav.component';
import { ModalComponent } from './modal/modal.component';
import { AdminListComponent } from './navbar/shared/admin-list/admin-list.component';
import { DownloadsListComponent } from './navbar/shared/downloads-list/downloads-list.component';
import { PipesModule } from '../core/pipes/pipes.module';
import { NotificationPanelComponent } from './navbar/shared/notification-panel/notification-panel.component';
import { ConnectionsComponent } from './connections/connections.component';
import { SwitchComponent } from './switch/switch.component';
import { VerticalMenuComponent } from './vertical-menu/vertical-menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CampoDecimalComa } from './directives/campo-decimal-coma.directive';
import { CampoNumericoDirective } from './directives/campo-numerico.directive';
import { StylePaginatorDirective } from './directives/stylePaginatorDirective';
import { DocHistorialDirective } from './directives/doc-historial.directive';
import { MaterialModule } from '../material-module';


//  AvatarModule
import { AvatarModule } from 'ngx-avatar';

// i18n modules
import { ToasterComponent } from './toaster/toaster.component';
import { ConnectedSupportComponent } from './connected-support/connected-support.component';
import { ConfirmacionDialogComponent } from './confirmacion-dialog/confirmacion-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { MensajesDocumentosComponent } from './navbar/shared/mensajes-documentos/mensajes-documentos.component';
import { stylePaginatorDirectiveConections } from './directives/stylePaginatorDirectiveConections';
import { DialogConciliacionContactarComponent } from './dialog-conciliacion-contactar/dialog-conciliacion-contactar.component';
import { DialogFakeDoorButtonComponent } from './dialog-fake-door-button/dialog-fake-door-button.component';



@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    PlanexLogoComponent,
    ScrollAutohideDirective,
    MainNavComponent,
    ModalComponent,
    AdminListComponent,
    DownloadsListComponent,
    NotificationPanelComponent,
    ConnectionsComponent,
    SwitchComponent,
    VerticalMenuComponent,
    ToasterComponent,
    CampoDecimalComa,
    CampoNumericoDirective,
    ConnectedSupportComponent,
    ConfirmacionDialogComponent,
    StylePaginatorDirective,
    stylePaginatorDirectiveConections,
    DocHistorialDirective,
    MensajesDocumentosComponent,
    DialogConciliacionContactarComponent,
    DialogFakeDoorButtonComponent
  ],
  imports: [
    FlexLayoutModule,
    CommonModule,
    RouterModule,
    NgbCollapseModule,
    FontAwesomeModule,
    NgbModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    AvatarModule,
    NgxDatatableModule,
    AutocompleteLibModule,
    MaterialModule,
    FlexLayoutModule,
    TranslateModule
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    TranslateModule,
    NgxDatatableModule,
    AutocompleteLibModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    ToasterComponent,
    MainNavComponent,
    CampoDecimalComa,
    CampoNumericoDirective,
    ConnectedSupportComponent,
    MaterialModule,
    PipesModule,
    FlexLayoutModule,
    StylePaginatorDirective,
    stylePaginatorDirectiveConections,
    DocHistorialDirective,
    ModalComponent

  ],
})
export class SharedModule {

  constructor() {

  }
}
