import {
    AfterViewInit,
    Directive,
    Host,
    Optional,
    Renderer2,
    Self,
    ViewContainerRef,
    Input
  } from '@angular/core';
  import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BusquedaConexiones } from 'src/app/pages/conexiones/components/models/BusquedaConexiones';
import { ConexionesService } from 'src/app/pages/conexiones/components/services/conexiones.service';


  
  @Directive({
    selector: '[appStylePaginatorDirectiveConections]',
  })
  export class stylePaginatorDirectiveConections implements AfterViewInit {
    private count: number | null = null;
    page = 1;
    pageSize = 1;
    length = 1;
    @Input() filterObject: BusquedaConexiones;
    constructor(
      @Host() @Self() @Optional() private readonly matPag: MatPaginator,
      private readonly vr: ViewContainerRef,
      private readonly ren: Renderer2,
      private conexionService: ConexionesService,
      private traslate: TranslateService
    ) {}

    private removeCreateContainer():void{
      const pagingContainerMain = this.vr.element.nativeElement.querySelector('.mat-paginator-range-actions');
      if (
        this.vr.element.nativeElement.querySelector('div.mat-paginator-range-actions div.mat-paginator-range-label')
      ) {
        this.ren.removeChild(
          pagingContainerMain,
          this.vr.element.nativeElement.querySelector('div.mat-paginator-range-actions div.mat-paginator-range-label'),
        );
      } else if (
        this.vr.element.nativeElement.querySelector('div.mat-paginator-range-actions div.btn_custom-paging-container')
      ) {
        this.ren.removeChild(
          pagingContainerMain,
          this.vr.element.nativeElement.querySelector('div.mat-paginator-range-actions div.btn_custom-paging-container mat-button'),
        );
      }
        //if ((this.matPag.length - 21) % 1000 != 0) this.count = this.matPag.length;
        const pagingContainerBtns = this.ren.createElement('div');
        const refNode = this.vr.element.nativeElement.childNodes[0].childNodes[0].childNodes[2].childNodes[2];

        this.ren.addClass(pagingContainerBtns, 'btn_custom-paging-container');
        this.ren.insertBefore(pagingContainerMain, pagingContainerBtns, refNode); 
        const pageRange = this.vr.element.nativeElement.querySelector(
          'div.mat-paginator-range-actions div.btn_custom-paging-container',
        );
        pageRange.innerHtml = this.matPag;
        this.matPag._intl.getRangeLabel = (page: number, pageSize: number, length: number) => this.rangeLabelPaginator(page,pageSize,length);
        this.ren.insertBefore(pageRange, this.createButtonCount(), null); 
    }
    
    private createButtonCount(): any {
      const linkBtn = this.ren.createElement('mat-button'); 
      let text = this.ren.createText(this.rangeLabelPaginator(this.matPag.pageIndex,this.matPag.pageSize,this.matPag.length));
      this.ren.addClass(linkBtn, 'mat-custom-page');
      this.ren.appendChild(linkBtn, text);
      return linkBtn;
    }

    private initPageButton(): void {
      this.removeCreateContainer();   
    }


  
    public ngAfterViewInit() {
    }

    public Update(count: number){
      this.count = count;
      this.matPag._intl.getRangeLabel = (page: number, pageSize: number, length: number) => this.rangeLabelPaginator(page,pageSize,length);   
      this.initPageButton();
    }

    rangeLabelPaginator(page: number, pageSize: number, length: number):string {
      this.page = page;
      this.pageSize = pageSize;
      this.length = length;
      const pageStartIndex = page * pageSize + 1;
      const pageEndIndex = Math.min(length, (page + 1) * pageSize);
      const itemsTotal = this.count;
      return `${pageStartIndex} - ${pageEndIndex} ${this.traslate.instant('DE_PAGER')} ${itemsTotal}`;

    };
  }