import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseView } from '../models/BaseView';
import { BusquedaConexiones } from '../models/BusquedaConexiones';
import { ClienteConexion, ClienteDetalle } from '../models/ClienteConexion';
import { Conexion } from '../models/Conexion';
import { ConexionView } from '../models/ConexionView';
import { InvitacionView } from '../models/InvitacionView';
import { SolicitudConexion } from '../models/SolicitudConexion';
import { RelacionIntercambioView } from '../models/RelacionIntercambioView';
import { TipoProveedorView } from '../models/TipoProveedorView';
import { CargaArchivo } from '../models/CargaArchivo';
import { RelacionIntercambio } from '../models/RelacionIntercambio';
import { BusquedaUltimaInvitacionSponsor, ReenvioInvitacion, UltimaInvitacionSponsor } from '../models/UltimaInvitacion';

@Injectable({
  providedIn: 'root'
})
export class ConexionesService {

  constructor(public http: HttpClient) {

  }

  getConexiones( searchFilters: BusquedaConexiones): Observable<any> {
    /* let asd = {CampoOrden: "FechaAlta",
      Count:0,
      CriterioFiltroFecha:"SIN_FILTRO_POR_FECHA",
      FechaDesde:"2023-03-30T12:35:22-03:00",
      FechaHasta:"2023-03-30T12:35:22-03:00",
      PageNumber:1,
      RangoFecha: 0,
      Reverse:true}
      */
     searchFilters.count = false;
    return this.http.post(`${environment.apiUrl}/Conexiones/GetConexiones`, searchFilters);
  }
  getConexionesCount(searchFilters: BusquedaConexiones): Observable<any> {
    if(searchFilters) searchFilters.count = true;
    else 
    return new Observable();
    return this.http.post(`${environment.apiUrl}/Conexiones/GetConexionesCount`, searchFilters);
  }

  getFranquicias(idCliente: number, idPaisCliente: number): Observable<any>{
    return this.http.get(`${environment.apiUrl}/Conexiones/GetClientesFranquiciaSponsor`, {params: {IdCliente : idCliente, IdPaisCliente: idPaisCliente}});
  }

  getUltimaInvitacionAFranquiciaSponsor(idCliente: number, idPaisCliente: number): Observable<any>{
    return this.http.get(`${environment.apiUrl}/Conexiones/GetUltimaInvitacionAFranquiciaSponsor`, {params: {idCliente : idCliente, idPaisCliente: idPaisCliente}});  
  }

  getTemplatesPdf(): Observable<any>{
    return this.http.get(`${environment.apiUrl}/Conexiones/GetTemplatesPdf`);
  }
  getCheckServicioMaestrosClientes(): Observable<any>{
    return this.http.get(`${environment.apiUrl}/Conexiones/GetCheckServicioMaestrosClientes`);
  }
  
  getExportarResultados(): Observable<HttpResponse<Blob>>{
    return this.http.get(`${environment.apiUrl}/Conexiones/GetExportarResultados`, {responseType: 'blob', observe: 'response'});
  }

  getCliente(cliente:BaseView): Observable<any>{
    return this.http.post(`${environment.apiUrl}/Conexiones/GetCliente`, cliente);
  }

  getLogoWebCliente(id: number, idPais:number): Observable<any>{
    return this.http.get(`${environment.apiUrl}/Conexiones/GetLogoWebCliente`,  {params: {IdCliente : id, IdPaisCliente: idPais}});
  }

  getCiudades(idPais: number): Observable<any>{
    return this.http.get(`${environment.apiUrl}/Conexiones/GetCiudades`,  {params: {idPais : idPais}});
  }

  getCheckConfiguracionRecordatoriosVencimientos(cliente: ClienteConexion): Observable<any>{
    return this.http.post(`${environment.apiUrl}/Conexiones/GetCheckConfiguracionRecordatoriosVencimientos`,  cliente);
  }

  getDatosExtra(idRelacion: number, idPaisRelacion:number): Observable<any>{
    return this.http.get(`${environment.apiUrl}/Conexiones/GetDatosExtra`,  {params: {idRelacion : idRelacion, idPaisRelacion: idPaisRelacion}});
  }
  getUsuariosParaAccesoADocumentos(conexion: Conexion): Observable<any>{
    return this.http.post(`${environment.apiUrl}/Conexiones/GetUsuariosParaAccesoADocumentos`,  conexion);
  }

  updateConfiguracionConexion(conexion:ConexionView): Observable<any>{
    return this.http.post(`${environment.apiUrl}/Conexiones/UpdateConfiguracionConexion`,  conexion);
  }

  getEstructuras(cliente:BaseView): Observable<any>{
    return this.http.post(`${environment.apiUrl}/Conexiones/GetEstructuras`,  cliente);
  }
  getUltimaInvitacion(relacion: RelacionIntercambioView): Observable<any>{
    return this.http.post(`${environment.apiUrl}/Conexiones/GetUltimaInvitacion`,  relacion);
  }
  getUltimaInvitacionSponsor(relacion: BusquedaUltimaInvitacionSponsor): Observable<UltimaInvitacionSponsor>{
    return this.http.post<UltimaInvitacionSponsor>(`${environment.apiUrl}/Conexiones/GetUltimaInvitacionSponsor`,  relacion);
  }
  getConfiguracionFormulario(id:number, idPais:number): Observable<any>{
    return this.http.get(`${environment.apiUrl}/Conexiones/GetConfiguracionFormulario`,  {params: {id : id, idPais: idPais}});
  }

  postReenviarInvitacion(invitacion: InvitacionView): Observable<any>{
    return this.http.post(`${environment.apiUrl}/Conexiones/PostReenviarInvitacion`,  invitacion);
  }

  postReenviarInvitacionSponsor(invitacion: ReenvioInvitacion): Observable<any>{
    return this.http.post(`${environment.apiUrl}/Conexiones/PostReenviarInvitacionSponsor`,  invitacion);
  }

  postRechazarConexion(solicitudConexion:SolicitudConexion): Observable<any>{
    return this.http.post(`${environment.apiUrl}/Conexiones/PostRechazarConexion`,  solicitudConexion);
  }

  postAceptarInvitacion(solicitudConexion:SolicitudConexion): Observable<any>{
    return this.http.post(`${environment.apiUrl}/Conexiones/PostAceptarInvitacion`,  solicitudConexion);
  }

  updateConfiguracionFormulario(tipoProveedorView:TipoProveedorView): Observable<any>{
    return this.http.post(`${environment.apiUrl}/Conexiones/UpdateConfiguracionFormulario`,  tipoProveedorView);
  }

  recuperarAliasEmpresa(): Observable<string>{
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    const requestOptions: Object = { responseType: 'text', headers };
    return this.http.post<string>(`${environment.apiUrl}/Cliente/RecuperarAliasEmpresa`,{}, requestOptions);
  }

  recuperarConexionesExistentesAnfitrionInvitado(identificadorTributario: string, idPais: number): Observable<any>{
    return this.http.get<any>(`${environment.apiUrl}/Conexiones/RecuperarConexionesExistentesAnfitrionInvitado`,  {params: {cuit: identificadorTributario, idPaisInvitado: idPais}});
  }

  validarCuitPais(pais: string, identificadorTributario: string): Observable<boolean>{
    return this.http.get<boolean>(`${environment.apiUrl}/Conexiones/ValidarCuitPais`,  {params: {pais : pais, cuit: identificadorTributario}});
  }
  
  verificarExistenciaCuit(identificadoresTributarios: string[]): Observable<boolean>{
    return this.http.post<boolean>(`${environment.apiUrl}/Cliente/VerificarExistenciaCuit`,  identificadoresTributarios);
  }
  
  recuperarDatosExtraClientePorCuit(identificadorTributario: string, idPais: number): Observable<any>{
    return this.http.get<any>(`${environment.apiUrl}/Conexiones/RecuperarDatosExtraClientePorCuit`,  {params: {cuit: identificadorTributario, idPais: idPais}});
  }
  
  esIdentificadorTributarioDeArgentina(aliasEmpresa:string, identificadorTributario: string): Observable<boolean>{
    return this.http.get<boolean>(`${environment.apiUrl}/Conexiones/EsIdentificadorTributarioDeArgentina`,  {params: {identificadorTributario: identificadorTributario, aliasEmpresa: aliasEmpresa}});
  }
  
  recuperarConfiguracionDocumentosMapas(configuracion: any): Observable<any>{
    return this.http.post<any>(`${environment.apiUrl}/Conexiones/RecuperarConfiguracionDocumentosMapas`, configuracion);
  }

  recuperarConfiguracionDocumentosPorCliente(cliente: ClienteConexion): Observable<any>{
    return this.http.post<any>(`${environment.apiUrl}/Conexiones/RecuperarConfiguracionDocumentosPorCliente`, cliente);
  }
  
  recuperarConfiguracionTiposDocumentosPorCliente(configuracion: any): Observable<any>{
    return this.http.post<any>(`${environment.apiUrl}/ConfiguracionServicios/RecuperarConfiguracionTiposDocumentosPorCliente`, configuracion);
  }

  getRecuperarConfiguracionTiposDeProveedoresPorCuitCliente(identificadorTributario: string): Observable<any>{
    return this.http.get<any>(`${environment.apiUrl}/Conexiones/GetRecuperarConfiguracionTiposDeProveedoresPorCuitCliente`,  {params: {cuit: identificadorTributario}});
  }

  subirZip(zip: Blob, fileName: string): Observable<any>{
    const requestOptions: Object = { responseType: 'text'};
    const formData = new FormData();
    formData.append("file", zip, fileName);

    return this.http.post<any>(`${environment.apiUrl}/CargaDocumento/SubirZip`, formData, requestOptions);
  }
  crearMetadata(archivo: CargaArchivo): Observable<any>{
    return this.http.post<any>(`${environment.apiUrl}/CargaDocumento/CrearMetadata`, archivo);
  }

  getRecuperarRelacionesIntercambioIncompatibles(relacion : Conexion): Observable<any>{
    return this.http.post<any>(`${environment.apiUrl}/Conexiones/RecuperarRelacionIntercambioIncompatible`, relacion);
  }
  getRecuperarRelacionesExistentes(relaciones : RelacionIntercambio[]): Observable<any>{
    return this.http.post<any>(`${environment.apiUrl}/Conexiones/RecuperarRelacionesExistentes`, relaciones);
  }

  getInterlocutoresConIdentificadorTributario(idCliente: number, idPaisCliente: number): Observable<ClienteDetalle[]>{
    return this.http.post<ClienteDetalle[]>(`${environment.apiUrl}/Conexiones/GetInterlocutoresConIdentificadorTributario`, {id:idCliente, idPais: idPaisCliente});
  }

  existeEventoCambioEstadoConexion(conexiones : number[]): Observable<any>{
    return this.http.post<any>(`${environment.apiUrl}/Conexiones/ExisteEventoCambioEstadoConexion`, conexiones);
  }
}
