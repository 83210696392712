import { Inject, Injectable } from '@angular/core'
import { CanActivate } from '@angular/router'
import { AuthService } from '../services/auth.service'
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {

  constructor(
    private authService: AuthService,
    @Inject(DOCUMENT) private document: Document
    ) {}

  canActivate(): boolean {
    let legacyURL = `${environment.url}/index.html#/`
    if (!this.authService.isAuthenticated()) {

      if (environment.production) {
        this.document.location.href = legacyURL
      } else {
        // if not in production environment, redirect after a minute
        // so that we have time to load a token in sessionStorage
        setTimeout(() => {
          //this.document.location.href = legacyURL
          this.document.location.href = this.document.location.href
        }, 160000);
      }
      return false
    }
    return true
  }
}
