import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/auth.service';
import { GenericToasterService } from 'src/app/core/services/generic-toaster.service';
import { GoogleAnalyticsEventsService } from 'src/app/core/services/google-analytics-events.service';

@Component({
  selector: 'app-dialog-conciliacion-contactar',
  templateUrl: './dialog-conciliacion-contactar.component.html',
  styleUrls: ['./dialog-conciliacion-contactar.component.scss']
})
export class DialogConciliacionContactarComponent implements OnInit {
  formEmail: FormGroup = this.fb.group({
    email: new FormControl('', [Validators.required]),
    nombreApellido: new FormControl('', [Validators.required])
  });
  private user = this.authService.userdata;
  constructor(
    public dialogRef: MatDialogRef<DialogConciliacionContactarComponent>,
    private fb: FormBuilder,
    private authService: AuthService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private genericToasterService: GenericToasterService,
  ) { }

  ngOnInit(): void {
    this.formEmail.controls['email'].disable()
    this.formEmail.controls['email'].setValue(this.user.NombreUsuario);
    this.formEmail.controls['nombreApellido'].setValue(this.user.Nombre + ' ' + this.user.Apellido);

  }

  Contactar(): void {
    let logAnalytic = "Contactar - " + this.user.Cliente.RazonSocial + ' - ' + this.formEmail.controls['nombreApellido'].value + ' - ' + this.user.NombreUsuario;
    this.googleAnalyticsEventsService.emitEvent('Conciliacion', logAnalytic, null, true);
    this.genericToasterService.pushSuccess("Sus datos han sido enviados correctamente. Pronto será contactado por alguien de nuestro equipo de Producto. ¡Muchas Gracias!");
    this.dialogRef.close();
  }
}
