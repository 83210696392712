import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ConnectionsService } from 'src/app/core/services/connections.service';
declare var $: any;
import { ModalService } from 'src/app/core/services/modal.service';
@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.scss']
})
export class ConnectionsComponent implements OnInit {
  constructor(public connectionsService: ConnectionsService, public modalService: ModalService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  // ngAfterViewInit() {
  //   // $(function () {
  //   //   $('[data-toggle="tooltip"]').tooltip({ container: '.connections__title__link' })
  //   // })
  // }

  ngOnDestroy() {
  }

  closeModal() {
    this.modalService.closeModal()
  }

}
