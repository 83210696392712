<h3 mat-dialog-title class="mensaje-titulo">{{data.titulo | translate}}</h3>
<div>
    <p class="salto-linea align-center">{{data.mensaje | translate:data.params}}</p>
    <p class="salto-linea align-center" *ngIf="data.mensaje2">{{data.mensaje2 | translate}}</p>
</div>
<div class="btn-margin">
    <div class="centrar">
        <button mat-raised-button class="boton" color="primary" mat-button (click)="confirmado()">{{data.confirmacion |
            translate | uppercase }}</button>
    </div>
    <div class="centrar">
        <button mat-button color="primary" class="boton" (click)="cerrarDialogo()">{{data.rechazo | translate |
            uppercase
            }}</button>
    </div>
</div>