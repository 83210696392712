import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class AuthInterceptorRequest implements HttpInterceptor {
    constructor(private authService: AuthService,
      private router: Router,
      private dialogService: MatDialog) {}
      private urlsSinToken = ["acceso","i18n","version", "contrasena/crear", "getmensajesparaelusuariobyresourcekey"];

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {

      // TODO SACAR CUANDO SE VALIDE DESDE EL BACK EL TOKEN
      var url = req.url.toLowerCase();
      var sinToken = true;
      if(!(url.includes("acceso/recuperarcontrasena") && req.body.rtu === "")){
        this.urlsSinToken.forEach(cada => {
          if(url.includes(cada))
            sinToken = false;
        });
      }

      if(sinToken && !this.authService.isAuthenticated() ){
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('ClientesHolding');
        sessionStorage.clear();
        localStorage.clear();
        this.router.navigateByUrl('/login')
        const sinAuthReq = req.clone();
        return next.handle(sinAuthReq);
      }
      const authHeader = `Bearer ${this.authService.getRawToken()}`;
      const authReq = req.clone({headers: req.headers.set('Authorization', authHeader)});
      return next.handle(authReq);

  }

}
