import { Component, Inject, OnInit } from '@angular/core';
import {
  faPhone,
  faEnvelope,
  faBell,
  faDownload,
  faQuestion,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import {
  NavigationData,
  NavigationService,
} from 'src/app/core/services/navigation.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { TokenUserData } from 'src/app/core/models/accessToken';
import { ModalService } from 'src/app/core/services/modal.service';

import { Administrators } from 'src/app/core/models/administrators.interface';
import { ConnectionsService } from 'src/app/core/services/connections.service';
import { DownloadService } from 'src/app/core/services/download.service';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, Subscription, of } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { isNullOrEmptyOrUndefined } from '../utils/utils.component';
import { JwtInfo } from 'src/app/pages/login/Models/JwtInfo';
import { MensajesDocumentosService } from 'src/app/core/services/mensajes-documentos.service';
import { GoogleAnalyticsEventsService } from 'src/app/core/services/google-analytics-events.service';

import { MsalService } from '@azure/msal-angular';
import { finalize, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {

  userData?: TokenUserData;
  isTopNavCollapsed: boolean;
  unreadNotifications$: BehaviorSubject<number>;
  adminsList: Array<Administrators>;

  mediaSub: Subscription;
  deviceXs: boolean;

  openAdminsModal: boolean;
  openDownloadsModal: boolean;

  notif = 6;
  downloads = 2;

  // icons
  faPhone = faPhone;
  faEnvelope = faEnvelope;
  faBell = faBell;
  faDownload = faDownload;
  faQuestion = faQuestion;
  faUser = faUser;

  // nav
  pagesNav: NavigationData['pages'];
  userNav: NavigationData['pages']['user'];
  topBarNav: NavigationData['topBar'];

  hideBell = false;
  usaSocialLogin = false;
  constructor(
    public mediaObserver: MediaObserver,
    public navService: NavigationService,
    public authService: AuthService,
    public modalService: ModalService,
    public connectionsService: ConnectionsService,
    public mensajesDocumentosService: MensajesDocumentosService,
    public downloadService: DownloadService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    @Inject(DOCUMENT) private document: Document,
    private authMsalService: MsalService 
  ) {
    this.pagesNav = navService.from.pages;
    this.userNav = this.pagesNav.user;
    this.topBarNav = navService.from.topBar;
    this.userData = authService.getUserData();
  }

  ngOnInit(): void {
    var ocultar = false;
    if (this.authService.userdata.TipoUsuario  === 'Planex' && isNullOrEmptyOrUndefined(this.authService.userdata.ConectadoComo) ) {
      ocultar = true;
      this.authService.generarTokenJWTDesconectarSoporte().subscribe(token => {
        let userInfo: JwtInfo = <JwtInfo>(token);
        sessionStorage.setItem('token', userInfo.jwt);
      });
    }
    this.hideBell = ( (this.authService.userdata.Tipo ?? this.authService.userdata.TipoUsuario) === 'Planex' && isNullOrEmptyOrUndefined(this.authService.userdata.ConectadoComo)) || ocultar;
    this.connectionsService.getNotificationsAmount();
    
    this.mensajesDocumentosService.checkServicioNoChat();
    this.analyticsCantidadMensajes()
    if(!this.mensajesDocumentosService.tieneServicioNoChat$) this.mensajesDocumentosService.getMensajesDocumentosAmount(); 
      
    this.getAdminsInfo();
    this.getLayout();
    this.usaSocialLogin = this.authService.getUserData()?.UsaSocialLogin?? false;
    
  }

  esUsuarioPLX(): boolean {
    return this.authService.esPlanexware();
  }
  
  estaConectadoComo(): boolean {
    return this.authService.conectadoComo();
  }


  ngOnDestroy(){
    this.mediaSub.unsubscribe()
  }

  getLayout(){
    this.mediaSub = this.mediaObserver.media$.subscribe((resutl:MediaChange) => {
      this.deviceXs = resutl.mqAlias === 'xs' ? true : false
    });
  }

  getAdminsInfo() {
    this.navService.getAdminsInfo().subscribe(
      (res: Array<Administrators>) => {
        this.adminsList = res;
      },
      (err) => {
        // TODO: toaster error
      }
    );
  }


  toggleNotifications() {
      this.connectionsService.loadingNotifications = true
      this.connectionsService.getConnections()
      this.connectionsService.getNotificationsAmount();

  }

  openDownloads() {
    this.downloadService.getDownloadsList()
    this.openModal('downloadsModal')
  }

  openAdmins() {
    this.openModal('adminModal')
  }

  openModal(id: string) {
    this.navService.openNotificationPanel = false;
    this.modalService.openModal(id);
  }

  logout() {
    if (this.authService.getUserData()?.UsaSocialLogin) {
      const account = this.authMsalService.instance.getActiveAccount();
        if(account?.idTokenClaims?.iss?.includes("microsoft")){
          this.authMsalService.instance.setActiveAccount(null);
        }
    }
    this.clearSessionAndRedirect();
    // this.navService.logout().pipe(
    //   switchMap(() => {
    //     if (this.authService.getUserData()?.UsaSocialLogin) {
    //       const account = this.authMsalService.instance.getActiveAccount();
    //       if(account?.idTokenClaims?.iss?.includes("microsoft")){
    //         this.authMsalService.instance.setActiveAccount(null);
    //          return this.authMsalService.logoutPopup(
    //            { idTokenHint: account?.idToken,
    //              mainWindowRedirectUri: `${environment.url}/v2/login`
    //            });
    //         return of(null);    
    //       } else {
    //         return of(null);    
    //       }
    //     }
    //     // If the user is not authenticated with Google or Microsoft, just return an empty observable
    //     return of(null);
        
    //   }),
    //   finalize(() => this.clearSessionAndRedirect())
    // ).subscribe({
    //   error: (err) => this.handleError(err),
    //   complete: () => this.handleComplete()
    // });
  }
  
  clearSessionAndRedirect() {
    this.clearSession();
    if(this.authService.getUserData()?.UsaSocialLogin) {
      this.authMsalService.logoutRedirect();
    }
    
    let fullURL = `${environment.url}/v2/login`
    this.document.location.href = fullURL
  }
  
  clearSession() {
    localStorage.removeItem('msal.interaction.status');
    sessionStorage.removeItem('msal.interaction.status');
    sessionStorage.removeItem('jwt');
    localStorage.clear();
    sessionStorage.clear();
  }

  getMensajesDocumentos(){
    this.mensajesDocumentosService.getMensajesDocumentosAmount();
    this.mensajesDocumentosService.getMensajesDocumentos();

    let owner = this.authService.getOwner();
    this.googleAnalyticsEventsService.emitEvent('Mensajeria', `Mostrar Msj: ${this.mensajesDocumentosService.mensajesDocumentosCount$.value} - Empresa: ${owner.razonSocial}`, null, true);
  }

  analyticsCantidadMensajes(){
    this.mensajesDocumentosService.getCantidadMensajesConRetorno().subscribe(cant =>{
      this.googleAnalyticsEventsService.emitEvent('Mensajeria', `Panel Msj: ${cant} - Empresa: ${this.authService.getOwner().razonSocial}`, null, true);
    });
  }
}
