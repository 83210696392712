import { Inject } from '@angular/core';
import { Component, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';



@Component({
  selector: 'app-popup-credenciales',
  templateUrl: './popup-credenciales.component.html',
  styleUrls: ['./popup-credenciales.component.scss']
})
export class PopUpCredencialesComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PopUpCredencialesComponent>,
     @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
  }

  aceptar() {
    this.dialogRef.close(true);
  }

  rechazar() {
    this.dialogRef.close(false);
  }
}
