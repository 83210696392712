import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  _openModal: string
  // _openModal: string = 'connectionsModal'

  constructor() { }

  openModal(id: string) {
    this._openModal = id
  }

  closeModal() {
    this._openModal = ''
  }


}
