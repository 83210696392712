import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label, SingleDataSet } from 'ng2-charts';
import { BehaviorSubject } from 'rxjs';
import { ConexionesDashboard } from './models/ConexionesDashboard';
import { HomePageService } from './services/home-page.service';
import { HttpErrorResponse } from '@angular/common/http';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ToasterService } from 'src/app/core/services/toaster.service';
import { AuthService } from 'src/app/core/services/auth.service';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  //#region Carousel
  limit: number = 10; // <==== Edit this number to limit API results
  customOptions: OwlOptions = {
    loop: true,
    autoplay: false,
    center: true,
    dots: false,
    autoHeight: true,
    autoWidth: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      }
    }
  }
  //#endregion

  spinner = true;

  //#region Variables de datos
  indicadoresSubject: BehaviorSubject<any[]>;
  fechasList: any[] = [];
  conexionesSubject: BehaviorSubject<ConexionesDashboard[]>;
  totalEmpresas: number = 0;
  noAceptaron: number = 0;
  COMUNIDAD_TOOLTIP = "Mi_Comunidad_Info";
  tieneComunidad = false;
  expandirComunidad = true;
  mesesBarras: string[];
  conexionesEstadoSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  indicadoresEstadoSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  //#endregion

  // Dona
  donaDataBSubject: BehaviorSubject<any[]>
  donaData = [
    {
      name: '',
      value: 0
    }
  ];


  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: "bottom",
      labels: {
        padding: 30,
        fontFamily: "montserrat-regular"
      }
    },

  };
  public pieChartLabels: Label[] = [[1 + ' Empresas Conectada(s) ' + 50 + "%"], [1 + ' Empresas No Conectada(s) ' + 50 + "%"]];
  public pieChartData: SingleDataSet = [0, 0];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public pieChartColors: any[] = [{
    backgroundColor: ["#01ADC3", "#FE2643"]
  }];

  //Fin de Dona

  // Barras V1
  listBar: any[];
  listFechas: any[]
  barlistBehavior: BehaviorSubject<any[]>
  xAxisLabelBehavior: BehaviorSubject<any[]>
  view: any[] = [650, 350];

  // options
  barPadding: number = 8;
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  animations: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = '';
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  label: string = this.translate.instant('TOTAL')

  colorScheme = {
    domain: ['#01adc3', '#fe2643', '#9f1daa']
  };

  public barTitleData: string[] = [
    "precarga"
  ];
  public barSentidoData: string[] = [
    "precarga"
  ];
  public barDescripcionDocumentoData: string[] = [
    "precarga"
  ];
  //Fin de Barras V1

  //#region Barras V2

  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  public barChartLabels: Label[] = ['2012', '2007', '2008', '2009', '2010', '2011', '2012'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 12], label: 'Series A', stack: 'a' },
    { data: [28, 48, 40, 5], label: 'Series B', stack: 'a' }
  ];
  doughnutChartData: number[][];
  doughnutChartLabels: any[];


  //#endregion Barras V2

  // grafico barras version 153022

  public chartColors: Array<any> = [
    { // color verde
      backgroundColor: '#01ADC3',
      borderColor: '#01ADC3',
      pointBackgroundColor: '#01ADC3',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#01ADC3'
    },
    { //  color rojo
      backgroundColor: '#FE2643',
      borderColor: '#FE2643',
      pointBackgroundColor: '#FE2643',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#FE2643'
    }];

  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "infinite": true,
    "arrows": true,
    "draggable": false,

  };


  // fin grafico barras version 153022
  static contadorAlerta = false;
  constructor(private homePageService: HomePageService,
    private toasterService: ToasterService,
    private authService: AuthService,
    public translate: TranslateService,
    private toastr: ToastrService) {

  }

  esUsuarioPLX(): boolean {
    return this.authService.esPlanexware();
  }
  
  estaConectadoComo(): boolean {
    return this.authService.conectadoComo();
  }


  async ngOnInit(): Promise<void> {
    await this.authService.getClientesHoldingFromApi();
    this.getAlertaProxVencimiento();
    this.homePageService.getTieneComunidad().subscribe((result: boolean) => {
      this.tieneComunidad = result;
      if (this.tieneComunidad) {
        this.getIndicadores();
        this.getConexiones();
      }
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(this.translate.instant('ERROR_APLICACION'), '', {
          positionClass: "toast-top-full-width",
          disableTimeOut: true,
          closeButton: true
        });
      });
      var idioma = this.authService.getUserData()?.Cultura;
  }

  onSelect(event: any) {
  }

  toggleComunidad(): void {
    this.expandirComunidad = !this.expandirComunidad;
  }

  onResize(event: any) {
    this.view = [event.target.innerWidth / 1.35, 400];
  }

  getIndicadores() {
    this.homePageService.getIndicadoresDashboard().subscribe((indicadoresDashboard: any[]) => {

      this.listFechas = indicadoresDashboard.map(element => element.fechaUltimaActualizacion)

      if(indicadoresDashboard.length > 0){
        this.indicadoresEstadoSubject.next(true)

        this.indicadoresSubject = new BehaviorSubject<any[]>(indicadoresDashboard);

      //Obtengo los meses a mostrar en el grafico
        this.getMesIndicadores(this.indicadoresSubject.value[0].fechaUltimaActualizacion);

        this.indicadoresSubject.value.forEach(elemento => {
          switch (elemento.tipoIdentificador) {
            case "SiLeySiCon":
            case "NoLeyNoCon":
            case "NoLeySiCon":
              elemento.sociedad = "comunidad";
              break
            case "EmpNoLey":
            case "EmpLey":
            case "EmpEmis":
              elemento.sociedad = "empresa";
              break;
            default:
              elemento.sociedad = "empresa";
              break;
          }
        })

        this.indicadoresSubject.value.forEach(elemento => {
          switch (elemento.tipoIdentificador) {
            case "SiLeySiCon": //Conectado
            case "NoLeySiCon": //Conectado
            case "EmpEmis": //Conectado
              elemento.estado = "conectado";
              break
            case "NoLeyNoCon": //NoConectado
            case "EmpNoLey": //NoConectado
            case "EmpLey": //NoConectado
              elemento.estado = "noConectado";
              break;
            default:
              elemento.estado = "noConectado";
              break;
          }
        })

        var grupete = _.groupBy(this.indicadoresSubject.value, function (indicador) {
          var props = ['tipoDocumento', 'sentido', 'sociedad', 'estado']; // server-defined
          var prop = [];

          for (var i = 0, length = props.length; i < length; i++) {
            prop.push(indicador[props[i]]);
          }

          return prop.join('|');
        });

        var data: any[] = []

        for (const [key, value] of Object.entries(grupete)) {

          value.map(element => {

            switch (element.tipoIdentificador) {
              case "SiLeySiCon":
                element.tipoIdentificador = this.translate.instant('LEIDOS_EMPRESAS_CONECTADAS')
                break;
              case "NoLeyNoCon":
                element.tipoIdentificador = this.translate.instant('NO_LEIDOS_EMP_NO_CONECTADAS')
                break;
              case "NoLeySiCon":
                element.tipoIdentificador = this.translate.instant('NO_LEIDOS_EMP_CONECTADAS')
                break;
              case "EmpNoLey":
                element.tipoIdentificador = this.translate.instant('NO_LEYERON')
                break;
              case "EmpLey":
                element.tipoIdentificador = this.translate.instant('LEYERON')
                break;
              case "EmpEmis":
                element.tipoIdentificador = this.translate.instant('CANTIDAD_EMPRESAS_CONECTADAS')
                break;
              default:
                element.tipoIdentificador = "no pactado"
                break;
            }

          })

          var char = [
            {
              "name": this.mesesBarras[0],
              "series": value.map(element => {
                return { "name": element.tipoIdentificador, "value": (element.periodoDos) }
              })
            },
            {
              "name": this.mesesBarras[1],
              "series": value.map(element => {
                return { "name": element.tipoIdentificador, "value": (element.periodoUno) }
              })
            },
            {
              "name": this.mesesBarras[2],
              "series": value.map(element => {
                return { "name": element.tipoIdentificador, "value": (element.periodo) }
              })
            },
          ]
          data.push(char);

          this.barTitleData.push(value[0].tipoDocumento)
          this.barSentidoData.push(this.validateSentido(value[0]))
          this.barDescripcionDocumentoData.push(this.validateDescripcionDocumento(value[0]))

        }
        this.barTitleData.shift();
        this.barSentidoData.shift();
        this.barDescripcionDocumentoData.shift();

        this.barlistBehavior = new BehaviorSubject<any[]>(data)

        }else{
          this.indicadoresEstadoSubject.next(false)
        }
        this.spinner = false;
      })
    }

    validateIndicador(indicadoresDashboard: any) {
      if (indicadoresDashboard.tipoIdentificador == "SiLeySiCon") {
        return (this.translate.instant('LEIDOS_EMPRESAS_CONECTADAS'));
      }
      else if (indicadoresDashboard.tipoIdentificador == "NoLeyNoCon") {
        return (this.translate.instant('NO_LEIDOS_EMP_NO_CONECTADAS'));
      }
      else if (indicadoresDashboard.tipoIdentificador == "NoLeySiCon") {
        return (this.translate.instant('NO_LEIDOS_EMP_CONECTADAS'));
      }
      else if (indicadoresDashboard.tipoIdentificador == "EmpNoLey") {
        return (this.translate.instant('NO_LEYERON'));
      }
      else if (indicadoresDashboard.tipoIdentificador == "EmpLey") {
        return (this.translate.instant('LEYERON'));
      }
      else if (indicadoresDashboard.tipoIdentificador == "EmpEmis") {
        return (this.translate.instant('CANTIDAD_EMPRESAS_CONECTADAS'));
      }
      else {
        return ("no pactado")
      }

    }

    validateSentido(indicadoresDashboard: any) {
      if (indicadoresDashboard.sentido == "R") {
        return (this.translate.instant('recibidas'));
      } else {
        return (this.translate.instant('enviadas'));
      }
    }

    validateDescripcionDocumento(indicadoresDashboard: any) {
      if (
        indicadoresDashboard.sociedad == "comunidad") {
          if(indicadoresDashboard.sentido == 'R')
          return (this.translate.instant('DE_SU_COMUNIDAD_DE_EMPRESAS'));
        return (this.translate.instant('A_SU_COMUNIDAD_DE_EMPRESAS'));
      } else {
        if (indicadoresDashboard.sentido == 'R')
          return (this.translate.instant('EMPRESAS_QUE_EMITIERON'));
        else {
          return (this.translate.instant('EMPRESAS_QUE_LEYERON'));
        }
      }
    }
    
    getConexiones() {
      this.homePageService.getConexionesComunidad().subscribe((conexionesDashboard: ConexionesDashboard[]) => {

        if(conexionesDashboard.length > 0){
          var conexiones
          conexionesDashboard.forEach(element => {
            switch (element.estadoEmpresa) {
              case "CONECTADA":
                  if(conexionesDashboard.length <= 1){
                    var obj = { cantidadConexiones: 0, estadoEmpresa: "NO CONECTADA"}
                    conexionesDashboard.push(obj)
                  }
                break;
              case "NO CONECTADA":
                if(conexionesDashboard.length <= 1){
                  var obj = { cantidadConexiones: 0, estadoEmpresa: "CONECTADA"}
                  conexionesDashboard.push(obj)
                }
              break;
              default:
                break;
            }
          })

          this.conexionesEstadoSubject.next(true)

          this.conexionesSubject = new BehaviorSubject<ConexionesDashboard[]>(conexionesDashboard);

          this.noAceptaron = conexionesDashboard[1].cantidadConexiones;
          this.totalEmpresas = conexionesDashboard[0].cantidadConexiones + conexionesDashboard[1].cantidadConexiones;
          var porcentajeEmpresasConectadas = ((conexionesDashboard[0].cantidadConexiones / this.totalEmpresas) * 100).toFixed();
          var porcentajeEmpresasNoConectadas = ((conexionesDashboard[1].cantidadConexiones / this.totalEmpresas) * 100).toFixed();

          this.pieChartData = [
            [conexionesDashboard[0].cantidadConexiones,conexionesDashboard[1].cantidadConexiones],
          ];

          this.pieChartLabels = [[conexionesDashboard[0].cantidadConexiones+ ' ' +
                                this.translate.instant('Empresas_Contectadas') + ' ' +
                                porcentajeEmpresasConectadas + ' %'],

                                [conexionesDashboard[1].cantidadConexiones + ' ' +
                                this.translate.instant('EMPRESAS_NO_CONECTADAS') + ' ' +
                                porcentajeEmpresasNoConectadas + ' %']
                                ];
        }else{
          this.conexionesEstadoSubject.next(false)
        }
        this.spinner = false;
    })
  }


  getMesIndicadores(date: any) {
    let idioma = this.authService.getUserData()?.Cultura;
    let mes = new Date(date);

    let mesEnCurso = idioma == "en-US" ? mes.toLocaleString('en-us', { month: 'long' }).charAt(0).toUpperCase() + mes.toLocaleString('en-us', { month: 'long' }).slice(1) : mes.toLocaleString('es-es', { month: 'long' }).charAt(0).toUpperCase() + mes.toLocaleString('es-es', { month: 'long' }).slice(1)

    mes.setDate(mes.getDate() - 30)
    let mesAnterior1 = idioma == "en-US" ? mes.toLocaleString('en-us', { month: 'long' }).charAt(0).toUpperCase() + mes.toLocaleString('en-us', { month: 'long' }).slice(1) : mes.toLocaleString('es-es', { month: 'long' }).charAt(0).toUpperCase() + mes.toLocaleString('es-es', { month: 'long' }).slice(1)

    mes.setDate(mes.getDate() - 30)
    let mesAnterior2 = idioma == "en-US" ? mes.toLocaleString('en-us', { month: 'long' }).charAt(0).toUpperCase() + mes.toLocaleString('en-us', { month: 'long' }).slice(1) : mes.toLocaleString('es-es', { month: 'long' }).charAt(0).toUpperCase() + mes.toLocaleString('es-es', { month: 'long' }).slice(1)

    this.mesesBarras = [this.translate.instant(mesAnterior2), this.translate.instant(mesAnterior1), this.translate.instant(mesEnCurso)]
  }

  getAlertaProxVencimiento() {
    this.authService.getRecuperarAvisoCambioContrasena().subscribe((respuesta) => {
      respuesta = respuesta;
      if (respuesta.value !== null) {

        if (HomePageComponent.contadorAlerta)
          return;
        var mensaje = this.translate.instant('FALTAN_N_DIAS_VENCIMIENTO_CONTRASENA') + respuesta.value + this.translate.instant('FALTAN_N_DIAS_VENCIMIENTO_CONTRASENA_2');
        var toasterMessage: any = {
          id: 0,
          mensaje: mensaje,
          tipoMensaje: "info",
          bloqueante: false,
          visibleEnCadaLogin: true
        }
        this.toasterService.pushMessage(toasterMessage);
        HomePageComponent.contadorAlerta = true;
      }
    });
  }

  agregarTimeZone(): string {
    return this.authService.getTimeZone();
  }

}
