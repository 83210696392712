import { BehaviorSubject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { MensajeHTMLView } from './models/mensajeHTMLView';
import { NovedadesService } from './services/novedades.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-novedades',
  templateUrl: './novedades.component.html',
  styleUrls: ['./novedades.component.scss']
})
export class NovedadesComponent implements OnInit {

  detalle: string;
  subject: BehaviorSubject<MensajeHTMLView>;
  constructor(private translate: TranslateService,
              public novedadesService: NovedadesService,
              public authService: AuthService
              ) { }

  ngOnInit(): void {
    //this.translate.currentLang;
    this.getMensaje();
  }

  getMensaje() {
    this.novedadesService.getNovedades().subscribe( (data: MensajeHTMLView) => {
      this.subject = new BehaviorSubject<MensajeHTMLView>(data);
      this.detalle = this.subject.value.mensaje;
      ////console.log("LA DATA DE GET NOVEDADES: ",this.detalle);
    })
  }

}
