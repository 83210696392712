<h1 class="plx-t3">Términos y Condiciones</h1>
<h4>1. SOBRE ESTE DOCUMENTO</h4>
<p>A continuación se describen los términos y condiciones de uso (en adelante los "Términos y Condiciones") aplicables al acceso y la utilización de la plataforma KRIKOS 360 (en adelante también la "Plataforma" o el “Sistema” indistintamente) de titularidad de PLANEXWARE S.A., con domicilio en Alberti 2154 piso 7°, Ciudad Autónoma de Buenos Aires, República Argentina, (en lo sucesivo “PLANEXWARE”) así como los alcances y limitaciones de los servicios provistos a través la misma.</p>
<p>Este documento conforma un acuerdo vinculante entre Ud. (en adelante el “Usuario”) y PLANEXWARE en los términos del artículo 1105 y concordantes del Código Civil y Comercial de la República Argentina.</p>
<h4>2. SU ACEPTACIÓN POR PARTE DE LOS USUARIOS</h4>
<p>PLANEXWARE en su calidad de prestador del servicio, exige que cada usuario, acepte y cumpla los términos aquí incluidos como condición necesaria para el acceso y la utilización de la plataforma KRIKOS 360.</p>
<p>En tal sentido, cualquier persona que pretenda acceder y/o utilizar la plataforma KRIKOS 360 podrá hacerlo sujetándose a las disposiciones aquí fijadas y a la normativa legal de orden nacional y local cuya aplicación corresponda.</p>
<p>El Usuario que no acepte, no esté de acuerdo, o incumpla lo dispuesto en estos Términos y Condiciones, y/o la Política de Privacidad, no contara con autorización para el acceso y utilización del Sistema, debiendo abstenerse de ingresar, iniciar su sesión de usuario, o hacer uso del Sistema bajo cualquier forma.</p>
<p>Cuando un Usuario iniciare su sesión de Usuario en el sistema a través de su identificación con las credenciales otorgadas, se entenderá que ha aceptado y prestado consentimiento en forma positiva respecto del contenido de este texto en su conjunto, asumiendo la obligación de respetar lo aquí dispuesto.</p>
<h4>3. CONDICIÓN DE USUARIO</h4>
<p class="subtitulo">3.1 Usuarios de KRIKOS 360</p>
<p>La plataforma KRIKOS 360 no resulta un servicio del libre acceso, sino que su utilización se encuentra sujeta a la previa existencia de un acuerdo (“Solicitud de Adhesión”) entre PLANEXWARE como prestador del servicio y el prestatario (también el “Titular del Servicio” o el “Licenciatario”).</p>
<p>El Titular del Servicio podrá autorizar una determinada cantidad de usuarios de KRIKOS 360 pertenecientes a su organización o externos a la misma, conforme los límites establecidos en la Solicitud de Adhesión. En este sentido, únicamente podrán ser usuarios de KRIKOS 360 aquellas personas que hubieren sido expresamente autorizadas por el titular del servicio, quien será responsable por todas las acciones que lleven adelante en el uso de la Plataforma los usuarios por él designados.</p>
<p>Para la adquisición de la calidad de “Usuario”, además de la expresa designación como usuario por parte del Titular del Servicio, se requiere la aceptación (según las formas aquí previstas) de estos Términos y Condiciones, y aquellas disposiciones particulares establecidas o que pueda disponer en el futuro PLANEXWARE en relación a los servicios prestados a través de KRIKOS 360.</p>
<p>Sin embargo, para la utilización de algunos servicios, podrá determinarse como requisito, el previo registro del Usuario. Dicho registro tendrá por finalidad establecer la identidad e información de contacto del Usuario.</p>
<p class="subtitulo">3.2 Credenciales de acceso a KRIKOS 360</p>
<p>Cuando el Titular del Servicio designe un usuario de KRIKOS 360, proporcionará un nombre de identificación y una contraseña o password que le permitirán el acceso personalizado, confidencial y seguro a su cuenta dentro de la Plataforma. Los servicios de KRIKOS 360 han sido concebidos para el uso propio del Usuario, por lo tanto, la contraseña de acceso concedida por el Titular del Servicio solo podrá ser usada por el Usuario, estando prohibida su utilización por otra persona distinta al mismo. El Usuario asume la obligación de guarda y custodia de su contraseña de acceso, debiendo informar inmediatamente al Titular del Servicio y a PLANEXWARE cuando los mismos hubieren perdido su estado de confidencialidad.</p>
<p>El Usuario será solidariamente responsable junto con el Titular del Servicio por todas las operaciones efectuadas a través de su cuenta personal, debiendo notificar a PLANEXWARE en forma inmediata y por medio idóneo y fehaciente, cualquier uso no autorizado de su cuenta, así como el ingreso por terceros no autorizados a la misma.</p>
<p>PLANEXWARE podrá suspender o cancelar en cualquier momento la cuenta del Usuario, sin que tal decisión deba ser justificada, y sin que ello genere derecho a reparación alguna en beneficio del Usuario.</p>
<p class="subtitulo">3.3 Capacidad legal de los usuarios</p>
<p>Únicamente podrán acceder y utilizar KRIKOS 360, quienes a tenor de la legislación vigente en su lugar de residencia puedan válidamente emitir su consentimiento para la celebración de contratos.</p>
<p>En el supuesto de actuación en representación de una persona jurídica, el Usuario debe contar con facultades suficientes para contratar en nombre de la entidad invocada y de obligar a la misma en los términos de este documento. Si el Usuario no contare con tales facultades, o actuare en exceso de las mismas, se entenderá que se ha comprometido también a título personal.</p>
<h4>4. LA PLATAFORMA KRIKOS 360</h4>
<p>KRIKOS 360 es una plataforma web de integración que permite la transmisión estructurada de documentos electrónicos y datos de negocio entre distintas organizaciones usarías del sistema (titulares del servicio), utilizando múltiples protocolos de comunicación. Permite convertir todos los formatos electrónicos, independientemente del origen y el destino y admite diferentes sistemas de intercambio. KRIKOS 360 es una plataforma multidireccional que no sólo permite la comunicación vertical con los socios del canal, sino que a su vez admite comunicaciones en sentido horizontal con otros usuarios de la misma, como parte emisora y receptora de información al mismo tiempo.</p>
<p>KRIKOS 360 no es un repositorio destinado al almacenamiento de documentos o mensajes. La conservación de la información dentro de KRIKOS 360 siempre será temporal, estando disponible para el Usuario por un término de tiempo determinado desde que hubiera sido enviada o recibida. Transcurrido dicho plazo la información será desecha, y no podrá ser accedida ni recuperada.</p>
<p>Los servicios brindados a través de KRIKOS 360, están sujetos a su modificación, supresión y/o reemplazo en cualquier momento por parte de PLANEXWARE. Consecuentemente el número, tipo, calidad, condiciones de acceso y utilización de los servicios ofrecidos, podrán variar a través del tiempo, sin que se requiera notificación alguna a los usuarios, a reparo de las obligaciones asumidas por PLANEXWARE en acuerdos con el Titular de Servicio.</p>
<h4>5. DISPONIBILIDAD DEL SISTEMA</h4>
<p>PLANEXWARE realiza sus mejores esfuerzos por mantener y mejorar la calidad de sus servicios, sin embargo, no garantiza la provisión continuada e ininterrumpida de los servicios de KRIKOS 360. Asimismo, los sistemas informáticos de los que depende KRIKOS 360 pueden eventualmente no estar disponibles debido a dificultades técnicas o fallas de conectividad, o por cualquier otra circunstancia ajena a PLANEXWARE. Por su parte el Usuario reconoce y acepta que el acceso a los servicios brindados por KRIKOS 360, sólo es posible gracias a la infraestructura de recursos de terceras partes como ser proveedores de conexión a Internet, proveedores de software y hardware, nodos de interconexión, carriers de datos, proveedores de energía eléctrica, etc., sobre los que PLANEXWARE no posee control. En conocimiento de esto el Usuario renuncia expresamente a reclamar cualquier reparación con base en la responsabilidad subjetiva u objetiva de PLANEXWARE por fallos, lentitud, y/o errores en el acceso y uso del servicio, debido a problemas de conectividad, comunicaciones, fallas o averías en los sistemas informáticos u otras contingencias posibles.</p>
<p>Tampoco será responsable PLANEXWARE en caso de interrupción del servicio o deficiencias en su prestación cuando ello sea consecuencia de:</p>
<ol type="a">
    <li>Fallas originadas a causa de virus informáticos, ataques Dos o DDoS, intrusión no autorizada a los sistemas informáticos, y cualquier otra circunstancia susceptible de ser calificada como un delito informático.</li>
    <li>Caso fortuito o fuerza mayor.</li>
    <li>Actos de ciberterrorismo conforme el alcance dado a la definición por la O.N.U. </li>
    <li>Actos criminales de terceros, como sabotajes, daño a las instalaciones, interrupción de las telecomunicaciones, actos del gobierno, emergencias, catástrofes naturales, crisis económico financieras, guerra.</li>
    <li>Huelga u otras medidas de fuerza sindicales.</li>
</ol>
<p>Por su parte, para la correcta utilización de los servicios, los usuarios deberán: (a) disponer de una computadora personal (PC) que soporte las últimas dos iteraciones cronológicamente publicadas del navegador de internet utilizado (Edge; Chrome; Firefox; Safari);  (b) una conexión de banda ancha a internet.</p>
<p>PLANEXWARE no garantiza que los servicios sean accesibles con un equipamiento de inferiores características a las descriptas o no configurado correctamente. Los requerimientos para el acceso al sitio podrán variar de tiempo en tiempo, motivo por el cual el usuario acepta que para la utilización del servicio es posible que sea necesario actualizar su equipamiento de software y/o hardware conforme el desarrollo tecnológico de los mismos.</p>
<h4>6. OBLIGACIONES DEL USUARIO</h4>
<p class="subtitulo">6.1. Uso correcto de los servicios</p>
<p>El Usuario se compromete a utilizar la plataforma KRIKOS 360 de conformidad con la ley, estos Términos y Condiciones, así como con la moral, las buenas costumbres y el orden público. El Usuario se obliga a abstenerse de utilizar el Sistema con fines o efectos ilícitos, contrarios a lo establecido en estos Términos y Condiciones, o que resulten lesivos para los derechos e intereses de terceros.</p>
<p class="subtitulo">6.2 Copias de respaldo</p>
<p>El Usuario reconoce y acepta que, dado que KRIKOS 360 sólo conserva en forma temporal y limitada los documentos y comunicaciones cursados por y hacia el Usuario, por dicha circunstancia deberá efectuar y almacenar por su cuenta copias de respaldo de todos y cada uno de los mensajes y documentos que envíe o reciba a través de KRIKOS 360.</p>
<p class="subtitulo">6.3. Responsabilidades del Usuario:</p>
<p>Cada usuario será exclusivo responsable por las acciones que lleve adelante dentro del marco del servicio KRIKOS 360.  Sin embargo, cuando PLANEXWARE reciba a través de su mecanismo de recepción de denuncias, la manifestación de una persona, que hubiere sufrido en forma injustificada un menoscabo en cualquiera de sus derechos, tomará en forma inmediata las medidas necesarias para evitar la continuación de la situación perjudicial, y pondrá en conocimiento de las autoridades competentes los acontecimientos del caso.</p>
<p class="subtitulo">6.4 Responsabilidad por el envío de mensajes y documentos</p>
<p>Todo usuario que remita un mensaje, un documento o dé origen a una comunicación a través de KRIKOS 360, asume plena y exclusiva responsabilidad por la remisión de los mismos, sin perjuicio de la responsabilidad solidaria del Titular del Servicio. Mantendrá asimismo indemne a PLANEXWARE en todos los casos en que esta fuere requerida a partir de los contenidos suministrados por el usuario.</p>
<p class="subtitulo">6.5 Sanciones. Suspensiones o inhabilitaciones</p>
<p>Sin perjuicio de otras medidas que estime pertinentes, PLANEXWARE advertirá, suspenderá temporalmente o inhabilitará definitivamente la cuenta de un Usuario si,</p>
<ol type="a">
    <li>Se quebrantara alguna ley, los derechos de un tercero, el orden público, la moral y las buenas costumbres, o cualquiera de las estipulaciones de los Términos y Condiciones Generales o los términos y condiciones particulares de cada servicio, y demás disposiciones de PLANEXWARE.</li>
    <li>Si incumpliera sus compromisos como Usuario.</li>
    <li>Si se incurriera a criterio de PLANEXWARE en conductas o actos dolosos o fraudulentos. </li>
    <li>Desarrolle actividades que tengan por objeto vulnerar la seguridad, y/o el normal funcionamiento de los sistemas informáticos de PLANEXWARE o de terceros.</li>
</ol>
<h4>7. FACULTADES DE DIRECCIÓN Y CONTROL SOBRE LOS SERVICIOS</h4>
<p class="subtitulo">7.1 Facultades reservadas</p>
<p>PLANEXWARE se reserva todas las facultades de control y dirección sobre KRIKOS 360, en particular sobre los servicios, contenidos y comunicaciones existentes en el mismo. Podrá en consecuencia PLANEXWARE, introducir todos los cambios y modificaciones que estime convenientes a su solo criterio, podrá agregar, alterar, sustituir o suprimir cualquiera de los servicios en todo momento.</p>
<p>SIN PERJUICIO DE ESTAS FACULTADES RESERVADAS, EN RESPETO A LA PRIVACIDAD Y CONFIDENCIALIDAD DE LAS COMUNICACIONES DE LOS USUARIOS, PLANEXWARE NO EJERCERÁ NINGÚN CONTROL DE LEGALIDAD SOBRE LAS COMUNICACIONES LLEVADAS ADELANTE POR LOS USUARIOS. CONSECUENTEMENTE NO SERÁ RESPONSABLE POR EL USO CONTRARIO A DERECHO DE LOS SERVICIOS QUE EFECTUEN LOS USUARIOS.</p>
<p class="subtitulo">7.2 Autorización de intercepción, acceso y desvío de comunicaciones</p>
<p>EL Usuario confiere expresa autorización a PLANEXWARE, para permitirle monitorear, supervisar, intervenir, captar, interceptar, desviar, acceder, suspender o interrumpir sus comunicaciones electrónicas cursadas a través de KRIKOS 360 a los fines de brindar el servicio, o por cualquier otro tipo de circunstancia que en principio y con carácter preventivo sea identificada como peligrosa, potencialmente peligrosa o indeseada por parte de PLANEXWARE. El Usuario reconoce y acepta que la actividad de PLANEXWARE con relación a sus comunicaciones resulta consentida y necesaria para la prestación del servicio KRIKOS 360, y que tal actividad no resulta alcanzada por el art. 153 del Código Penal Argentino.</p>
<p>En igual sentido el Usuario autoriza a PLANEXWARE a desviar, suspender o interrumpir sus comunicaciones electrónicas cursadas a través de KRIKOS 360, a los fines de la realización de tareas de mantenimiento y/o actualización de sus sistemas informáticos.</p>
<h4>8. EXCLUSIÓN DE RESPONSABILIDAD POR LOS SERVICIOS Y CONTENIDOS</h4>
<p class="subtitulo">8.1 Responsabilidad de PLANEXWARE</p>
<p>Los servicios de KRIKOS 360 PLANEXWARE se brindan únicamente como una herramienta auxiliar. PLANEXWARE no asume responsabilidad por ninguna consecuencia que resulte directa o indirectamente de cualquier acción o falta de acción que el Usuario emprenda sobre la base de la información, los servicios u otros elementos resulten accesibles a través de KRIKOS 360.</p>
<p>El Usuario acepta específicamente que PLANEXWARE no será responsable por el acceso o la alteración de las transmisiones, datos y/o archivos, por cualquier material o datos enviados o recibidos, o no enviados o no recibidos.</p>
<p>PLANEXWARE no responderá por la pérdida de datos y/o archivos, cualesquiera que hayan sido las causas, personas o circunstancias que lo hayan provocado, salvo cuando ello derivare de su propia y exclusiva culpa o dolo.</p>
<p>PLANEXWARE no responderá en ningún caso de la utilidad, idoneidad, disponibilidad, exactitud, oportunidad, fiabilidad, pertinencia, actualidad, licitud o veracidad de los archivos transmitidos hacia y desde KRIKOS 360.</p>
<p>PLANEXWARE no será responsable por a) los daños que pudiera sufrir el Usuario originados en los riesgos propios de Internet, incluyendo a título de ejemplo, la interacción entre KRIKOS 360 y los equipos del Usuario, o la alteración por terceros de la información transmitida a través de KRIKOS 360 o alojada en los equipos informáticos del Usuario o de PLANEXWARE, b) la alteración, pérdida o sustracción de datos cursados por medio de las redes públicas de comunicaciones.</p>
<p>PLANEXWARE no responderá en el caso que el Usuario experimentare problemas de acceso a KRIKOS 360 derivados de la utilización de equipos o con programas informáticos obsoletos o mediante conexiones a Internet de calidad pobre o escaso ancho de banda.</p>
<p>PLANEXWARE excluye cualquier responsabilidad por los daños y perjuicios de toda naturaleza que puedan deberse a la falta de disponibilidad o de continuidad del funcionamiento del Producto, a la defraudación de las expectativas que los usuarios hubieren podido atribuir al Producto.</p>
<p>PLANEXWARE EXCLUYE, CON TODA LA EXTENSIÓN PERMITIDA POR EL ORDENAMIENTO JURÍDICO APLICABLE, CUALQUIER RESPONSABILIDAD POR LOS DAÑOS Y PERJUICIOS DE TODA NATURALEZA QUE PUEDAN DEBERSE A LA TRANSMISIÓN, DIFUSIÓN, ALMACENAMIENTO, PUESTA A DISPOSICIÓN, ENVÍO Y RECEPCIÓN, DE MENSAJES O DOCUMENTOS, ASÍ COMO CUALQUIER OTRO MATERIAL O INFORMACIÓN QUE SE ENVÍE HACIA O DESDE LA PLATAFORMA KRIKOS 360.</p>
<p>PLANEXWARE no responderá por cualquier circunstancia (tales como pérdidas o cualquier otra), ya sea del Usuario o de un tercero, que pueda derivarse del extravío, robo, o uso de cualquier clave de acceso o password del Usuario.</p>
<p>La puesta a disposición del software se considerará bajo la modalidad “as-is” (“en la condición en que se encuentra”) y sin garantía alguna, expresa o implícita, de ningún tipo sobre un resultado concreto.</p>
<p class="subtitulo">8.2 Confidencialidad de la información</p>
<p>PLANEXWARE tratará la información de los usuarios, bajo estrictas normas de confidencialidad en resguardo de su privacidad, en ningún caso accederá o utilizará la información de los usuarios salvo en aquellas cuestiones que haga estrictamente a la prestación de los servicios de KRIKOS 360 (conforme art. 3 Ley 24.766).</p>
<p class="subtitulo">8.3 Material titularidad de terceros</p>
<p>En uso de KRIKOS 360, el usuario puede tener acceso a documentos provistos por terceros. PLANEXWARE no extiende ningún tipo de garantía sobre la licitud, exactitud, fiabilidad y/o legitimidad del material provisto por terceros.</p>
<h4>9. POLÍTICA DE PRIVACIDAD</h4>
<p><a ng-href="#/politicasDePrivacidad/pt-BR">Ver Política de Privacidad</a></p>
<h4>10. INFORMACIÓN DE CONTACTO</h4>
<p>A los fines que los usuarios puedan tomar contacto con PLANEXWARE, se considerarán válidas las comunicaciones dirigidas a: <a href="mailto:info@planexware.com">info@planexware.com</a></p>
<p>Las notificaciones y comunicaciones cursadas por PLANEXWARE a la dirección postal o, a la casilla de correo electrónico consignada directamente por el usuario, en los formularios de registro o, aquella que surja como domicilio o dirección de correo del remitente en los casos que entable comunicación un visitante no registrado, se considerarán eficaces y plenamente válidas. Asimismo, se considerarán eficaces las comunicaciones que consistan en avisos y mensajes insertos en KRIKOS 360, o que se envíen durante la prestación de un servicio, que tengan por finalidad informar a los usuarios sobre determinada circunstancia.</p>
<h4>11. CAMBIOS Y MODIFICACIONES</h4>
<p>PLANEXWARE podrá introducir todos los cambios y modificaciones que estime convenientes a su solo criterio, sobre estos Términos y Condiciones, en cualquier momento y sin previo aviso, bastando la sola publicación en la página de acceso a KRIKOS 360 de los términos modificados. Todos los términos modificados entrarán en vigor al día siguiente al de su publicación. Dentro de los 3 (tres) días siguientes a la publicación de las modificaciones introducidas, el Usuario deberá comunicar por correo electrónico el rechazo de las mismas; en ese supuesto quedará disuelto el vínculo y será inhabilitado como Usuario. Vencido este plazo, se considerará que el Usuario acepta los nuevos términos.</p>
<h4>12. JURISDICCIÓN Y COMPETENCIA</h4>
<p>Este acuerdo estará regido en todos sus puntos por las leyes vigentes en la República Argentina. Cualquier controversia derivada del presente acuerdo, su existencia, validez, interpretación, alcance o cumplimiento, será sometida a la Justicia Nacional con jurisdicción en la Ciudad Autónoma de Buenos Aires.</p>
<br />
<div class="revision left">
    <span><strong>Código: POL/DES-8-008</strong></span>
    <br />
    <span>Revisión: 002</span>
    <br />
    <span>F.U.R: 08/02/2022</span>
    <br />
</div>
<br />
