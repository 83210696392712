import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-planex-logo',
  templateUrl: './planex-logo.component.html',
  styleUrls: ['./planex-logo.component.scss'],
})
export class PlanexLogoComponent implements OnInit {
  @Input() width?: string
  @Input() height?: string
  @Input() inline?: boolean

  constructor() { }

  ngOnInit(): void {
  }

}
