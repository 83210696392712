import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isNullOrEmptyOrUndefined } from 'src/app/shared/utils/utils.component';

@Pipe({
  name: 'formatoMoneda',
  pure: true
})

export class FormatoMoneda implements PipeTransform {

  
  constructor( private translate: TranslateService){
  }
  
  transform(input: number, maximumFractionDigits: number): string {
    const lang: string = this.translate.defaultLang;
    const formatter = new Intl.NumberFormat(lang,{
      style: 'currency',
      currency: 'ARS',
      minimumFractionDigits: 2,
      maximumFractionDigits: isNullOrEmptyOrUndefined(maximumFractionDigits) == true ? 2 : maximumFractionDigits
    })
    // @ts-ignore: Salta un error por la version desactualizada de TS
    const output: string = formatter.formatToParts(input).map(({type, value}) => {
      switch (type) {
        case 'currency': return '';
        default: return value;
      }
    }).reduce((string: string, part: string) => string + part);
    return output.trim();
  }
}