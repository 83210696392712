import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pyp-br',
  templateUrl: './pyp-br.component.html',
  styleUrls: ['./pyp-br.component.scss']
})
export class PypBRComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
