<h1 class="plx-t3" translate>Privacy Policy</h1>
<p>The purpose of this Data Privacy Policy  is to inform the parties concerned  about the scope of the comprehensive protection of all personal data entered in the files; registers; banks or databases; or in any further technical means for processing data implemented by PLANEXWARE S.A., domiciled at Alberti Nr. 2154, 7th floor, Autonomous City of Buenos Aires, Republic of Argentina (herein after PLANEXWARE),  ensuring compliance with the right of privacy of individuals, as well as free access to the  information which might eventually be registered regarding the individuals.</p>
<p>PLANEXWARE considers that any kind of information related to an individual is personal information, and therefor it protects privacy and confidential information with professional zeal. Privacy of information involves maintaining information in safe files and/or banks or databases, in such a way to prevent access by any non-authorized third party</p>
<p>For questions about this Policy or about any circumstance related to the processing of personal information, please contact any of the following channels:</p>
<p>E-mail:</p>
<p class="cuadroContacto"> <a href="mailto:info@planexware.com">info@planexware.com</a></p>
<p>Postal address:</p>
<p class="cuadroContacto">
    Alberti Nr. 2154, 7th floor<br />
    C1247AAT<br />
    Ciudad Autónoma de Buenos Aires<br />
    República Argentina<br />
</p>
<p>All notices and communications sent by PLANEXWARE to the postal address or to the e-mail address informed directly by the interested party, or to the address appearing as the sender's address shall be considered valid and effective. Likewise, all communications consisting of prompts and messages posted in PLANEXWARE website (www.planexware.com) or sent during the provision of a service with the purpose of informing the users  about any special circumstance, shall also be considered valid and effective</p>
<p>This Privacy Policy refers to the following issues:</p>
<ul>
    <li>Willingness to hand over data</li>
    <li>Sensitive Data	</li>
    <li>Financial Data</li>
    <li>Authorization to use personal information</li>
    <li>Collection and use of information</li>
    <li>What kind of information does PLANEXWARE collect</li>
    <li>Transfer of personal information</li>
    <li>Disclosure of information</li>
    <li>Physical location where the processing of user's personal data takes place</li>
    <li>Communications of the Website</li>
    <li>Rights of the owner of data</li>
    <li>Use of Appropriate Security Methods</li>
    <li>Compliance with Act 25326</li>
    <li>Changes to this Policy</li>
    <li>What happens with your personal information after you close your account with us?</li>
</ul>
<h4>Willingness to hand over data</h4>
<p>This Policy is aimed to inform the parties concerned about the processing of personal data by PLANEXWARE, with the purpose to get their free and voluntary decision to hand over or not any personal data required, or to allow their collection as from  the use of any of the services provided by PLANEXWARE.</p>
<p>As a general rule, whenever a person is required to provide personal information to use a service or to access certain content, handing over said information is not compulsory, except for those cases when it is specifically stated that the information required is necessary for the provision of the service.</p>
<h4>Sensitive Data</h4>
<p>PLANEXWARE does not collect sensitive data of any kind. In case that any of the required data would be qualified in the future as sensitive personal  information  by the National Direction for the Protection of Personal Data or by any other competent authority, no user will be obliged to hand that information over to get access to the services provided by PLANEXWARE.</p>
<h4>Financial Data</h4>
<p>PLANEXWARE does not collect financial data of any kind.</p>
<h4>Authorization to use personal information</h4>
<p>The client or user who hands over its personal data, does expressly authorize PLANEXWARE to use all the information provided for the purposes herein stated. The above mentioned also implies that the client or user accepts all the terms and conditions included in this Policy, and in the pertinent contracts for the provision of services. </p>
<h4>Collection and use of information </h4>
<p>Collection and processing of the user´s personal data aims to the provision, management, administration, customization, updating and improvement of the products and services provided by PLANEXWARE to the users.  It is also probable that the user´s personal information be used for any legal purpose, which might include answering requirements, processing transactions,  administrative purposes, without limitations thereto. </p>
<h4>What kind of information does PLANEXWARE collect</h4>
<p>The information collected  is the following:</p>
<ol>
    <li>User's name and password.</li>
    <li>It is possible that while using the services provided, PLANEXWARE receives non-personal information from the users, as for example "browsing references", IP directions, users' actions and other different environmental variables.</li>
</ol>
<br />
<ul>
    <li><strong>References:</strong><p>A "reference" is information transmitted by the Web browser to the servers of PLANEXWARE, and points out the URL from which the website is accessed. </p></li>
    <li><strong>IP Address</strong> <p>When accessing PLANEXWARE website, it is possible get  the IP (Internet Protocol) address and the type of browser used by the User . The IP address also allows to identify the geographical location of the computer or of the browsing device from which PLANEXWARE was accessed,  as well as the date and time of access. The browser may also collect information about the web sites previously visited by the user.</p></li>
    <li><strong>Environmental variables</strong><p>The "environmental variables" are, among others, the time of access, the kind of browser, the operative system or platform used, the internet address or the web site address from which the user is directed to PLANEXWARE.</p></li>
    <li><strong>User´s actions</strong><p>"Users' actions" refer to the information related to the use of the resources offered in the site. This information may refer to the documents consulted by the user, among others, as well as to their number and kinds, the services accessed by the user within the site, the search criteria used, and in general any other data describing the activity of the users within the site. All this information may be stored on an individual basis or be used to produce statistical information.</p></li>
    <li><strong>Cookies</strong><p>PLANEXWARE might eventually use cookies, which shall be installed in the user's computer while browsing the site. Cookies are used to improve navigation for the user in the site, and to provide information to  PLANEXWARE in order to develop more efficiently its services and contents. Under no circumstance the cookies used by PLANEXWARE shall provide personal information of the user, who will remain at all times anonymous while browsing the site, even for PLANEXWARE, since the cookies do not provide information to identify the user. It is the intention of PLANEXWARE to emphasize that in order to browse the site, it is not necessary for the user to allow the installation of the cookies sent by PLANEXWARE, since this might only be required in connection with certain services and/or contents.</p></li>
</ul>
<p>
    This information is only collected for the exclusive internal use by PLANEXWARE, for example, to improve the security of its service.
</p>
<h4>Transfer of data</h4>
<p>Any and all personal data collected from the Users, are for the internal and exclusive use by PLANEXWARE. Every time when personal information is collected as a result of a direct relation with a user, and as regards privacy and confidentiality of the users, PLANEXWARE shall not transfer or assign such personal information to any third party not being a party related to PLANEXWARE, or to its associates. Personal information of the users shall only be shared with third parties in the following cases:</p>
<ul>
    <li>If there is a legal obligation to do so. </li>
    <li>If there is an order issued by a competent Judge.</li>
</ul>
<h4>Disclosure of information</h4>
<p>PLANEXWARE may share some personal information with third parties on the following basis: </p>
<ul>
    <li>
        <strong>Associates</strong><p>
            Information -including personal information handed over by the users- might be shared with third parties associated to PLANEXWARE,  as for example business associates. PLANEXWARE shall ensure that all its associates comply with the obligations and duties  imposed by the Personal Data Protection Law, including the implementation of appropriate security systems, and also that they enter into and fulfill -when applicable- all the principles held by PLANEXWARE as regards protection and privacy of personal data.
        </p>
    </li>
    <li>
        <strong>Third party data processing service providers</strong><p>
            To render its services available, PLANEXWARE may probably engage the provision of certain services to third parties. The provision of these kind of services might include, without limitation, automated processing of data, storage of information, services related to forwarding electronic communications, processing administrative transactions. In order to provide their services correctly, the third parties engaged might possibly require access to PLANEXWARE databases. However, PLANEXWARE shall not assign to these third parties any personal information of any nature whatsoever, and the access by those providers to PLANEXWARE databases is strictly limited to the activities necessary for the provision of the service involved, not being allowed to get any data or to make total or partial copies of the databases belonging to PLANEXWARE. PLANEXWARE shall ensure that all its providers comply with the obligations and duties  imposed by the Personal Data Protection Law, including the implementation of appropriate security systems, and also that they enter into and fulfill -when applicable- all the principles held by PLANEXWARE as regards protection and privacy of personal data.
        </p>
    </li>
</ul>
<h4>Physical location where the processing of users personal data takes place</h4>
<p>All the information collected in relation with the services provided by PLANEXWARE may be processed and stored within the territory of the Republic of Argentina or abroad. USERS NON-RESIDENT IN THE REPUBLIC OF ARGENTINA EXPRESSLY AGREE TO THE EVENTUAL TRANSFER OF PERSONAL INFORMATION TO THE REPUBLIC OF ARGENTINA. LIKEWISE, ALL RESIDENT OR NON-RESIDENT USERS EXPRESSLY AGREE TO THE MAINTENANCE OF THEIR PERSONAL INFORMATION IN ANY MEANS LOCATED OUT OF THE REPUBLIC OF ARGENTINA. This agreement shall be deemed a  tacit consent when the user has already used any of the services granted by PLANEXWARE. In any event, PLANEXWARE guarantees that the information shall only be processed  in a jurisdiction which might ensure an appropriate legal protection to the owners of the data involved.</p>
<h4>Communications by PLANEXWARE</h4>
<p>Ocassionaly, user´s data might be used to send communications to the users regarding the services provided by PLANEXWARE. In case a user prefers not to receive any information by PLANEXWARE, the user may: </p>
<ul>
    <li>Contact PLANEXWARE at the contact data herein provided for, and inform he/she does not want to receive any information about products and services.</li>
    <li>Follow the steps included in the e-mails sent by PLANEXWARE to that end.</li>
</ul>
<p>Finally, messages shall be sent to the users informing the status or changes entered in connection with the services, whenever necessary. For example, information about changes in services, new functions or updates.</p>
<h4>Rights of the owner of data</h4>
<p>
    Res 10/08, DNPDP, section 1: "The owner of personal data  may exercise its right to request and  obtain information on its personal data in intervals no shorter than six months free of charge, unless  a legitimate interest  to do otherwise is shown,  according to the provisions of section 14 subsection 3 of Act Nr. 25326". Likewise, and as a consequence of the guarantee regarding the protected rights, it is also provided that access is allowed at all times to any person  related to the registered data. In this way, the interested party may exercise its rights to access, rectify, block and remove, herein above mentioned.
</p>
<p>
    <strong>The right to access data:</strong> is the right to request and to obtain information on the personal data included in databases.<br />
    <strong>The right to request the rectification of data:</strong> in principle, the right to request rectification may be exercised upon the falsehood, inaccuracy, ambiguity, ineffectiveness or error in the data. The recognition of the above mentioned implies recognition to the preservation of  the truthfulness of the information, a statuts closely related to its quality.<br />
    <strong>The right to block: </strong>is the right to request the removal of personal data from a database intended for advertising purposes. <br />
    <strong>The right of removal: </strong> "Removal" of a datum implies its final deletion from the file or register, that means its complete and definite elimination.<br />
</p>
<p>Each user may exercise these rights by informing PLANEXWARE, according to the  contact information herein included. The request to access, rectify, update or remove personal data must include and mention the following information:</p>
<ul>
    <li>Name of the owner, e-mail address and domicile to receive the answer to its request;</li>
    <li>Documents proving identity of the owner, or legal representation if the case may be;</li>
    <li>A clear and accurate description of the personal data on which any of the above mentioned rights is intended to be exercised, and</li>
    <li>Any further element or document which may facilitate the localization of the personal data involved.</li>
    <li>In case of a request to rectify personal data, the owner must clearly state, not only the above mentioned, buy also the amendments to be entered and enclose the documents supporting its request.</li>
</ul>
<p>Once a request is submitted by the owner of the date, PLANEXWARE shall issue an acknowledgment of receipt and proceed accordingly:</p>
<p>Request to  <strong>access </strong>personal data already stored: data stored shall be informed within a maximal term of 10 calendar days as of reception of the request by the data's owner. </p>
<p>Request to <strong>rectify</strong>, or <strong>remove </strong>personal data already stored: the necessary steps to comply with the request shall be taken within 5 working days as of reception of the request submitted by the data's owner.</p>
<h4>Use of Appropriate Security Methods </h4>
<p>For PLANEXWARE security is relevant. PLANEXWARE takes all the logical and physical security measures required by the rules in force, including those arising from due diligence and prudence, for the protection of the Users, who place their confidence in PLANEXWARE to protect the personal information collected against unauthorized access, alteration or destruction. PLANEXWARE  evaluates and improves its security systems whenever necessary.</p>
<h4>Compliance with Act 25326 </h4>
<p>
    In full compliance with the obligations imposed for by Act Nr. 25326 on the Protection of Personal Data and supplementary rules, PLANEXWARE has duly registered its databases with personal information with the register managed by the  National Direction for the Protection of Personal Data under the Ministry of Justice and Human Rights, Executive Branch, Republic of Argentina. Those Users requiring more information on the  Personal Data Protection Legislation, in force in the Republic of Argentina may refer to: <br />
    Dirección Nacional de Protección de Datos Personales.<br />
    Sarmiento  Nr. 1118, 5th floor, Autonomous City of  Buenos Aires, Republic of Argentina<br />
    Telephone: +5411 5300-4000<br />
    http://www.jus.gob.ar/datos-personales/
</p>
<p>The NATIONAL DIRECTION FOR THE PROTECTION OF PERSONAL DATA, the control body of Act Nr. 25326, has the power to receive compliances and claims filed in relation to the non-fulfillment of the rules governing the protection of personal data.</p>
<h4>Changes to this Policy </h4>
<p>In case of any amendment to this Policy, we shall publish the changes in this section, the main page of the Site, and in any other places deemed properly to notify the interested parties about such amendments. PLANEXWARE  reserves the right to amend this Policy at any time, and to that end all the interested parties shall take note of the changes by regularly checking this section.</p>
<h4>What happens with your Personal Information after you close your account with us?</h4>
<p>In case a client or user decides to close its account with PLANEXWARE, our Privacy Policy shall be effective in relation to such client or users. All the personal information shall appear as not available; however, the client or user must know that this does not imply the removal of its information. The client´s or user´s information shall still be stored in PLANEXWARE's systems as unavailable, and can only be used for the resolution of any legal dispute or controversy which may arise and up to the expiration of the term  applicable by  the statute of limitation on contractual obligations. The confidentiality obligation shall be in force indefinitely as regards the information which might constitute personal data according to the provisions of Act Nr. 25326, provided that such data is kept by PLANEXWARE.</p>
<br />
<div class="revision left">
    <span><strong>Code: POL/DES-8-004</strong></span>
    <br />
    <span>Revision: 002</span>
    <br />
    <span>F.U.R: 08/02/2022</span>
    <br />
</div>
<br />
