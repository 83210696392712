<div class="vertical-menu">
  <div class="menu-entrie" *ngFor="let item of menu">
    <div
      class="menu_item"
      *ngIf="this.canAccessToMenuPoint(item)"
      class="vertical-menu__item"
      [ngClass]="{
        'vertical-menu__item--disabled':
        this.itemDisabledAndConectedAsClient(item)
      }"
      [routerLink]="
          this.itemEnabledAndConectedAsSupport(item)
          ? ['/' + item.url]
          : []
      "
    >
      <div class="item__icon">
        <span
          *ngIf="getIcon(item.resourceKey)"
          class="material-symbols-outlined"
          [ngClass]="{
            disabled: this.itemDisabledAndConectedAsClient(item),
            enabled: this.enableItemOrBackOffice(item)
          }"
        >
          {{ getIcon(item.resourceKey) }}
        </span>
      </div>

      <div
        class="item__content"
        id="item.id"
          [ngClass]="{
            disabled: this.itemDisabledAndConectedAsClient(item),
            enabled: this.enableItemOrBackOffice(item)
          }"
      >
        <div class="title-wrapper">
          <h3>{{ item.resourceKey | translate }}</h3>
          <span
            *ngIf="this.itemDisabledAndConectedAsSupport(item)"
            class="material-symbols-outlined"
            ngbPopover="{{ 'MSG_ITEMS_MENU_POPOVER' | translate }}"
            placement="right"
            triggers="mouseenter:mouseleave"
          >
            {{ "person_off" }}
          </span>
        </div>
        <div
          class="item__content__description"
          [ngClass]="{
            'disabled': this.itemDisabledAndConectedAsClient(item),
            'enabled': this.enableItemOrBackOffice(item)
          }"
        >
          {{ item.resourceKey + "_DESC" | translate }}
        </div>
      </div>
      <div class="disabled-message" *ngIf="this.itemDisabledAndConectedAsClient(item)">
        <span>
          {{
            "EMPRESA_NO_TIENE_SERVICIO" | translate : { numero: this.numero }
          }}
        </span>
      </div>
    </div>
  </div>
</div>
