export let icons: any = {
    "ABMClientes_ICON": "business_center",
    "ABMMensajCli_ICON": "campaign",
    "ABMMensaje_ICON": "drafts",
    "ABMUsuariosBO_ICON": "groups",
    "ABMVisualizadores_ICON": "newspaper",
    "ABM_COMP_ICON": "account_tree",
    "ABM_PROC_ICON": "share",
    "ABM_SERVICIOS_ICON": "manufacturing",
    "ABM_SERV_ICON": "",
    "ABM_US_ICON": "groups",
    "AdmApps_ICON": "apps",
    "BACKOFFICE_ICON": "",
    "CONEXIONESBO_ICON": "link",
    "CONEXIONES_ICON": "",
    "CONFIGURACIONES_ICON": "",
    "CONFIG_DOCS_ICON": "file_copy",
    "CONFIG_SEG_ICON": "lock",
    "ClientesMixtos_ICON": "groups_3",
    "ConfigDocE_ICON": "manufacturing",
    "ConfigDocR_ICON": "manufacturing",
    "ConfigNotif_ICON": "notifications",
    "ConvUnidDest_ICON": "arrow_back_ios",
    "ConvUnidOrig_ICON": "arrow_forward_ios",
    "ConvUnid_ICON": "repeat",
    "CrearFactura_ICON": "",
    "DATOS_EMP_ICON": "domain",
    "DOCUMENTOSBO_ICON": "file_copy",
    "DOCUMENTOS_ICON": "",
    "DatosExtra_ICON": "add_notes",
    "Delegaciones_ICON": "business_center",
    "DomiciliosRecepAfip_ICON": "domain",
    "EVENTOS_ICON": "bolt",
    "EquivalenciasArt_ICON": "barcode", 
    "EquivalenciasCli_ICON": "sync_alt",
    "GrillaFCred_ICON": "file_copy",
    "ImpresionOD_ICON": "print",
    "ListaPre_ICON": "list",
    "MAPAS_ICON": "code",
    "MaestroArt_ICON": "list_alt",
    "OPERACIONES_ESP_ICON": "",
    "OperationCompanyM_ICON": "description",
    "PistasAudit_ICON": "person_search",
    "RecordVencimien_ICON": "calendar_today",
    "Representaciones_ICON": "business_center",
    "ResumenTrafDocsEmp_ICON": "monitoring",
    "SOPORTEAPOYO_ICON": "visibility",
    "SeguimientoFCred_ICON": "speed",
    "SubidaArchivos_ICON": "upload",
    "TraficoBO_ICON": "quick_reference_all",
    "VALIDADOC_ICON": "task",
    "VISTAS_ICON": "grid_on",
    "VerTrazaProc_ICON": "menu",
    "trafico_ICON": "query_stats",
    "ABMTemplates_ICON": "picture_as_pdf",
    "ABMConfigForm_ICON": "manufacturing",
    "GestionSolicNCredito_ICON": "sync_alt",
    "CatalogoArticPrecios_ICON": "price_check",
    "AltaMail_ICON": "alternate_email",
}