import { MainK360Component } from './pages/main-k360/main-k360.component';
import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

import { RoleGuardService as RoleGuard } from './core/guards/role-guard.service'
import { VerticalMenuComponent } from './shared/vertical-menu/vertical-menu.component'
import { HomePageComponent } from './pages/home-page/home-page.component';
import { LoginComponent } from './pages/login/login.component'
import { FAQComponent } from './pages/faq/faq.component';
import { NovedadesComponent } from './pages/novedades/novedades.component';
import { ModificarContrasenaComponent } from './pages/modificar-contrasena/modificar-contrasena.component';
import { PoliticasDePrivacidadComponent } from './pages/politicas-de-privacidad/politicas-de-privacidad.component';
import { TerminosYCondicionesComponent } from './pages/terminos-ycondiciones/terminos-ycondiciones.component';
import { MensajeCriticoComponent } from './pages/mensaje-critico/mensaje-critico.component';
import { LinkVencidoComponent } from './pages/link-vencido/link-vencido.component';

const routes: Routes = [

  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, },
  { path: 'contrasena/crear', component: LoginComponent },
  { path: 'terminosYCondiciones', component: TerminosYCondicionesComponent },
  { path: 'politicasDePrivacidad', component: PoliticasDePrivacidadComponent },
  {
    path: 'main/Home/index', redirectTo: 'main/home',
  },
  {
    path: 'main/terminosYCondiciones',
    component: TerminosYCondicionesComponent,
    children: [
      {
        path: 'es-AR',
        redirectTo: '/terminosYCondiciones',
      },
      {
        path: 'en-US',
        redirectTo: '/terminosYCondiciones',
      },
      {
        path: 'pt-BR',
        redirectTo: '/terminosYCondiciones',
      }
    ]
  },
  {
    path: 'main/politicasDePrivacidad',
    component: PoliticasDePrivacidadComponent,
    children: [
      {
        path: 'es-AR',
        redirectTo: '/politicasDePrivacidad',
      },
      {
        path: 'en-US',
        redirectTo: '/politicasDePrivacidad',
      },
      {
        path: 'pt-BR',
        redirectTo: '/politicasDePrivacidad',
      }
    ]
  },
  {
    path: 'main', component: MainK360Component,
    children: [
      {
        path: 'home',
        component: HomePageComponent,
        canActivate: [RoleGuard],
        data: { requiredRole: '*' },
      },
      {
        path: 'ayuda',
        component: FAQComponent,
        canActivate: [RoleGuard], data: { requiredRole: '*' },
      },
      {
        path: 'novedades',
        component: NovedadesComponent,
        canActivate: [RoleGuard], data: { requiredRole: '*' },
      },
      {
        path: 'contrasena/cambiar',
        component: ModificarContrasenaComponent,
        canActivate: [RoleGuard],
        data: { requiredRole: 'Seguridad/ModificarContrasena' }
      },
      {
        path: 'configuraciones/:menuId/datosBasicos',
        loadChildren: () => import('./pages/datos-generales-empresa/datos-generales-empresa.module').then(m => m.DatosGeneralesEmpresaModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'configuraciones/datosBasicos' }
      },
      {
        path: 'ConfiguracionProcesosEspeciales/:menuId/ConfiguracionDatosExtraDocumentoSalida',
        loadChildren: () => import('./pages/datos-extra-en-documentos-salida/datos-extra-en-documentos-salida.module').then(m => m.DatosExtraEnDocumentosSalidaModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'ConfiguracionProcesosEspeciales/ConfiguracionDatosExtraDocumentoSalida' }
      },
      {
        path: 'documentos/:menuId',
        loadChildren: () => import('./pages/documentos-clientes/documentos-clientes.module').then(m => m.DocumentosClientesModule),
        canActivate: [RoleGuard],
        data: { requiredRole: '/documentos' }
      },
      {
        path: 'configuraciones/:menuId/servicios',
        loadChildren: () => import('./pages/configuracion-servicios/configuracion-servicios.module').then(m => m.ConfiguracionServiciosModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'configuraciones/servicios' }
      },
      {
        path: 'conexiones/:menuId',
        loadChildren: () => import('./pages/conexiones/conexiones.module').then(m => m.ConexionesModule),
        canActivate: [RoleGuard],
        data: { requiredRole: '/conexiones' }
      },
      {
        path: 'conexiones/:menuId',
        loadChildren: () => import('./pages/conexiones/conexiones.module').then(m => m.ConexionesModule),
        canActivate: [RoleGuard],
        data: { requiredRole: '/conexiones' }
      },
      {
        path: 'configuraciones/:menuId/componentes',
        loadChildren: () => import('./pages/estructura-empresa/estructura-empresa.module').then(m => m.EstructuraEmpresaModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'configuraciones/datosBasicos' }
      },
      {
        path: 'configuraciones/:menuId/SubirArchivo',
        loadChildren: () => import('./pages/subir-documento/subir-documento.module').then(m => m.SubirDocumentoModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'configuraciones/SubirArchivo' }
      },
      {
        path: 'ConfiguracionProcesosEspeciales/:menuId/ConfiguracionEquivalenciaArticulos',
        loadChildren: () => import('./pages/configuracion-equivalencias-am/configuracion-equivalencias-am.module').then(m => m.ConfiguracionEquivalenciasAmModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'ConfiguracionProcesosEspeciales/ConfiguracionEquivalenciaArticulos' }
      },
      {
        path: 'ConfiguracionProcesosEspeciales/:menuId/ConfiguracionEquivalenciaClientes',
        loadChildren: () => import('./pages/equivalencia-estructuras/equivalencia-estructuras.module').then(m => m.EquivalenciaEstructurasModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'ConfiguracionProcesosEspeciales/ConfiguracionEquivalenciaClientes' }
      },
      { 
        path: 'ConfiguracionProcesosEspeciales/:menuId/ListaPrecios',
        loadChildren: () => import('./pages/lista-de-precios/lista-de-precios.module').then(m => m.ListaDePreciosModule), 
        canActivate: [RoleGuard],
        data: { requiredRole: '*' }
      },
      { 
        path: 'ConfiguracionProcesosEspeciales/:menuId/CatalogoArticulosPrecios',
        loadChildren: () => import('./pages/catalogo-articulos-precios/catalogo-articulos-precios.module').then(m => m.CatalogoArticulosPreciosModule), 
        canActivate: [RoleGuard],
        data: { requiredRole: '*' }
      },
      {
        path: 'configuraciones/:menuId/seguridad',
        loadChildren: () => import('./pages/configuracion-seguridad/configuracion-seguridad.module').then(m => m.ConfiguracionSeguridadModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'configuraciones/seguridad' }
      },
      {
        path: 'backoffice/:menuId/AltaMail',
        loadChildren: () => import('./pages/alta-documento-mail/alta-documento-mail.module').then(m => m.AltaDocumentoMailModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'backoffice/AltaMail' }
      },
      {
        path: 'ConfiguracionProcesosEspeciales/:menuId/MaestroArticulos',
        loadChildren: () => import('./pages/maestro-articulos/maestro-articulos.module').then(m => m.MaestroArticulosModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'ConfiguracionProcesosEspeciales/MaestroArticulos' }
      },
      {
        path: 'ConfiguracionProcesosEspeciales/:menuId/ClientesMixtos',
        loadChildren: () => import('./pages/clientes-mixtos/clientes-mixtos.module').then(m => m.ClientesMixtosModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'ConfiguracionProcesosEspeciales/ClientesMixtos' }
      },
      {
        path: 'ConversionUnidades/:menuId/Destino',
        loadChildren: () => import('./pages/conversion-unidades/conversion-destino/conversion-destino.module').then(m => m.ConversionDestinoModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'ConversionUnidades/Destino' }
      },
      {
        path: 'ConfiguracionProcesosEspeciales/ConversionUnidades/Destino',
        loadChildren: () => import('./pages/conversion-unidades/conversion-destino/conversion-destino.module').then(m => m.ConversionDestinoModule),
        canActivate: [RoleGuard],
        data: { requiredRole: '*' },
      },

      {
        path: 'ConversionUnidades/:menuId/Origen',
        loadChildren: () => import('./pages/conversion-unidades/conversion-origen/conversion-origen.module').then(m => m.ConversionOrigenModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'ConversionUnidades/Origen' }
      },
      {
        path: 'ConfiguracionProcesosEspeciales/:menuId/OperationCompany',
        loadChildren: () => import('./pages/operation-company/operation-company.module').then(m => m.OperationCompanyModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'ConfiguracionProcesosEspeciales/OperationCompany' }
      },
      {
        path: 'ExampleModuleRoute', loadChildren: () => import('./pages/example-module/example-module.module').then(m => m.ExampleModuleModule),
        canActivate: [RoleGuard],
        data: { requiredRole: '*' }
      },
      //Biblioteca de euge frula
      //{ path: 'indice-ui', loadChildren: () => import('./pages/indice-ui/indice-ui.module').then(m => m.IndiceUiModule) },
      {
        path: 'configuraciones/:menuId',
        component: VerticalMenuComponent,
        canActivate: [RoleGuard],
        data: { requiredRole: '/configuraciones' }
      },
      {
        path: 'operacionesEspeciales/:menuId',
        component: VerticalMenuComponent,
        canActivate: [RoleGuard],
        data: { requiredRole: '/operacionesEspeciales' }
      },
      {
        path: 'backoffice/:menuId/eventos',
        loadChildren: () => import('./pages/eventos-grilla/eventos-grilla.module').then(x => x.EventosGrillaModule),
        canActivate: [RoleGuard],
        data: { requiredRole: '/backoffice' },
      },
      {
        path: 'backoffice/:menuId',
        component: VerticalMenuComponent,
        canActivate: [RoleGuard],
        data: { requiredRole: '/backoffice' },
      },
      {
        path: 'backoffice/:menuId/configuracion-plantillas',
        loadChildren: () => import('./pages/configuracion-templates/configuracion-templates.module').then(m => m.ConfiguracionTemplatesModule),
        canActivate: [RoleGuard],
        data: { requiredRole: '/backoffice' }
      },
      {
        path: 'backoffice/:menuId/Usuarios',
        loadChildren: () => import('./pages/usuarios-bo/usuarios-bo.module').then(m => m.UsuariosBoModule),
        canActivate: [RoleGuard],
        data: { requiredRole: '/backoffice' }
      }, 
      {
        path: 'backoffice/:menuId/configuracion-formulario-carga-manual',
        loadChildren: () => import('./pages/configuracion-formulario-carga-manual/configuracion-formulario-carga-manual.module').then(m => m.ConfiguracionFormularioCargaManualModule),
        canActivate: [RoleGuard],
        data: { requiredRole: '/backoffice' }
      },
      {
        path: 'ConfiguracionProcesosEspeciales/:menuId',
        component: VerticalMenuComponent,
        canActivate: [RoleGuard],
        data: { requiredRole: '/ConfiguracionProcesosEspeciales' }
      },
      {
        path: 'ConfiguracionDocumentos/:menuId',
        component: VerticalMenuComponent,
        canActivate: [RoleGuard],
        data: { requiredRole: '/ConfiguracionDocumentos' }
      },
      {
        path: 'ConversionUnidades/:menuId',
        component: VerticalMenuComponent,
        canActivate: [RoleGuard],
        data: { requiredRole: '/ConversionUnidades' }
      },
      {
        path: 'crearOrdenCompra/:menuId', loadChildren: () => import('./pages/carga-manual-oc/carga-manual-oc.module').then(m => m.CargaManualOcModule),
        canActivate: [RoleGuard],
        data: { requiredRole: '*' }
      },
      {
        path: 'ConfiguracionDocumentos/:menuId',
        component: VerticalMenuComponent,
        canActivate: [RoleGuard],
        data: { requiredRole: '/ConfiguracionDocumentos' }
      },
      {
        path: 'crear/:tipoDoc/:menuId', loadChildren: () => import('./pages/carga-manual/carga-manual-redirect.module').then(m => m.CargaManualRedirectModule),
        canActivate: [RoleGuard],
        data: { requiredRole: '*' }
      },
      {
        path: 'crear/:tipoDoc/:menuId/argentina', loadChildren: () => import('./pages/carga-manual/paises/argentina/carga-manual.module').then(m => m.CargaManualModule),
        canActivate: [RoleGuard],
        data: { requiredRole: '*' }
      },
      {
        path: 'crear/:tipoDoc/:menuId/mexico', loadChildren: () => import('./pages/carga-manual/paises/mexico/carga-manual-mexico.module').then(m => m.CargaManualMexicoModule),
        canActivate: [RoleGuard],
        data: { requiredRole: '*' }
      },
      {
        path: 'operacionesEspeciales/:menuId/AsociadorSncyNcComponent',
        loadChildren: () => import('./pages/asociador-sncy-nc/asociador-sncy-nc.module').then(m => m.AsociadorSncyNcModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'operacionesEspeciales/AsociadorSncyNcComponent' }
      },
      { path: 'operacionesEspeciales/:menuId/SeguimientoFacturaCredito',
      loadChildren: () => import('./pages/seguimiento-factura-credito/seguimiento-factura-credito.module').then(m => m.SeguimientoFacturaCreditoModule),
      canActivate: [RoleGuard],
      data: { requiredRole: 'operacionesEspeciales/SeguimientoFacturaCredito' }
      },
      { path: 'operacionesEspeciales/:menuId/ConsultaFacturaCreditoMiPyme',
      loadChildren: () => import('./pages/consulta-facturas-credito/consulta-facturas-credito.module').then(m => m.ConsultaFacturaCreditoModule),
      canActivate: [RoleGuard],
      data: { requiredRole: 'operacionesEspeciales/ConsultaFacturaCreditoMiPyme' }
      },
      {
        path: 'configuraciones/:menuId/admUsuariosCorporativos',
        loadChildren: () => import('./pages/usuarios-de-la-empresa/usuarios-de-la-empresa.module').then(m => m.UsuariosDeLaEmpresaModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'configuraciones/admUsuariosCorporativos' }
      },
      {
        path: 'configuraciones/:menuId/ConfiguracionNotificaciones',
        loadChildren: () => import('./pages/configuracion-notificacion/configuracion-notificacion.module').then(m => m.ConfiguracionNotificacionModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'configuraciones/ConfiguracionNotificaciones' }
      },
      {
        path: 'configuraciones/:menuId/Trafico',
        loadChildren: () => import('./pages/trafico-de-documentos/trafico-de-documentos.module').then(m => m.TraficoDeDocumentosModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'configuraciones/Trafico' }
      },
      {
        path: 'operacionesEspeciales/:menuId/ImpresionOutboundDelivery',
        loadChildren: () => import('./pages/outbound-delivery/outbound-delivery.module').then(m => m.OutboundDeliveryModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'operacionesEspeciales/ImpresionOutboundDelivery' }
      },
      {
        path: 'configuraciones/:menuId/vistas',
        loadChildren: () => import('./pages/vistas/vistas.module').then(m => m.VistasModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'configuraciones/vistas' }
      },
      {
        path: 'configuraciones/:menuId/Representaciones',
        loadChildren: () => import('./pages/configuracion-representados/configuracion-representados.module').then(m => m.ConfiguracionRepresentadosModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'configuraciones/Representaciones' }
      },
      {
        path: 'configuraciones/:menuId/Recordatorios',
        loadChildren: () => import('./pages/recordatorios-por-vencimiento/recordatorios-por-vencimiento.module').then(m => m.RecordatoriosPorVencimientoModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'configuraciones/Recordatorios' }
      },
      {
        path: 'configuraciones/:menuId/Representantes',
        loadChildren: () => import('./pages/configuracion-representantes/configuracion-representantes.module').then(m => m.ConfiguracionRepresentantesModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'configuraciones/Representantes' }
      },
      {
        path: 'ConfiguracionDocumentos/:menuId/ConfiguracionEmision',
        loadChildren: () => import('./pages/configuracion-documentos/configuracion-emision-documentos/configuracion-emision-documentos.module').then(m => m.ConfiguracionEmisionDocumentosModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'ConfiguracionDocumentos/ConfiguracionEmision' }
      },
      {
        path: 'ConfiguracionDocumentos/:menuId/validadorDeDocumentos',
        loadChildren: () => import('./pages/configuracion-documentos/configuracion-validador-documentos/configuracion-validador-documentos.module').then(m => m.ConfiguracionValidadorDocumentosModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'ConfiguracionDocumentos/validadorDeDocumentos' }
      },
      { 
        path: 'ConfiguracionDocumentos/:menuId/ConfiguracionRecepcion', 
        loadChildren: () => import('./pages/configuracion-documentos/configuracion-descarga-documentos/configuracion-descarga-documentos.module').then(m => m.ConfiguracionDescargaDocumentosModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'ConfiguracionDocumentos/ConfiguracionRecepcion' }
      },
      { 
        path: 'backoffice/:idMenu/documentosBO', 
        loadChildren: () => import('./pages/documentos-backoffice/documentos-backoffice.module').then(m => m.DocumentosBackofficeModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'backoffice/documentosBO' }
      },
      {
        path: 'backoffice/:menuId/TraficoBO',
        loadChildren: () => import('./pages/trafico-de-documentos-bo/trafico-de-documentos-bo.module').then(m => m.TraficoDeDocumentosBoModule),
        canActivate: [RoleGuard],
        data: { requiredRole: '/backoffice' }
      }, 
      {
        path: 'backoffice/:menuId/pistaAuditoria',
        loadChildren : () => import('./pages/pista-auditoria/pistas-auditoria.module').then(m => m.PistasAuditoriaModule),
        canActivate: [RoleGuard],
        data: { requiredRole: '/backoffice' }
      },
      {
        path: 'backoffice/:menuId/Usuarios',
        loadChildren: () => import('./pages/usuarios-bo/usuarios-bo.module').then(m => m.UsuariosBoModule),
        canActivate: [RoleGuard],
        data: { requiredRole: '/backoffice' }
      }, 
      {
        path: 'backoffice/:menuId/conectar',
        loadChildren: () => import('./pages/soporte/soporte.module').then(m => m.SoporteModule),
        canActivate: [RoleGuard],
        data: { requiredRole: '/backoffice' }
      }, 
      {
        path: 'backoffice/:menuId/MensajesaClientes',
        loadChildren: () => import('./pages/mensajes-a-clientes/mensajes-a-clientes.module').then(m => m.MensajesAClientesModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'backoffice/MensajesaClientes' }
      },
      {
        path: 'backoffice/:menuId/mapas',
        loadChildren: () => import('./pages/mapas/mapas.module').then(m => m.MapasModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'backoffice/mapas' }
      },
      {
        path: 'backoffice/:menuId/visualizadores',
        loadChildren: () => import('./pages/visualizadores/visualizadores.module').then(m => m.VisualizadoresModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'backoffice/visualizadores' }
      },
      {
        path: 'backoffice/:menuId/conexiones',
        loadChildren: () => import('./pages/conexiones-bo/conexiones-bo.module').then(m => m.ConexionesBoModule),
        canActivate: [RoleGuard],
        data: { requiredRole: 'backoffice/conexiones' }
      }, 
      {
        path: 'renovacion/datosBasicos',
        loadChildren: () => import('./pages/renovacion-asistida/renovacion-asistida.module').then(m => m.RenovacionAsistidaModule),
        canActivate: [RoleGuard],
        data: { requiredRole: '*' }
      }, 
    ]
  },
  { path: 'mensajesCriticos', component: MensajeCriticoComponent },
  { path: 'linkVencido', component: LinkVencidoComponent },

  { path: '**', component: MainK360Component, canActivate: [RoleGuard], data: { requiredRole: '*' }, pathMatch: 'full' },
  //{ path: '**', redirectTo: '/main', pathMatch: 'full' }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
